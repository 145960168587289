import Grid from "@material-ui/core/Grid";
import { compare, getFilteredOffers } from "./flightServices";
import ANIMATIONS from "constants/animations.js";
import bg2 from "../../assets/img/background-three.jpg";

import Warnings from "./Warnings";
import GridItem from "components/Grid/GridItem.js";
import "react-pro-sidebar/dist/css/styles.css";
import dateformat from "dateformat";
import { max } from "moment";
import { Component } from "react";
import { connect } from "react-redux";
import store from "store";
import { getOffers } from "../../redux/actions/offerActions";
import Filters from "./Filters.js";
import Home from "./Home.js";
import Navbar from "./NavBar";
import HomeTitle from "./HomeTitle";
import RouteWays from "./RouteWays";
import AutoCompleteFlights from "./AutoCompleteFields";
import Spinners from "./Spinners";
import Sorting from "./Sorting";
import SearchEntries from "./SearchEntries";
import MultiCityOption from "./MultiCityOption";
import { getOfferRequest } from "views/Services/clientServices";
import delay from "views/Services/clientServices";
import Offers from "./OffersDetails";
export class Form extends Component {
  constructor(props) {
    super(props);
    this.state = {
      class: "economy",
      route: "Two Way",
      adults: 1,
      modal: true,
      slices: [
        {
          origin: "",
          destination: "",
          departure_date: dateformat(new Date(), "isoDate"),
        },
      ],

      children: [],
      airportSearch: "",
      origin: "",
      destination: "",
      offers: this.props.offers,
      filteredOffers: this.props.filtered,
      airlines: [],
      maxPrice: 0,
      minDuration: 0,
      green: false,
      MaxDuration: 0,
      spinner: false,
      warning: false,
      filtersWarning: false,
      page: 1,

      discount: "",
    };
  }
  toggle = () => {
    this.setState({ modal: !this.state.modal });
  };

  componentWillMount = async () => {
    this.setState({
      offers: this.props.offers,
      filteredOffers: this.props.filtered,
      airlines: [],
      maxPrice: 0,
      minDuration: 9999,
      maxDuration: 0,
    });
  };
  
   reverseOriginAndDestination () {
    const { origin, destination } = this.state;
    alert('here');
  };
  onSubmit = async (event) => {
    scroll(0, 500);
    this.setState({
      spinner: true,
      warning: false,
    });
    try {
      let offers = await getOfferRequest(this.state);
      if (offers.cheapestFlights.length == 0) {
        this.setState({ warning: true });
      }
      store.dispatch({
        type: "FILTERED_OFFERS",
        payload: offers.cheapestFlights,
      });

      this.setState({
        cheapestFlights: offers.cheapestFlights,
        airline: [],
        offers: offers.cheapestFlights,
        filteredOffers: offers.cheapestFlights,
        spinner: false,
        chatbot: this.state.chatbotSearch,
      });
    } catch (e) {
      console.log(e);
      this.setState({ warning: true, spinner: false });
      scroll(0, 500);
    }
  };

  handleShortest = async () => {
    this.setState({ spinner: true });
    let cheapest = this.state.filteredOffers.sort(compare);
    await delay(1000);

    this.setState({
      spinner: false,
      filteredOffers: cheapest,
      offers: cheapest,
    });
  };
  handleCheapest = async () => {
    this.setState({ spinner: true });

    let cheapest = this.state.filteredOffers.sort(compare);
    await delay(1000);

    this.setState({
      spinner: false,
      filteredOffers: cheapest,
      offers: cheapest,
    });
  };
  selectAge = (index, event) => {
    let arr = JSON.parse(JSON.stringify(this.state.children));
    arr[index] = event;
    this.setState({
      children: arr,
    });
  };

  selectOriginAirport = async (event, newValue) => {
    this.setState({
      origin: newValue,
    });
  };

  selectDestinationAirport = async (event, newValue) => {
    this.setState({
      destination: newValue,
    });
  };

  addAdults = (event) => {
    event.preventDefault();
    // maximum is 8 adults
    if (this.state.adults !== 8) {
      this.setState({
        adults: this.state.adults + 1,
      });
    }
  };

  removeAdults = (event) => {
    event.preventDefault();

    if (this.state.adults !== 1) {
      this.setState({
        adults: this.state.adults - 1,
      });
    }
  };

  addChild = (event) => {
    event.preventDefault();
    if (this.state.children.length !== 8) {
      let arr = JSON.parse(JSON.stringify(this.state.children));
      arr.push(0);
      this.setState({
        children: arr,
      });
    }
  };

  removeChild = (event) => {
    event.preventDefault();

    if (this.state.adults !== 0) {
      let arr = JSON.parse(JSON.stringify(this.state.children));
      arr.pop();
      this.setState({
        children: arr,
      });
    }
  };
  handleDepartureDateChange = (date) => {
    console.log(date);
    this.setState({
      departureDate: date,
    });
  };
  handleDates = (start, end) => {
    this.setState({
      departureDate: start,
      returnDate: end,
    });
  };
  handleReturnDateChange = (date) => {
    this.setState({
      returnDate: date,
    });
  };

  handleCabinChange = (event) => {
    store.dispatch({ type: "setOfferClass", payload: event.target.value });
    this.setState({
      class: event.target.value,
    });
  };

  handleRoute = (event) => {
    this.setState({
      route: event.target.value,
    });
  };

  // filter offers
  filter = (filters) => {
    let offers = this.state.offers;
    offers = getFilteredOffers(offers, filters, this.state.route);
    store.dispatch({ type: "FILTERED_OFFERS", payload: offers });
    if (offers.length == 0) this.setState({ filtersWarning: true });
    else this.setState({ filtersWarning: false });
    this.setState({
      green: filters.green,
      filteredOffers: offers,
    });
  };

  addFlight = (event) => {
    event.preventDefault();
    let arr = this.state.slices;
    arr.push({
      origin: "",
      destination: "",
      departure_date: dateformat(new Date(), "isoDate"),
    });

    this.setState({
      slices: arr,
    });
  };

  sliceOrigin = (index, value) => {
    let arr = this.state.slices;
    console.log(arr);
    console.log(value);

    arr[index].origin = value;
    console.log(value);
    this.setState({
      slices: arr,
    });
  };

  sliceDestination = (index, value) => {
    let arr = this.state.slices;
    arr[index].destination = value;
    this.setState({
      slices: arr,
    });
  };

  sliceDate = (index, value) => {
    let arr = this.state.slices;
    arr[index].departure_date = value;
    this.setState({
      slices: arr,
    });
  };

  sliceRemove = (index) => {
    let arr = this.state.slices;
    arr.splice(index, 1);

    this.setState({
      slices: arr,
    });
  };

  render() {
    const classes = this.props.classes;
    this.state.offers.map((offer) => {
      const airline = offer[0].owner.name;
      const price = parseFloat(offer[0].total_amount);
      if (!this.state.airlines.includes(airline))
        this.state.airlines.push(airline);
      if (price >= max) {
        max = price;
      }
      offer[0].slices.map((slice) => {
        let duration = slice.duration;
        duration = duration.split("H");
        duration = duration[0].match(/\d/g);
        const hours = parseInt(duration.join(""));

        if (hours > this.state.maxDuration) this.state.maxDuration = hours;
        if (hours < this.state.minDuration) this.state.minDuration = hours;
      });
    });
    const Styles = {
      background: {
        backgroundImage: `url(${
          store.getState().AgencyReducer.agency.background
            ? store.getState().AgencyReducer.agency.background
            : bg2
        })`,
        backgroundPosition: "center",

        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        minHeight: "80vh",
      },
      entriesBackground: {
        paddingLeft: "20px",
        paddingRight: "20px",

        backgroundColor: store.getState().AgencyReducer.agency.branding
          .sixthColor
          ? store.getState().AgencyReducer.agency.branding.sixthColor
          : "#FAFAFA",
        borderRadius: "7px",
      },
    };

    return (
      <div>
        <div style={Styles.background}>
          <Navbar countrySelection={true} history={this.props.history} />
          <Grid container>
            <HomeTitle />
            <GridItem>
              <div style={{ width: "100%" }} className={classes.container}>
                <form className={classes.root} noValidate autoComplete="off">
                  <Grid style={Styles.entriesBackground}>
                    <RouteWays
                      handleCabinChange={this.handleCabinChange}
                      cabinClass={this.state.class}
                      classes={classes}
                      route={this.state.route}
                      handleRoute={this.handleRoute}
                    />

                    <GridItem xs={12}>
                      {this.state.route !== "Multi City" ? (
                        <AutoCompleteFlights
                          route={this.state.route}
                          reverseOriginAndDestination = {this.reverseOriginAndDestination}
                          handleReturnDateChange={this.handleReturnDateChange}
                          handleDates={this.handleDates}
                          handleDepartureDateChange={
                            this.handleDepartureDateChange
                          }
                          selectDestinationAirport={
                            this.selectDestinationAirport
                          }
                          selectOriginAirport={this.selectOriginAirport}
                        />
                      ) : (
                        <MultiCityOption
                          slices={this.state.slices}
                          selectDestination={this.sliceDestination}
                          selectOrigin={this.sliceOrigin}
                          changeDate={this.sliceDate}
                          sliceOrigin={this.sliceOrigin}
                          sliceDestination={this.sliceDestination}
                          sliceRemove={this.sliceRemove}
                          addFlight={this.addFlight}
                          classes={classes}
                        />
                      )}
                    </GridItem>

                    <SearchEntries
                      handleCabinChange={this.handleCabinChange}
                      cabinClass={this.state.class}
                      onSubmit={this.onSubmit}
                      state={this.state}
                      classes={classes}
                      selectAge={this.selectAge}
                      removeAdults={this.removeAdults}
                      addAdults={this.addAdults}
                      addChild={this.addChild}
                      removeChild={this.removeChild}
                    />
                  </Grid>
                </form>
              </div>
            </GridItem>
          </Grid>
        </div>

        {this.state.warning && <Warnings filters={false} />}
        {this.state.filtersWarning && <Warnings filters={true} />}
        {this.state.spinner ? (
          <div style={{ padding: "10%", backgroundColor: "white" }}>
            <Spinners />
            <div style={{ top: "5%" }}>{ANIMATIONS.Radar}</div>
          </div>
        ) : (
          <div>
            {this.state.offers.length !== 0 ? (
              <Offers
                classes={classes}
                filter={this.filter}
                state={this.state}
                history={this.props.history}
                handleCheapest={this.handleCheapest}
                handleShortest={this.handleShortest}
              />
            ) : (
              <Home
                classes={classes}
                destination={this.selectDestinationAirport}
              />
            )}
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  offers: state.offers.offers,
  filtered: state.offers.filteredOffers,
});

export default connect(mapStateToProps, { getOffers })(Form);
