const initialState = {
  success: false,
  ord_id: "",
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "setOrderID":
      return {
        ...state,
        ord_id: action.payload,
      };

    case "setSuccess":
      return {
        ...state,
        success: action.payload,
      };
    default:
      return state;
  }
};
