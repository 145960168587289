import { Typography } from "@material-ui/core";
import { Grid, useTheme, useMediaQuery } from "@mui/material";
import "@fontsource/rubik/500.css";
import "@fontsource/rubik/400.css";
import "@fontsource/inter/500.css";
import store from "store";
import "@fontsource/rubik/600.css";

import "@fontsource/fredoka/600.css";

import Divider from "@mui/material/Divider";

import React, { Component } from "react";
import { ReactComponent as YourSvg } from "./icons8-google.svg";
import "./styles.css";
import { LoginService } from "views/Services/clientServices";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import ResetPassword from "./ResetPassword";

export default function Signin({ setSignInStatus, signInStatus }) {
  let history = useNavigate();

  const [Photos, setPhotos] = React.useState([
    {
      text:
        "Search Millions of Routes & Find The Best Travel Deals around the world",
      path: "./Rectangle.jpg",
    },
    {
      text: "Get The Best Deals for Your Next Hotel Booking with few steps.",
      path: "./slide2.jpg",
    },
    {
      text: "Discover the most beautiful places to travel around the world.",
      path: "./slide3.jpg",
    },
  ]);
  const { useState } = React;
  const [inputtext, setinputtext] = useState({
    email: "",
    password: "",
  });

  const [warnemail, setwarnemail] = useState(false);
  let [counter, setCounter] = React.useState(0);
  const [isActive, setIsActive] = React.useState(false);

  const [warnpassword, setwarnpassword] = useState(false);

  const [eye, seteye] = useState(true);
  const [password, setpassword] = useState("password");
  const [type, settype] = useState(false);

  const inputEvent = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setinputtext((lastValue) => {
      return {
        ...lastValue,
        [name]: value,
      };
    });
  };
  const submitForm = async (e) => {
    e.preventDefault();
    setwarnemail(false);
    console.log(inputtext.email);
    console.log(inputtext.password);

    setwarnpassword(false);
    if (inputtext.email == "") {
      alert("incorrect creditinals");

      setwarnemail(true);
    } else if (inputtext.password == "") {
      alert("incorrect creditinals");
      setwarnpassword(true);
    } else {
      try {
        const user = await LoginService({
          agency: store.getState().AgencyReducer.agency.url,
          email: inputtext.email,
          password: inputtext.password,
        });
        store.dispatch({ type: "setToken", token: user.token });
        store.dispatch({ type: "setUserName", userName: user.user.name });
        store.dispatch({ type: "setEmail", email: user.user.email });
        store.dispatch({ type: "setLoggedIn", loggedIn: true });
        Cookies.set("token", user.token);
        history("/");
      } catch (e) {
        console.log(e);
        alert("incorrect creditinals");
      }
    }
  };

  const Eye = () => {
    if (password == "password") {
      setpassword("text");
      seteye(false);
      settype(true);
    } else {
      setpassword("password");
      seteye(true);
      settype(false);
    }
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Grid
      container
      sx={{ backgroundColor: "white", height: isMobile ? null : "100vh" }}
    >
      <Grid item md={2} sm={2} xs={2} />
      <ResetPassword open={isActive} onCloseModal={setIsActive} />
      <Grid item>
        <Typography
          style={{
            fontFamily: "Fredoka",
            fontStyle: "normal",
            letterSpacing: "-0.3%",
            color: "#5F41B3",
            fontWeight: 600,
          }}
          variant="h4"
        >
          Trollii
        </Typography>
      </Grid>
      <Grid container sx={{ mt: 3 }}>
        <Grid item md={3} sm={3} xs={3} />
        <Grid
          item
          md={6}
          sm={6}
          xs={6}
          style={{
            backgroundColor: "#FFFFFF",
          }}
        >
          <form onSubmit={submitForm}>
            <Grid container>
              <Grid item md={1} sm={1} xs={1} />
              <Grid item md={10} sm={10} xs={10}>
                <Grid item md={12} sm={12} xs={12}>
                  <Typography
                    style={{
                      display: "flex",
                      flexDirection: "flex-start",
                      fontFamily: "Rubik",
                      fontStyle: "normal",
                      letterSpacing: "-0.3%",
                      fontWeight: 500,
                      color: "#362566",
                    }}
                    variant={window.innerWidth < 720 ? "h5" : "h4"}
                  >
                    Welcome Back
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    style={{
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                      display: "flex",
                      fontFamily: "Rubik",
                      fontStyle: "normal",
                      letterSpacing: "-0.3%",
                      color: "#5D5C66",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    Welcome back, please enter your details
                  </Typography>
                </Grid>

                <div className="input-text">
                  <Typography
                    style={{
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                      display: "flex",
                      fontFamily: "Rubik",
                      fontStyle: "normal",
                      letterSpacing: "-0.3%",
                      color: "#5D5C66",
                      fontWeight: 500,
                    }}
                  >
                    Email
                  </Typography>
                  <input
                    type="text"
                    className={` ${warnemail ? "warning" : ""}`}
                    value={inputtext.email}
                    onChange={inputEvent}
                    name="email"
                  />
                </div>
                <div className="input-text" style={{ paddingTop: "5%" }}>
                  <Typography
                    style={{
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                      display: "flex",
                      fontFamily: "Rubik",
                      fontStyle: "normal",
                      letterSpacing: "-0.3%",
                      color: "#5D5C66",
                      fontWeight: 500,
                    }}
                  >
                    Password
                  </Typography>{" "}
                  <input
                    type={password}
                    className={` ${warnpassword ? "warning" : ""} ${
                      type ? "type_password" : ""
                    }`}
                    value={inputtext.password}
                    onChange={inputEvent}
                    name="password"
                  />
                  {eye ? (
                    <div onClick={Eye}>
                      <Typography
                        style={{
                          fontFamily: "Rubik",
                          fontWeight: 500,
                          color: "#5F41B3",
                        }}
                        className="fa-eye-slash"
                      >
                        show
                      </Typography>
                    </div>
                  ) : (
                    <div onClick={Eye}>
                      <Typography
                        style={{
                          fontFamily: "Rubik",
                          fontWeight: 500,
                          color: "#5F41B3",
                        }}
                        className="fa-eye-slash"
                      >
                        hide
                      </Typography>
                    </div>
                  )}
                  <p
                    onClick={() => {
                      setIsActive(true);
                    }}
                    style={{
                      cursor: "pointer",
                      fontFamily: "Rubik",
                      fontWeight: 500,
                      alignItems: "flex-end",
                      justifyContent: "flex-end",
                      display: "flex",
                      color: "#5DSC66",
                    }}
                  >
                    Forgot Password?
                  </p>
                </div>
                <div className="buttons" style={{ paddingTop: "30%" }}>
                  <button type="submit" style={{ backgroundColor: "#5F41B3" }}>
                    <Typography
                      style={{ fontFamily: "Inter", fontWeight: 500 }}
                    >
                      Log in
                    </Typography>
                  </button>
                </div>
                <Grid sx={{ pt: 4 }}>
                  <Divider>
                    <Typography
                      style={{ fontFamily: "Rubik", fontWeight: 500 }}
                    >
                      {" "}
                      or{" "}
                    </Typography>
                  </Divider>
                </Grid>
                <div className="buttons">
                  <button
                    type="submit"
                    style={{
                      backgroundColor: "#FFFFFF",
                      border: "1px solid #C4C4C4",
                    }}
                  >
                    <Grid
                      container
                      style={{
                        alignItems: "center",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Grid item>
                        <YourSvg height={"25"} />
                      </Grid>
                      <Grid item>
                        <Typography
                          style={{
                            fontFamily: "Inter",
                            fontWeight: 500,
                            color: "#5D5C66",
                          }}
                        >
                          Sign in with Google
                        </Typography>
                      </Grid>
                    </Grid>
                  </button>
                </div>
              </Grid>
            </Grid>

            <div className="forgot">
              <p style={{ fontFamily: "Rubik", fontWeight: 400 }}>
                Don’t have an account?{" "}
                <a
                  onClick={() => {
                    setSignInStatus(false);
                  }}
                  style={{
                    cursor: "pointer",
                    color: "#5F41B2",
                    fontFamily: "Rubik",
                    fontWeight: 500,
                  }}
                >
                  Sign up
                </a>
              </p>
            </div>
          </form>
        </Grid>
      </Grid>
    </Grid>
  );
}
