import { CircularProgress } from "@material-ui/core";
import "assets/scss/material-kit-react.scss?v=1.10.0";
import { createBrowserHistory } from "history";
import React, { Suspense, useEffect, useState } from "react";
import { Provider } from "react-redux";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { getAgency } from "./views/Services/clientServices";
import RegisterationNavigation from "./Navigations/RegisterationNavigation";
import store from "./store";
import "@fontsource/rubik/500.css";
import "@fontsource/rubik/600.css";
import "@fontsource/rubik/400.css";
import LoginNavigation from "./Navigations/LoginNaviagtion.js";
import PlaneAnimation from "./components/CardAnimations/plane";
import HotelNavigation from "Navigations/HotelNavigation";
import AdminPanelNavigations from "Navigations/AdminPanelNavigation";
import { getSnippet } from "views/Services/agencyServices";
import { Chatpopup, Facebook } from "Navigations/NavigationHelper";
import GA4React from "ga-4-react";
import { hotjar } from "react-hotjar";
import FlightNavigation from "Navigations/FlightNavigation";
import { GoogleOAuthProvider } from '@react-oauth/google';
import SwaggerUI from "swagger-ui-react"
import "swagger-ui-react/swagger-ui.css"
var hist = createBrowserHistory({
  basename: "search",
});

function App() {
  const [mounted, setMounted] = useState(false);
  const [tawk, setTawk] = useState("");
  const [google_anayltics, setGoogle_anayltics] = useState("");
  const [facebook_pixel, setFacebook_pixel] = useState("");
  const [hotjarID, setHotjar] = useState("");
  const [webCallback, setWebCallback] = useState("");
  const injectGA = () => {
    if (typeof window == "undefined") {
      return;
    }
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }
    gtag("js", new Date());

    gtag("config", "G-Y4XGH8XC5L");
  };
  const getSnippets = async (url) => {
    try {
      const snippets = await getSnippet({ url: url });
      const ga4react = new GA4React(snippets.google_anayltics);
      await ga4react.initialize();
      hotjar.initialize(3332757, 6);
      hotjar.identify("USER_ID", { userProperty: "value" });

      setTawk(snippets.tawk);
      setGoogle_anayltics(snippets.google_anayltics);
      setFacebook_pixel(snippets.facebook_pixel);
      setHotjar(snippets.hotjar);
      setWebCallback(snippets.webCallback);
    } catch (e) {
      console.log(e);
    }
  };

  const loadData = async (devMode) => {
    try {
      const agency = await getAgency({
        url: location.host,
      });
      await getSnippets(agency.url);
      store.dispatch({ type: "setAgency", payload: agency });
      setMounted(true);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    window.process = {
      ...window.process,
    };
    const devMode = process.env.NODE_ENV == "development";

    loadData(devMode);
    {
      facebook_pixel !== "" ? Facebook(facebook_pixel) : "";
    }
  }, []);

  if (mounted)
    return (
      <GoogleOAuthProvider clientId="710485207338-5v4u6e3el86ek81dtiaejjb1d8ip8ana.apps.googleusercontent.com">
      <Suspense fallback={<CircularProgress />}>
        <div className="App">
          <header className="App-header">
            <Provider store={store}>
              <BrowserRouter history={hist}>
                <HotelNavigation />
              </BrowserRouter>
              <BrowserRouter history={hist}>
                <RegisterationNavigation />
                <LoginNavigation />
                <AdminPanelNavigations />
                <FlightNavigation />

              </BrowserRouter>
            </Provider>
            {
              <>
                <script
                  async
                  src="https://www.googletagmanager.com/gtag/js?id=G-Y4XGH8XC5L"
                />
                <script>{injectGA()}</script>
              </>
            }
          </header>

          {tawk !== "" ? Chatpopup(tawk) : ""}
        </div>
      </Suspense>
      </GoogleOAuthProvider>
    );
  else {
    return <PlaneAnimation />;
  }
}

export default App;
