import Axios from "axios";
import agency from "constants/agency";
import Cookie from "js-cookie";
import dateformat from "dateformat";
import moment from "moment";

import hosts from "../../constants/hosts";
import store from "../../store";
import countries from "views/Hotels/Components/Countries";
import axios from "axios";
const localhost = "http://localhost:8080";

export async function forgotPassword(reqBody) {
  let url = `${hosts}/users/forgotPassword`;

  let response = await Axios.post(url, reqBody);
  if (response) {
    let info = response.data;
    return info;
  }
}

export async function createDuffelPayment(reqBody) {
  let url = `${hosts}/users/duffelPayments`;

  let response = await Axios.post(url, reqBody);
  if (response) {
    let info = response.data;
    return info;
  }
}

export async function confirmDuffelPayment(reqBody) {
  let url = `${hosts}/users/confirmDuffelPayments`;

  let response = await Axios.post(url, reqBody);
  if (response) {
    let info = response.data;
    return info;
  }
}

export async function signUpService(reqBody) {
  let url = `${hosts}/users/signup`;
  let response = await Axios.post(url, reqBody);
  if (response) {
    let info = response.data;
    return info;
  }
}

export async function LoginService(reqBody) {
  let url = `${hosts}/users/login`;
  let response = await Axios.post(url, reqBody);
  if (response) {
    let info = response.data;
    return info;
  }
}

export async function createPayment(reqBody) {
  let token;
  if (Cookie.get("token")) {
    token = Cookie.get("token");
  } else {
    token = store.getState().client.client.token;
  }

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  let url = `${hosts}/orders/createPayment`;
  let response = await Axios.post(url, reqBody, config);
  if (response) {
    let data = response.data;
    return data;
  }
}

export async function getSeatMap(reqBody) {
  let url = `${hosts}/orders/getSeatMap`;
  let response = await Axios.post(url, reqBody);
  if (response) {
    let data = response.data;
    return data;
  }
}

export async function createOrder(reqBody) {
  let token;
  if (Cookie.get("token")) {
    token = Cookie.get("token");
  } else {
    token = store.getState().client.client.token;
  }
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  let url = `${hosts}/orders/create`;
  let response = await Axios.post(url, reqBody, config);
  return response;
}

export async function confirmAccountAuto(reqBody) {
  let url = `${hosts}/users/confirmationPageAuto`;

  let response = await Axios.post(url, reqBody);
  if (response) {
    let info = response.data;
    return info;
  }
}

export async function stripeService(reqBody) {
  let url = `${hosts}/users/stripe`;

  let response = await Axios.post(url, reqBody);
  if (response) {
    let info = response.data;
    return info;
  }
}

export async function confirmationPage(reqBody) {
  let url = `${hosts}/users/confirmationPage`;

  let response = await Axios.post(url, reqBody);
  if (response) {
    let info = response.data;
    return info;
  }
}

export async function downloadPDF(body) {
  let url = `${hosts}/orders/generatepdf/${body.order_id}`;

  let response = await Axios.post(url, body);
  if (response) {
    return response.data;
  }
}

export async function getCountryPhoto(reqBody) {
  try {
    let url = `https://pixabay.com/api/?key=22743781-4888ca72e818269433b9b207e&q=${
      reqBody + " city"
    }&image_type=photo&pretty=true`;
    let response = await Axios.get(url);
    if (response.data.totalHits != 0) {
      return response.data.hits[0].webformatURL;
    } else {
      url = `https://pixabay.com/api/?key=22743781-4888ca72e818269433b9b207e&q=plane&image_type=photo&pretty=true`;
      response = await Axios.get(url);

      return response.data.hits[1].webformatURL;
    }
  } catch (e) {
    console.log(e);
    return `http://www.vanaturalbeauty.com/wp-content/rockettheme/rt_audacity_wp/pages/offline-page/img-01.jpg`;
  }
}

export async function getBookingsService() {
  let config;
  if (Cookie.get("token")) {
    config = {
      headers: {
        Authorization: `Bearer ${Cookie.get("token")}`,
      },
    };
  } else {
    config = {
      headers: {
        Authorization: `Bearer ${store.getState().client.client.token}`,
      },
    };
  }

  let url = `${hosts}/users/viewmybookings`;

  let response = await Axios.get(url, config);

  if (response) {
    let info = response.data;
    return info.bookings;
  }
}

export async function calculateRevenue(cost, cabinClass) {
  let url = `${hosts}/users/calculateRevenue`;
  let reqBody = {
    flightCost: cost,
    discount: "summer",
    flightClass: cabinClass,
    url: agency,
  };
  let response = await Axios.post(url, reqBody).catch((e) => console.log(e));
  if (response) {
    let finalCost = parseFloat(response.data.revenue).toFixed(2);
    return finalCost;
  }
}

export async function paginateHotels(hotels) {
  let url = `${hosts}/hotels/paginate?page=1&limit=10`;
  hotels.currency = store.getState().client.client.currency;
  hotels.markup = store.getState().AgencyReducer.agency.markups;
  hotels.agencyID = store.getState().AgencyReducer.agency._id;
  let today = new Date();
  let paxes = [];
  for (let i = 0; i < store.getState().hotelSearch.children; i++) {
    paxes.push({ type: "CH", age: 5 });
  }
  hotels.searchInformation = {
    agencyID: store.getState().AgencyReducer.agency._id,
    currency: store.getState().client.client.currency,
    stay: {
      checkIn: store.getState().hotelSearch.checkInDate
        ? moment(store.getState().hotelSearch.checkInDate).format("YYYY-MM-DD")
        : moment().format("YYYY-MM-DD"),
      checkOut: store.getState().hotelSearch.checkOutDate
        ? moment(store.getState().hotelSearch.checkOutDate).format("YYYY-MM-DD")
        : moment(new Date(today.getTime() + 24 * 60 * 60 * 1000)).format(
            "YYYY-MM-DD"
          ),
    },
    occupancies: [
      {
        rooms: store.getState().hotelSearch.rooms,
        adults: store.getState().hotelSearch.adults,
        children: store.getState().hotelSearch.children,
        paxes: paxes.length > 0 ? paxes : undefined,
      },
    ],
  };
  let response = await Axios.post(url, hotels);
  if (response) {
    return response.data;
  }
}

export async function SearchHotels(first) {
  let url = `${hosts}/hotels/getHotels`;
  const temp = await axios.get(
    "https://api.ipregistry.co/?key=5ijyqhhbokioha11"
  );
  if (first) {
    let obj = countries.find(
      (o) => o.countryName === temp.data.location.country.name
    );
    store.dispatch({
      type: "setCurrency",
      payload: obj.currencyCode,
    });
    store.dispatch({
      type: "setSearchPlace",
      payload: obj.countryName,
    });
  }

  let today = new Date();
  let paxes = [];
  for (let i = 0; i < store.getState().hotelSearch.children; i++) {
    paxes.push({ type: "CH", age: 5 });
  }
  let reqBody = {
    agencyID: store.getState().AgencyReducer.agency._id,
    currency: store.getState().client.client.currency,
    stay: {
      checkIn: store.getState().hotelSearch.checkInDate
        ? moment(store.getState().hotelSearch.checkInDate).format("YYYY-MM-DD")
        : moment().format("YYYY-MM-DD"),
      checkOut: store.getState().hotelSearch.checkOutDate
        ? moment(store.getState().hotelSearch.checkOutDate).format("YYYY-MM-DD")
        : moment(new Date(today.getTime() + 24 * 60 * 60 * 1000)).format(
            "YYYY-MM-DD"
          ),
    },

    occupancies: [
      {
        rooms: store.getState().hotelSearch.rooms,
        adults: store.getState().hotelSearch.adults,
        children: store.getState().hotelSearch.children,
        paxes: paxes.length > 0 ? paxes : undefined,
      },
    ],

    long: `${
      store.getState().hotelSearch.searchPlace.longitude
        ? store.getState().hotelSearch.searchPlace.longitude
        : temp.data.location.longitude
    }`,
    lat: `${
      store.getState().hotelSearch.searchPlace.latitude
        ? store.getState().hotelSearch.searchPlace.latitude
        : temp.data.location.latitude
    }`,
  };
  let response = await Axios.post(url, reqBody);

  if (response) {
    return response.data;
  }
}

export async function getAgency(agencyBody) {
  let url = `${hosts}/travelagency/getAgency`;
  const temp = await axios.get(
    "https://api.ipregistry.co/?key=5ijyqhhbokioha11"
  );
  let obj = countries.find(
    (o) => o.countryName === temp.data.location.country.name
  );

  store.dispatch({
    type: "setCurrency",
    payload: obj.currencyCode,
  });

  store.dispatch({
    type: "setCountryCode",
    payload: obj.countryCode,
  });

  let response = await Axios.post(url, agencyBody).catch((e) => console.log(e));
  if (response) {
    return response.data;
  }
}

export async function generateHotelPdf(body) {
  let url = `${hosts}/hotels/generatepdf`;
  let response = await Axios.post(url, body);
  if (response) {
    return response.data;
  }
}
export async function abandonedHotel(body) {
  let url = `${hosts}/hotels/click`;
  let response = await Axios.post(url, body);
  if (response) {
    return response.data;
  }
}

export async function getOfferRequest(state) {
  let passengers = [];
  let i = 0;
  while (i < state.adults) {
    passengers.push({
      type: "adult",
      id: "passenger_" + i,
    });
    i = i + 1;
  }
  state.children.map((child) => {
    if (child > 1)
      passengers.push({
        age: child,
        id: "passenger_" + i,
      });
    i = i + 1;
  });
  i = 0;
  state.children.map((child) => {
    if (child < 2)
      passengers.push({
        age: child,
        id: "passenger_" + i,
      });
    i = i + 1;
  });
  const origin = state.origin.iata;
  const destination = state.destination.iata;
  const cabin_class = state.class;
  let slices = [];
  if (state.route === "Two Way") {
    slices.push({
      origin: origin,
      destination: destination,
      departure_date: dateformat(
        store.getState().offers.departureDate
          ? store.getState().offers.departureDate
          : new Date(),
        "isoDate"
      ),
    });
    let today = new Date();
    slices.push({
      origin: destination,
      destination: origin,
      departure_date: dateformat(
        store.getState().offers.returnDate
          ? store.getState().offers.returnDate
          : new Date(today.getTime() + 24 * 60 * 60 * 1000),
        "isoDate"
      ),
    });
  } else if (state.route === "One Way") {
    slices.push({
      origin: origin,
      destination: destination,
      departure_date: dateformat(
        store.getState().offers.departureDate
          ? store.getState().offers.departureDate
          : new Date(),
        "isoDate"
      ),
    });
  } else {
    const arr = state.slices;
    console.log(arr);
    for (let i = 0; i < arr.length; i++) {
      slices.push({
        origin: arr[i].origin.iata,
        destination: arr[i].destination.iata,
        departure_date: dateformat(
          store.getState().offers.dateArr[i],
          "isoDate"
        ),
      });
    }
  }
  const body = {
    data: {
      passengers,
      slices,
      cabin_class,
      discount: state.discount,
      url: store.getState().AgencyReducer.agency.url,
      currency: store.getState().client.client.currency,
    },
  };

  let response = await Axios.post(`${hosts}/offers/pagination`, body);
  const offers = response.data;
  const searches = offers.cheapestFlights;
  const data = {
    passengers,
    slices,
    cabin_class,
  };
  await Axios.post(`${hosts}/offers/addsearch`, {
    flights: searches,
    data,
    url: store.getState().AgencyReducer.agency.url,
  });
  return offers;
}
export async function confirmBooking() {
  let url = `${hosts}/booking/booking`;
  let reqBody = {
    holder: {
      name: store.getState().hotelSearch.passengers[0].firstName,
      surname: store.getState().hotelSearch.passengers[0].lastName,
    },
    rooms: [
      {
        rateKey: store.getState().hotelSearch.rateKey,
      },
    ],
    clientReference: "IntegrationAgency",
    remark: "remarks",
    trolliiBooking: store.getState().hotelSearch,
    owner: store.getState().client.client.email,
    url: store.getState().AgencyReducer.agency.url,

    owner: store.getState().client.client.email,
  };

  let response = await Axios.post(url, reqBody);
  if (response) {
    return response.data;
  }
}

export async function getUserBookings() {
  let url = `${hosts}/booking/getUserBookings`;
  let reqBody = {
    owner: store.getState().client.client.email,
  };

  let response = await Axios.post(url, reqBody);
  if (response) {
    return response.data;
  }
}
export async function checkDiscount(reqBody) {
  let url = `${hosts}/travelagency/checkDiscount`;

  let response = await Axios.post(url, reqBody);
  if (response) {
    return response.data;
  }
}

export default function delay(delayInms) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(2);
    }, delayInms);
  });
}
