const initialState = {
  verificationCode: "false",
  color: "false",
  firstNameRegister: "false",
  lastNameRegister: "false",
  registerEmail: "false",
  passwordReducer: "false",
  businessTypeReducer: "false",
  businessNameReducer: "false",
  businessPrivateEmailReducer: "false",
  addressLine1Reducer: "false",
  addressLine2Reducer: "false",
  cityReducer: "false",
  stateReducer: "false",
  postReducer: "false",
  countryReducer: "false",
  privateNumberReducer: "false",
  publicEmailReducer: "false",
  publicNumberReducer: "false",
  fbReducer: "false",
  ytReducer: "false",
  igReducer: "false",
  ptReducer: "false",
  twReducer: "false",
  chosenProductsReducer: [],
  themeReducer: {},
  agencyTokenReducer: "false",
};

const SignUpReducer = (state = initialState, action) => {
  //reducer
  if (action.type === "setVerificationCode") {
    state.verificationCode = action.code;
    return { ...state };
  } else if (action.type === "setFirstNameRegister") {
    state.firstNameRegister = action.firstNameRegister;
    return { ...state };
  } else if (action.type === "setLastNameRegister") {
    state.lastNameRegister = action.lastNameRegister;
    return { ...state };
  } else if (action.type === "setRegisterEmail") {
    state.registerEmail = action.registerEmail;
    return { ...state };
  } else if (action.type === "setPasswordReducer") {
    state.passwordReducer = action.passwordReducer;
    return { ...state };
  } else if (action.type === "setBusinessTypeReducer") {
    state.businessTypeReducer = action.businessTypeReducer;
    return { ...state };
  } else if (action.type === "setBusinessNameReducer") {
    state.businessNameReducer = action.businessNameReducer;
    return { ...state };
  } else if (action.type === "setBusinessPrivateEmailReducer") {
    state.businessPrivateEmailReducer = action.businessPrivateEmailReducer;
    return { ...state };
  } else if (action.type === "setAddressLine1Reducer") {
    state.addressLine1Reducer = action.addressLine1Reducer;
    return { ...state };
  } else if (action.type === "setAddressLine2Reducer") {
    state.addressLine2Reducer = action.addressLine2Reducer;
    return { ...state };
  } else if (action.type === "setCityReducer") {
    state.cityReducer = action.cityReducer;
    return { ...state };
  } else if (action.type === "setStateReducer") {
    state.stateReducer = action.stateReducer;
    return { ...state };
  } else if (action.type === "setPostReducer") {
    state.postReducer = action.postReducer;
    return { ...state };
  } else if (action.type === "setCountryReducer") {
    state.countryReducer = action.countryReducer;
    return { ...state };
  } else if (action.type === "setPrivateNumberReducer") {
    state.privateNumberReducer = action.privateNumberReducer;
    return { ...state }; //private number is here!
  } else if (action.type === "setPublicEmailReducer") {
    state.publicEmailReducer = action.publicEmailReducer;
    return { ...state };
  } else if (action.type === "setPublicNumberReducer") {
    state.publicNumberReducer = action.publicNumberReducer;
    return { ...state };
  } else if (action.type === "setFbReducer") {
    state.fbReducer = action.fbReducer;
    return { ...state };
  } else if (action.type === "setYtReducer") {
    state.ytReducer = action.ytReducer;
    return { ...state };
  } else if (action.type === "setIgReducer") {
    state.igReducer = action.igReducer;
    return { ...state };
  } else if (action.type === "setPtReducer") {
    state.ptReducer = action.ptReducer;
    return { ...state };
  } else if (action.type === "setTwReducer") {
    state.twReducer = action.twReducer;
    return { ...state };
  } else if (action.type === "setAgencyTokenReducer") {
    state.agencyTokenReducer = action.agencyTokenReducer;
    // alert(action.agencyTokenReducer);
    return { ...state };
  } else if (action.type === "setChosenProductsReducer") {
    const items = [
      "Flights",
      "Hotels",
      "Excursions",
      "Tours",
      "Holidays",
      "Car Rentals",
    ];
    const temp = [];
    for (let i = 0; i < action.chosenProductsReducer.length; i++) {
      if (action.chosenProductsReducer[i] == true) {
        temp.push(items[i]);
      }
    }
    state.chosenProductsReducer = temp;
    return {
      ...state,
    };
  } else if (action.type === "setThemeReducer") {
    state.themeReducer = action.themeReducer;
    return { ...state };
  }
  return state;
};

export default SignUpReducer;
