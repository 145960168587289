import { Grid } from "@material-ui/core";
import ANIMATIONS from "constants/animations";
import React, { Component, useState } from "react";
import store from "store";
import Box from "@mui/material/Box";
import Hotel from "./Hotel";
import HotelDetails from "../HotelDetails/HotelDetails";
export default function AnimationHotel({ loading }) {
  const [hotelDetails, setHotelDetails] = useState(false);
  const [hotelDetailsView, setHotelDetailsView] = useState(false);
  return loading == "home" ? null : (
    <div>
      {loading ? (
        <div style={{ backgroundColor: "white", paddingTop: "5%" }}>
          {ANIMATIONS.Radar}
        </div>
      ) : (
        <Box sx={{ pb: 7, backgroundColor: "white" }}>
          {hotelDetailsView ? (
            <HotelDetails hotelDetails={hotelDetails} />
          ) : (
            <Grid container>
              {store.getState().hotelResults.hotels.length < 1
                ? null
                : store.getState().hotelResults.hotels.map((hotel, index) => {
                    return (
                      <Hotel
                        key={index}
                        hotel={hotel}
                        setHotelDetails={setHotelDetails}
                        setHotelDetailsView={setHotelDetailsView}
                      />
                    );
                  })}
            </Grid>
          )}
        </Box>
      )}
    </div>
  );
}
