import React, { Component } from "react";
import { Grid, Typography, Rating } from "@mui/material";
import Booking from "./Booking";

export default function Bookings({ bookings }) {
  return (
    <Grid container>
      <Grid item md={2} sm={2} xs={2} />
      <Grid item md={8} sm={8} xs={8}>
        <Grid container sx={{ pt: 3 }} spacing={2}>
          {bookings.map((item) => {
            return <Booking item={item} />;
          })}
        </Grid>
      </Grid>
    </Grid>
  );
}
