import React, { useState } from "react";
import { useStyles } from "react-styles-hook";
import SignUp from "./SignUp";

const Instagram = () => {
  const [isPink, switchPink] = useState(false);
  const styles = useStyles({
    heading: {
      width: "100%",
      backgroundColor: isPink ? "#f75172" : "#333333",
      marginTop: 200,
    },
  });
  const handleClick = () => {
    switchPink(!isPink);
    alert("hello");
  };
  return (
    <div styles={styles.heading}>
      <button style={{ height: 500 }} onClick={handleClick}>
        Click Mee!
      </button>
    </div>
  );
};
export default Instagram;
