import { CardMedia, Grid, Typography } from "@mui/material";
import React from "react";
import "@fontsource/rubik/500.css";
import { searchSubmit } from "../HotelSearch/SearchPanel";
import store from "store";
function PopularDestinations({
  setLoading,
  setDialogOpen,
  setMsg,
  setError,
  msg,
}) {
  return (
    <Grid container>
      <Grid item md={12} sm={12} xs={12} sx={{ pt: 6 }}>
        <Typography
          sx={{
            fontFamily: "Rubik",
            fontStyle: "normal",
            fontWeight: 700,
            fontSize: "40px",
            color: "#362566",
            textAlign: "center",
          }}
        >
          Popular destinations
        </Typography>
        <Grid container sx={{ pt: 3, justifyContent:"center", gap:"53px" }}>
          <Grid item md={3}>
            <CardMedia
              onClick={() => {
                let v = { longitude: 2.349014, latitude: 48.864716 };
                store.dispatch({
                  type: "setSearchPlace",
                  payload: v,
                });
                searchSubmit(setLoading, setDialogOpen, setMsg, setError, msg);
              }}
              component="img"
              height="365"
              width="365"
              image={require("assets/img/Photos/france.png")}
              sx={{
                cursor: "pointer",

                alignItems: "left",
                objectFit: "contain",
              }}
            />
          </Grid>

          <Grid item md={3}>
            <CardMedia
              onClick={() => {
                let v = { longitude: 9.18854, latitude: 45.464664 };
                store.dispatch({
                  type: "setSearchPlace",
                  payload: v,
                });
                searchSubmit(setLoading, setDialogOpen, setMsg, setError, msg);
              }}
              component="img"
              height="365"
              width="365"
              image={require("assets/img/Photos/milan.png")}
              sx={{
                cursor: "pointer",

                alignItems: "left",
                objectFit: "contain",
              }}
            />{" "}
          </Grid>

          <Grid item md={3}>
            <CardMedia
              onClick={() => {
                let v = { longitude: -0.118092, latitude: 51.509865 };
                store.dispatch({
                  type: "setSearchPlace",
                  payload: v,
                });
                searchSubmit(setLoading, setDialogOpen, setMsg, setError, msg);
              }}
              component="img"
              height="365"
              width="365"
              image={require("assets/img/Photos/london.png")}
              sx={{
                cursor: "pointer",
                alignItems: "left",
                objectFit: "contain",
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default PopularDestinations;
