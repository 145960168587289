import Icon from "@material-ui/core/Icon";
import InputAdornment from "@material-ui/core/InputAdornment";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Email from "@material-ui/icons/Email";
import image from "assets/img/bg7.jpg";
import styles from "assets/jss/material-kit-react/views/loginPage.js";
import axios from "axios";
import store from "../../store";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Success from "components/Typography/Success.js";
import Warning from "components/Typography/Warning.js";
import React from "react";
import hosts from "constants/hosts";
const useStyles = makeStyles(styles);

export default function AgencyPasswordChange(props) {
  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [newPassword, setNewPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [text, setText] = React.useState(false);
  const [passwordMatch, setPasswordMatch] = React.useState(false);
  const [fail, setFail] = React.useState(false);
  const [success, Setsuccess] = React.useState(false);

  window.scrollTo(0, 0);

  const handleSubmit = () => {
    let body = {
      email,
      password,
      newPassword,
      agency: store.getState().AgencyReducer.agency.url,
      id: new URLSearchParams(props.location.search).get("resetId"),
    };

    if (newPassword.length >= 8 && newPassword === confirmPassword) {
      let url = `${hosts}/travelAgency/changepasswordMobile`;
      alert("error cannot verify the owner");

      axios
        .post(url, body)
        .then(async (response) => {
          Setsuccess(true);
        })
        .catch((e) => {
          alert("error cannot verify the owner");
          console.log("error ===>", e);

          Setsuccess(false);
          setFail(true);
        });
    } else {
      alert("passwords didn't match");
    }
  };

  const handleEmail = (value) => {
    setEmail(value);
  };

  const handleLogin = (event) => {
    event.preventDefault();
    props.history("/AgencyLogin");
  };

  const handleOldPassword = (value) => {
    setPassword(value);
  };

  const handleNewPassword = (value) => {
    setNewPassword(value);
  };

  const handleConfirmPassword = (value) => {
    setConfirmPassword(value);
  };

  setTimeout(function () {
    setCardAnimation("");
  }, 700);
  const classes = useStyles();

  return (
    <div>
      <div
        className={classes.pageHeader}
        style={{
          backgroundImage: "url(" + image + ")",
          backgroundSize: "cover",
          backgroundPosition: "top center",
        }}
      >
        <div className={classes.container}>
          <GridContainer justifyContent="center">
            <GridItem>
              <Typography style={{ textAlign: "center" }}>
                <h1>Welcome</h1>
              </Typography>
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <Card className={classes[cardAnimaton]}>
                <form className={classes.form}>
                  <CardHeader color="primary" className={classes.cardHeader}>
                    <h4>Change Your Password</h4>
                  </CardHeader>
                  <CardBody>
                    <CustomInput
                      required
                      labelText="Email..."
                      id="email"
                      formControlProps={{
                        fullwidth: true,
                      }}
                      inputProps={{
                        type: "email",
                        onChange: (event) => handleEmail(event.target.value),
                        endAdornment: (
                          <InputAdornment position="end">
                            <Email className={classes.inputIconsColor} />
                          </InputAdornment>
                        ),
                      }}
                    />

                    <CustomInput
                      required
                      labelText="New Password"
                      id="pass2"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        type: "password",
                        onChange: (event) =>
                          handleNewPassword(event.target.value),
                        endAdornment: (
                          <InputAdornment position="end">
                            <Icon className={classes.inputIconsColor}>
                              lock_outline
                            </Icon>
                          </InputAdornment>
                        ),
                        autoComplete: "off",
                      }}
                    />
                    <CustomInput
                      required
                      labelText="Confirm New Password"
                      id="pass3"
                      formControlProps={{
                        fullwidth: true,
                      }}
                      inputProps={{
                        type: "password",
                        onChange: (event) =>
                          handleConfirmPassword(event.target.value),
                        endAdornment: (
                          <InputAdornment position="end">
                            <Icon className={classes.inputIconsColor}>
                              lock_outline
                            </Icon>
                          </InputAdornment>
                        ),
                        autoComplete: "off",
                      }}
                    />
                  </CardBody>
                  <GridContainer justifyContent="center">
                    {passwordMatch && (
                      <GridItem style={{ textAlign: "center" }}>
                        <Warning>
                          <b>Fail:</b> New Passwords must match
                        </Warning>
                      </GridItem>
                    )}

                    {text && (
                      <GridItem style={{ textAlign: "center" }}>
                        <Warning>
                          <b>Fail:</b> enter a password that has a minimum
                          length of 6 characters.
                        </Warning>
                      </GridItem>
                    )}

                    {fail && (
                      <GridItem style={{ textAlign: "center" }}>
                        <Warning>
                          <b>Fail:</b> Enter correct credentials
                        </Warning>
                      </GridItem>
                    )}

                    {success && (
                      <GridItem style={{ textAlign: "center" }}>
                        <Success>
                          <b>Success:</b> Password Changed Successfully.
                        </Success>
                      </GridItem>
                    )}
                    <GridItem style={{ textAlign: "center" }}>
                      <Button
                        simple
                        color="primary"
                        size="lg"
                        onClick={handleSubmit}
                      >
                        Change password
                      </Button>
                    </GridItem>
                    <GridItem style={{ textAlign: "center", fontSize: 9 }}>
                      <Button
                        simple
                        color="primary"
                        size="lg"
                        onClick={handleLogin}
                      >
                        sign in?
                      </Button>
                    </GridItem>
                  </GridContainer>
                </form>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </div>
  );
}
