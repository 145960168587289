import { combineReducers } from "redux";
import SignUpReducer from "../../AgencyRegisteration/Reducer/SignUpReducer";
import AgencyReducer from "./AgencyReducer";
import clientReducer from "./userReducer.js";
import ProgressReducer from "./ProgressReducer.js";
import hotelSearchReducer from "./HotelSearchReducer";
import hotelResultReducer from "./hotelResultReducer";
import PaymentReducer from "./PaymentReducer";
import SuperAdminReducer from "./SuperAdminReducer";
import chosenOfferReducer from "./chosenOfferReducer";
import offersReducer from "./offersReducer";
import CheckoutReducer from "./CheckoutReducer";

const rootReducer = combineReducers({
  SignUpReducer: SignUpReducer,
  hotelSearch: hotelSearchReducer,
  hotelResults: hotelResultReducer,
  client: clientReducer,
  AgencyReducer: AgencyReducer,
  ProgressReducer: ProgressReducer,
  PaymentReducer: PaymentReducer,
  ProgressReducer: ProgressReducer,

  superAdminReducer: SuperAdminReducer,
  chosenOffer: chosenOfferReducer,
  offers: offersReducer,
  checkout: CheckoutReducer,
});

export default rootReducer;
