//import "assets/scss/material-kit-react.scss?v=1.10.0";
import React from "react";
import { CssBaseline } from "@mui/material";
import { Route, Routes } from "react-router-dom";
import SearchFlight from "../views/SearchFlight/SearchFlight.js";
import Passengers from "../views/BookingFlights/PassengersFunctional.js";
import LandingSign from "views/Hotels/pages/LoginModule/LandingSign.jsx";
import Signup from "views/Signup/Signup.js";
import SelectRooms from "views/Hotels/pages/SelectRooms/SelectRooms.jsx";
import LandingPage from "../views/Hotels/pages/LandingPage/LandingPage";
import HotelDetails from "../views/Hotels/pages/HotelDetails/HotelDetails";
import HotelResults from "views/Hotels/pages/HotelResults/HotelResults.jsx";
import HotelSearch from "views/Hotels/pages/HotelSearch/HotelSearch.js";
import HotelPayment from "views/Hotels/pages/HotelPayments/HotelPayment.jsx";
import Footer from "views/Hotels/Components/Footer.jsx";
const FlightDetails = React.lazy(() =>
  import("../views/BookingFlights/FlightDetailsFunctional.js")
);
import PassengersFunctional from "../views/BookingFlights/PassengersFunctional.js";

import Filters from "views/Hotels/pages/HotelResults/Filters";
import PaymentDetails from "views/Hotels/pages/HotelPayments/PaymentDetails.jsx";
import Summary from "views/Hotels/pages/Summary/Summary.jsx";
import StripeComp from "views/Hotels/pages/HotelPayments/Stripe.jsx";
import CreditCardForm from "views/Hotels/pages/HotelPayments/CreditCardForm.jsx";
import MyBookings from "views/Hotels/pages/Bookings/MyBookings.jsx";
import PasswordChangeMobile from "views/LoginModule/PasswordChangeMobile.js";
import { MyBookingsFunctional } from "views/MyBookings/MyBookingsFunctional.js";
import SwaggerUI from "swagger-ui-react"
import "swagger-ui-react/swagger-ui.css"
const Pay = React.lazy(() => import("../views/Payments/PayFunctional.js"));
const SummaryPage = React.lazy(() =>
  import("../views/Summary/SummaryFunctional")
);
function HotelNavigation() {
  return (
    <Routes>
      <Route
        exact
        path="/signup"
        render={() => {
          return <LandingSign SignUpRoute />;
        }}
      />
      <Route exact path="/" element={<HotelResults />} />
      <Route exact path="/swagger" element={<SwaggerUI url="https://petstore.swagger.io/v2/swagger.json" />} />

      <Route
        exact
        path="/changepasswordMobile"
        element={<PasswordChangeMobile />}
      />
      <Route exact path="/landingSign" element={<LandingSign />} />,
      <Route exact path="/my-bookings" element={<MyBookings />} />,
      <Route exact path="/Stripe" element={<CreditCardForm />} />,
      <Route exact path="/searchHotel" element={<HotelSearch />} />,
      <Route exact path="/select-rooms" element={<SelectRooms />} />
      <Route exact path="/hotelPayment" element={<HotelPayment />} />
      <Route exact path="/paymentDetails" element={<PaymentDetails />} />
      <Route exact path="/summary" element={<Summary />} />
      <Route exact path="/landing-page" element={<LandingPage />} />
      <Route exact path="/hotelDetails" element={<HotelDetails />} />
      <Route exact path="/Filters" element={<Filters />} />
      <Route path="/FlightDetails" element={<FlightDetails />} />
       <Route path="/Passengers" element={<PassengersFunctional />} />
        <Route path="/pay" element={<Pay />} />
        <Route path="/mybookings" element={<MyBookingsFunctional />} />

        <Route path="/Summary" element={<SummaryPage />} />
    </Routes>
  );
}

export default HotelNavigation;
