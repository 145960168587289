import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import { Icon } from "@iconify/react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText";
import AppButton from "./AppButton";
import Slide from "@material-ui/core/Slide";
import { CardMedia, Typography } from "@mui/material";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const PopUpModal = ({ isOpen, setDialogOpen, msg, error }) => {
  return (
    <div>
      {isOpen ? (
        <div>
          <Dialog
            open={isOpen}
            onClose={() => setDialogOpen((prev) => !prev)}
            TransitionComponent={Transition}
            keepMounted
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <div>
              {error ? (
                <DialogContent style={{ textAlign: "center" }}>
                  <DialogContentText
                    id="alert-dialog-description"
                    style={{ justifyContent: "center" }}
                  >
                    <CardMedia
                      component="img"
                      height="300"
                      width="300"
                      image={require("assets/img/fail.jpg")}
                      sx={{
                        alignItems: "left",
                        objectFit: "contain",
                      }}
                    />
                  </DialogContentText>
                  <DialogContentText id="alert-dialog-description">
                    <Typography style={{ color: "#5D5C66" }}> {msg}</Typography>
                  </DialogContentText>
                </DialogContent>
              ) : (
                <DialogContent style={{ textAlign: "center" }}>
                  <DialogContentText
                    id="alert-dialog-description"
                    style={{ justifyContent: "center" }}
                  >
                    <CardMedia
                      component="img"
                      height="300"
                      width="300"
                      image={require("assets/img/success.jpg")}
                      sx={{
                        alignItems: "left",
                        objectFit: "contain",
                      }}
                    />
                  </DialogContentText>
                  <DialogContentText id="alert-dialog-description">
                    <Typography
                      style={{
                        color: "#5D5C66",
                        fontWeight: 400,
                        fontFamily: "Rubik",
                        fontStyle: "normal",
                        fontSize: "18px",
                        textAlign: "left",
                      }}
                    >
                      {" "}
                      {msg}
                    </Typography>
                  </DialogContentText>
                </DialogContent>
              )}

              <AppButton
                text="BACK TO DASHBOARD"
                onClick={() => {
                  setDialogOpen(!open);
                }}
              />
            </div>
          </Dialog>
        </div>
      ) : null}
    </div>
  );
};

export default PopUpModal;
