const initState = {
  token: "",
  agency: {
    siteStatus: false,
    paymentGateway: {
      stripe: false,
      duffel: false,
      stripeEmail: "ExampleEmail@gmail.com",
    },
    serviceSubscription: {
      flights: false,
      hotels: false,
      carRentals: false,
      tours: false,
    },
  },
  discountExpirationDate: "",
};

const AgencyReducer = (state = initState, action) => {
  if (action.type == "setAgencyToken") {
    state.token = action.payload;
    return {
      ...state,
    };
  }
  if (action.type == "setDiscountExpirationDate") {
    state.discountExpirationDate = action.payload;
    return {
      ...state,
    };
  }
  if (action.type == "setMobileApp") {
    state.agency.mobileappSubscription.mobileapp = action.payload;
    return {
      ...state,
    };
  }
  if (action.type == "setChatbot") {
    state.agency.chatbotSubscription.chatbot = action.payload;
    return {
      ...state,
    };
  }
  if (action.type == "setStatus") {
    state.agency.siteStatus = action.payload;
    return {
      ...state,
    };
  }
  if (action.type == "setAgency") {
    state.agency = action.payload;
    return {
      ...state,
    };
  }
  if (action.type == "setLogo") {
    state.agency.logo = action.payload;
    return {
      ...state,
    };
  }
  if (action.type == "setPublicInfo") {
    state.agency.public_info = action.payload;
    return {
      ...state,
    };
  }
  if (action.type == "deleteLogo") {
    delete state.agency.logo;
    return {
      ...state,
    };
  }
  if (action.type == "setPixabay") {
    state.agency.pixabay_media = action.payload;
    return {
      ...state,
    };
  }
  if (action.type == "disconnectInstagram") {
    delete state.agency.instagram_info;
    return {
      ...state,
    };
  }
  if (action.type == "setSubscription") {
    state.agency.serviceSubscription = action.payload;
    return {
      ...state,
    };
  }
  if (action.type == "setGateway") {
    state.agency.paymentGateway = action.payload;
    return {
      ...state,
    };
  }
  if (action.type == "setPaymentMethod") {
    state.agency.payment = action.payload;
    return {
      ...state,
    };
  }
  if (action.type == "setStaff") {
    state.agency.staff = action.payload;
    return {
      ...state,
    };
  }
  if (action.type == "setMarkup") {
    state.agency.markups = action.payload;
    return {
      ...state,
    };
  }
  if (action.type == "setColors") {
    state.agency.branding = action.payload;
    return {
      ...state,
    };
  }
  if (action.type == "setSocials") {
    state.agency.social = action.payload;
    return {
      ...state,
    };
  }

  return state;
};

export default AgencyReducer;
