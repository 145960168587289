import React from "react";
import store from "store";

import Grid from "@material-ui/core/Grid";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Avatar from "@material-ui/core/Avatar";
import { RangeDatePicker } from "react-google-flight-datepicker";
import "react-google-flight-datepicker/dist/main.css";
import airport from "airportsjs";
import TextFieldLandingForm from "./TextFieldLandingForm";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import { SingleDatePicker } from "react-google-flight-datepicker";
import TextField from '@mui/material/TextField';
import reactdatepicker from './reactdatepicker.scss';
import AutoCompleteFields from './AutoCompleteFields.scss';
import routereverse from "../../../../assets/img/routereverse.png"

function AutoCompleteFlights({
  selectDestinationAirport,
  route,
  state,
  setState,
  selectOriginAirport,
  reverseOriginAndDestination
}) {
  const options = airport.searchByAirportName(" ");
  const defaultProps = {
    getOptionLabel: (option) => {
      return option.city + " " + option.name + "\n ," + option.country;
    },
  };
  const today = new Date();

  return (
    <Grid
      style={{
        position:"relative",
        display: "flex",
      }}
    >
      {/* Outbound Flight Auto Complete */}
      <Grid item xs={12} sm={6} md={5} style={{ margin:"0"  }}>
        <Autocomplete className="flights"
        freeSolo
          {...defaultProps}
          options={options}
          groupBy={(option) => option.city}
          id="grouped-demo"
          autoSelect={false}
          color="white"
          size="small"
          onChange={selectOriginAirport}
          renderInput={(params) => {
            params.inputProps.value=state.origin.name;
            return <TextFieldLandingForm
          params={params}  text="from where?" />}}
        />
      </Grid>
      <Grid
        item
        style={{
          position:"absolute", left:"28%", top:"15px","z-index":"2"
        }}
      >

      <img
          src={routereverse}
          onClick={()=>{
            reverseOriginAndDestination()
          }}
          style={{width: "70px", height: "70px", marginTop:"5px"}}
        />
      </Grid>    
      <Grid
        item
        xs={12}
        sm={6}
        md={5}
        style={{ "z-index":"1"  }}
      >
        <Autocomplete className="flights"
        freeSolo
          required
          {...defaultProps}
          options={options}
          autoSelect={false}
          groupBy={(option) => option.city}
          id="grouped-demo"
          size="small"
          onChange={selectDestinationAirport}
            renderInput={(params) => 
            { 
              params.inputProps.value=state.destination.name;

            return <TextFieldLandingForm
               params={params}   style={{ margin:"0",  }} text="where to?" />}}

        />
      </Grid>

      {/* Return Date Picker */}
      <Grid item xs={12} sm={6} md={6} style={{ marginTop:"30px",}}>
        {route !== "One Way"? (
          <RangeDatePicker 
          style={{padding:"1rem", }}
            startDate={new Date()}
            endDate={new Date(today.getTime() + 24 * 60 * 60 * 1000)}
            onChange={(startDate, endDate) => {
              store.dispatch({
                type: "setDepartureDate",
                payload: startDate,
              });
              store.dispatch({
                type: "setReturnDate",
                payload: endDate,
              });
            }}
            onFocus={() => {
              return "";
            }}
            minDate={new Date()}
            maxDate={new Date(2100, 0, 1)}
            dateFormat="DD MMM YYYY"
            monthFormat="MMM YYYY"
            startDatePlaceholder="Start Date"
            endDatePlaceholder="End Date"
            disabled={false}
            startWeekDay="monday"
            className="react-datepicker"
          />
        ) : (
          <SingleDatePicker
            startDate={new Date()}
            onChange={(startDate) => {
              store.dispatch({
                type: "setDepartureDate",
                payload: startDate,
              });
            }}
            minDate={new Date()}
            maxDate={new Date(2100, 0, 1)}
            dateFormat="DD MMM YYYY"
            monthFormat="MMM YYYY"
            startDatePlaceholder="Start Date"
            endDatePlaceholder="End Date"
            disabled={false}
            startWeekDay="monday"
            className="react-single__datepicker"
          />
        )}
      </Grid>
            </Grid>

  );
}

export default AutoCompleteFlights;
