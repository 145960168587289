import validator from "email-validator";
import Cookie from "js-cookie";
import React, { Component } from "react";
import { connect } from "react-redux";
import { phone } from "phone";
import moment from "moment";
import { getPassengerInfo } from "../../redux/actions/offerActions.js";
import store from "../../store";
import { signUpService } from "../Services/clientServices";
import Stepper from "../Steppers/Stepper0";
import "./styles.css";
import Navbar from "views/SearchFlight/NavBar";
import VerificationModal from "./VerificationModal";
import LoginModal from "./LoginModal";
import Instructions from "./Instructions";

export class Passengers extends Component {
  constructor(props) {
    super(props);
    let ids = [];
    try {
      ids = this.props.travellers.passengers.map((passenger) => {
        ids.push(passenger.id);
      });
    } catch (e) {
      window.location.assign("/");
    }
    this.state = {
      ids: ids,
      passengerInfo: new Array(ids.length),
      check: true,
      modal: false,
      animationVisible: false,
      verificationModal: false,
    };
  }

  
  toggle = () => {
    this.setState({ modal: !this.state.modal });
  };

  closeModal = () => {
    this.setState({ modalIsOpen: false });
  };

  update = (state, index) => {
    let arr = this.state.passengerInfo;
    const info = {
      ...state,
    };
    arr[index] = JSON.parse(JSON.stringify(info));
    this.setState({
      passengerInfo: arr,
    });
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    let flag = true;
    let currentItem = this.state.passengerInfo;
    for (let i = 0; i < this.state.passengerInfo.length; i++) {
      if (!phone(currentItem[0].phone_number).isValid) {
        alert("warning phone number");
        flag = false;
      }
      if (!validator.validate(currentItem[0].email)) {
        alert("warning email", "please enter an email");
        flag = false;
      }
    }

    if (flag) {
      if (
        store.getState().client.client.token.length > 1 ||
        Cookie.get("token")
      ) {
        this.props.getPassengerInfo(
          JSON.parse(JSON.stringify(this.state.passengerInfo))
        );
        this.props.history("/pay");
      } else {
        try {
          let reqBody = {
            email: this.state.passengerInfo[0]
              ? this.state.passengerInfo[0].email
              : "",
            url: store.getState().AgencyReducer.agency.url,
          };

          let code = await signUpService(reqBody);
          this.setState({
            code: code.verificationCode,
            verificationModal: true,
          });
        } catch (e) {
          console.log(e);
          this.setState({ modal: true });
        }
      }
    }
  };
  onRequestClose = (e) => {
    this.setState({ verificationModal: false });
  };
  setPassword = (text) => {
    this.setState({ pass: text.target.value });
  };
  animationVisible = (mode, verificationModal, loginModal) => {
    if (loginModal) {
      this.setState({ modal: false });
      this.setState({ animationVisible: mode });
    }
    if (verificationModal) {
      this.setState({
        verificationModal: false,
        animationVisible: false,
      });
    } else {
      this.setState({ animationVisible: mode });
    }
  };
  onCloseModal = (e) => {
    e.preventDefault();

    this.setState({ modal: false });
  };

  render() {
    const classes = this.props.classes;
    const offer = this.props.chosenOffer || null;
    let ages = store
      .getState()
      .chosenOffer.passengers.children.sort(function (a, b) {
        return b - a;
      });
    const travellers = this.props.travellers || {
      adults: -1,
      children: [],
    };

    let adults = "";
    let children = "";
    if (travellers.adults === 1) adults = "1 adult";
    else if (travellers.adults > 1) adults = travellers.adults + " adults";

    if (travellers.children.length === 1) children = "1 child";
    else if (travellers.children.length > 1)
      children = travellers.children.length + " children";

    let date = "";
    offer.slices.map((slice) => {
      date = slice.segments[0].departing_at;
    });
    let ageCounter = 0;

    travellers.passengers.map((passenger) => {
      if (passenger.type != "adult") {
        if (ages[ageCounter] > 0) {
          passenger.born_on = moment()
            .subtract(ages[ageCounter], "years")
            .add(37, "days")
            .format("YYYY-MM-DD");
        } else {
          passenger.born_on = moment()
            .subtract(20, "days")
            .format("YYYY-MM-DD");
        }
        ageCounter++;
      }
    });

    return (
      <div className={classes.main} style={{ backgroundColor: "#F6F7F8" }}>
        <Navbar />
        <div className={classes.raised}>
          <VerificationModal
            state={this.state}
            history={this.props.history}
            onRequestClose={this.onRequestClose}
            animationVisible={this.animationVisible}
          />
          <LoginModal
            history={this.props.history}
            state={this.state}
            onCloseModal={this.onCloseModal}
            setPassword={this.setPassword}
            animationVisible={this.animationVisible}
          />
          <Stepper />
          <Instructions
            offer={offer}
            classes={classes}
            handleSubmit={this.handleSubmit}
            travellers={travellers}
            update={this.update}
            date={date}
            adults={adults}
            children={children}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  chosenOffer: state.chosenOffer.offer,
  travellers: state.chosenOffer.passengers,
  passengerInfo: state.chosenOffer.passengerInfo,
  user: state.client,
});

export default connect(mapStateToProps, { getPassengerInfo })(Passengers);
