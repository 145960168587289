import React from "react";
import ANIMATIONS from "constants/animations.js";
import Modal from "react-modal";
import store from "store";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Cookie from "js-cookie";
import { forgotPassword } from "views/Services/clientServices";
import { LoginService } from "views/Services/clientServices";

function LoginModal({
  state,
  onCloseModal,
  setPassword,
  animationVisible,
  history,
}) {
  return (
    <div className="App" style={{ zIndex: 10 }}>
      <Modal
        isOpen={state.modal}
        onRequestClose={() => onCloseModal()}
        contentLabel="My dialog"
        className="mymodal"
        closeTimeoutMS={500}
        style={{
          content: {
            background:
              "linear-gradient(#F5F7FA," +
              store.getState().AgencyReducer.agency.branding.primaryColor +
              ")",
          },
        }}
      >
        {state.animationVisible ? (
          <div>{ANIMATIONS.ManLoad}</div>
        ) : (
          <div
            style={{
              alignItems: "center",
              textAlign: "center",
              justifyContent: "Center",
            }}
          >
            <AccountCircleOutlinedIcon
              style={{
                height: 100,
                width: 100,
                color: store.getState().AgencyReducer.agency.branding
                  .primaryColor,
              }}
            ></AccountCircleOutlinedIcon>
            <h2
              style={{
                textAlign: "center",
                color: "white",
                fontFamily: "cursive",
              }}
            >
              Welcome back !
            </h2>
            <Typography
              id="modal-modal-title"
              variant="h3"
              component="span"
              style={{
                textAlign: "center",
                color: "white",
                fontSize: 16,
              }}
            >
              {`${
                state.passengerInfo[0] ? state.passengerInfo[0].email : null
              }`}
            </Typography>
            <Box component="form">
              <TextField
                fullWidth
                size="large"
                type="password"
                name="password"
                placeholder=" Please enter your password"
                variant="standard"
                style={{
                  backgroundColor: "white",

                  marginTop: "20px",
                  borderRadius: 8,
                }}
                onChange={(text) => {
                  setPassword(text);
                }}
              />
            </Box>
            <br />

            <Button
              size="large"
              variant="contained"
              style={{
                borderWidth: 0,
                borderRadius: 15,
                fontSize: "16px",
                backgroundColor: "#4caf50",
                fontFamily: "cursive",
                color: "white",
                width: "220px",
              }}
              onClick={async () => {
                try {
                  store.dispatch({
                    type: "PASSENGER_INFO",
                    payload: state.passengerInfo,
                  });
                  let reqBody = {
                    email: state.passengerInfo[0].email,
                    password: state.pass ? state.pass : "",
                    agency: store.getState().AgencyReducer.agency.url,
                  };

                  animationVisible(true, false);
                  const data = await LoginService(reqBody);

                  Cookie.set("token", data.token);

                  store.dispatch({
                    type: "setEmail",
                    email: data.user.email,
                  });

                  store.dispatch({
                    type: "setAcessLevel",
                    accessLevel: data.user.accessLevel,
                  });

                  store.dispatch({
                    type: "setToken",
                    token: data.token,
                  });

                  animationVisible(false, false, true);
                  history("/pay");
                } catch (error) {
                  console.log(error);
                  animationVisible(false, false, false);
                  alert("Please check your credentials");
                }
              }}
            >
              <div style={{ color: "black" }}>Sign in</div>
            </Button>
            <div> </div>
            <Button
              size="large"
              variant="contained"
              style={{
                borderWidth: 0,
                borderRadius: 15,
                fontSize: "16px",
                marginTop: "15px",
                backgroundColor: "#f50057",
                fontFamily: "cursive",
                color: "white",
                width: "220px",
              }}
              onClick={async () => {
                const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                if (re.test(state.passengerInfo[0].email.toLowerCase())) {
                  try {
                    let body = {
                      email: state.passengerInfo[0].email,
                      agency: store.getState().AgencyReducer.agency.url,
                    };

                    await forgotPassword(body);
                    alert("check your email to get your new password");
                  } catch (error) {
                    console.log(error);
                    alert("this email is not registered with us");
                  }
                } else {
                  alert("please enter a correct email");
                }
              }}
            >
              Reset Password
            </Button>
          </div>
        )}
      </Modal>
    </div>
  );
}

export default LoginModal;
