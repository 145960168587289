import React from "react";
import Grid from "@material-ui/core/Grid";
import "react-google-flight-datepicker/dist/main.css";
import "../HotelSearch/style.css";
import Selectors from "../HotelSearch/Selectors";
import { Typography } from "@material-ui/core";
import DateSelector from "./DateSelector";
import PlaceSelector from "../HotelSearch/PlaceSelector";
import store from "store";
import Styles from "../LandingPage/Styles";
import { SearchHotels } from "views/Services/clientServices";
import { paginateHotels } from "views/Services/clientServices";

export async function searchSubmit(
  setLoading,
  first,
  setDialogOpen,
  setMsg,
  setError
) {
  try {
    setLoading("home");
    const response = await SearchHotels(first);
    store.dispatch({ type: "setAllHotels", payload: response });

    const hotels = await paginateHotels({ hotels: response });

    store.dispatch({ type: "setHotelResults", payload: hotels });
    setLoading(false);
    if (!hotels) alert("please try again with different datess");
  } catch (e) {
    setLoading("empty");
    if (e.response.status == 404) {
      setMsg(e.response.data.msg);
      console.log("---------------------");
      console.log(e.response);
      setError(true);
      setDialogOpen(true);
    } else {
      setMsg("no results matched your checked filters or dates");
      setError(true);
      setDialogOpen(true);
    }
  }
}

function SearchEntries({ setLoading, loading }) {
  return (
    <Grid container style={{ position: "absolute", paddingBottom: "2%" }}>
      <Grid item xs={1} sm={1} md={1} />
      <Grid item xs={10} sm={10} md={10}>
        <Grid container style={Styles.Entries}>
          <PlaceSelector />
          <span className="divider" />
          <Selectors />
          <span className="divider" style={{ marginRight: "3%" }} />
          <DateSelector />
          <Grid item md={1} />
          <Grid
            item
            className="griditem"
            md={12}
            style={{
              backgroundColor: "#1F90D0",
              cursor: "pointer",
              boxShadow: "0px 4px 10px rgba(31, 144, 208, 0.4)",
              borderRadius: "5%",
              textAlign: "center",
            }}
          >
            <div
              onClick={() => {
                searchSubmit(setLoading);
              }}
            >
              <Typography
                style={{
                  color: "white",
                  fontFamily: "Poppins",
                  fontStyle: "normal",
                }}
              >
                Search Hotelss
              </Typography>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default SearchEntries;
