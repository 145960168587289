import React from "react";
import CounterUp from "../../../../assets/img/countup.png";
import CounterDown from "../../../../assets/img/countdown.png";
import CountDownDisabled from "../../../../assets/img/countdowndisabled.png";

export default function CounterInput(props) {
  const [count, setCount] = React.useState(props.min);
  const increment = () => {
    if (count < props.max) {
      props.onCountChange(count + 1);
      setCount(count + 1);
    }
  };
  const decrement = () => {
    if (count > 0) {
      props.onCountChange(count - 1);
      setCount(count - 1);
    }
  };
  return (
    <div style={{ display: "flex", marginLeft: "1.5vw" }}>
      {count === 0 ? (
        <img
          src={CountDownDisabled}
          style={{
            background: "#C4C4C4",
            borderRadius: "16px",
            width: "24px",
            height: "24px",
            justifyContent: "center",
            alignContent: "center",
            marginRight: "0.9vw",
            borderWidth: "0px",
            color: "white",
          }}
          onClick={() => decrement()}
          onChange={props.onCountChange}
        ></img>
      ) : (
        <img
          src={CounterDown}
          style={{
            background: "#C4C4C4",
            borderRadius: "16px",
            width: "24px",
            height: "24px",
            justifyContent: "center",
            alignContent: "center",
            marginRight: "0.9vw",
            borderWidth: "0px",
            color: "white",
          }}
          onClick={() => decrement()}
          onChange={props.onCountChange}
        ></img>
      )}
      <div style={{ paddingTop: "0.1vw" }}> {count}</div>

      <img
        src={CounterUp}
        onClick={() => {
          increment();
          props.onCounterChangeAdultIncrement();
        }}
        onChange={props.onCountChange}
        style={{
          backgroundColor: "#5f41b3",
          borderRadius: "16px",
          width: "24px",
          height: "24px",
          justifyContent: "center",
          alignContent: "center",
          marginLeft: "0.9vw",
          borderWidth: "0px",
          color: "white",
        }}
      ></img>
    </div>
  );
}
