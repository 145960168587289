import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import Email from "@material-ui/icons/Email";

// core components
import Header from "components/Header/Header.js";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import PersonIcon from "@material-ui/icons/Person";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import BookmarkIcon from "@material-ui/icons/Bookmark";
import SearchIcon from "@material-ui/icons/Search";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Typography from "@material-ui/core/Typography";
import Warning from "components/Typography/Warning.js";
import Success from "components/Typography/Success.js";

import axios from "axios";
import Cookie from "js-cookie";

import styles from "assets/jss/material-kit-react/views/loginPage.js";

import image from "assets/img/bg7.jpg";

const useStyles = makeStyles(styles);

export default function PasswordChange(props) {
  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [newPassword, setNewPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [text, setText] = React.useState(false);
  const [passwordMatch, setPasswordMatch] = React.useState(false);
  const [fail, setFail] = React.useState(false);
  const [success, Setsuccess] = React.useState(false);

  window.scrollTo(0, 0);

  const handleSubmit = () => {
    let body = {
      email,
      password,
      newPassword,
    };

    setPasswordMatch(false);
    setText(false);

    if (newPassword !== confirmPassword) {
      setPasswordMatch(true);
    }
    if (newPassword.length < 6) {
      setText(true);
    }

    if (newPassword.length >= 6 && newPassword === confirmPassword) {
      // let url = "https://api.trollii.co/users/changePassword"
      let url = "https://trollii.uk:8080/travelagency/changepassword";

      axios
        .post(url, body)
        .then(async (response) => {
          Setsuccess(true);
          setText(false);
          setPasswordMatch(false);
          setFail(false);
        })
        .catch((e) => {
          console.log("error ===>", e);
          Setsuccess(false);
          setFail(true);
        });
    }
  };

  const handleEmail = (value) => {
    setEmail(value);
  };

  const handleLogin = (event) => {
    event.preventDefault();
    props.history("/AgencyLogin");
  };

  const handleOldPassword = (value) => {
    setPassword(value);
  };

  const handleNewPassword = (value) => {
    setNewPassword(value);
  };

  const handleConfirmPassword = (value) => {
    setConfirmPassword(value);
  };

  setTimeout(function () {
    setCardAnimation("");
  }, 700);
  const classes = useStyles();

  return (
    <div>
      {/* <Header
        absolute
        brand="Trollii"
        color="transparent"
        rightLinks={
          <List
            className={classes.list}
            style={{ flexDirection: "row", display: "flex" }}
            dense
          >
            <ListItem className={classes.listItem}>
              <Button
                color="transparent"
                onClick={() => {
                  if (Cookie.get("token")) {
                    props.history("/mybookings");
                  } else props.history("/login");
                }}
                className={classes.navLink + " " + classes.socialIconsButton}
              >
                <BookmarkIcon style={{ fontSize: "large" }} />
                View my bookings
              </Button>
            </ListItem>
            <ListItem className={classes.listItem}>
              <Button
                color="transparent"
                className={classes.navLink + " " + classes.socialIconsButton}
                onClick={() => props.history("/")}
              >
                <SearchIcon style={{ fontSize: "large" }} />
                Search Flights
              </Button>
            </ListItem>
            {Cookie.get("token") ? (
              <ListItem className={classes.listItem}>
                <Button
                  color="transparent"
                  className={classes.navLink + " " + classes.socialIconsButton}
                  onClick={() => {
                    Cookie.remove("token");
                    window.location.reload();
                  }}
                >
                  <ExitToAppIcon style={{ fontSize: "large" }} />
                  SIGN OUT
                </Button>
              </ListItem>
            ) : (
              <ListItem className={classes.listItem} style={{ margin: "5px" }}>
                <Button
                  color="transparent"
                  className={classes.navLink + " " + classes.socialIconsButton}
                  onClick={() => props.history("/login")}
                >
                  <PersonIcon style={{ fontSize: "large" }} />
                  SIGN IN
                </Button>
              </ListItem>
            )}
          </List>
        }
      /> */}
      <div
        className={classes.pageHeader}
        style={{
          backgroundImage: "url(" + image + ")",
          backgroundSize: "cover",
          backgroundPosition: "top center",
        }}
      >
        <div className={classes.container}>
          <GridContainer justifyContent="center">
            <GridItem>
              <Typography style={{ textAlign: "center" }}>
                <h1>Welcome</h1>
              </Typography>
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <Card className={classes[cardAnimaton]}>
                <form className={classes.form}>
                  <CardHeader color="primary" className={classes.cardHeader}>
                    <h4>Change Your Password</h4>
                  </CardHeader>
                  <CardBody>
                    <CustomInput
                      required
                      labelText="Email..."
                      id="email"
                      formControlProps={{
                        fullwidth: true,
                      }}
                      inputProps={{
                        type: "email",
                        onChange: (event) => handleEmail(event.target.value),
                        endAdornment: (
                          <InputAdornment position="end">
                            <Email className={classes.inputIconsColor} />
                          </InputAdornment>
                        ),
                      }}
                    />
                    <CustomInput
                      required
                      labelText="Old Password"
                      id="pass1"
                      formControlProps={{
                        fullwidth: true,
                      }}
                      inputProps={{
                        type: "password",
                        onChange: (event) =>
                          handleOldPassword(event.target.value),
                        // endAdornment: (
                        //   <InputAdornment position="end">
                        //     <Icon className={classes.inputIconsColor}>
                        //       lock_outline
                        //     </Icon>
                        //   </InputAdornment>
                        // ),
                        autoComplete: "off",
                      }}
                    />
                    <CustomInput
                      required
                      labelText="New Password"
                      id="pass2"
                      formControlProps={{
                        fullwidth: true,
                      }}
                      inputProps={{
                        type: "password",
                        onChange: (event) =>
                          handleNewPassword(event.target.value),
                        // endAdornment: (
                        //   <InputAdornment position="end">
                        //     <Icon className={classes.inputIconsColor}>
                        //       lock_outline
                        //     </Icon>
                        //   </InputAdornment>
                        // ),
                        autoComplete: "off",
                      }}
                    />
                    <CustomInput
                      required
                      labelText="Confirm New Password"
                      id="pass3"
                      formControlProps={{
                        fullwidth: true,
                      }}
                      inputProps={{
                        type: "password",
                        onChange: (event) =>
                          handleConfirmPassword(event.target.value),
                        // endAdornment: (
                        //   <InputAdornment position="end">
                        //     <Icon className={classes.inputIconsColor}>
                        //       lock_outline
                        //     </Icon>
                        //   </InputAdornment>
                        // ),
                        autoComplete: "off",
                      }}
                    />
                  </CardBody>
                  <GridContainer justifyContent="center">
                    {passwordMatch && (
                      <GridItem style={{ textAlign: "center" }}>
                        <Warning>
                          <b>Fail:</b> New Passwords must match
                        </Warning>
                      </GridItem>
                    )}

                    {text && (
                      <GridItem style={{ textAlign: "center" }}>
                        <Warning>
                          <b>Fail:</b> enter a password that has a minimum
                          length of 6 characters.
                        </Warning>
                      </GridItem>
                    )}

                    {fail && (
                      <GridItem style={{ textAlign: "center" }}>
                        <Warning>
                          <b>Fail:</b> Enter correct credentials
                        </Warning>
                      </GridItem>
                    )}

                    {success && (
                      <GridItem style={{ textAlign: "center" }}>
                        <Success>
                          <b>Success:</b> Password Changed Successfully.
                        </Success>
                      </GridItem>
                    )}
                    <GridItem style={{ textAlign: "center" }}>
                      <Button
                        simple
                        color="primary"
                        size="lg"
                        onClick={handleSubmit}
                      >
                        Change password
                      </Button>
                    </GridItem>
                    <GridItem style={{ textAlign: "center", fontSize: 9 }}>
                      <Button
                        simple
                        color="primary"
                        size="lg"
                        onClick={handleLogin}
                      >
                        sign in?
                      </Button>
                    </GridItem>
                  </GridContainer>
                </form>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </div>
  );
}
