const initState = {
  hotelBookings: {},
  client: {
    username: "",
    loggedIn: false,
    email: "ziadshreet@gmail.com",
    currency: "GBP",
    country: "",
    countryCode: "",

    accessLevel: "",
    token: "",
    flights: {
      loading: false,
      data: [],
    },
  },
  passwordVerification: "",
  emailVerification: "",
  order: {},
  date: "",
  passengers: [],
  orderId: "",
  verificationCode: "0",
  ticketAnimation: false,
};

const clientReducer = (state = initState, action) => {
  if (action.type == "setUserName") {
    state.client.username = action.userName;
    return {
      ...state,
    };
  }
  if (action.type == "setHotelBookings") {
    state.hotelBookings = action.payload;
    return {
      ...state,
    };
  }
  if (action.type == "setCountry") {
    state.client.country = action.payload;
    return {
      ...state,
    };
  }
  if (action.type == "setCountryCode") {
    state.client.countryCode = action.payload;
    return {
      ...state,
    };
  }

  if (action.type == "addFlight") {
    let temp = state.client.flights.data;
    temp.push(action.flight);
    state.client.flights.data = temp;
    return {
      ...state,
    };
  }
  if (action.type == "setCurrency") {
    state.client.currency = action.payload;
    return {
      ...state,
    };
  }
  if (action.type == "setEmailVerification") {
    state.emailVerification = action.payload;
    return {
      ...state,
    };
  }
  if (action.type == "setPasswordVerification") {
    state.passwordVerification = action.payload;
    return {
      ...state,
    };
  }
  if (action.type == "deleteFlight") {
    let temp = state.client.flights.data;
    temp = temp.filter(function (obj) {
      return obj.id !== action.payload;
    });
    state.client.flights.data = temp;
    return {
      ...state,
    };
  }

  if (action.type == "addPassenger") {
    let temp = state.passengers;
    temp.push(action.passenger);
    state.passengers = temp;
    return {
      ...state,
    };
  }

  if (action.type == "editPassenger") {
    state.passengers.find((o, i) => {
      if (o.id === action.id) {
        state.passengers[i] = action.passenger;
        return true; // stop searching
      }
    });
    return {
      ...state,
    };
  }

  if (action.type == "clearPassenger") {
    let temp = [];
    state.passengers = temp;
    return {
      ...state,
    };
  }

  if (action.type == "setDate") {
    state.date = action.date;
    return {
      ...state,
    };
  }

  if (action.type == "setVerificationCode") {
    state.verificationCode = action.payload;
    return {
      ...state,
    };
  }

  if (action.type == "setTicketAnimation") {
    state.ticketAnimation = action.payload;
    return {
      ...state,
    };
  }

  if (action.type == "setOrder") {
    state.order = action.payload;
    return {
      ...state,
    };
  }

  if (action.type == "setAcessLevel") {
    state.client.accessLevel = action.accessLevel;
    return {
      ...state,
    };
  }

  if (action.type == "setToken") {
    state.client.token = action.token;
    return {
      ...state,
    };
  }

  if (action.type == "setEmail") {
    state.client.email = action.email;
    return {
      ...state,
    };
  }

  if (action.type == "setFlightsLoading") {
    state.client.flights.loading = action.payload;
    return {
      ...state,
    };
  }

  if (action.type == "setFlightsData") {
    state.client.flights.data = action.payload;
    return {
      ...state,
    };
  }

  if (action.type == "setLoggedIn") {
    state.client.loggedIn = action.loggedIn;
    return {
      ...state,
    };
  }

  if (action.type == "setOrderId") {
    state.orderId = action.payload;
    return {
      ...state,
    };
  }

  return state;
};

export default clientReducer;
