import {
  Box,
  Button,
  Checkbox,
  Divider,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";
import "@fontsource/fredoka/600.css";
import { useNavigate } from "react-router-dom";
import SignPhotos from "views/Hotels/pages/LoginModule/SignPhotos";
import axios from "axios";
import hosts from "../../constants/hosts";
import store from "../../store";
import { GoogleLogin, useGoogleLogin } from '@react-oauth/google';

export default function AgencyLogin(props) {
  const [showPassword, setShowPassword] = React.useState(false);
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");


  const history = useNavigate();
  const handleSignUp = (event) => {
    event.preventDefault();
    history("/register");
  };
  const login = useGoogleLogin({
    onSuccess: (codeResponse) => {
      console.log(codeResponse);
      axios
      .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${codeResponse.access_token}`, {
          headers: {
              Authorization: `Bearer ${codeResponse.access_token}`,
              Accept: 'application/json'
          }
      })
      .then((res) => {
        try{
       
       let body={
        email:'aa@df.com',
          token :codeResponse.access_token
        };
        let url = `${hosts}/travelagency/googleLogin`;
        axios
      .post(url, body)
      .then(async (response) => {
        await store.dispatch({
          type: "setAgency",
          payload: response.data.user,
        });

        const token = response.data.token;

        //  Cookie.set("token", token);
        if (response.data.user.instagram_token != "") {
          localStorage.setItem("instagram", "connected");
        } else {
          localStorage.setItem("instagram", "disconnected");
        }
        localStorage.setItem("id", response.data.user._id);
        localStorage.setItem("trolliiurl", response.data.user.url);
        localStorage.setItem("agencyToken", token);
        history("/AdminPanel");
      })
      .catch((e) => {
        console.log('errorrrrrrrrrrrrrrr');
      });}
      catch(e){
        console.log(e);
      }

        
          console.log(res.data.email);
      })
      .catch((err) => console.log('here error'));    
    },
    onError: (error) => console.log('Login Failed:', error)
});

  const handleSubmit = async () => {
    let body = {
      email,
      password,
    };
    let url = `${hosts}/travelagency/login`;
    await axios
      .post(url, body)
      .then(async (response) => {
        await store.dispatch({
          type: "setAgency",
          payload: response.data.user,
        });

        const token = response.data.token;

        //  Cookie.set("token", token);
        if (response.data.user.instagram_token != "") {
          localStorage.setItem("instagram", "connected");
        } else {
          localStorage.setItem("instagram", "disconnected");
        }
        localStorage.setItem("id", response.data.user._id);
        localStorage.setItem("trolliiurl", response.data.user.url);
        localStorage.setItem("agencyToken", token);
        history("/AdminPanel");
      })
      .catch((e) => {
        console.log(e);
      });
  };


  const handleResetLink = (event) => {
    event.preventDefault();
    history("/resetLinkAgency");
  };
  const handleSignIn = (event) => {
    event.preventDefault();
    handleSubmit();

    //history("/AdminPanel");
  };
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  let theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));
  return (
    <Box
      sx={{
        display: "flex",
        height: "100vh",
      }}
    >
      <Box
        sx={{
          width: isMobile ? "100vw" : "38vw",
          display: "flex",
          alignItems: isMobile ? "start" : "center",
        }}
      >
        {!isMobile && (
          <Box
            sx={{
              margin: { lg: "0.875em 0 0 5em", md: "0.875em 0 0 2em" },
              position: "fixed",
              top: "0",
            }}
          >
            <Typography
              fontFamily="Fredoka"
              fontWeight="600"
              fontSize="2.19rem"
              color="#5F41B2"
              lineHeight="1.2em"
            >
              Trollii
            </Typography>
            <Typography
              fontFamily="Rubik"
              fontWeight="500"
              fontSize="0.875rem"
              color="#362566"
              lineHeight="1.21em"
            >
              Travel Admin Dashboard
            </Typography>
          </Box>
        )}
        <Box
          sx={{
            margin: "0 auto",
            width: "fit-content",
            display: "flex",
            flexDirection: "column",
            width: isMobile ? "91%" : "60%",
            minWidth: "20.5em",
            marginTop: isMobile ? "2.5em" : "0",
          }}
        >
          {!isMobile && (
            <>
              <Typography
                fontFamily="Rubik"
                fontWeight="500"
                fontSize="2.5rem"
                color="#362566"
                lineHeight="1.175em"
                marginBottom="0.2em"
              >
                Welcome Back
              </Typography>
              <Typography
                fontFamily="Rubik"
                fontSize="0.875rem"
                color="#5D5C66"
                lineHeight="1.214em"
                marginBottom="2.286em"
              >
                Let's Set Up Your Website
              </Typography>
            </>
          )}
          <Typography
            fontFamily="Rubik"
            fontWeight="500"
            fontSize="1.25rem"
            color="#362566"
            lineHeight="1.2em"
            marginBottom={isMobile ? "1.6em" : "0.8em"}
          >
            Login to your account
          </Typography>
          <label
            style={{
              color: "#5D5C66",
              fontFamily: "Rubik",
              fontWeight: "500",
              fontSize: "0.875rem",
              lineHeight: "1.214em",
              marginBottom: "0.57em",
            }}
          >
            Email
          </label>
          <input
            type="email"
            onChange={(e)=>{
              setEmail(e.target.value);
            }}
            placeholder="Example@gmail.com"
            style={{
              color: "#5D5C66",
              fontFamily: "Rubik",
              marginBottom: "1em",
              borderRadius: "0.9375em",
              height: "3em",
              border: "1px solid #C4C4C4",
              padding: "1em",
              backgroundColor: "#FBFBFB",
            }}
          />
          <label
            style={{
              color: "#5D5C66",
              fontFamily: "Rubik",
              fontWeight: "500",
              fontSize: "0.875rem",
              lineHeight: "1.214em",
              marginBottom: "0.57em",
            }}
          >
            Password
          </label>
          <div style={{ position: "relative" }}>
            <input
              type={showPassword ? "text" : "password"}
              placeholder="••••••••••••••"
              onChange={(e)=>{
                setPassword(e.target.value);
              }}
              style={{
                fontFamily: "Rubik",
                borderRadius: "0.9375em",
                height: "3em",
                border: "1px solid #C4C4C4",
                padding: "1em",
                backgroundColor: "#FBFBFB",
                color: "#5D5C66",
                marginBottom: "1.1875em",
                fontSize: "1rem",
                width: "100%",
              }}
            />
            <p
              style={{
                fontSize: "1rem",
                fontWeight: "500",
                fontFamily: "Rubik",
                lineHeight: "1.1875em",
                color: "#5F41B2",
                margin: "0",
                cursor: "pointer",
                position: "absolute",
                top: "0.9375em",
                right: "1em",
              }}
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
            >
              {showPassword ? "hide" : "show"}
            </p>
          </div>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              marginTop: "0.4375em",
            }}
          >
            <Checkbox
              sx={{
                width: "1.125em",
                height: "1.125em",
                marginRight: "0.6875em",
              }}
            />
            <p
              style={{
                color: "#5D5C66",
                fontFamily: "Rubik",
                fontSize: "0.875rem",
                lineHeight: "1.214em",
                margin: "0",
              }}
            >
              Remember me
            </p>
            <p
              onClick={handleResetLink}
              style={{
                fontFamily: "Inter",
                fontWeight: "600",
                fontSize: "0.875rem",
                lineHeight: "1.214em",
                textDecoration: "underline",
                color: "#212427",
                margin: "0 0 0 auto",
                cursor: "pointer",
              }}
            >
              Forgot password?
            </p>
          </Box>
          <Button
            onClick={handleSignIn}
            style={{
              height: "3em",
              borderRadius: "0.9375em",
              backgroundColor: "#5F41B2",
              color: "#FFFFFF",
              fontFamily: "Inter",
              fontSize: "1rem",
              textTransform: "none",
              margin: "2.1875em 0 2em 0",
            }}
          >
            Log in
          </Button>
          <Divider
            sx={{
              fontFamily: "Montserrat",
              fontSize: "0.875rem",
              color: "#5D5C66",
              marginBottom: "1.5em",
            }}
          >
            Or
          </Divider>
          <Button
        onClick={() => login()}
            startIcon={<img src={require("../../assets/img/google.png")} />}
            style={{
              height: "3.2em",
              borderRadius: "1.33em",
              color: "#5D5C66",
              fontFamily: "Inter",
              fontWeight: "500",
              fontSize: "0.9375rem",
              textTransform: "none",
              marginBottom: "1.0667em",
              border: "1px solid #C4C4C4",
            }}
          >
            Sign in with Google
          </Button>
          <p
            style={{
              textAlign: "center",
              fontFamily: "Rubik",
              fontWeight: "500",
              lineHeight: "1.1875em",
              color: "#212427",
              fontSize: "1rem",
            }}
          >
            Don't have an account?{" "}
            <span
              style={{
                fontWeight: "600",
                color: "#5F41B2",
                textDecoration: "underline",
                textUnderlineOffset: "3px",
                cursor: "pointer",
              }}
              onClick={handleSignUp}
            >
              Sign up
            </span>
          </p>
        </Box>
      </Box>
      <SignPhotos isAgency={true} />
    </Box>
  );
}
