import { Card } from "@mui/material";
import LineBreak from "./LineBreak";
import BreakpointSize from "../Hooks/BreakpointSize";
const TitleAndBodyCard = ({ title, children, style }) => {
  const { isMobile } = BreakpointSize();

  return (
    <>
      <Card
        style={{
          borderRadius: 20,
          ...style,
          boxShadow: "0px 2px 12px rgba(38, 36, 131, 0.15)",
        }}
      >
        <div style={{ margin: 5 }}>
          <h2
            style={{
              marginLeft: !isMobile && 20,
              color: "#3B3666",
              textAlign: isMobile ? "center" : "left",
              fontFamily: "Rubik",
              fontStyle: "normal",
              fontSize: "32px",

              fontWeight: 500,
            }}
          >
            {title}
          </h2>
          <LineBreak />
          {children}
        </div>
      </Card>
    </>
  );
};
export default TitleAndBodyCard;
