import React from "react";
import store from "store";

import Grid from "@material-ui/core/Grid";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Avatar from "@material-ui/core/Avatar";
import { RangeDatePicker } from "react-google-flight-datepicker";
import "react-google-flight-datepicker/dist/main.css";
import airport from "airportsjs";
import TextFieldLandingForm from "./TextFieldLandingForm";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import { SingleDatePicker } from "react-google-flight-datepicker";

function AutoCompleteFlights({
  selectDestinationAirport,
  route,
  selectOriginAirport,
}) {
  const options = airport.searchByAirportName(" ");
  const defaultProps = {
    getOptionLabel: (option) => {
      return option.city + " " + option.name + "\n ," + option.country;
    },
  };
  const today = new Date();

  return (
    <Grid
      style={{
        display: "flex",
        flex: 1,
        flexDirection: window.innerWidth < 720 ? "column" : "row",
      }}
    >
      {/* Outbound Flight Auto Complete */}
      <Grid item xs={12} sm={6} md={3} style={{ paddingRight: "1.5%" }}>
        <Autocomplete
          {...defaultProps}
          options={options}
          groupBy={(option) => option.city}
          id="grouped-demo"
          autoSelect={false}
          color="white"
          size="small"
          onChange={selectOriginAirport}
          renderInput={(params) => (
            <TextFieldLandingForm params={params} text="from" />
          )}
        />
      </Grid>
      <Grid
        item
        style={{
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
          marginTop: "2%",
        }}
      >
        <Avatar
          style={{
            border: "1px solid",
            position: "absolute",
            zIndex: 1,
            backgroundColor: "white",
            borderInlineColor: store.getState().AgencyReducer.agency.branding
              .primaryColor,
          }}
        >
          <SwapHorizIcon
            style={{ width: "50%" }}
            htmlColor={
              store.getState().AgencyReducer.agency.branding.primaryColor
            }
          />
        </Avatar>
      </Grid>
      <Grid
        item
        xs={12}
        sm={6}
        md={3}
        style={{ paddingRight: "2%", paddingLeft: "1.5%" }}
      >
        <Autocomplete
          required
          {...defaultProps}
          options={options}
          autoSelect={false}
          groupBy={(option) => option.city}
          id="grouped-demo"
          size="small"
          onChange={selectDestinationAirport}
          renderInput={(params) => (
            <TextFieldLandingForm params={params} text="to" />
          )}
        />
      </Grid>

      {/* Return Date Picker */}
      <Grid item xs={12} sm={6} md={6} style={{ marginTop: "2%" }}>
        {route == "Two Way" ? (
          <RangeDatePicker
            startDate={new Date()}
            endDate={new Date(today.getTime() + 24 * 60 * 60 * 1000)}
            onChange={(startDate, endDate) => {
              store.dispatch({
                type: "setDepartureDate",
                payload: startDate,
              });
              store.dispatch({
                type: "setReturnDate",
                payload: endDate,
              });
            }}
            onFocus={() => {
              return "";
            }}
            minDate={new Date()}
            maxDate={new Date(2100, 0, 1)}
            dateFormat="DD MMM YYYY"
            monthFormat="MMM YYYY"
            startDatePlaceholder="Start Date"
            endDatePlaceholder="End Date"
            disabled={false}
            startWeekDay="monday"
          />
        ) : (
          <SingleDatePicker
            startDate={new Date()}
            onChange={(startDate) => {
              store.dispatch({
                type: "setDepartureDate",
                payload: startDate,
              });
            }}
            minDate={new Date()}
            maxDate={new Date(2100, 0, 1)}
            dateFormat="DD MMM YYYY"
            monthFormat="MMM YYYY"
            startDatePlaceholder="Start Date"
            endDatePlaceholder="End Date"
            disabled={false}
            startWeekDay="monday"
          />
        )}
      </Grid>
    </Grid>
  );
}

export default AutoCompleteFlights;
