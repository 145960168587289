//  card imports
import React, { Component } from "react";
//  import images
import { FaqsSections } from "./FAQs/Faqs";
import { IllustrationSection } from "./illustrationSection/IllustrationSection";
import InstagramProfile from "./instagramSection/InstagramProfile";

export class Home extends Component {
  render() {
    return (
      <div style={{ backgroundColor: "#ffff" }}>
        <InstagramProfile />
        <div style={{ backgroundColor: "#F1F2F8" }}>
          <IllustrationSection />
        </div>

        <FaqsSections />
      </div>
    );
  }
}

export default Home;
