import { Box, Grid, Drawer, IconButton } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import IconAndText from "./IconAndText";
import BreakpointSize from "../Hooks/BreakpointSize";
import LineBreak from "./LineBreak";
import ImageAndText from "./ImageAndText";

const Sidebar = ({ pathMap }) => {
  const { isMobile } = BreakpointSize();

  const [drawerState, setDrawerState] = useState(false);
  const navigate = useNavigate();
  const currentPath = window.location.pathname;
  if (isMobile) {
    return (
      <>
        <Grid
          item
          md={12}
          sm={12}
          xs={12}
          style={{
            position: "fixed",
            width: "100%",
            background: "linear-gradient(187.2deg, #665EB1 , #3B3666 232.8%)",
            boxShadow: "10px 0px 20px rgba(102, 94, 177, 0.2)",
          }}
        >
          <IconButton
            onClick={() => setDrawerState(true)}
            style={{ color: "white" }}
          >
            <MenuIcon />
          </IconButton>
        </Grid>

        <Drawer
          anchor="left"
          open={drawerState}
          onClose={() => setDrawerState(false)}
        >
          <Box
            onClick={() => setDrawerState(false)}
            onKeyDown={() => setDrawerState(false)}
            style={{
              background:
                "linear-gradient(187.2deg, #665EB1 -43.35%, #3B3666 232.8%)",
              boxShadow: "10px 0px 20px rgba(102, 94, 177, 0.2)",
            }}
          >
            <img
              src="./images/main-logo.svg"
              alt=""
              style={{
                padding: 10,
                marginLeft: 20,
                paddingTop: 30,
                paddingBottom: 30,
              }}
            />
            <Grid container>
              {pathMap.map(({ path, ComponentName, Icon, img }, index) => (
                <Grid
                  item
                  xs={12}
                  key={index}
                  onClick={() => navigate(path)}
                  style={{ pointer: "cursor" }}
                >
                  <div
                    style={{ marginLeft: 20, padding: 10, textAlign: "left" }}
                  >
                    <IconAndText
                      Icon={Icon[+(currentPath === path)]}
                      text={ComponentName}
                    />
                  </div>
                </Grid>
              ))}
              <div style={{ margin: 20 }} />
            </Grid>
          </Box>
        </Drawer>
      </>
    );
  }

  return (
    <>
      <div
        style={{
          minHeight: "100%",

          background:
            "linear-gradient(187.2deg, #665EB1 -43.35%, #3B3666 232.8%)",
          boxShadow: "10px 0px 20px rgba(102, 94, 177, 0.2)",
        }}
      >
        <img
          src="./images/main-logo.svg"
          alt=""
          style={{
            padding: 10,
            marginLeft: 20,
            paddingTop: 40,
            paddingBottom: 30,
          }}
        />
        <Grid container sx={{ pt: 3 }}>
          {pathMap.map(({ path, ComponentName, Icon, img }, index) => (
            <Grid
              item
              xs={12}
              key={index}
              onClick={() => navigate(path)}
              style={{ pointer: "cursor" }}
            >
              <div
                style={{
                  borderLeft: currentPath === path && "4px solid white",
                  borderRadius: 2,
                  paddingLeft: 30,
                  paddingTop: 10,
                  paddingBottom: 10,
                  textAlign: "left",
                }}
              >
                <IconAndText
                  textStyle={{
                    fontWeight: 300,
                    fontSize: 18,
                  }}
                  Icon={Icon[+(currentPath === path)]}
                  text={ComponentName}
                />
              </div>
            </Grid>
          ))}
          <div style={{ margin: 20 }} />
        </Grid>
      </div>
    </>
  );
};
export default Sidebar;
