import DateFnsUtils from "@date-io/date-fns";
import IconButton from "@material-ui/core/IconButton";
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import ClearIcon from "@material-ui/icons/Clear";
import Autocomplete from "@material-ui/lab/Autocomplete";
import airport from "airportsjs";
import { SingleDatePicker } from "react-google-flight-datepicker";
import "react-google-flight-datepicker/dist/main.css";

import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import MaterialButton from "components/CustomButtons/Button.js";
import dateformat from "dateformat";
import AddIcon from "@material-ui/icons/Add";

import React, { Component } from "react";
import store from "store";
import TextFieldLandingForm from "./TextFieldLandingForm";
import { Grid } from "@material-ui/core";
export class Multicity extends Component {
  constructor(props) {
    super(props);
    this.state = {
      departureDate: dateformat(new Date(), "isoDate"),
      Origin: "",
      destination: "",
    };
  }

  handleDepartureDateChange = (date) => {
    this.props.changeDate(this.props.index, date);
  };

  handleOrigin = (event, newValue) => {
    this.props.changeOrigin(this.props.index, newValue);
  };

  handleDestination = (event, newValue) => {
    this.props.changeDestination(this.props.index, newValue);
  };

  handleRemove = (event) => {
    event.preventDefault();
    this.props.remove(this.props.index);
  };

  render() {
    const options = airport.searchByAirportName(" ");

    const defaultProps = {
      getOptionLabel: (option) => option.name,
    };

    const theme = createTheme({
      palette: {
        primary: {
          main: store.getState().AgencyReducer.agency.branding.primaryColor,
        },
      },
    });

    const index = this.props.index;
    const slices = this.props.slices;
    let minDate = dateformat(new Date(), "isoDate");
    if (this.props.index !== 0) {
      minDate = this.props.slices[index - 1].departure_date;
    }

    return (
      <GridContainer>
        <MuiThemeProvider theme={theme}>
          {/* Outbound Flight Auto Complete */}
          <GridItem xs={11} sm={6} md={4}>
            <Autocomplete
              {...defaultProps}
              options={options}
              groupBy={(option) => option.city}
              id="grouped-demo"
              autoSelect={false}
              color="white"
              size="small"
              onChange={this.handleOrigin}
              renderInput={(params) => (
                <TextFieldLandingForm params={params} text="from" />
              )}
            />
          </GridItem>

          {/* Return Flight Auto Complete */}
          <GridItem xs={11} sm={6} md={4}>
            <Autocomplete
              {...defaultProps}
              options={options}
              groupBy={(option) => option.city}
              id="grouped-demo"
              autoSelect={false}
              color="white"
              size="small"
              onChange={this.handleDestination}
              renderInput={(params) => (
                <TextFieldLandingForm params={params} text="from" />
              )}
            />
          </GridItem>

          {/* Outbound Date Picker */}
          <Grid item xs={11} sm={6} md={3} style={{ marginTop: "2%" }}>
            <SingleDatePicker
              startDate={new Date()}
              onChange={(startDate) => {
                store.dispatch({
                  type: "setArrDate",
                  payload: { date: startDate, index: this.props.index },
                });
              }}
              minDate={new Date()}
              maxDate={new Date(2100, 0, 1)}
              dateFormat="DD MMM YYYY"
              monthFormat="MMM YYYY"
              startDatePlaceholder="Start Date"
              endDatePlaceholder="End Date"
              disabled={false}
              startWeekDay="monday"
            />
          </Grid>

          {}
          <GridItem xs={1}>
            <IconButton
              aria-label="delete"
              color="primary"
              style={{ size: "small", marginTop: "25px" }}
              onClick={this.handleRemove}
            >
              <ClearIcon />
            </IconButton>
          </GridItem>
        </MuiThemeProvider>
      </GridContainer>
    );
  }
}

export default Multicity;
