import React, { Component } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "./Stripe";
import { connect } from "react-redux";

const stripePromise = loadStripe(
  "pk_test_51LZAtpGKeAqaUdx8A5WphmDRmTc9bMg4ZXSUaDit1HFCuZ5446xh4WtF6ojIkexOV9NY16JcTqDzNQWOrQkrrTXu00O9mwi2dR"
);

export class CreditCardForm extends Component {
  render() {
    return (
      <div>
        <Elements stripe={stripePromise}>
          <CheckoutForm
            plan={this.props.plan}
            amount={this.props.amount}
            classes={this.props.classes}
          />
        </Elements>
      </div>
    );
  }
}

export default CreditCardForm;
