import React, { useEffect } from "react";
import { connect } from "react-redux";
import axios from "helpers/axios";
import { useNavigate } from "react-router-dom";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { launchService } from "./SignUpService";

const mapDispatchToState = (dispatch) => {
  return {
    setVerificationCode: (
      verificationCode //whenever we call this function we are dispatching this action
    ) => {
      dispatch({ type: "setVerificationCode", code: verificationCode });
    },
    setRegisterEmail: (registerEmail) => {
      dispatch({ type: "setRegisterEmail", registerEmail: registerEmail });
    },
  };
};

const mapStateToProps = (state) => {
  return { signUpInfo: state.SignUpReducer }; //state.initialState is what we grab from redux
  //and signupInfo iswhat we apply this data to
};

const Launch = ({ signUpInfo }) => {
  const [text, setText] = React.useState(false);
  const history = useNavigate();

  const handleLaunch = async () => {
    let body = {
      email: signUpInfo.registerEmail,
      password: signUpInfo.passwordReducer,
    };

    let url = "https://trollii.uk:8080/travelagency/login";
    await launchService({
      email: signUpInfo.registerEmail,
      businessName: signUpInfo.businessNameReducer,
      phoneNumber: signUpInfo.publicNumberReducer,
      address: signUpInfo.addressLine1Reducer,
    });
    await axios
      .post(url, body)
      .then(async (response) => {
        const token = response.data.token;

        setText(false);
        if (response.data.user.instagram_token != "") {
          localStorage.setItem("instagram", "connected");
        } else {
          localStorage.setItem("instagram", "disconnected");
        }
        localStorage.setItem("id", response.data.user._id);
        localStorage.setItem("trolliiurl", response.data.user.url);
        localStorage.setItem("agencyToken", token);
        setText(false);
      })
      .catch((e) => {
        console.log(e);
        setText(true);
      });
  };
  useEffect(() => {
    handleLaunch();
  });

  return (
    <div style={{ paddingTop: "1.2rem", backgroundColor: "white" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          flex: 1,
          width: "100%",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <h1
          className="h1"
          style={{
            padding: 10,
            borderRadius: 10,
            color: "rgb(20, 30, 97)",
            fontFamily: "Poppins",
            margin: 10,
            display: "flex",
            flex: 1,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          Launch your Agency!
        </h1>
        <div
          style={{
            marginTop: "2.5em",
            display: "flex",
            flex: 1,
            alignItems: "center",
            justifyContent: "center",
          }}
          className="d-flex align-items-center justify-content-center"
        >
          <button
            className="btn btn-primary mb-5"
            style={{
              width: "15%",
              height: 70,
              border: 1,
              borderRadius: "40%",
              backgroundColor: "rgb(20, 30, 97)",
            }}
            onClick={() => {
              window.location.replace(
                `http://${signUpInfo.businessNameReducer}.trollii.co`
              );
            }}
          >
            LAUNCH
          </button>
        </div>
      </div>
      <Card sx={{ maxWidth: "70%", textAlign: "start", margin: "0 auto" }}>
        <CardContent style={{ padding: "0" }}>
          <Typography
            style={{
              marginBottom: "20px",
              backgroundColor: "#9c27b0",
              margin: "0",
              fontFamily: "Poppins",
              color: "white",
              fontSize: "1.2rem",
              padding: "10px",
            }}
            gutterBottom
            component="span"
          >
            Personal Information
          </Typography>
          <Typography
            style={{ margin: "1em", marginLeft: "2.5em", fontSize: "1.1em" }}
            variant="body2"
            color="text.secondary"
          >
            <strong> First Name: </strong> {signUpInfo.firstNameRegister}
          </Typography>
          <Typography
            style={{ margin: "1em", marginLeft: "2.5em", fontSize: "1.1em" }}
            variant="body2"
            color="text.secondary"
          >
            <strong> Last Name: </strong> {signUpInfo.lastNameRegister}
          </Typography>
          <Typography
            style={{ margin: "1em", marginLeft: "2.5em", fontSize: "1.1em" }}
            variant="body2"
            color="text.secondary"
          >
            <strong> Email: </strong> {signUpInfo.registerEmail}
          </Typography>
        </CardContent>
      </Card>

      <Card sx={{ maxWidth: "70%", textAlign: "start", margin: "1em auto" }}>
        <CardContent style={{ padding: "0" }}>
          <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
            <Typography
              style={{
                display: "flex",
                flex: 1,
                alignItems: "flex-start",
                justifyContent: "flex-start",
                marginBottom: "20px",
                backgroundColor: "#9c27b0",
                margin: "0",
                fontFamily: "Poppins",
                color: "white",
                fontSize: "1.2rem",
                padding: "10px",
              }}
              gutterBottom
              component="span"
            >
              Private Bussiness Information
            </Typography>
            <Typography
              style={{
                display: "flex",
                flex: 1,
                alignItems: "center",
                justifyContent: "center",
                marginBottom: "20px",
                backgroundColor: "#9c27b0",
                fontFamily: "Poppins",
                color: "white",
                margin: "0",
                fontSize: "1.2rem",
                padding: "10px",
              }}
              gutterBottom
              component="span"
            >
              Public Bussiness Information
            </Typography>
            <Typography
              style={{
                display: "flex",
                flex: 1,
                alignItems: "flex-end",
                justifyContent: "flex-end",
                marginBottom: "20px",
                backgroundColor: "#9c27b0",
                margin: "0",
                fontFamily: "Poppins",
                color: "white",
                fontSize: "1.2rem",
                padding: "10px",
              }}
              gutterBottom
              component="span"
            >
              Products
            </Typography>
          </div>
          <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
            <Typography
              style={{
                display: "flex",
                flex: 1,
                alignItems: "flex-start",
                justifyContent: "flex-start",
                margin: "1em",
                marginLeft: "2.5em",
                fontSize: "1em",
              }}
              variant="body2"
              color="text.secondary"
            >
              <strong> Business Type:</strong> {signUpInfo.businessTypeReducer}
            </Typography>
            <Typography
              style={{
                display: "flex",
                flex: 1,
                alignItems: "center",
                justifyContent: "center",
                margin: "1em",
                marginLeft: "2.5em",
                fontSize: "1em",
              }}
              variant="body2"
              color="text.secondary"
            >
              <strong>Phone Number:</strong> {signUpInfo.publicNumberReducer}
            </Typography>
            {signUpInfo.chosenProductsReducer.map((item, index) => (
              <Typography
                key={index}
                style={{
                  display: "flex",
                  flex: 1,
                  alignItems: "flex-end",
                  justifyContent: "flex-end",
                  margin: "1em",
                  marginLeft: "2.5em",
                  fontSize: "1em",
                }}
                variant="body2"
                color="text.secondary"
              >
                <strong>{item}</strong>
              </Typography>
            ))}
          </div>
          <div style={{ display: "flex", flexDirection: "row", width: "68%" }}>
            <Typography
              style={{
                display: "flex",
                flex: 1,
                alignItems: "flex-start",
                justifyContent: "flex-start",
                margin: "1em",
                marginLeft: "2.5em",
                fontSize: "1em",
              }}
              variant="body2"
              color="text.secondary"
            >
              <strong> Business Name: </strong> {signUpInfo.businessNameReducer}
            </Typography>
            <Typography
              style={{
                display: "flex",
                flex: 1,
                alignItems: "center",
                justifyContent: "center",
                margin: "1em",
                marginLeft: "2.5em",
                fontSize: "1em",
              }}
              variant="body2"
              color="text.secondary"
            >
              <strong>Email:</strong> {signUpInfo.publicEmailReducer}
            </Typography>
          </div>
          <Typography
            style={{ margin: "1em", marginLeft: "2.5em", fontSize: "1em" }}
            variant="body2"
            color="text.secondary"
          >
            <strong> Email: </strong> {signUpInfo.businessPrivateEmailReducer}
          </Typography>
          <Typography
            style={{ margin: "1em", marginLeft: "2.5em", fontSize: "1em" }}
            variant="body2"
            color="text.secondary"
          >
            <strong> Line 1: </strong> {signUpInfo.addressLine1Reducer}
          </Typography>
          <Typography
            style={{ margin: "1em", marginLeft: "2.5em", fontSize: "1em" }}
            variant="body2"
            color="text.secondary"
          >
            <strong> Line 2: </strong> {signUpInfo.addressLine2Reducer}
          </Typography>
          <Typography
            style={{ margin: "1em", marginLeft: "2.5em", fontSize: "1em" }}
            variant="body2"
            color="text.secondary"
          >
            <strong> City / District: </strong>
            {signUpInfo.cityReducer}
          </Typography>
          <Typography
            style={{ margin: "1em", marginLeft: "2.5em", fontSize: "1em" }}
            variant="body2"
            color="text.secondary"
          >
            <strong> State / Province: </strong>
            {signUpInfo.stateReducer}
          </Typography>
          <Typography
            style={{ margin: "1em", marginLeft: "2.5em", fontSize: "1em" }}
            variant="body2"
            color="text.secondary"
          >
            <strong> Postal Code:</strong>
            {signUpInfo.postReducer}
          </Typography>
          <Typography
            style={{ margin: "1em", marginLeft: "2.5em", fontSize: "1em" }}
            variant="body2"
            color="text.secondary"
          >
            <strong> Country: </strong>
            {signUpInfo.countryReducer}
          </Typography>
        </CardContent>
      </Card>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToState)(Launch);
