import "assets/scss/material-kit-react.scss?v=1.10.0";
import React from "react";
import { Route, Routes } from "react-router-dom";
import RegisterationBusinessProfile from "../AgencyRegisteration/BusinessProfile";
import RegisterationChooseProducts from "../AgencyRegisteration/ChooseProducts";
import RegisterationInstagram from "../AgencyRegisteration/Instagram";
import RegisterationLaunch from "../AgencyRegisteration/Launch";
import RegisterationNavBar from "../AgencyRegisteration/Navbar";
import RegisterationSignUp from "../AgencyRegisteration/SignUp";
import RegisterationTheme from "../AgencyRegisteration/Theme";
import RegisterationTheme1Details from "../AgencyRegisteration/Theme1Details";
import RegisterationTheme2Details from "../AgencyRegisteration/Theme2Details";
import RegisterationTheme3Details from "../AgencyRegisteration/Theme3Details";
import RegisterationVerificationCode from "../AgencyRegisteration/VerificationCode";
import PasswordChange from "../views/LoginModule/PasswordChange.js";

function RegisterationNavigation() {
  return (
    <div>
      <Routes>
        <Route exact path="/register" element={<RegisterationSignUp />} />
        <Route exact path="/changepassword" element={<PasswordChange />} />

        <Route
          exact
          path="/verification"
          element={<RegisterationVerificationCode />}
        />
        <Route
          exact
          path="/businessProfile"
          element={<RegisterationBusinessProfile />}
        />
        <Route
          exact
          path="/chooseProducts"
          element={<RegisterationChooseProducts />}
        />
        <Route exact path="/theme" element={<RegisterationTheme />} />
        <Route
          exact
          path="/theme1Details"
          element={<RegisterationTheme1Details />}
        />
        <Route
          exact
          path="/theme2Details"
          element={<RegisterationTheme2Details />}
        />
        <Route
          exact
          path="/theme3Details"
          element={<RegisterationTheme3Details />}
        />
        <Route exact path="/launch" element={<RegisterationLaunch />} />
        <Route exact path="/instagram" element={<RegisterationInstagram />} />
      </Routes>
    </div>
  );
}

export default RegisterationNavigation;
