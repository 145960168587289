import React from "react";
import store from "store";
import InputLabel from "@material-ui/core/InputLabel";
import TextField from "@material-ui/core/TextField";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import InputAdornment from "@mui/material/InputAdornment";
import FlightLandIcon from "@mui/icons-material/FlightLand";
import LocationIcon from "../../../../assets/img/location.png";

import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
  gpsIcon: {
    position: "absolute",
    borderLeftColor: "3%",
    marginLeft: "3%",
  },
  input: {
    "& > label": {
      paddingLeft: "2.5em !important",
    },
    "& > div > input": {
      paddingLeft: "2.5em !important",
    },
  },
}));
function TextFieldLandingForm({ params, text }) {
  const classes = useStyles();

  const [val, setVal] = React.useState("");

  return (
    <div>
      <TextField
        {...params}
        className={classes.input}
        InputProps={{
          ...params.InputProps,
          disableUnderline: true,
          startAdornment: (
            <InputAdornment position="start">
              <LocationOnIcon
                style={{ position: "absolute", left: "5%", marginBottom:'13px' }}
                htmlColor="#353539"
              />
            </InputAdornment>
          ),
        }}
        style={{
          borderRadius: "5%",
          marginBottom: "5%",
          backgroundColor: store.getState().AgencyReducer.agency.branding.sixth
            ? store.getState().AgencyReducer.agency.branding.sixth
            : "transparent",
        }}
        required
      />
    </div>
  );
}

export default TextFieldLandingForm;
