import Card from "@material-ui/core/Card";
import Book from "../../../../components/CardAnimations/book";
import hosts from "../../../../constants/hosts";
import React, { Component } from "react";
import { connect } from "react-redux";

import Grid from "@mui/material/Grid";
import { chooseOffer, getPassengers } from "../../../../redux/actions/offerActions";
import axios from "axios";
import store from "../../../../store";
import Styles from "./OfferStyles";
import OfferStops from "./offerStops";
import BookFlight from "./BookFlight";
import MoreInfo from "./MoreInfo";
import OfferExpand from "./OfferExpand";

export class Offer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expand: false,
      mount: false,
      fare: "0",
    };
  }

  handleExpand = (event) => {
    event.preventDefault();
    this.setState({
      expand: !this.state.expand,
    });
  };
  handleFare = (event) => {
    this.setState({
      fare: event.target.value,
    });
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    this.setState({ mount: true });
    let index = parseInt(this.state.fare);
    const chosen = this.props.offer[index];
    const passengers = {
      passengers: this.props.offer[index].passengers,
      adults: this.props.adults,
      children: this.props.children,
    };
    this.props.getPassengers(passengers);
    this.props.chooseOffer(chosen, () => {});
    this.props.history("/FlightDetails");
    this.setState({ mount: false });
    let url = `${hosts}/orders/click`;
    await axios.post(url, { offer_id: this.props.offer[0].id });
  };

  render() {
    const classes = this.props.classes;
    const offer = this.props.offer[0];
    const color = store.getState().AgencyReducer.agency.branding.primaryColor;
    let co2 = parseFloat(offer.total_emissions_kg);
    let green = co2 <= this.props.avgCO2 ? true : false;

    return (
      <div>
        <Card className={classes.root} raised style={Styles.CardStyle}>
          {this.state.mount ? (
            <Book />
          ) : (
            <div style={{ backgroundColor: "#ffffff" }}>
              <Grid container>
                <Grid item xs={12} sm={2} md={2} lg={2}>
                  <img
                    width="75%"
                    src={
                      "https://daisycon.io/images/airline/?width=150&height=75&color=ffffff&iata=" +
                      offer.owner.iata_code
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={7} md={7} lg={7}>
                  <OfferStops offer={offer} />
                </Grid>
                <BookFlight
                  offer={offer}
                  green={green}
                  handleSubmit={this.handleSubmit}
                />
              </Grid>
              <MoreInfo
                classes={classes}
                handleExpand={this.handleExpand}
                expand={this.state.expand}
              />
              <OfferExpand
                color={color}
                handleFare={this.handleFare}
                offer={offer}
                state={this.state}
              />
            </div>
          )}
        </Card>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  chosenOffer: state.chosenOffer.offer,
  travellers: state.chosenOffer.passengers,
});

export default connect(mapStateToProps, { chooseOffer, getPassengers })(Offer);
