const initialState = {
  offers: [],
  filteredOffers: [],
  dateArr: [],
  offerRef: "",
  departureDate: "",
  returnDate: "",
  offerClass: "economy",
};

export default (state = initialState, action) => {
  if (action.type == "GET_OFFERS") {
    state.offers = action.payload;
    return {
      ...state,
    };
  }
  if (action.type == "FILTERED_OFFERS") {
    state.filteredOffers = action.payload;
    return {
      ...state,
    };
  }
  if (action.type == "setOfferRef") {
    state.offerRef = action.payload;
    return {
      ...state,
    };
  }

  if (action.type == "setDepartureDate") {
    state.departureDate = action.payload;
    return {
      ...state,
    };
  }
  if (action.type == "setArrDate") {
    let temp = state.dateArr;
    temp[action.payload.index] = action.payload.date;
    state.dateArr = temp;
    return {
      ...state,
    };
  }
  if (action.type == "setReturnDate") {
    state.returnDate = action.payload;
    return {
      ...state,
    };
  }
  if (action.type == "setOfferClass") {
    state.offerClass = action.payload;
    return {
      ...state,
    };
  }
  return state;
};
