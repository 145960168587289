import React from "react";
import store from "../../store";
function Warnings({ filters }) {
  return (
    <div>
      <h4
        style={{
          fontSize: 30,
          color: store.getState().AgencyReducer.agency.branding.primaryColor,
          textAlign: "center",
        }}
      >
        {filters ? (
          <div>
            {" "}
            <b> Sorry! We found no results.</b>
            <br />
            Try again with different <b>filters</b>
          </div>
        ) : (
          <div>
            {" "}
            <b> Sorry! We found no results.</b>
            <br />
            Try again with different <b>airports</b> and <b>dates.</b>
          </div>
        )}
      </h4>
    </div>
  );
}

export default Warnings;
