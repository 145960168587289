import {
  Typography,
  Button,
  MenuItem,
  FormControl,
  Grid,
  ListItem,
  List,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  ListSubheader,
  Link,
  Box,
  Drawer,
  Toolbar,
  Divider,
  IconButton,
} from "@mui/material";
import store from "store";
import Dropdown from "react-bootstrap/Dropdown";

import Countries from "views/Hotels/Components/Countries";
import { styled, useTheme } from "@mui/material/styles";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import SettingsIcon from "../assets/icons/SettingsIcon";
import CurrencyIcon from "../assets/icons/CurrencyIcon";
import HomeIcon from "../assets/icons/HomeIcon";
import HelpIcon from "../assets/icons/HelpIcon";
import BookingsIcon from "../assets/icons/BookingsIcon";
import TripsIcon from "../assets/icons/TripsIcon";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { createTheme } from "@mui/material/styles";
import { purple } from "@mui/material/colors";
import { Book } from "@material-ui/icons";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";
import Cookies from "js-cookie";

const drawerWidth = 240;
const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-start",
  color: "white",
  backgroundColor: "white",
}));

const styles = {
  drawertxt: {
    fontFamily: "Rubik",
    fontStyle: "normal",
    fontWeight: 500,
    color: "#362566",
    fontSize: 16,
    align: "left",
  },
  Trollii: {
    fontFamily: "Fredoka",
    fontStyle: "normal",
    fontWeight: 600,
    color: "#5F41B3",
    fontSize: 24,
    justifyContent: "right",
  },
};
const Navbar = (props) => {
  const theme = createTheme({
    status: {
      danger: "#e53e3e",
    },
    palette: {
      primary: {
        main: "#0971f1",
        darker: "#053e85",
        purple: "#5D5C66",
      },
      neutral: {
        main: "#64748B",
        contrastText: "#fff",
      },
    },
  });
  const [openType, setOpenType] = React.useState("");
  const [openCountry, setOpenCountry] = React.useState("");

  let navigate = useNavigate();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const handleType = (event) => {
    setOpenType(event.target.value);
  };

  const handleCountry = (event) => {
    setOpenCountry(event.target.value);
  };

  return (
    <Grid container sx={{ pt: 2, backgroundColor: "white" }}>
      <Grid
        item
        md={5.5}
        sm={5.5}
        onClick={() => {
          navigate("/");
        }}
        sx={{
          pl: 1,
          cursor: "pointer",
          pb: 1,
          display: { md: "block", sm: "block", xs: "none" },
        }}
      >
        <Typography
          align="left"
          style={{
            color: "#5F41B3",
            fontFamily: "Fredoka",
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: 35,
            lineHeight: "42px",
            letterSpacing: -0.3,
          }}
        >
          Trollii
        </Typography>
      </Grid>

      <Grid
        item
        md={5}
        sm={5}
        xs={0}
        sx={{
          display: {
            xs: "none",
            md: "flex",
            sm: "flex",
          },
        }}
      >
        <Grid container sx={{ display: "flex" }}>
          <Grid item md={2.4} sm={2.4} sx={{ pt: 0.5 }}></Grid>

          <Grid item md={2.4} sm={2.4} xs={0} sx={{ pt: 1 }}>
            <Link
              onClick={() => {
                navigate("/my-bookings");
              }}
              sx={{
                fontFamily: "Rubik",
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: 16,
                textAlign: "center",
                outline: "none",
                color: "#362566",
                cursor: "pointer",
              }}
              underline="none"
            >
              Booking
            </Link>
          </Grid>

          <Grid item md={2.4} sm={2.4} xs={0} sx={{ pt: 0.5 }}>
            <FormControl variant="standard">
              <Select
                sx={{
                  fontFamily: "Rubik",
                  fontStyle: "normal",
                  fontWeight: 400,
                  fontSize: 16,
                  textAlign: "center",
                  outline: "none",
                  color: "#362566",
                }}
                disableUnderline
                value={
                  openCountry != ""
                    ? openCountry
                    : store.getState().client.client.currency
                }
                onChange={(event) => {
                  setOpenCountry(event.target.value);

                  store.dispatch({
                    type: "setCurrency",
                    payload: event.target.value,
                  });
                }}
              >
                {Countries.map((country, index) => {
                  return (
                    <MenuItem key={index} value={country.currencyCode}>
                      {country.countryName + " " + country.currencyCode}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        item
        md={1.5}
        sm={1.5}
        xs={0}
        sx={{
          justifyContent: "right",
          alignItems: "right",
          pl: 3,
          pt: 0.5,
          display: { xs: "none", md: "block", sm: "block" },
        }}
      >
        {store.getState().client.client.loggedIn == false ? (
          <Button
            onClick={() => {
              navigate("/landingSign");
            }}
            style={{
              backgroundColor: "#5F41B3",
              borderRadius: 10,
              width: 85,
            }}
            variant="filled"
          >
            <Typography
              style={{
                fontFamily: "Inter",
                fontStyle: "normal",
                fontWeight: 500,
                fontSize: 16,
                textAlign: "center",
                textTransform: "none",
                color: "white",
              }}
            >
              Login
            </Typography>
          </Button>
        ) : (
          <Dropdown>
            <Dropdown.Toggle
              style={{ backgroundColor: "white" }}
              id="dropdown-basic"
            >
              <Typography
                sx={{
                  fontFamily: "Inter",
                  fontStyle: "normal",
                  fontWeight: 500,
                  fontSize: 18,
                  textAlign: "center",
                  textTransform: "none",
                  color: "black",
                  cursor: "pointer",
                }}
              >
                {store.getState().client.client.username}
              </Typography>{" "}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item href="#/Account Settings">
                Account Settings
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  store.dispatch({ type: "setLoggedIn", loggedIn: false });
                }}
                href="#/logged out"
              >
                Log out
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        )}
      </Grid>

      <Grid
        item
        xs={12}
        sx={{ display: { md: "none", sm: "none", xs: "block" } }}
      >
        <Box sx={{ display: "flex" }}>
          <AppBar
            sx={{
              height: "9%",
              boxShadow: "0px 2px 8px rgba(38, 36, 131, 0.05)",
              width: "100%",
              left: 0,
              top: 0,
              display: "flex",
            }}
            open={open}
            color="transparent"
          >
            <Toolbar>
              <IconButton
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerOpen}
                sx={{ ...(open && { display: "none" }), color: purple[900] }}
              >
                <MenuIcon />
              </IconButton>
              <Typography
                variant="h6"
                noWrap
                sx={{
                  flexGrow: 1,
                  alignItems: "center",
                  fontFamily: "Fredoka",
                  fontStyle: "normal",
                  fontWeight: 600,
                  fontSize: 20,
                  color: "#5F41B3",
                }}
                component="span"
              >
                Trollii
              </Typography>

              <IconButton
                color="inherit"
                aria-label="login in"
                edge="end"
                onClick={() => {
                  navigate("/landingSign");
                }}
                sx={{ color: purple[900] }}
              >
                <AccountCircleOutlinedIcon />
              </IconButton>
            </Toolbar>
          </AppBar>

          <Drawer
            sx={{
              width: drawerWidth,
              flexShrink: 0,
              "& .MuiDrawer-paper": {
                width: drawerWidth,
              },
            }}
            variant="persistent"
            anchor="left"
            open={open}
          >
            <DrawerHeader>
              <IconButton onClick={handleDrawerClose}>
                {theme.direction === "ltr" ? (
                  <HighlightOffRoundedIcon sx={{ color: purple[900] }} />
                ) : (
                  <ChevronRightIcon />
                )}
              </IconButton>
              <Typography paddingLeft={15} sx={styles.Trollii}>
                Trollii
              </Typography>
            </DrawerHeader>
            <Box sx={{ width: "100%", bgcolor: "white" }}>
              <List sx={{ pb: 1 }}>
                <ListItem>
                  <ListItemButton>
                    <ListItemIcon>
                      <HomeIcon />
                    </ListItemIcon>
                    <ListItemText
                      primaryTypographyProps={styles.drawertxt}
                      primary="Home"
                    />
                  </ListItemButton>
                </ListItem>
                <ListItem>
                  <ListItemButton>
                    <ListItemIcon>
                      <TripsIcon />
                    </ListItemIcon>
                    <ListItemText
                      primaryTypographyProps={styles.drawertxt}
                      primary="Trips"
                    />
                  </ListItemButton>
                </ListItem>
                <ListItem>
                  <ListItemButton>
                    <ListItemIcon>
                      <BookingsIcon />
                    </ListItemIcon>
                    <ListItemText
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        navigate("/my-bookings");
                      }}
                      primaryTypographyProps={styles.drawertxt}
                      primary="Bookings"
                    />
                  </ListItemButton>
                </ListItem>
                <ListItem>
                  <ListItemButton>
                    <ListItemIcon>
                      <HelpIcon />
                    </ListItemIcon>
                    <ListItemText
                      primaryTypographyProps={styles.drawertxt}
                      primary="Help"
                    />
                  </ListItemButton>
                </ListItem>
                <ListItem>
                  <ListItemButton>
                    <ListItemIcon>
                      <CurrencyIcon />
                    </ListItemIcon>
                    <ListItemText
                      primaryTypographyProps={styles.drawertxt}
                      primary="Currency"
                    />
                  </ListItemButton>
                </ListItem>
                <ListItem sx={{ pb: 2 }}>
                  <ListItemButton>
                    <ListItemIcon>
                      <SettingsIcon />
                    </ListItemIcon>
                    <ListItemText
                      primaryTypographyProps={styles.drawertxt}
                      primary="Settings"
                    />
                  </ListItemButton>
                </ListItem>
                <Divider />
                <ListItem sx={{ pt: 2 }}>
                  <Grid container>
                    <Grid item xs={12}>
                      <Button
                        fullWidth
                        variant="contained"
                        sx={{
                          fontFamily: "Inter",
                          fontStyle: "normal",
                          fontWeight: 500,
                          fontSize: 16,
                          color: "white",
                          textAlign: "center",
                          textTransform: "none",
                          backgroundColor: "#5F41B3",
                          borderRadius: 3,
                        }}
                      >
                        Log in
                      </Button>
                    </Grid>
                  </Grid>
                </ListItem>
              </List>
            </Box>
          </Drawer>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Navbar;
