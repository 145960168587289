import React, { Component } from "react";
import { Typography, Rating } from "@mui/material";
import Grid from "@mui/material/Grid";

export default function TimingNavbar({ setTimingOption }) {
  return (
    <Grid container flexDirection={"row"} sx={{ pt: 3 }}>
      <Grid item md={2} sm={2} xs={2} />
      <Grid item md={8} sm={8} xs={8}>
        <Grid
          container
          flexDirection={"row"}
          sx={{
            borderColor: "#C4C4C4",
            borderStyle: "solid",
            borderWidth: 0.1,
            p: 3,
            borderRadius: 5,
          }}
        >
          <Grid
            item
            sx={{ cursor: "pointer" }}
            md={4}
            sm={4}
            xs={4}
            onClick={() => {
              setTimingOption("Active");
            }}
          >
            <Typography>Active</Typography>
          </Grid>
          <Grid
            item
            onClick={() => {
              setTimingOption("Past");
            }}
            sx={{ cursor: "pointer" }}
            md={4}
            sm={4}
            xs={4}
          >
            <Typography>Past</Typography>
          </Grid>
          <Grid item sx={{ cursor: "pointer" }} md={4} sm={4} xs={4}>
            <Typography>Cancelled</Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
