import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import EditIcon from '@mui/icons-material/Edit';
import Divider from '@mui/material/Divider';
import ArchiveIcon from '@mui/icons-material/Archive';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import ChildrenIconOutlined from "../../../../assets/img/childreniconoutlined.png"
import InfantsIconOutlined from "../../../../assets/img/infantsiconoutlined.png"
import Fab from "@material-ui/core/Fab";
import RemoveIcon from "@material-ui/icons/Remove";
import AddIcon from "@material-ui/icons/Add";
import store from "store";
import Menu, { MenuProps } from '@mui/material/Menu';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import Typography from "@material-ui/core/Typography";
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));

export default function TravellersDropdown({removeAdults,adults,addAdults,removeChild,selectAge,addChild,children,infants,addInfant,removeInfant,selectAgeInfant}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
   
      <Button
        id="demo-customized-button"
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
        style={{backgroundColor: 'white', color:"#5F41B2", "font-family": "Rubik",
            "font-size": "16px",
            "font-weight": "400",
            "line-height": "normal", textTransform: 'none', }}
      >
        {adults + children.length + infants.length} Traveller{(adults + children.length + infants.length )> 1? "s" : ""}
      </Button>
      <StyledMenu
 sx={{
    '& .MuiPaper-root': {width: "400px",
borderRadius: "20px",
border: "1px solid #C4C4C4",
background: "#FFF",
boxShadow: "0px 2px 8px 0px rgba(38, 36, 131, 0.10",}
  }}
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
     <MenuItem style={{color: "#362566",
fontFamily: "Rubik",
fontSize: "18px",
fontStyle: "normal",
fontWeight: "600",
lineHeight: "normal", padding:"1rem"}} disableRipple>
     Travellers
     </MenuItem>
        <MenuItem style={{display:"flex", justifyContent: "space-between",}}  disableRipple>
        <div style={{display:"flex", justifyContent: "space-between"}}>
          <PersonOutlineOutlinedIcon style={{width: "24px",
height: "24px",}} />
          <div>
          Adults <br></br>
          12+ years
          </div>
          </div>
          <div style={{display:"flex", justifyContent: "space-between", marginLeft: "1rem"}}>
            <RemoveCircleIcon
           
            onClick={removeAdults}
           style={{width: "24px", color:"#5F41B2", fontSize: "40px"
}}
            aria-label="Add"
          >
          </RemoveCircleIcon>
          <b
            style={{
             color: "#3B3666",
textAlign: "center",
fontFamily: "Rubik",
fontSize: "16px",
fontStyle: "normal",
fontWeight: "400",
lineHeight: "normal",
marginRight:"12px",
marginTop:"0.6rem",
            }}
          >{adults}
          </b>
          <AddCircleIcon
          style={{width: "24px", color:"#5F41B2", fontSize: "40px"
}}
          onClick={addAdults}
            size="small"
            color={
              store.getState().AgencyReducer.agency
                .branding.fifthColor
                ? store.getState().AgencyReducer.agency
                    .branding.fifthColor
                : "black"
            }
            aria-label="Add"
          >
            <AddIcon  />
          </AddCircleIcon>
          </div>
        </MenuItem>
        <br></br>
          <Divider sx={{ my: 0.5 }} />
        <MenuItem style={{display: "flex", flexWrap: "wrap", justifyContent: "space-between", gap:"1rem"}} disableRipple>
        <div style={{display:"flex", justifyContent: "space-between"}}>
           <img
                src={ChildrenIconOutlined}
                style={{ width: "30px", height: "24px", marginTop: "0.5vw" }}
              />
              <div>
          Children <br></br>
          1-12
          </div>
          </div> 
          <div style={{display:"flex", justifyContent: "space-between", marginLeft: "1rem"}}>
           <RemoveCircleIcon
           style={{width: "24px", color:"#5F41B2", fontSize: "40px"
}}
           onClick={removeChild}
           
            
            aria-label="Add"
          >
          </RemoveCircleIcon>
          <b
            style={{
             color: "#3B3666",
textAlign: "center",
fontFamily: "Rubik",
fontSize: "16px",
fontStyle: "normal",
fontWeight: "400",
lineHeight: "normal",
marginRight:"12px",
marginTop:"0.6rem"
            }}
          >
            {children.length}
           
          </b>
          <AddCircleIcon
          style={{width: "24px", color:"#5F41B2", fontSize: "40px"
}}
          onClick={addChild}
          
            aria-label="Add"
            
          >
          </AddCircleIcon>
          </div>
        <div
          style={{ width:"100%" }}
        >
          {children.map((child, index) => {
            return (
              <div
                key={"div" + index}
                style={{
                  flex: 1,
                  flexBasis: "30%",
                }}
              >
                <Typography
                  key={index}
                  style={{
                    color: store.getState().AgencyReducer
                      .agency.branding.fifthColor
                      ? store.getState().AgencyReducer
                          .agency.branding.fifthColor
                      : "black",
                    fontSize: 12,
                  }}
                >
                  {" "}
                  Select Age of Child {index + 1}
                </Typography>
                <CustomDropdown
                  dropdown
                  key={"drop" + index}
                  style={{ width: "80px", margin: 0, }}
                  onClick={selectAge.bind(
                    this,
                    index
                  )}

                  dropdownHeader={"Age of Child" + index}
                  buttonText={children[index]}
                  buttonProps={{
                    color: "primary",
                  }}
                  dropdownList={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15]}
                />
              </div>
               );
          })}
        </div>
        </MenuItem>
        <Divider sx={{ my: 0.5 }} />
        <MenuItem style={{display: "flex", flexWrap: "wrap", justifyContent: "space-between", gap:"1rem"}} disableRipple>
          <div style={{display:"flex", justifyContent: "space-between"}}>
           <img
                src={InfantsIconOutlined}
                style={{ width: "35px",height:"35px",marginTop: "0.5vw" }}
              />
              <div>
          Infants <br></br>
          Under 2 years
          </div>
          </div>
          <div style={{display:"flex", justifyContent: "space-between", marginLeft: "1rem"}}>
                     <RemoveCircleIcon
                     style={{width: "24px", color:"#5F41B2", fontSize: "40px"
}}
           onClick={removeInfant}
            
           
            aria-label="Add"
          >
          </RemoveCircleIcon>
          <b
             style={{
             color: "#3B3666",
textAlign: "center",
fontFamily: "Rubik",
fontSize: "16px",
fontStyle: "normal",
fontWeight: "400",
lineHeight: "normal",
marginRight:"12px",
marginTop:"0.6rem"
            }}
          >
            {infants.length}
           
          </b>
          <AddCircleIcon
          style={{width: "24px", color:"#5F41B2", fontSize: "40px"
}}
          onClick={addInfant}
           
            aria-label="Add"
            
          >
          </AddCircleIcon>
          </div>
        <div
          style={{ width:"100%" }}
        >
          {infants.map((infant, index) => {
            return (
              <div
                key={"div" + index}
                style={{
                  flex: 1,
                  flexBasis: "30%",
                }}
              >
                <Typography
                  key={index}
                  style={{
                    color: store.getState().AgencyReducer
                      .agency.branding.fifthColor
                      ? store.getState().AgencyReducer
                          .agency.branding.fifthColor
                      : "black",
                    fontSize: 12,
                  }}
                >
                  {" "}
                  Select Age of infant {index + 1}
                </Typography>
                <CustomDropdown
                  dropdown
                  key={"drop" + index}
                  style={{ width: "80px", margin: 0 }}
                  onClick={selectAgeInfant.bind(
                    this,
                    index
                  )}

                  dropdownHeader={"Age of infant" + index}
                  buttonText={infants[index]}
                  buttonProps={{
                    color: "primary",
                  }}
                  dropdownList={[0, 1]}
                />
              </div>
               );
          })}
        </div>
        </MenuItem>
      </StyledMenu>
    </div>
  );
}
