import React from "react";
import CardHeader from "@mui/material/CardHeader";
import Avatar from "@mui/material/Avatar";
import avi from "../../../assets/img/faces/avatar.jpg";
import { Button } from "@material-ui/core";
import "./instagramSection.css";
import store from "../../../store";

function InstagramProfile() {
  return (
    <div className="insta-profile">
      <Avatar
        className="insta-profile-image"
        alt="Remy Sharp"
        src={
          store.getState().AgencyReducer.agency.instagram_info
            ? store.getState().AgencyReducer.agency.instagram_info.avatar
            : avi
        }
        sx={{ width: 160, height: 160 }}
      />
      <div className="insta-info">
        <div>
          {" "}
          {store.getState().AgencyReducer.agency.instagram_info
            ? store.getState().AgencyReducer.agency.instagram_info.username
            : "Agency name"}{" "}
        </div>
        <p>
          {store.getState().AgencyReducer.agency.instagram_info
            ? store.getState().AgencyReducer.agency.instagram_info.bio
            : "bio"}
        </p>
        <p>
          {store.getState().AgencyReducer.agency.instagram_info
            ? store.getState().AgencyReducer.agency.instagram_info.followers +
              " followers"
            : "12k followers"}
        </p>
      </div>

      <Button
        color="secondary"
        variant="contained"
        onClick={() => {
          window.open(
            `https://www.instagram.com/${
              store.getState().AgencyReducer.agency.instagram_info
                ? store.getState().AgencyReducer.agency.instagram_info.username
                : "Agency name"
            }`,
            "_blank" // <- This is what makes it open in a new window.
          );
        }}
      >
        Follow
      </Button>
    </div>
  );
}
export default InstagramProfile;
