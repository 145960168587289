import DateFnsUtils from "@date-io/date-fns";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import dateformat from "dateformat";
import React, { Component } from "react";
import "react-phone-input-2/lib/style.css";
import store from "../../store";
export class Passenger extends Component {
  constructor(props) {
    super(props);

    let date = dateformat(this.props.date, "isoDate");
    date = new Date(date);
    date.setFullYear(date.getFullYear() - 15);
    date.setMonth(date.getMonth() + 1);
    let date1 = dateformat(this.props.date, "isoDate");
    date1 = new Date(date);

    date1.setFullYear(date1.getFullYear());

    let born_on_date = (this.state = {
      family_name: "",
      given_name: "",
      born_on: dateformat(date1, "isoDate"),
      gender: "m",
    });
  }

  handleGivenName = (event) => {
    this.setState(
      {
        given_name: event.target.value,
        email: "child@gmail.com",
        title: this.state.gender == "m" ? "mr" : "mrs",
        phone_number: "+442080160509",
        type: this.props.name,
        id: this.props.id,
        born_on: this.props.born_on,
      },
      () => this.props.update(this.state, this.props.index)
    );
  };

  handleFamilyName = (event) => {
    this.setState(
      {
        family_name: event.target.value,
      },
      () => this.props.update(this.state, this.props.index)
    );
  };

  handleGender = (event) => {
    this.setState(
      {
        gender: event.target.value,
        title: event.target.value == "m" ? "mr" : "miss",
      },
      () => this.props.update(this.state, this.props.index)
    );
  };

  render() {
    const classes = this.props.classes;
    let date = this.props.date;
    date = new Date(date);
    date.setFullYear(date.getFullYear() - 16);
    if (this.props.name !== "Adult") {
      date.setDate(date.getDate() + 1);
    }

    return (
      <div
        style={{
          marginBottom: "15px",
          marginLeft: "8px",
          padding: "16px",
        }}
      >
        <GridContainer
          spacing={2}
          style={{
            marginBottom: "20px",
            display: "flex",
            flex: 1,
            flexDirection: "column",
          }}
        >
          <GridItem xs={11} style={{ color: "#512D6D" }}>
            <h3> {this.props.name + " " + this.props.number}</h3>
          </GridItem>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flex: 1,
            }}
          >
            <GridItem xs={12} md={6}>
              <TextField
                required
                label="Given Name"
                onChange={this.handleGivenName}
                inputProps={{ maxLength: 20 }}
              />
            </GridItem>
            <GridItem xs={6} style={{ marginLeft: "10px" }}>
              <TextField
                required
                label="Family Name"
                onChange={this.handleFamilyName}
                inputProps={{ maxLength: 20 }}
              />
            </GridItem>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flex: 1,
              marginTop: "10px",
            }}
          >
            {this.props.name === "Adult" ? (
              <GridItem xs={6}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    required
                    maxDate={date}
                    autoOk={true}
                    color="primary"
                    disableToolbar
                    variant="inline"
                    format="MM/dd/yyyy"
                    margin="normal"
                    id="Birthdate"
                    openTo="year"
                    label="Birthdate"
                    value={this.state.born_on}
                    onChange={this.handleBirthdate}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </MuiPickersUtilsProvider>
              </GridItem>
            ) : null}

            <GridItem
              xs={6}
              style={{
                marginTop: "17px",
                marginLeft: "15px",
              }}
            >
              <FormControl
                className={classes.formControl}
                style={{ minWidth: 80 }}
                fullWidth
                required
              >
                <InputLabel id="demo-simple-select-label">Gender</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  onChange={this.handleGender}
                  value={this.state.gender}
                >
                  <MenuItem value={"m"}>Male</MenuItem>
                  <MenuItem value={"f"}>Female</MenuItem>
                </Select>
              </FormControl>
            </GridItem>
          </div>
        </GridContainer>
      </div>
    );
  }
}

export default Passenger;
