import Pagination from "@material-ui/lab/Pagination";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React, { Component } from "react";
import { connect } from "react-redux";
import { getOffers } from "../../redux/actions/offerActions";
import Offer from "./Offer";
import store from "store";

export class Offers extends Component {
  constructor(props) {
    super(props);

    this.state = {
      page: 1,
    };
  }

  handleChange = (event, page) => {
    this.setState({
      page: page,
    });
  };

  render() {
    let offers = "";

    offers = this.props.filtered;

    const classes = this.props.classes;

    let avgCO2 = 0;
    for (let i = 0; i < offers.length; i++) {
      avgCO2 = avgCO2 + parseFloat(offers[i][0].total_emissions_kg);
    }

    try {
      avgCO2 = avgCO2 / offers.length;
    } catch (e) {
      console.log(e);
    }

    if (this.props.green) {
      offers = offers.filter((item) => {
        if (parseFloat(item[0].total_emissions_kg) <= avgCO2) return true;
        else return false;
      });
    }

    const currentPage = this.state.page;
    const offersPerPage = 10;
    const indexOfLastOffer = offersPerPage * currentPage;
    const indexOfFirstOffer = indexOfLastOffer - offersPerPage;

    const currentArray = offers.slice(indexOfFirstOffer, indexOfLastOffer);

    return (
      <div>
        <GridContainer className={classes.container}>
          {currentArray.map((offer, index) => {
            return (
              <GridItem xs={12} style={{ margin: "10px" }} key={index}>
                <Offer
                  offer={offer}
                  classes={classes}
                  avgCO2={avgCO2}
                  adults={this.props.adults}
                  children={this.props.children}
                  history={this.props.history}
                  position={currentPage * offersPerPage + index + 1}
                />
              </GridItem>
            );
          })}
        </GridContainer>
        <GridContainer alignItems="center" justifyContent="center">
          <GridItem xs={5} style={{ margin: "3% 0" }}>
            <Pagination
              style={{
                color: store.getState().AgencyReducer.agency.branding
                  .primaryColor,
              }}
              count={Math.ceil(offers.length / offersPerPage)}
              page={currentPage}
              onChange={this.handleChange}
              color="primary"
            />
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  //offers: state.offers.offers,
  filtered: state.offers.filteredOffers,
});

export default connect(mapStateToProps, { getOffers })(Offers);
