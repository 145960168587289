import React from "react";
import ANIMATIONS from "constants/animations.js";
import Modal from "react-modal";
import store from "store";
import Typography from "@material-ui/core/Typography";
import ReactCodeInput from "react-verification-code-input";
import { confirmAccountAuto } from "views/Services/clientServices";
import Cookie from "js-cookie";

function VerificationModal({
  state,
  onRequestClose,
  animationVisible,
  history,
}) {
  return (
    <div
      className="App"
      style={{
        zIndex: 100,
        flex: 1,
        display: "flex",
      }}
    >
      <Modal
        isOpen={state.verificationModal}
        onRequestClose={() => {
          onRequestClose();
        }}
        contentLabel="My dialog"
        className="mymodal"
        closeTimeoutMS={500}
        style={{
          content: {
            background:
              "linear-gradient(#F5F7FA," +
              store.getState().AgencyReducer.agency.branding.primaryColor +
              ")",
          },
        }}
      >
        {state.animationVisible ? (
          <div>{ANIMATIONS.ManLoad}</div>
        ) : (
          <div>
            <Typography
              id="modal-modal-title"
              variant="h2"
              component="span"
              style={{
                textAlign: "center",
                fontSize: 20,
                fontWeight: "Bold",
              }}
            >
              {`Please Enter the Verification Code that has been sent to "${
                state.passengerInfo[0] ? state.passengerInfo[0].email : null
              }"`}
              <p> </p>
              <Typography
                style={{
                  textAlign: "center",
                  fontSize: 15,
                }}
              >
                {" "}
                we will send to you the auto generated password and will be
                redirected to the payment page
              </Typography>
            </Typography>

            <div
              style={{
                alignItems: "center",
                textAlign: "center",
                justifyContent: "Center",
                display: "flex",
                paddingTop: "5%",
              }}
            >
              <ReactCodeInput
                onComplete={async (code) => {
                  animationVisible(true, false, false);
                  if (code == state.code) {
                    try {
                      const reqBody = {
                        email: state.passengerInfo[0].email,
                        url: store.getState().AgencyReducer.agency.url,
                        input: code,
                        verificationCode: `${state.code}`,
                      };

                      const data = await confirmAccountAuto(reqBody);
                      animationVisible(false, false, false);

                      store.dispatch({
                        type: "setEmail",
                        email: data.activatedUser.email,
                      });
                      store.dispatch({
                        type: "setAcessLevel",
                        accessLevel: data.activatedUser.accessLevel,
                      });
                      Cookie.set("token", data.token);
                      store.dispatch({
                        type: "setToken",
                        token: data.token,
                      });
                      animationVisible(false, true, false);
                      store.dispatch({
                        type: "PASSENGER_INFO",
                        payload: state.passengerInfo,
                      });

                      history("/pay");
                    } catch (e) {
                      console.log(e);
                      animationVisible(false, false, false);

                      alert("please check your internet connection");
                    }
                  } else {
                    alert("wrong verification code");
                    animationVisible(false, false, false);
                  }
                }}
              />
            </div>

            <br />
          </div>
        )}
      </Modal>
    </div>
  );
}

export default VerificationModal;
