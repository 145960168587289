const initState = {
  hotels: [{ name: "hotels results" }],
  allHotels: "",
};

const hotelResultReducer = (state = initState, action) => {
  if (action.type == "setHotelResults") {
    state.hotels = action.payload;
    return {
      ...state,
    };
  }
  if (action.type == "setAllHotels") {
    state.allHotels = action.payload;
    return {
      ...state,
    };
  }

  return state;
};

export default hotelResultReducer;
