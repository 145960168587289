import React from "react";
import Offers from "./Offers";
import { useNavigate } from "react-router-dom";

function Sorting({
  offers,
  classes,
  green,
  adults,
  children,
  history,
  handleCheapest,
  handleShortest,
}) {
  const navigate = useNavigate();

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <h3 style={{ marginTop: "1%" }}> sort the flights by : </h3>
        <button
          style={{
            borderRadius: "20%",
            marginTop: "1%",
            marginLeft: "7%",
          }}
          onClick={handleCheapest}
          type="button"
          className="btn btn-primary"
        >
          cheapest
        </button>
        <button
          style={{
            borderRadius: "20%",
            marginTop: "1%",
            marginLeft: "1%",
          }}
          onClick={handleShortest}
          type="button"
          className="btn btn-primary"
        >
          shortest duration
        </button>
      </div>
      <Offers
        offers={offers}
        classes={classes}
        green={green}
        history={navigate}
        adults={adults}
        children={children}
      />
    </div>
  );
}

export default Sorting;
