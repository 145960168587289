import React, { useState } from "react";
import { ElementsConsumer, CardElement } from "@stripe/react-stripe-js";
import axios from "axios";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import CardSection from "./CardSection";
import { Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import hosts from "../../../../constants/hosts";
import store from "store";
import { confirmBooking } from "views/Services/clientServices";
import Popoverhotels from "views/Hotels/Components/Popover";
import { generateHotelPdf } from "views/Services/clientServices";
import { addSubscription } from "views/Services/agencyServices";
import { addMarketPlace } from "views/Services/agencyServices";
export function CheckoutForm({ stripe, navigate, elements, amount, plan }) {
  // constructor(props) {
  //   super(props);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [fail, setFail] = useState(false);
  const [message, setMessage] = useState("");
  const [open, setDialogOpen] = React.useState(false);
  const [msg, setMsg] = React.useState("");
  const [error, setError] = React.useState(false);

  async function handleSubmit(event) {
    event.preventDefault();
    setLoading(true);

    if (!stripe || !elements) {
      setLoading(false);
      return;
    }

    let body = {
      currency: amount ? "USD" : store.getState().hotelSearch.hotel.currency,
      amount: amount
        ? amount
        : parseFloat(store.getState().hotelSearch.hotel.minRate),
    };

    let url = `${hosts}/users/createHotelPaymentIntent`;

    axios
      .post(url, body)
      .then(async (response) => {
        const clientSecret = response.data.clientSecret;
        console.log(clientSecret);
        const result = await stripe.confirmCardPayment(clientSecret, {
          payment_method: {
            card: elements.getElement(CardElement),
          },
        });

        if (result.error) {
          console.log(result.error.message);
          setSuccess(false);
          setFail(true);
          setLoading(false);
          setMessage(result.error.message);
        } else {
          if (result.paymentIntent.status === "succeeded") {
            setSuccess(true);
            setFail(false);
            setLoading(false);
            try {
              if (!amount) {
                const data = await confirmBooking();
                const pdf = await generateHotelPdf({
                  bookingID: data.booking.reference,
                });

                store.dispatch({
                  type: "setQrLink",
                  payload: pdf,
                });
                console.log(pdf);
                navigate("/summary");
              } else {
                if (!plan) {
                  await addSubscription({
                    package: amount == 350 ? "Yearly" : "Monthly",
                  });
                } else {
                  await addMarketPlace(plan);
                }
                // setSuccess(true);
                // setFail(false);
                // setDialogOpen(true);
                // setMsg("Successfully subscribed");
              }
            } catch (e) {
              console.log(e);
              setMsg("error in booking and your money will be refunded ASAP");
              setError(true);
              setDialogOpen(true);
            }
          }
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  return (
    <form onSubmit={handleSubmit}>
      <Popoverhotels
        isOpen={open}
        setDialogOpen={setDialogOpen}
        msg={msg}
        error={error}
      />
      <Grid container>
        <Grid item xs={12} sm={12} md={6}>
          <CardSection />
        </Grid>
        <Grid item xs={4} sx={{ pt: 2 }}>
          {amount == 0 ? null : (
            <Button
              disabled={!stripe || success || loading}
              color="primary"
              type="submit"
              variant="contained"
            >
              Pay{" "}
              {amount
                ? amount + " " + "$"
                : store.getState().hotelSearch.hotel.currency +
                  " " +
                  parseFloat(store.getState().hotelSearch.hotel.minRate)}
            </Button>
          )}
        </Grid>
        <Grid item xs={2} style={{ marginTop: "5px" }}>
          {loading && <CircularProgress size={28} />}
        </Grid>
        <Grid item xs={12} style={{ marginTop: "5px" }}>
          {success && (
            <Typography style={{ color: amount ? "white" : "black" }}>
              <b>SUCCESS:</b> Payment Complete.{" "}
              {amount
                ? `successfully subscribed to the selected plan `
                : `Your hotel has been booked
              successfull`}
            </Typography>
          )}
          {fail && (
            <Typography style={{ color: amount ? "white" : "black" }}>
              <b>Fail:</b> Your card has insufficient fund
            </Typography>
          )}
        </Grid>
      </Grid>
    </form>
  );
}

export default function InjectedCheckoutForm({ classes, amount, plan }) {
  const navigate = useNavigate();

  return (
    <ElementsConsumer>
      {({ stripe, elements }) => (
        <CheckoutForm
          stripe={stripe}
          amount={amount}
          plan={plan}
          navigate={navigate}
          elements={elements}
          classes={classes}
        />
      )}
    </ElementsConsumer>
  );
}
