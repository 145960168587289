import moment from "moment";
import dateformat from "dateformat";

export function compare(a, b) {
    if (parseFloat(a[0].total_amount) < parseFloat(b[0].total_amount)) {
      return -1;
    }
    if (parseFloat(a[0].total_amount) > parseFloat(b[0].total_amount)) {
      return 1;
    }
    return 0;
  }
  export function getFilteredOffers(offers,filters,route){
// airline filter
offers = offers.filter((offer) => {
    return filters.filteredAirlines.includes(offer[0].owner.name);
  });
  // direct flights filter
  if (!filters.direct) {
    offers = offers.filter((offer) => {
      for (let i = 0; i < offer[0].slices.length; i++) {
        if (offer[0].slices[i].segments.length === 1) return false;
        else return true;
      }
    });
  }

  // 1 stop filter
  if (!filters.oneStop) {
    offers = offers.filter((offer) => {
      for (let i = 0; i < offer[0].slices.length; i++) {
        if (offer[0].slices[i].segments.length === 2) return false;
      }

      return true;
    });
  }

  // two stops filter
  if (!filters.twoStops)
    offers = offers.filter((offer) => {
      for (let i = 0; i < offer[0].slices.length; i++) {
        if (offer[0].slices[i].segments.length > 2) return false;
      }

      return true;
    });
  //bags filter
  if (!filters.noBags)
    offers = offers.filter((offer) => {
      for (let i = 0; i < offer[0].slices.length; i++) {
        if (offer[0].slices[i].segments[0].passengers[0].baggages.length == 0)
          return false;
      }

      return true;
    });
  if (!filters.oneBags)
    offers = offers.filter((offer) => {
      for (let i = 0; i < offer[0].slices.length; i++) {
        if (offer[0].slices[i].segments[0].passengers[0].baggages.length == 1)
          return false;
      }

      return true;
    });
  if (!filters.twoBags)
    offers = offers.filter((offer) => {
      for (let i = 0; i < offer[0].slices.length; i++) {
        if (offer[0].slices[i].segments[0].passengers[0].baggages.length > 1)
          return false;
      }

      return true;
    });

  // price filter
  if (!(filters.price === 3400)) {
    offers = offers.filter((offer) => {
      for (let i = 0; i < offer.length; i++) {
        if (offer[0].total_amount > filters.price) return false;
      }

      return true;
    });
  }

  // duration filter
  if (!(filters.duration === 9999)) {
    offers = offers.filter((offer) => {
      let maxHours = 0;
      for (let i = 0; i < offer[0].slices.length; i++) {
        let duration = offer[0].slices[i].duration;
        duration = duration.split("H");
        duration = duration[0].match(/\d/g);
        let hours = parseInt(duration.join(""));
        if (hours > maxHours) maxHours = hours;

        if (filters.duration < maxHours) return false;
      }

      return true;
    });
  }

  // outbound departure
  if (!(filters.outbound[0] === 24 && filters.outbound[1] === 24)) {
    offers = offers.filter((offer) => {
      for (let i = 0; i < offer[0].slices.length; i++) {
        let departureTime = offer[0].slices[i].segments[0].departing_at;
        departureTime = dateformat(departureTime, "HH");
        if (
          filters.outbound[0] >= parseInt(departureTime) ||
          parseInt(departureTime) >= filters.outbound[1]
        ) {
          return false;
        }
      }

      return true;
    });
  }
  if (route !== "One Way") {
    offers = offers.filter((offer) => {
      const i = offer[0].slices.length - 1;
      let departureTime = offer[0].slices[i].segments[0].departing_at;
      departureTime = dateformat(departureTime, "HH");
      if (
        filters.inbound[0] <= parseInt(departureTime) &&
        parseInt(departureTime) <= filters.inbound[1]
      )
        return true;
      return false;
    });
  }
  return offers
  }
export  function compareShortestDuration(offer1, offer2) {
    let departureTime = offer1[0].slices[0].segments[0].departing_at;
    let lengthOfSlices = offer1[0].slices.length - 1;
    let lengthOfSegments = offer1[0].slices[lengthOfSlices].segments.length - 1;
    let arrivalTime =
      offer1[0].slices[lengthOfSlices].segments[lengthOfSegments].arriving_at;
    arrivalTime = moment(arrivalTime);
    departureTime = moment(departureTime);
    let firstOfferDuration = arrivalTime.diff(departureTime, "minutes");
    departureTime = offer2[0].slices[0].segments[0].departing_at;
    lengthOfSlices = offer2[0].slices.length - 1;
    lengthOfSegments = offer2[0].slices[lengthOfSlices].segments.length - 1;
    arrivalTime =
      offer2[0].slices[lengthOfSlices].segments[lengthOfSegments].arriving_at;
    arrivalTime = moment(arrivalTime);
    departureTime = moment(departureTime);
    let secondOfferDuration = arrivalTime.diff(departureTime, "minutes");
    if (Number(firstOfferDuration) < Number(secondOfferDuration)) {
      return -1;
    }
    if (Number(firstOfferDuration) > Number(secondOfferDuration)) {
      return 1;
    }
    return 0;
  }