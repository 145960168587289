import {
  Home,
  HomeOutlined,
  ConfirmationNumber,
  ConfirmationNumberOutlined,
  MonetizationOn,
  MonetizationOnOutlined,
  Percent,
  PercentOutlined,
  Handshake,
  HandshakeOutlined,
  Cached,
  CachedOutlined,
  Settings,
  SettingsOutlined,
  Groups,
  GroupsOutlined,
  People,
  PeopleOutlined,
  Store,
  StoreOutlined,
  Logout,
  LogoutOutlined,
  MovieFilter,
  MovieFilterOutlined,
} from "@mui/icons-material";
import { Grid } from "@mui/material";
import { Routes, Route } from "react-router-dom";
import React, { Suspense } from "react";
import Sidebar from "../views/NewAdminPanel/Components/Sidebar";
import Staff from "views/NewAdminPanel/Pages/Staff/Staff";
import { SnackbarProvider } from "notistack";

const HomePage = React.lazy(() => import("../views/NewAdminPanel/Pages/Home"));
const BookingsPage = React.lazy(() =>
  import("../views/NewAdminPanel/Pages/Bookings/Booking")
);
const FinancePage = React.lazy(() =>
  import("../views/NewAdminPanel/Pages/Finance/Finance")
);
const HotelResult = React.lazy(() =>
  import("../views/Hotels/pages/HotelResults/HotelResults")
);
const DiscountsPage = React.lazy(() =>
  import("../views/NewAdminPanel/Pages/Discount")
);
const ServicesPage = React.lazy(() =>
  import("../views/NewAdminPanel/Pages/Services")
);
const SettingsPage = React.lazy(() =>
  import("../views/NewAdminPanel/Pages/Settings")
);
const SubscriptionsPage = React.lazy(() =>
  import("../views/NewAdminPanel/Pages/Subscriptions")
);
const MarketplacePage = React.lazy(() =>
  import("../views/NewAdminPanel/Pages/Marketplace")
);
const MarketingPage = React.lazy(() =>
  import("../views/NewAdminPanel/Pages/Marketing")
);

const LogoutPage = React.lazy(() =>
  import("../views/NewAdminPanel/Pages/Logout")
);
const pathMap = [
  {
    Icon: [Home, HomeOutlined],
    img: "../../../assets/img/AdminPanel_Icons/home.png",
    imgOutlined: "../../../assets/img/AdminPanel_Icons/home-outlined.png",
    path: "/AdminPanel",
    ComponentName: "Home",
    Component: HomePage,
  },
  {
    Icon: [ConfirmationNumber, ConfirmationNumberOutlined],
    img: "../../../assets/img/AdminPanel_Icons/ticket.png",
    imgOutlined: "../../../assets/img/AdminPanel_Icons/ticket-outlined.png",
    path: "/bookings",
    ComponentName: "Bookings",
    Component: BookingsPage,
  },
  {
    Icon: [MonetizationOn, MonetizationOnOutlined],
    img: "../../../assets/img/AdminPanel_Icons/dollar-square.png",
    imgOutlined:
      "../../../assets/img/AdminPanel_Icons/dollar-square-outlined.png",
    path: "/finance",
    ComponentName: "Finance",
    Component: FinancePage,
  },
  {
    Icon: [Percent, PercentOutlined],
    img: "../../../assets/img/AdminPanel_Icons/percentage-square.png",
    imgOutlined:
      "../../../assets/img/AdminPanel_Icons/percentage-square-outlined.png",
    path: "/discounts",
    ComponentName: "Discounts",
    Component: DiscountsPage,
  },

  {
    Icon: [Handshake, HandshakeOutlined],
    img: "../../../assets/img/AdminPanel_Icons/hand-shake.png",
    imgOutlined: "../../../assets/img/AdminPanel_Icons/hand-shake-outlined.png",
    path: "/services",
    ComponentName: "Services",
    Component: ServicesPage,
  },
  {
    Icon: [People, PeopleOutlined],
    img: "../../../assets/img/AdminPanel_Icons/people.png",
    imgOutlined: "../../../assets/img/AdminPanel_Icons/people-outlined.png",
    path: "/staff",
    ComponentName: "Staff",
    Component: Staff,
  },
  {
    Icon: [Store, StoreOutlined],
    img: "../../../assets/img/AdminPanel_Icons/shop.png",
    imgOutlined: "../../../assets/img/AdminPanel_Icons/shop-outlined.png",
    path: "/marketplace",
    ComponentName: "Market Place",
    Component: MarketplacePage,
  },
  {
    Icon: [Cached, CachedOutlined],
    img: "../../../assets/img/AdminPanel_Icons/refresh-circle.png",
    imgOutlined:
      "../../../assets/img/AdminPanel_Icons/refresh-circle-outlined.png",
    path: "/subscription",
    ComponentName: "Subscription",
    Component: SubscriptionsPage,
  },
  {
    Icon: [MovieFilter, MovieFilterOutlined],
    img: "../../../assets/img/AdminPanel_Icons/Group.png",
    imgOutlined: "../../../assets/img/AdminPanel_Icons/Group-outlined.png",
    path: "/marketing",
    ComponentName: "Marketing",
    Component: MarketingPage,
  },
  {
    Icon: [Settings, SettingsOutlined],
    img: "../../../assets/img/AdminPanel_Icons/setting.png",
    imgOutlined: "../../../assets/img/AdminPanel_Icons/setting-outlined.png",
    path: "/settings",
    ComponentName: "Settings",
    Component: SettingsPage,
  },
  {
    Icon: [Logout, LogoutOutlined],
    img: "../../../assets/img/AdminPanel_Icons/logout.png",
    imgOutlined: "../../../assets/img/AdminPanel_Icons/logout.png",
    path: "/logout",
    ComponentName: "Logout",
    Component: LogoutPage,
  },
];

function AdminPanelNavigations() {
  return (
    <>
      <SnackbarProvider>
        <Grid container>
          <Grid item md={12} xs={12}>
            <Routes>
              {pathMap.map(({ path, Component }, index) => (
                <Route
                  key={index}
                  path={path}
                  exact
                  element={
                    <div style={{ fontFamily: "Rubik" }}>
                      <Suspense fallback={<div>loading</div>}>
                        <Grid container>
                          <Grid item md={2} xs={12}>
                            <Sidebar pathMap={pathMap} />
                          </Grid>
                          <Grid item md={10}>
                            <Component />
                          </Grid>
                        </Grid>
                      </Suspense>
                    </div>
                  }
                />
              ))}
            </Routes>
          </Grid>
        </Grid>
      </SnackbarProvider>
    </>
  );
}

export default AdminPanelNavigations;
