const initState = {
  markups: [],
  publicInfo: null,
  progressHomeImages: [],
  staff: [],
  brandingProgress: 0,
  contactProgress: 0,
  financeProgress: 0,
  marketingProgress: 0,
  chatProgress: 0,
  staffProgress: 0,
};

const ProgressReducer = (state = initState, action) => {
  if (action.type == "progressMarkups") {
    state.markups = action.payload;
    return {
      ...state,
    };
  }
  if (action.type == "progressPublicInfo") {
    state.publicInfo = action.payload;
    return {
      ...state,
    };
  }
  if (action.type == "progressHomeImages") {
    state.progressHomeImages = action.payload;
    return {
      ...state,
    };
  }
  if (action.type == "setBrandingProgress") {
    state.brandingProgress = action.payload;
    return {
      ...state,
    };
  }
  if (action.type == "setContactProgress") {
    state.contactProgress = action.payload;
    return {
      ...state,
    };
  }
  if (action.type == "setFinanceProgress") {
    state.financeProgress = action.payload;
    return {
      ...state,
    };
  }
  if (action.type == "setMarketingProgress") {
    state.marketingProgress = action.payload;
    return {
      ...state,
    };
  }
  if (action.type == "setChatProgress") {
    state.chatProgress = action.payload;
    return {
      ...state,
    };
  }
  if (action.type == "progressStaff") {
    state.staff = action.payload;
    return {
      ...state,
    };
  }
  if (action.type == "setStaffProgress") {
    state.staffProgress = action.payload;
    return {
      ...state,
    };
  }

  return state;
};

export default ProgressReducer;
