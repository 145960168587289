import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";

const Navbar = (props) => {
  let history = useNavigate();
  const location = useLocation();
  return (
    <nav
      className="navbar navbar-expand-lg navbar-light bg-light w-100 "
      style={{ height: "12%" }}
    >
      <div className="container-fluid">
        <div className="navbar-brand d-flex" style={{ marginRight: 50 }}>
          <svg
            onClick={() => history("/")}
            className=""
            width="76"
            height="71"
            viewBox="0 0 76 71"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M50.095 16.8333C53.515 16.8333 56.3333 19.4367 56.3333 22.75V55.2917C56.3333 58.6346 53.515 61.2083 50.095 61.2083C50.095 62.9242 48.6067 64.1667 46.8333 64.1667C45.25 64.1667 43.6667 62.9242 43.6667 61.2083H24.6667C24.6667 62.9242 23.0833 64.1667 21.5 64.1667C19.7267 64.1667 18.2383 62.9242 18.2383 61.2083C14.8183 61.2083 12 58.6346 12 55.2917V22.75C12 19.4367 14.8183 16.8333 18.2383 16.8333H24.6667V7.95833C24.6667 6.2425 26.1233 5 27.8333 5H40.5C42.21 5 43.6667 6.2425 43.6667 7.95833V16.8333H50.095ZM38.9167 16.8333V9.4375H29.4167V16.8333H38.9167ZM21.5 25.7083V52.3333H26.25V25.7083H21.5ZM42.0833 25.7083V52.3333H46.8333V25.7083H42.0833ZM31.7917 25.7083V52.3333H36.5417V25.7083H31.7917Z"
              fill="#141E61"
            />
          </svg>

          <div
            href="/"
            className=""
            style={{
              color: "#141e61",
              fontFamily: "Cabin",
              fontStyle: "normal",
              fontWeight: "bold",
              fontSize: "200%",
              lineHeight: "46px",
              letterSpacing: "0.02em",
              paddingTop: "20px",
            }}
          >
            Trollii
          </div>
        </div>
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          {/* <MoreHorizSharpIcon /> */}
          <span class="navbar-toggler-icon"></span>
        </button>

        <div
          className="collapse navbar-collapse"
          id="navbarSupportedContent"
          style={{
            background: "#f8f9fa",
            display: "flex",
            flex: 1,
            alignItems: "flex-end",
            alignContent: "flex-end",
            justifyContent: "flex-end",
          }}
        >
          <form class="d-flex">
            <button
              onClick={() => {
                location.pathname == "/register" ? null : history("/register");
              }}
              className="btn shadow-sm rounded"
              style={{
                color: "#141e61",
                alignItems: "flex-end",
                justifyContent: "flex-end",
                background: "white",
                borderRadius: "10px",
                fontFamily: "Cabin",
                fontStyle: "normal",
                fontWeight: "bold",
                lineHeight: "15px",
                textAlign: "flex-end",
                letterSpacing: "0.02em",
                fontSize: "12px",
                marginRight: "5px",
              }}
            >
              <div className=""> REGISTER </div>
            </button>

            <button
              onClick={() => history("/AgencyLogin")}
              // history("/AgencyLogin")}
              className="btn shadow-sm rounded"
              style={{
                borderRadius: "10px",
                background: "#9c27b0",
                height: "50px",

                background: "#9c27b0",
              }}
            >
              <div
                className=""
                style={{
                  borderRadius: "10px",
                  fontFamily: "Cabin",
                  fontStyle: "normal",
                  fontWeight: "bold",
                  lineHeight: "15px",
                  textAlign: "center",
                  letterSpacing: "0.02em",
                  fontSize: "12px",
                }}
              >
                {" "}
                LOGIN{" "}
              </div>
            </button>
          </form>
        </div>
      </div>
    </nav>
  );
};
const mapStateToProps = (state) => {
  return { Agency: state.AgencyReducer }; //state.initialState is what we grab from redux
  //and signupInfo iswhat we apply this data to
};
export default connect(mapStateToProps, null)(Navbar);
