import React from "react";
import store from "store";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer";
import Fab from "@material-ui/core/Fab";
import RemoveIcon from "@material-ui/icons/Remove";
import AddIcon from "@material-ui/icons/Add";
import Typography from "@material-ui/core/Typography";
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";


function Children({removeAdults,state,addAdults,removeChild,selectAge, classes,addChild}) {
  return (
    <GridItem
    xs={12}
    sm={12}
    md={7}
    style={{ marginBottom: "10px" }}
  >
    <GridContainer>
      <GridItem xs={12} sm={6} md={6}>
        <div
          style={{
            flex: 1,
            flexBasis: "40%",
            marginTop: "20px",
          }}
        >
          <Fab
            size="small"
            color={
              store.getState().AgencyReducer.agency
                .branding.fifthColor
                ? store.getState().AgencyReducer.agency
                    .branding.fifthColor
                : "black"
            }
            aria-label="Add"
            className={classes.margin}
          >
            <RemoveIcon onClick={removeAdults} />
          </Fab>
          <b
            style={{
              fontSize: "110%",
              color: store.getState().AgencyReducer.agency
                .branding.fifthColor
                ? store.getState().AgencyReducer.agency
                    .branding.fifthColor
                : "black",
              fontWeight: "bold",
            }}
          >
            {" "}
            {" " +
              "Adults" +
              " " +
            state.adults +
              "  "}{" "}
          </b>
          <Fab
            size="small"
            color={
              store.getState().AgencyReducer.agency
                .branding.fifthColor
                ? store.getState().AgencyReducer.agency
                    .branding.fifthColor
                : "black"
            }
            aria-label="Add"
            className={classes.margin}
          >
            <AddIcon onClick={addAdults} />
          </Fab>
        </div>
      </GridItem>
      <GridItem xs={12} sm={6} md={6}>
        <div
          style={{
            flex: 1,
            flexBasis: "60%",
            marginTop: "20px",
          }}
        >
          <Fab
            size="small"
            color={
              store.getState().AgencyReducer.agency
                .branding.fifthColor
                ? store.getState().AgencyReducer.agency
                    .branding.fifthColor
                : "black"
            }
            aria-label="Add"
            className={classes.margin}
          >
            <RemoveIcon onClick={removeChild} />
          </Fab>
          <b
            style={{
              fontSize: "110%",
              color: store.getState().AgencyReducer.agency
                .branding.fifthColor
                ? store.getState().AgencyReducer.agency
                    .branding.fifthColor
                : "black",
              fontWeight: "bold",
            }}
          >
            {" "}
            {" " +
              "Children" +
              " " +
            state.children.length +
              "  "}{" "}
          </b>
          <Fab
            size="small"
            color={
              store.getState().AgencyReducer.agency
                .branding.fifthColor
                ? store.getState().AgencyReducer.agency
                    .branding.fifthColor
                : "black"
            }
            aria-label="Add"
            className={classes.margin}
          >
            <AddIcon onClick={addChild} />
          </Fab>
        </div>
        <div
          className={classes.column}
          style={{ display: "flex", flexWrap: "wrap" }}
        >
          {state.children.map((child, index) => {
            return (
              <div
                key={"div" + index}
                style={{
                  flex: 1,
                  flexBasis: "30%",
                }}
              >
                <Typography
                  key={index}
                  style={{
                    color: store.getState().AgencyReducer
                      .agency.branding.fifthColor
                      ? store.getState().AgencyReducer
                          .agency.branding.fifthColor
                      : "black",
                    fontSize: 12,
                  }}
                >
                  {" "}
                  Select Age of Child {index + 1}
                </Typography>
                <CustomDropdown
                  dropdown
                  key={"drop" + index}
                  style={{ width: "80px", margin: 0 }}
                  onClick={selectAge.bind(
                    this,
                    index
                  )}

                  dropdownHeader={"Age of Child" + index}
                  buttonText={state.children[index]}
                  buttonProps={{
                    color: "primary",
                  }}
                  dropdownList={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15]}
                />
              </div>
            );
          })}
        </div>
      </GridItem>
    </GridContainer>
  </GridItem>
  );
}

export default Children;
