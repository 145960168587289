import { Card, Grid, Divider, Typography, Checkbox } from "@mui/material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import React, { Component } from "react";
import { ReactComponent as Paypal } from "../../assets/svgs/paypal.svg";
import { ReactComponent as Visa } from "../../assets/svgs/visa.svg";
import CreditCardForm from "./CreditCardForm";

export default function PaymentOption({ handleCheckout }) {
  const [paymentOption, setpaymentOption] = React.useState("Credit");
  return (
    <Card
      sx={{
        border: "1px solid #C4C4C4",
        borderRadius: 4,
        boxShadow: "0px 0px 4px rgba(0,0,0,0.04), 0px 4px 8px rgba(0,0,0,0.06)",
      }}
    >
      <Grid item>
        <RadioGroup defaultValue="Credit">
          <Grid container sx={{ display: "flex", flexDirection: "column" }}>
            <FormControl>
              <Grid container>
                <Grid item md={0.3} sm={0.3} xs={0.3} />
                <Grid item>
                  <FormControlLabel
                    onChange={() => {
                      setpaymentOption("Credit");
                    }}
                    value="Credit"
                    control={
                      <Radio
                        sx={{
                          "&.Mui-checked": {
                            color: "#5F41B3",
                          },
                        }}
                      />
                    }
                    label={
                      <Grid container sx={{ pt: 2, pb: 2 }}>
                        <Grid
                          item
                          sx={{ display: "flex", alignItems: "center" }}
                        >
                          <Typography
                            sx={{
                              color:
                                paymentOption == "Credit"
                                  ? "#5F41B3"
                                  : "#5D5C66",
                              fontFamily: "Rubik",
                              fontStyle: "normal",
                              fontWeight: 500,
                            }}
                          >
                            Credit/Debit Card
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          onClick={() => {
                            handleCheckout();
                          }}
                          sx={{ pl: 4 }}
                        >
                          <Visa />
                        </Grid>
                      </Grid>
                    }
                  />
                </Grid>
              </Grid>
            </FormControl>
            <Grid item md={12}>
              <Divider />
            </Grid>
            <Grid container sx={{ bgcolor: "#F5F5F5" }}>
              <Grid container>
                <Grid item md={0.3} sm={0.3} xs={0.3} />
                <Grid item md={11.4} sx={{ pb: 4, pt: 2 }}>
                  <Typography
                    sx={{
                      textAlign: "left",
                      color: "#5D5C66",
                      fontFamily: "Rubik",
                      fontStyle: "normal",
                      fontWeight: 500,
                    }}
                  >
                    Card holder name
                  </Typography>
                  <input
                    style={{
                      height: "100%",
                      width: "100%",
                      textIndent: "10%",

                      borderRadius: "15px",
                      border: "1px solid #C4C4C4",
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item md={0.3} sm={0.3} xs={0.3} />
                <Grid item md={12}>
                  <CreditCardForm />
                </Grid>
                {/* <Grid item md={11.4} sx={{ pb: 4, pt: 2 }}>
                  <Typography
                    sx={{
                      textAlign: "left",
                      color: "#5D5C66",
                      fontFamily: "Rubik",
                      fontStyle: "normal",
                      fontWeight: 500,
                    }}
                  >
                    Card number
                  </Typography>
                  <input
                    type={"number"}
                    style={{
                      height: "100%",
                      width: "100%",
                      textIndent: "10%",

                      borderRadius: "15px",
                      border: "1px solid #C4C4C4",
                    }}
                  />
                </Grid> */}
              </Grid>
              {/* <Grid container>
                <Grid item md={0.3} sm={0.3} xs={0.3} />
                <Grid item md={2} sx={{ pb: 1, pt: 2 }}>
                  <Typography
                    sx={{
                      textAlign: "left",
                      color: "#5D5C66",
                      fontFamily: "Rubik",
                      fontStyle: "normal",
                      fontWeight: 500,
                    }}
                  >
                    Expiration date
                  </Typography>
                </Grid>
                <Grid item md={4.3} />
                <Grid item md={2} sx={{ pb: 1, pt: 2 }}>
                  <Typography
                    sx={{
                      textAlign: "left",
                      color: "#5D5C66",
                      fontFamily: "Rubik",
                      fontStyle: "normal",
                      fontWeight: 500,
                    }}
                  >
                    CVV
                  </Typography>
                </Grid>
              </Grid> */}
              <Grid container>
                {/* <Grid item md={0.3} sm={0.3} xs={0.3} />
                <Grid item md={2} sx={{ pb: 6, height: { md: "130%" } }}>
                  <input
                    type={"number"}
                    placeholder="MM"
                    style={{
                      height: "100%",
                      width: "100%",
                      textIndent: "10%",

                      borderRadius: "15px",
                      border: "1px solid #C4C4C4",
                    }}
                  />
                </Grid> */}
                {/* <Grid item md={0.5} />
                <Grid
                  item
                  sx={{ display: { md: "block", sm: "none", xs: "none" } }}
                >
                  <Typography sx={{ fontSize: "200%" }}>/</Typography>
                </Grid> */}
                {/* <Grid item md={0.5} />

                <Grid item md={2} sx={{ pb: 6 }}>
                  <input
                    type={"number"}
                    placeholder="YY"
                    style={{
                      height: "100%",
                      width: "100%",
                      textIndent: "10%",

                      borderRadius: "15px",
                      border: "1px solid #C4C4C4",
                    }}
                  />
                </Grid>
                <Grid item md={1} />
                <Grid item md={4} sx={{ pb: 6 }}>
                  <input
                    type={"number"}
                    style={{
                      height: "100%",
                      width: "100%",
                      textIndent: "10%",

                      borderRadius: "15px",
                      border: "1px solid #C4C4C4",
                    }}
                  />
                </Grid> */}
              </Grid>
              <Grid container />
              <Grid item md={0.3} />
              <Grid item>
                {" "}
                <Checkbox
                  sx={{
                    "&.Mui-checked": {
                      color: "#5F41B3",
                    },
                  }}
                />
              </Grid>
              <Grid item>
                <Typography
                  sx={{
                    textAlign: "left",
                    color: "#362566",
                    fontFamily: "Rubik",
                    pt: 1,
                    fontStyle: "normal",
                    fontWeight: 500,
                  }}
                >
                  Save the card information for future use
                </Typography>
              </Grid>
            </Grid>
            <Grid item>
              <Divider />
            </Grid>
            <Grid container>
              <Grid item md={0.3} />
              <Grid item sx={{ display: "flex", alignItems: "flex-start" }}>
                <FormControlLabel
                  onChange={() => {
                    setpaymentOption("Paypal");
                  }}
                  value="Paypal"
                  control={
                    <Radio
                      disabled
                      sx={{
                        "&.Mui-checked": {
                          color: "#5F41B3",
                        },
                      }}
                    />
                  }
                  label={
                    <Grid container sx={{ pt: 2, pb: 2 }}>
                      <Grid item sx={{ display: "flex", alignItems: "center" }}>
                        <Typography
                          sx={{
                            color:
                              paymentOption == "Paypal" ? "#5F41B3" : "#5D5C66",
                            fontFamily: "Rubik",
                            fontStyle: "normal",
                            fontWeight: 500,
                          }}
                        >
                          PayPal
                        </Typography>
                      </Grid>
                      <Grid item sx={{ pl: 4 }}>
                        <Paypal />
                      </Grid>
                    </Grid>
                  }
                />
              </Grid>
            </Grid>
          </Grid>
        </RadioGroup>
      </Grid>
    </Card>
  );
}
