import { Grid, Typography } from "@material-ui/core";
import React, { Component } from "react";
import AcUnitIcon from "@mui/icons-material/AcUnit";
import Rating from "@mui/material/Rating";
import Box from "@mui/material/Box";

import WifiIcon from "@mui/icons-material/Wifi";
export default function Hotel({
  hotel,
  index,
  setHotelDetailsView,
  setHotelDetails,
}) {
  hotel.images = hotel.images.sort((a, b) => a.order - b.order);
  const hotelGeneralViewImage = hotel.images.find((x) => x.type.code == "GEN");

  const hotelWifi = hotel.facilities.find((x) => x.facilityCode == 550);
  const hotelAirConditioning = hotel.facilities.find(
    (x) => x.facilityCode == 10
  );

  return (
    <Grid key={index} item md={3}>
      <Box sx={{ pt: 10 }} />
      <img
        onClick={() => {
          setHotelDetailsView(true);
          setHotelDetails(hotel);
        }}
        style={{ borderRadius: "10%", cursor: "pointer" }}
        width={"80%"}
        height={"60%"}
        src={`http://photos.hotelbeds.com/giata/${
          hotelGeneralViewImage ? hotelGeneralViewImage.path : null
        }`}
      />
      <Typography
        style={{
          fontFamily: "Poppins",
          fontStyle: "normal",
          fontWeight: 500,
        }}
      >
        {hotel.name}
      </Typography>
      {hotelWifi ? (
        <Grid container justifyContent="center">
          <Typography>Free Wi-Fi</Typography>
          <WifiIcon htmlColor="#1F90D0" />
          {hotelAirConditioning ? (
            <div style={{ display: "flex", paddingLeft: "2%" }}>
              <Typography>Air conditioning</Typography>
              <AcUnitIcon htmlColor="#1F90D0" />{" "}
            </div>
          ) : null}
        </Grid>
      ) : null}
      <Grid container justifyContent="center">
        <Rating
          name="half-rating-read"
          value={parseInt(hotel.categoryName.charAt(0))}
          readOnly
        />
        <Typography>{hotel.minRate + " " + hotel.currency}</Typography>
      </Grid>
    </Grid>
  );
}
