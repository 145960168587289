import * as React from "react";
import Owl from "./Owl";
import { styled } from "@mui/material/styles";
import { purple } from "@mui/material/colors";
import store from "store";
import {
  Typography,
  Card,
  Button,
  CardActions,
  CardMedia,
  Rating,
  Grid,
  Checkbox,
} from "@mui/material";
import StarRoundedIcon from "@mui/icons-material/StarRounded";
import FiberManualRecordSharpIcon from "@mui/icons-material/FiberManualRecordSharp";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import PoolRoundedIcon from "@mui/icons-material/PoolRounded";
import WifiIcon from "@mui/icons-material/Wifi";
import FitnessCenterIcon from "@mui/icons-material/FitnessCenter";
import RestaurantIcon from "@mui/icons-material/Restaurant";
import LocalParkingIcon from "@mui/icons-material/LocalParking";
import LocalCafeIcon from "@mui/icons-material/LocalCafe";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import { useNavigate } from "react-router-dom";
import { abandonedHotel } from "views/Services/clientServices";
import moment from "moment";
const truncate = (str, no_words) => {
  return str.split(" ").splice(0, no_words).join(" ");
};
const StyledRating = styled(Rating)({
  "& .MuiRating-iconFilled": {
    color: "#00A698",
  },
  "& .MuiRating-iconHover": {
    color: "#00A698",
  },
});
const StyledRating1 = styled(Rating)({
  "& .MuiRating-iconFilled": {
    color: "#FFC725",
  },
  "& .MuiRating-iconHover": {
    color: "#C4C4C4",
  },
});
const hotelCard = ({ hotel }) => {
  let navigate = useNavigate();
  const hotelGeneralViewImage = hotel.images
    ? hotel.images.find((x) => x.type.code == "GEN")
    : null;
  store.dispatch({
    type: "setHotelImage",
    payload: hotelGeneralViewImage
      ? `http://photos.hotelbeds.com/giata/${hotelGeneralViewImage.path}`
      : "https://thumbs.dreamstime.com/b/hotel-10732347.jpg",
  });
  return (
    <Card
      variant="outlined"
      style={{ borderRadius: 20, margin: 0 }}
      sx={{
        boxSizing: "border-box",
        border: "1px solid #C4C4C4",
        boxShadow: "0px 2px 8px rgba(38, 36, 131, 0.25)",
        display: "flex",
      }}
    >
      <Grid container>
        <Grid
          item
          md={12}
          sm={12}
          xs={0}
          sx={{ display: { md: "block", sm: "block", xs: "none" } }}
        >
          <Grid container>
            <Grid item md={4} sm={4} xs={4} sx={{ pr: 1 }}>
              <CardMedia
                component="img"
                height="300"
                width="300"
                image={
                  hotelGeneralViewImage
                    ? `http://photos.hotelbeds.com/giata/${hotelGeneralViewImage.path}`
                    : "https://thumbs.dreamstime.com/b/hotel-10732347.jpg"
                }
                sx={{
                  alignItems: "left",
                }}
              />
            </Grid>
            <Grid item md={8} sm={8}>
              <Grid container sx={{ pb: 2 }}>
                <Grid item md={10.75} sm={11} sx={{ pt: 2 }}>
                  <Typography
                    sx={{
                      fontFamily: "Rubik",
                      fontStyle: "normal",
                      fontWeight: 600,
                      fontSize: 22,
                      color: "#362566",
                      textAlign: "left",
                    }}
                  >
                    {hotel.name}
                  </Typography>
                </Grid>
                <Grid item md={1.25} sm={1} sx={{ pt: 0 }}>
                  <Checkbox
                    style={{ top: 0 }}
                    icon={
                      <BookmarkBorderIcon fontSize="large" color="disabled" />
                    }
                    checkedIcon={
                      <BookmarkIcon
                        fontSize="large"
                        sx={{ color: "#FFC725" }}
                      />
                    }
                  />
                </Grid>
                <Grid
                  item
                  md={12}
                  sm={12}
                  sx={{ display: "flex", pt: 1, pb: 1 }}
                >
                  <StyledRating1
                    size="large"
                    readOnly
                    defaultValue={parseInt(hotel.categoryName.charAt(0))}
                    precision={0.5}
                    icon={<StarRoundedIcon fontSize="medium" />}
                    emptyIcon={<StarRoundedIcon fontSize="large" />}
                  />
                  &nbsp;
                  <Typography
                    align="right"
                    sx={{
                      fontFamily: "Rubik",
                      fontStyle: "normal",
                      fontWeight: 400,
                      fontSize: 14,
                      color: "#5D5C66",
                    }}
                  >
                    {`${hotel.destinationName}, ${hotel.hotelContent.country.description.content}`}
                  </Typography>
                </Grid>

                <Grid
                  item
                  md={12}
                  sm={12}
                  sx={{
                    textAlign: "left",
                  }}
                >
                  <Typography
                    variant="subtitle"
                    align="left"
                    sx={{
                      textAlign: "left",
                      fontFamily: "Rubik",
                      fontStyle: "normal",
                      fontWeight: 400,
                      fontSize: 14,
                      color: "#5D5C66",
                    }}
                  >
                    {`${truncate(
                      hotel.hotelContent.description.content,
                      30
                    )} ... More`}
                  </Typography>
                </Grid>

                <Grid item md={12} sm={12} xs={12}>
                  <Grid container>
                    <Grid item md={9} sm={9} sx={{ display: "flex", pt: 1 }}>
                      <Typography
                        align="right"
                        sx={{
                          fontFamily: "Rubik",
                          fontStyle: "normal",
                          fontWeight: 500,
                          fontSize: 14,
                          color: "#000000",
                        }}
                      >
                        {`${hotel.categoryName.charAt(0)}/5`}
                      </Typography>
                      &nbsp;
                      <Owl />
                      <StyledRating
                        defaultValue={parseInt(hotel.categoryName.charAt(0))}
                        precision={0.5}
                        max={5}
                        size="small"
                        icon={<FiberManualRecordSharpIcon fontSize="small" />}
                        emptyIcon={
                          <FiberManualRecordSharpIcon fontSize="small" />
                        }
                      />
                      <Typography
                        sx={{
                          fontFamily: "Rubik",
                          fontStyle: "normal",
                          fontWeight: 300,
                          fontSize: 14,
                          color: "#5D5C66",
                        }}
                      >
                        7,262 Reviews
                      </Typography>
                    </Grid>
                    <Grid item md={3} sm={3} />
                    <Grid item md={12} sm={12}>
                      <Typography
                        align="right"
                        sx={{
                          fontFamily: "Rubik",
                          fontStyle: "normal",
                          fontWeight: 600,
                          fontSize: 22,
                          color: "#5F41B3",
                          pr: 1,
                        }}
                      >
                        {`${store.getState().client.client.currency} ${
                          hotel.minRate
                        }`}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  md={12}
                  sm={12}
                  sx={{ display: "flex", textAlign: "left" }}
                >
                  <Grid container sx={{ display: "flex" }}>
                    <Grid item md={6} sm={6} xs={6}>
                      <PoolRoundedIcon sx={{ color: purple[900] }} />
                      &nbsp;
                      <WifiIcon sx={{ color: purple[900] }} />
                      &nbsp;
                      <FitnessCenterIcon sx={{ color: purple[900] }} />
                      &nbsp;
                      <RestaurantIcon sx={{ color: purple[900] }} />
                      &nbsp;
                      <LocalParkingIcon sx={{ color: purple[900] }} />
                      &nbsp;
                      <LocalCafeIcon sx={{ color: purple[900] }} />
                    </Grid>
                    <Grid item md={2} sm={2} xs={2} />
                    <Grid
                      item
                      md={4}
                      sm={6}
                      xs={0}
                      sx={{ display: { xs: "none", md: "block", sm: "block" } }}
                    >
                      <CardActions
                        onClick={async () => {
                          navigate("/hotelDetails", {
                            state: { hotel: hotel },
                          });
                          let reqBody = {
                            checkInDate: moment(
                              store.getState().hotelSearch.checkInDate
                            ).format("YYYY-MM-DD"),
                            checkOutDate: moment(
                              store.getState().hotelSearch.checkOutDate
                            ).format("YYYY-MM-DD"),
                            destinationCity: hotel.destinationName,
                            price: `${
                              store.getState().client.client.currency
                            } ${hotel.minRate}`,
                            searchTime: hotel.searchTime,
                            travel_agency_id: store.getState().AgencyReducer
                              .agency._id,
                          };
                          await abandonedHotel(reqBody);
                        }}
                      >
                        <Button
                          onClick={() => {
                            // navigate("/SelectRooms");
                          }}
                          sx={{ justifyContent: "center" }}
                          size="medium"
                          variant="filled"
                          style={{
                            backgroundColor: "#5F41B3",
                            borderRadius: 10,
                            width: "100%",
                          }}
                        >
                          <Typography
                            sx={{
                              fontFamily: "Inter",
                              fontStyle: "normal",
                              fontWeight: 500,
                              fontSize: 16,
                              color: "white",
                              textTransform: "none",
                            }}
                          >
                            Hotel Details
                          </Typography>
                        </Button>
                      </CardActions>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          md={0}
          sm={0}
          xs={12}
          sx={{ display: { md: "none", sm: "none", xs: "block" } }}
        >
          <Grid container>
            <Grid item xs={4} sx={{ pr: 1 }}>
              <CardMedia
                component="img"
                image={
                  hotelGeneralViewImage
                    ? `http://photos.hotelbeds.com/giata/${hotelGeneralViewImage.path}`
                    : "https://thumbs.dreamstime.com/b/hotel-10732347.jpg"
                }
                sx={{
                  alignItems: "left",
                  height: "20vh",
                  width: "30vw",
                }}
                onClick={() => {
                  navigate("/hotelDetails", { state: { hotel: hotel } });
                }}
              />
            </Grid>
            <Grid item xs={8}>
              <Grid container sx={{ pb: 2 }}>
                <Grid item xs={10} sx={{ pt: 2 }}>
                  <Typography
                    sx={{
                      fontFamily: "Rubik",
                      fontStyle: "normal",
                      fontWeight: 600,
                      fontSize: 22,
                      color: "#362566",
                      textAlign: "left",
                    }}
                  >
                    {hotel.name}
                  </Typography>
                </Grid>

                <Grid item xs={2} sx={{ pt: 0 }}>
                  <Checkbox
                    style={{ top: 0 }}
                    icon={
                      <BookmarkBorderIcon fontSize="large" color="disabled" />
                    }
                    checkedIcon={
                      <BookmarkIcon
                        fontSize="large"
                        sx={{ color: "#FFC725" }}
                      />
                    }
                  />
                </Grid>

                <Grid item xs={12} sx={{ display: "flex" }}>
                  <StyledRating1
                    sx={{ justifyContent: "left" }}
                    size="large"
                    defaultValue={5}
                    precision={0.5}
                    icon={<StarRoundedIcon fontSize="medium" />}
                    emptyIcon={<StarRoundedIcon fontSize="large" />}
                  />
                </Grid>
                <Grid item xs={12} sx={{ display: "flex", pb: 1 }}>
                  <Typography
                    align="center"
                    sx={{
                      fontFamily: "Rubik",
                      fontStyle: "normal",
                      fontWeight: 400,
                      fontSize: 14,
                      color: "#5D5C66",
                    }}
                  >
                    {`${hotel.destinationName}, ${hotel.hotelContent.country.description.content}`}
                  </Typography>
                </Grid>
                <Grid item xs={12} sx={{ display: "flex", pt: 1 }}>
                  <Typography
                    align="right"
                    sx={{
                      fontFamily: "Rubik",
                      fontStyle: "normal",
                      fontWeight: 500,
                      fontSize: 14,
                      color: "#000000",
                    }}
                  >
                    {`${hotel.categoryName.charAt(0)}/5`}
                  </Typography>
                  &nbsp;
                  <Owl />
                  <StyledRating
                    readOnly
                    defaultValue={parseInt(hotel.categoryName.charAt(0))}
                    precision={0.5}
                    max={5}
                    size="small"
                    icon={<FiberManualRecordSharpIcon fontSize="small" />}
                    emptyIcon={<FiberManualRecordSharpIcon fontSize="small" />}
                  />
                </Grid>
                <Grid item xs={12} sx={{ padding: 1, display: "flex" }}>
                  <PoolRoundedIcon sx={{ color: purple[900] }} />
                  &nbsp;
                  <WifiIcon sx={{ color: purple[900] }} />
                  &nbsp;
                  <FitnessCenterIcon sx={{ color: purple[900] }} />
                  &nbsp;
                  <RestaurantIcon sx={{ color: purple[900] }} />
                  &nbsp;
                  <LocalParkingIcon sx={{ color: purple[900] }} />
                  &nbsp;
                  <LocalCafeIcon sx={{ color: purple[900] }} />
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    align="right"
                    sx={{
                      fontFamily: "Rubik",
                      fontStyle: "normal",
                      fontWeight: 600,
                      fontSize: 22,
                      color: "#5F41B3",
                      pr: 1,
                    }}
                  >
                    {`${store.getState().client.client.currency} ${
                      hotel.minRate
                    }`}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
};

export default hotelCard;
