import { Typography, Link, Grid } from "@mui/material";
import * as React from "react";
const LinkElement = ({ name = "N/A", to = "/" }) => (
  <li
    style={{
      listStyle: "none",
      textAlign: "left",
    }}
  >
    <Link
      style={{
        textDecoration: "none",
        fontFamily: "Rubik",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: 14,
        color: "#362566",
      }}
      to={to}
    >
      {name}
    </Link>
  </li>
);

const Footer = () => {
  return (
    <Grid
      container
      sx={{
        boxShadow: "0px 2px 8px rgba(38, 36, 131, 0.1)",
      }}
    >
      <Grid
        item
        md={3.5}
        sm={3.5}
        xs={2.5}
        style={{
          left: 80,
        }}
      >
        <Typography
          sx={{
            textAlign: "left",
            color: "#5F41B3",
            fontFamily: "Fredoka",
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: 35,
            lineHeight: "42px",
            letterSpacing: -0.3,
          }}
        >
          Trollii
        </Typography>
      </Grid>
      <Grid item md={2} sm={2} xs={2} className="company">
        <h5
          style={{
            textAlign: "left",
            fontFamily: "Rubik",
            fontStyle: "normal",
            fontWeight: "500%",
            fontSize: 16,
            color: "#362566",
          }}
        >
          Company
        </h5>
        <hr
          align="left"
          style={{
            width: "25%",
            color: "orange",
            fontWeight: "bolder",
            border: "1px solid #FFC725",
            borderTop: "2px solid #FFC725",
            backgroundColor: "orange",
          }}
        />
        {[
          { name: "About" },

          { name: "Mobile" },
          { name: "Blog" },
          { name: "How we work" },
          { name: "" },
        ].map(({ name, to }, index) => (
          <LinkElement key={index} name={name} to={to} />
        ))}
      </Grid>

      <Grid item className="legal" md={2} sm={2} xs={2}>
        <h5
          style={{
            fontFamily: "Rubik",
            fontStyle: "normal",
            textAlign: "left",

            fontWeight: "500%",
            fontSize: 16,
            color: "#362566",
          }}
        >
          Legal
        </h5>
        <hr align="left" style={{ width: "25%", color: "#FFC725" }} />

        {[{ name: "Terms & Conditions" }, { name: "Privacy Policy" }].map(
          ({ name, to }, index) => (
            <LinkElement key={index} name={name} to={to} />
          )
        )}
      </Grid>

      <Grid item md={2} sm={2} xs={2}>
        <h5
          style={{
            fontFamily: "Rubik",
            textAlign: "left",

            fontStyle: "normal",
            fontWeight: "500%",
            fontSize: 16,
            color: "#362566",
          }}
        >
          Support
        </h5>
        <hr align="left" style={{ width: "25%", color: "#FFC725" }} />

        {[{ name: "Contact Us" }, { name: "FAQs" }].map(
          ({ name, to }, index) => (
            <LinkElement key={index} name={name} to={to} />
          )
        )}
      </Grid>
      <Grid item md={1} sm={2} xs={2} className="More">
        <h5
          style={{
            fontFamily: "Rubik",
            fontStyle: "normal",
            textAlign: "left",

            fontWeight: "500%",
            fontSize: 16,
            color: "#362566",
          }}
        >
          More
        </h5>
        <hr align="left" style={{ color: "#FFC725" }} />

        {[
          { name: "Airline Fees" },
          { name: "Airlines" },
          { name: "Partners" },
          { name: "Advertise with us" },
        ].map(({ name, to }, index) => (
          <LinkElement key={index} name={name} to={to} />
        ))}
      </Grid>
    </Grid>
  );
};

export default Footer;
