import React from "react";
import store from "store";
import AddIcon from "@material-ui/icons/Add";
import { Button } from "@material-ui/core";

import Multicity from "./Multicity";
import MaterialButton from "components/CustomButtons/Button.js";
function MultiCityOption({slices,changeDate,sliceOrigin,sliceDestination,sliceRemove,addFlight,classes,selectOrigin,selectDestination}) {
  return (  
    <div>
    {slices.map((slice, index) => {
      return (
        <Multicity
          index={index}
          changeDate={changeDate}
          slices={slices}
          changeOrigin={sliceOrigin}
          changeDestination={sliceDestination}
          remove={sliceRemove}
        />
      );
    })}
    <Button
     style={{
      width: 245,
      backgroundColor: store.getState().AgencyReducer
        .agency.branding.thirdColor
        ? store.getState().AgencyReducer.agency.branding
            .thirdColor
        : store.getState().AgencyReducer.agency.branding
            .primaryColor,
      flexDirection: "row",

      marginTop: "20px",
      padding: "10px 0",
    }}

      className={classes.button}
      onClick={addFlight}
    >
            <b
        style={{
          color: store.getState().AgencyReducer.agency
            .branding.fifth
            ? store.getState().AgencyReducer.agency
                .branding.fifth
            : "white",
        }}
      >
      Add Flight
      </b>
      <AddIcon
          htmlColor={  store.getState().AgencyReducer.agency
            .branding.fifth
            ? store.getState().AgencyReducer.agency
                .branding.fifth
            : "white"}
        style={{ marginLeft: "5px" }}
      />
    </Button>
    <br />
    <br />
  </div>
   
  );
}

export default MultiCityOption;
