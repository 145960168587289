
   
  
import React from "react";
import store from "store"
import Grid from "@mui/material/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import EcoIcon from "@material-ui/icons/Eco";
import FlightTakeoffIcon from "@material-ui/icons/FlightTakeoff";
import MButton from "@material-ui/core/Button";

function BookFlight({green ,handleSubmit, offer}) {
  return (  

    <div>
              <Grid item xs={12} sm={1} md={1} lg={1}>
                  <div style={{ marginTop: "15px" }}>
                    {store.getState().client.client.currency +
                      " " +
                      parseFloat(offer.total_amount).toLocaleString()}
                    {green ? (
                      <Tooltip
                        style={{
                          backgroundColor: "#fafafa",
                        }}
                        arrow
                        placement="top"
                        title="Lower Carbon Dioxide Emissions"
                      >
                        <EcoIcon
                          style={{
                            color: "#4caf50",

                            marginLeft: "2px",
                            fontSize: "medium",
                          }}
                        />
                      </Tooltip>
                    ) : (
                      <div />
                    )}
                  </div>
                </Grid>

                <Grid item xs={12} sm={2} md={2} lg={2}>
                  <MButton
                    variant="outlined"
                    style={{
                      display: "flex",
                      alignItems: "flex-end",
                      flex: 1,
                      justifyContent: "flex-end",
                      color: "white",
                      backgroundColor: store.getState().AgencyReducer.agency
                        .branding.primaryColor,
                      marginTop: "25px",
                    }}
                    size="large"
                    onClick={handleSubmit}
                  >
                    {" "}
                    Book Flight <FlightTakeoffIcon />
                  </MButton>
                </Grid>
    </div>)
}

export default BookFlight;
