const initialState = {
  offer: {},
  additionalServices: {},
  addons: 0,
  numberOfSelectedBags: 0,
  passengerInfo: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "CHOOSE_OFFER":
      return {
        ...state,
        offer: action.payload,
      };
    case "setAdditionalServices":
      return {
        ...state,
        additionalServices: action.payload,
      };
    case "setAddons":
      return {
        ...state,
        addons: action.payload,
      };
    case "serviceAddition":
      state.offer.total_amount = action.payload;
      return {
        ...state,
      };
    case "setNumberOfSelectedBags":
      state.numberOfSelectedBags = action.payload;
      return {
        ...state,
      };
    case "PASSENGERS":
      return {
        ...state,
        passengers: action.payload,
      };
    case "PASSENGER_INFO":
      return {
        ...state,
        passengerInfo: action.payload,
      };
    default:
      return state;
  }
};
