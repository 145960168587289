import {
  CHOOSE_OFFER,
  FILTERED_OFFERS,
  GET_OFFERS,
  PASSENGERS,
  PASSENGER_INFO,
  Service_Additon,
} from "./types";

export const getPassengers = (passengers) => (dispatch) => {
  dispatch({
    type: PASSENGERS,
    payload: passengers,
  });
};
export const serviceAddition = (amount) => (dispatch) => {
  dispatch({
    type: Service_Additon,
    payload: amount,
  });
};
export const chooseOffer = (offer, callback) => (dispatch) => {
  dispatch({
    type: CHOOSE_OFFER,
    payload: offer,
  });
  callback();
};

export const getPassengerInfo = (passengerInfo) => (dispatch) => {
  dispatch({
    type: PASSENGER_INFO,
    payload: passengerInfo,
  });
};

export const getOffers = (offers) => (dispatch) => {
  dispatch({
    type: GET_OFFERS,
    payload: offers,
  });
};
