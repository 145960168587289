import React, { useEffect } from "react";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import { makeStyles } from "@material-ui/core/styles";
import Passengers from "./Passengers";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles(styles);

function PassengersFunctional(props) {
  const classes = useStyles();
  let history = useNavigate();

useEffect(()=>{
  console.log("here");
  console.log(history);
})

  return (
    <div>
      <Passengers classes={classes} history={history}/>
    </div>
  );
}

export default PassengersFunctional;
