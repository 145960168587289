const initState = {
  paymentId: "",
  paymentClientToken: "",
};

const PaymentReducer = (state = initState, action) => {
  if (action.type == "setPaymentClientToken") {
    state = action.payload;
    return {
      ...state,
    };
  }

  return state;
};

export default PaymentReducer;
