import React from "react";
import store from "store";
import InputLabel from "@material-ui/core/InputLabel";
import TextField from "@material-ui/core/TextField";
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import InputAdornment from '@mui/material/InputAdornment';
import FlightLandOutlinedIcon from '@material-ui/icons/FlightLandOutlined';
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2)
  },
  gpsIcon: {
    position: "absolute",
    borderLeftColor: "3%",
  },
  input: {
    "& > label": {
    },
    "& > div > input": {
    }
  }
}));
function TextFieldLandingForm({params,text }) {
  const classes = useStyles();

  const [val, setVal] = React.useState("");

  return (
    <>
    <InputLabel
      id="demo-simple-select-label"
      style={{
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        color: store.getState().AgencyReducer
          .agency.branding.fifth
          ? store.getState().AgencyReducer.agency
              .branding.fifth
          : "black",
      }}
    >
      {text}
    </InputLabel>
    <TextField
  
        {...params}
          className={classes.input}
          InputProps={{
            
            ...params.InputProps,
            disableUnderline:true,
            startAdornment: (
              <InputAdornment position="start">
                {text=="from where?"?
               <FlightTakeoffIcon className={classes.gpsIcon} htmlColor="#353539"/> :<FlightLandOutlinedIcon className={classes.gpsIcon} htmlColor="#353539"/> }
              </InputAdornment>
            ),
          }}
      style={{
                borderRadius:'5%',
        backgroundColor: store.getState()
          .AgencyReducer.agency.branding
          .sixth
          ? store.getState().AgencyReducer.agency
              .branding.sixth
          : "white",
      }}
      sx={{
       " & .MuiAutocomplete-input":{
          paddingLeft:"35px"
        }
      }}
      required
      margin="normal"
    />
  </>
  );
}

export default TextFieldLandingForm;
