import { Grid } from "@material-ui/core";
import React from "react";
import Filters from "./Filters.js";
import GridItem from "components/Grid/GridItem.js";
import Sorting from "./Sorting";

function OffersDetails({classes,state,history,handleCheapest,handleShortest,filter}) {
  return (  
 
      <div
                className={classes.main}
                style={{ backgroundColor: "#F6F7F8" }}
              >
                <h1>asdasdads</h1>
                <Grid container>
                  <Filters
                    classes={classes}
                    airlines={state.airlines}
                    max={state.maxPrice}
                    minDuration={state.minDuration}
                    maxDuration={state.maxDuration}
                    filter={filter}
                  />
                  <GridItem xs={12} sm={8} style={{ marginTop: "16px" }}>
                    {!state.filtersWarning && !state.spinner && (
                      <Sorting
                        offers={state.offers}
                        classes={classes}
                        green={state.green}
                        adults={state.adults}
                        children={state.children}
                        history={history}
                        handleCheapest={handleCheapest}
                        handleShortest={handleShortest}
                      />
                    )}
                  </GridItem>
                </Grid>
              </div>
  );
}

export default OffersDetails;
