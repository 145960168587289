import React, { useState } from "react";
import FAQ from "./Faq";
import Typography from "@material-ui/core/Typography";

import "./Faqs.css";
import store from "store";
export const FaqsSections = () => {
  const [faqs, setFaqs] = useState(
    store.getState().AgencyReducer.agency.FAQs
      ? store.getState().AgencyReducer.agency.FAQs
      : []
  );

  const toggleFAQ = (index) => {
    setFaqs(
      faqs.map((faq, i) => {
        if (i === index) {
          faq.open = !faq.open;
        } else {
          faq.open = false;
        }

        return faq;
      })
    );
  };

  return (
    <div className="faqs">
      {faqs.length > 0 ? (
        <div>
          {" "}
          <Typography
            style={{
              color: "#616161",
              fontWeight: "bold",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            FAQs
          </Typography>
          {faqs.map((faq, i) => {
            return (
              <FAQ
                faq={faq}
                key={i}
                index={i}
                toggleFAQ={toggleFAQ}
                canDelete={false}
              />
            );
          })}
        </div>
      ) : null}
    </div>
  );
};
