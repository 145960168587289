import React, { Component } from "react";
import { Grid, Typography, Rating } from "@mui/material";
import DoDisturbIcon from "@mui/icons-material/DoDisturb";
import LocalAtmIcon from "@mui/icons-material/LocalAtm";
import { borderTop } from "@mui/system";
import SouthSharpIcon from "@mui/icons-material/SouthSharp";
import moment from "moment";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";

const Item = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  boxShadow: "0px 0px 10px rgba(0,0,0,0.5)",
  textAlign: "center",
}));
export default function Booking({ item }) {
  return (
    <Grid item sm={4} xs={4} md={4}>
      <Item>
        <Grid container>
          <Grid item md={6}>
            <Typography
              sx={{
                p: 1,
                height: 70,
                fontFamily: "Rubik",
                fontStyle: "normal",
                fontSize: 16,
                fontWeight: 500,
                color: "#362566",
              }}
            >
              {item.trolliiBooking.hotel.name}
            </Typography>{" "}
            <Typography
              sx={{
                fontFamily: "Rubik",
                fontStyle: "normal",
                fontSize: 20,
                fontWeight: 600,
                color: "#5F41B3",
              }}
            >
              {`${item.trolliiBooking.hotel.currency} ${item.trolliiBooking.hotel.minRate}`}
            </Typography>
            <Rating
              sx={{ pt: 1 }}
              name="read-only"
              value={parseInt(item.trolliiBooking.hotel.categoryName.charAt(0))}
              readOnly
            />
          </Grid>
          <div class="vertical_dotted_linoe" />
          <Grid item md={5.5}>
            <Typography
              sx={{
                p: 1,
                fontFamily: "Rubik",
                fontStyle: "normal",
                fontSize: 17,
                fontWeight: 400,
                color: "#5D5C66",
              }}
            >
              {moment(item.trolliiBooking.checkInDate).format("DD, MMM YY")}
            </Typography>{" "}
            <SouthSharpIcon />
            <Typography
              sx={{
                p: 1,
                fontFamily: "Rubik",
                fontStyle: "normal",
                fontSize: 17,
                fontWeight: 400,
                color: "#5D5C66",
              }}
            >
              {moment(item.trolliiBooking.checkOutDate).format("DD, MMM YY")}
            </Typography>{" "}
          </Grid>
          <Grid container flexDirection={"row"} sx={{ pt: 3 }}>
            <LocalAtmIcon sx={{ ml: 1 }} htmlColor="#5F41B2" />
            <Typography
              onClick={() => {
                window.open(item.voucher);
              }}
              sx={{
                cursor: "pointer",
                ml: 1,
                fontFamily: "Rubik",
                fontStyle: "normal",
                fontSize: 17,
                fontWeight: 400,
                color: "#5F41B2",
              }}
            >
              Show Voucher
            </Typography>{" "}
            <Grid item md={1.8} />
            <Grid item md={1.5}>
              <DoDisturbIcon htmlColor="#CC0033" />
            </Grid>
            <Grid item md={1}>
              <Typography
                sx={{
                  fontFamily: "Rubik",
                  fontStyle: "normal",
                  fontSize: 17,
                  fontWeight: 400,
                  color: "#CC0033",
                }}
              >
                Cancel
              </Typography>{" "}
            </Grid>
          </Grid>
        </Grid>
      </Item>
    </Grid>
  );
}
