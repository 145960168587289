import React from "react";
import { RangeDatePicker } from "react-google-flight-datepicker";
import Grid from "@material-ui/core/Grid";
import store from "store";
import "../HotelSearch/dateSelector.css";
import dateFormat from "dateformat";

export default function DateSelector({
  setCheckin,
  setCheckout,
  setCheckinDate,
  setCheckoutDate,
  checkin,
  checkout,
  isOpen,
}) {
  const today = new Date();
  return (
    <Grid item xs={12} sm={3} md={3}>
      <RangeDatePicker
        className="selector"
        startDate={checkin}
        endDate={checkout}
        onChange={(startDate, endDate) => {
          setCheckinDate(startDate);
          setCheckoutDate(endDate);
          let date1 = dateFormat(startDate, "ddd, d mmm");
          setCheckin(date1);
          let date2 = dateFormat(endDate, "ddd, d mmm");
          setCheckout(date2);
          store.dispatch({
            type: "setHotelCheckInDate",
            payload: startDate,
          });
          store.dispatch({
            type: "setHotelCheckOutDate",
            payload: endDate,
          });
        }}
        onFocus={() => {
          return "";
        }}
        minDate={new Date()}
        maxDate={new Date(2100, 0, 1)}
        dateFormat="ddd, D MMM "
        monthFormat="MMMM"
        startDatePlaceholder="Start Date"
        endDatePlaceholder="End Date"
        disabled={false}
        startWeekDay="sunday"
        isOpen={isOpen}
      ></RangeDatePicker>
    </Grid>
  );
}
