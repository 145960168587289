import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { styled } from '@mui/material/styles';
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { makeStyles } from "@mui/styles";
import PlaceSelector from "../LandingPage/PlaceSelector";
import DateSelector from "../LandingPage/DateSelector";
import Selectors from "./Selectors";
import CalendarIcon from "../../../../assets/img/calendar.png";
import { Button, Grid, Popover } from "@mui/material";
import dateFormat from "dateformat";
import "./dateSelector.css";
import { SearchHotels, paginateHotels } from "views/Services/clientServices";
import store from "store";
import FlightIcon from '@mui/icons-material/Flight';
import LocalHotelIcon from '@mui/icons-material/LocalHotel';
import MapIcon from '@mui/icons-material/Map';
import TimeToLeaveIcon from '@mui/icons-material/TimeToLeave';
import DirectionsBoatFilledIcon from '@mui/icons-material/DirectionsBoatFilled';
import SearchFlight from "../SearchFlight/SearchFlight.js";
import searchflightform from "./searchflightform.scss"

import PlaneTakeOff from "../../../../assets/img/PlaneTakeOff.png";
import FlightCalendar from "../../../../assets/img/FlightCalendar.png";
import PlaneLand from "../../../../assets/img/PlaneLand.png";
import InputLabel from "@material-ui/core/InputLabel";
  import GridItem from "components/Grid/GridItem.js";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 6 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export const useStyles = makeStyles({
  tabPanel: {
    backgroundColor: "#ffffff",
    borderRadius: "0px 20px 20px 20px",
    
  },
  tab: {
    backgroundColor: "#E7E7E7 !important",
    borderRadius: "20px 20px 0px 0px !important",
    width: "10vw ",
    height: "6vw ",
    marginRight: "0.3vw !important",
    color: "#362566 !important",
    fontFamily: "Rubik !important",
    textTransform: "none !important",
    fontStyle: "normal",
    fontWeight: "500 !important",
    fontSize: "1.2vw !important",
    "&.MuiTab-root.Mui-selected": {
      backgroundColor: "#ffffff !important",
      "&.MuiTabs-indicator": {
        backgroundColor: "rgba(255, 199, 37, 1) !important",
        width: "4.5vw !important",
        left: "2.75vw !important",
        height: "0.25vw",
      },
    },
    "&:hover": {
      backgroundColor: "#e7e7e7 !important",
    },
  },
  selector: {
    width: "17.5vw",
    height: "42px",
    borderRadius: "15px",
    border: "1px solid #C4C4C4",
    display: "flex",
  },

  dateSelector: {
    width: "219px",
    height: "42px",
    border: "1px solid #C4C4C4",
    borderRadius: "15px",
    display: "flex",
  },

  bigdiv: {
    display: "flex",
    justifyContent: "space-between",
    margin: "auto",
    marginTop: "1vw",
    marginBottom: "1.5vw",
  },
  selectorText: {
    textAlign: "left",
    fontFamily: "Rubik",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "1.15vw",
    lineHeight: "17px",
    color: "#5D5C66",
  },
  icon: {
    width: "1.4vw",
    height: "1.4vw",
    marginTop: "0.5vw",
  },
  iconDiv: {
    width: "20%",
  },
  button: {
    width: "300px !important ",
    height: "42px !important",
    background: "#5F41B3 !important",
    borderRadius: "10px !important",
    color: "white !important",
    textTransform: "none !important",
    fontFamily: "Inter !important",
    fontStyle: "normal !important",
    fontWeight: "500 !important",
    fontSize: "16px !important",
    lineHeight: "19px !important",
    textAlign: "center !important",
    marginTop:"20px !important",
  },
  customTabs: {
     display:'flex', justifyContent: 'space-between !important', width: "fit-content !important",
    gap: "4rem !important", margin: "auto !important", 
  },
  customTab: {
  padding: "1.5rem 3rem !important" ,
              borderRadius:'8px !important',
              backgroundColor: 'white !important',
              boxShadow: "1 !important",
              marginRight:"64px !important",
              color:"#5F41B2 !important",
              fontFamily: "Rubik !important",
              fontSize: "18px !important",
              fontWeight: "500 !important",
              minWidth: "150px !important",
              maxWidth: "150px !important",
}

});



export async function searchSubmit(
  setLoading,
  setDialogOpen,
  setMsg,
  setError,
  msg
) {
  try {
    setLoading("home");

    const response = await SearchHotels();
    store.dispatch({ type: "setAllHotels", payload: response });

    const hotels = await paginateHotels({ hotels: response });
    store.dispatch({ type: "setHotelResults", payload: hotels });
    if (hotels) setLoading(false);
    if (!hotels) alert("please try again with different datess");
  } catch (e) {
    setLoading("empty");
    if (e.response.status == 404) {
      setMsg(e.response.data.msg);
      setError(true);
      setDialogOpen(true);
    } else {
      setMsg("no results matched your checked filters or dates");
      setError(true);
      setDialogOpen(true);
    }
  }
}
 const CustomTabs = styled(Tabs)({

  },
);

const CustomTab = styled(Tab)({
 
  },
);

export default function SearchPanel({
  setLoading,
  loading,
  setDialogOpen,
  setError,
  setMsg,
  msg,
  classes,
  
}) {
  const [value, setValue] = React.useState(
    store.getState().AgencyReducer.agency.serviceSubscription.flights &&
      store.getState().AgencyReducer.agency.serviceSubscription.hotels
      ? 1
      : 0
  );
    const [showPanel, setShowPanel] = React.useState(false);
  const [dateSelected, setDateSelected] = React.useState(false);

  const today = new Date();
  const [checkin, setCheckin] = React.useState(
    dateFormat(new Date(), "ddd, d mmm")
  );
  const [checkout, setCheckout] = React.useState(
    dateFormat(new Date(today.getTime() + 24 * 60 * 60 * 1000), "ddd, d mmm")
  );
  const [checkinDate, setCheckinDate] = React.useState(new Date());
  const [checkoutDate, setCheckoutDate] = React.useState(
    new Date(today.getTime() + 24 * 60 * 60 * 1000)
  );

  const styles = useStyles();
  const [persons, setPersons] = React.useState(false);
  const [routes, setRoutes] = React.useState("");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const btnRef = React.useRef(null);

  React.useEffect(() => {
    const closeDropdown = (e) => {};

    document.body.addEventListener("click", closeDropdown, true);

    return () => document.body.removeEventListener("click", closeDropdown);
  }, []);

  return (
    <Box
      sx={{
      width:"100%",
        filter: "drop-shadow(0px 2px 8px rgba(38, 36, 131, 0.25))",
      }}
    >
      <CustomTabs
      className={showPanel ? "" : styles.customTabs}
        value={value}
        onChange={handleChange}
        aria-label="basic tabs example"
         style={{ }}>
        {store.getState().AgencyReducer.agency.serviceSubscription.flights ? (
          <CustomTab
            style={{
            
              }}
            className={showPanel ? styles.tab : styles.customTab}
            label="Flights"
            {...a11yProps(0)}
             icon={<FlightIcon sx={{
                  width: "56px",
                  height:'56px',
                  color:"#5F41B2",
                  transform:"Rotate(45deg)"
                }}/>}
            onClick={()=>{setShowPanel(true)}}

          />
        ) : null}

        {store.getState().AgencyReducer.agency.serviceSubscription.hotels ? (
          <CustomTab onClick={()=>{setShowPanel(true)}}  style={{
           
              }} className={showPanel ? styles.tab : styles.customTab} label="Hotels" {...a11yProps(1)} icon={<LocalHotelIcon sx={{
                  width: "56px",
                  height:'56px',
                  color:"#5F41B2",
                }}/>} />
        ) : null}
        {store.getState().AgencyReducer.agency.serviceSubscription.tours ? (
          <CustomTab  onClick={()=>{setShowPanel(true)}} style={{
           
              }} className={showPanel ? styles.tab : styles.customTab} label="Tours" InputLabelProps={{
                  color:"#5F41B2" }} {...a11yProps(2)} icon={<MapIcon sx={{
                  width: "56px",
                  height:'56px',
                  color:"#5F41B2",
                }}/>} />
        ) : null}
        {store.getState().AgencyReducer.agency.serviceSubscription
          .carRentals ? (
          <CustomTab  onClick={()=>{setShowPanel(true)}} style={{
              
              }} className={showPanel ? styles.tab : styles.customTab} label="Car Rental"  {...a11yProps(3)} icon={<TimeToLeaveIcon sx={{
                  width: "56px",
                  height:'56px',
                  color:"#5F41B2",
                }}/>} />
          ) : null}
      
          <CustomTab  onClick={()=>{setShowPanel(true)}} style={{
              
              }} className={showPanel ? styles.tab : styles.customTab} label="Cruises" {...a11yProps(3)} icon={<DirectionsBoatFilledIcon sx={{
                  width: "56px",
                  height:'56px',
                  color:"#5F41B2",
                }}/>} />
      </CustomTabs>

      {showPanel ?<TabPanel className={styles.tabPanel} value={value} index={0}>
          <SearchFlight />
      </TabPanel>:""}

      {showPanel ?
      <TabPanel  value={value} index={1} className={styles.tabPanel}>
        <div className={styles.bigdiv}>
          <div>
            <p className={styles.selectorText}>Destination</p>
            <div className={styles.selector}>
              <PlaceSelector />
            </div>
          </div>

          <div>
            <p className={styles.selectorText}>Travellers</p>
              <Selectors persons={persons} setPersons={setPersons} />
          </div>

          <div>
            <p className={styles.selectorText}>Check-in</p>
            {dateSelected === false ? (
              <div
                className={styles.dateSelector}
                onClick={() => {
                  setDateSelected(!dateSelected);
                }}
              >
                <div className={styles.iconDiv}>
                  <img
                    className={styles.icon}
                    src={CalendarIcon}
                    alt="CalendarIcon"
                  />
                </div>

                <div
                  style={{
                    textAlign: "center",
                    marginTop: "0.5vw",
                  }}
                >
                  <Typography>
                    {dateFormat(
                      store.getState().hotelSearch.checkInDate,
                      "ddd, d mmm"
                    )}
                  </Typography>
                </div>
              </div>
            ) : (
              <div className={styles.dateSelector} ref={btnRef}>
                <DateSelector
                  isOpen={true}
                  checkin={checkinDate}
                  setCheckin={setCheckin}
                  setCheckout={setCheckout}
                  setCheckinDate={setCheckinDate}
                  setCheckoutDate={setCheckoutDate}
                  checkout={checkoutDate}
                />
              </div>
            )}
          </div>

          <div>
            <p className={styles.selectorText}>Check-out</p>
            <div
              className={styles.dateSelector}
              onClick={() => {
                setDateSelected(!dateSelected);
              }}
            >
              <div className={styles.iconDiv}>
                <img
                  className={styles.icon}
                  src={CalendarIcon}
                  alt="CalendarIcon"
                />
              </div>
              <div
                style={{
                  textAlign: "center",
                  marginTop: "0.5vw",
                }}
              >
                <Typography>
                  {dateFormat(
                    store.getState().hotelSearch.checkOutDate,
                    "ddd, d mmm"
                  )}
                </Typography>
              </div>
            </div>{" "}
          </div>
        </div>
        <Grid md={12} sx={{ textAlign: "center" }}>
          <Button
            onClick={() =>
              searchSubmit(setLoading, setDialogOpen, setMsg, setError, msg)
            }
            className={styles.button}
          >
            Search Hotels
          </Button>
        </Grid>
      </TabPanel>:""}

    </Box>
  );
}
