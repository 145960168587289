import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import InputCode from "./InputCode";

import { Container, Input } from "@material-ui/core";

const mapDispatchToState = (dispatch) => {
  return {
    setVerificationCode: (verificationCode) => {
      dispatch({
        type: "setVerificationCode",
        verificationCode: verificationCode,
      });
    },
  };
};

const mapStateToProps = (state) => {
  return { signUpInfo: state.SignUpReducer };
};

const VerificationCode = ({ signUpInfo }) => {
  const [loading, setLoading] = useState(false);
  return (
    <Container>
      <div className="loading"></div>
      <div></div>
      <div className="d-flex justify-content-center mt-5">
        <h1
          className="h1"
          style={{
            backgroundColor: "#9c27b0",
            padding: 40,
            borderRadius: 10,
            color: "white",
            fontFamily: "Cabin",
          }}
        >
          Verify Email
        </h1>
      </div>
      <div
        style={{
          height: "100%",
          width: "100%",
          flexDirection: "column",
          marginTop: "8%",
          marginBottom: "5%",
        }}
      >
        <div className="">
          <h4 className="mb-5 h4">can you check it in your spam ?</h4>
        </div>
        <InputCode
          length={6}
          label="Code Label"
          loading={loading}
          onComplete={(code) => {
            setLoading(true);
            setTimeout(() => setLoading(false), 10000);
          }}
        />
      </div>

      <div className="">
        <h4 className="mb-5 h4">
          Didn’t receive code? <a href="#"> Request again </a>
        </h4>
      </div>
      {/* </div> */}
      {/* // </div> */}
    </Container>
  );
};

export default connect(mapStateToProps, mapDispatchToState)(VerificationCode);
