import Icon from "@material-ui/core/Icon";
import InputAdornment from "@material-ui/core/InputAdornment";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import BookmarkIcon from "@material-ui/icons/Bookmark";
// @material-ui/icons
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import { Button } from "reactstrap";
import store from "store";
import Email from "@material-ui/icons/Email";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import PersonIcon from "@material-ui/icons/Person";
import SearchIcon from "@material-ui/icons/Search";
import image from "assets/img/bg7.jpg";
import hosts from "../../constants/hosts";
import styles from "assets/jss/material-kit-react/views/loginPage.js";
import axios from "axios";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import MaterialButton from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import TextField from "@mui/material/TextField";
// core components
import Header from "components/Header/Header.js";
import Success from "components/Typography/Success.js";
import Warning from "components/Typography/Warning.js";
import Cookie from "js-cookie";
import React from "react";
import { confirmationPage } from "../Services/clientServices";

const useStyles = makeStyles(styles);

export default function Verification(props) {
  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  const [code, setCode] = React.useState("");

  const [text, setText] = React.useState(false);
  const [success, Setsuccess] = React.useState(false);

  window.scrollTo(0, 0);

  const handleSubmit = async () => {
    try {
      let body = {
        input: code,
        verificationCode: store.getState().client.verificationCode,
        email: store.getState().client.emailVerification,
        password: store.getState().client.passwordVerification,
        url: store.getState().AgencyReducer.agency.url,
      };
      await confirmationPage(body);
      props.history("/login");
    } catch (e) {
      alert(e.response.data.msg);
    }
  };
  const handleVerification = (value) => {
    setCode(value);
  };

  setTimeout(function () {
    setCardAnimation("");
  }, 700);
  const classes = useStyles();

  return (
    <div>
      <Header
        absolute
        brand="Trollii"
        color="transparent"
        rightLinks={
          <List
            className={classes.list}
            style={{ flexDirection: "row", display: "flex" }}
            dense
          >
            <ListItem className={classes.listItem}>
              <MaterialButton
                color="transparent"
                onClick={() => {
                  if (Cookie.get("token")) {
                    props.history("/mybookings");
                  } else props.history("/login");
                }}
                className={classes.navLink + " " + classes.socialIconsButton}
              >
                <BookmarkIcon style={{ fontSize: "large" }} />
                View my bookings
              </MaterialButton>
            </ListItem>
            <ListItem className={classes.listItem}>
              <MaterialButton
                color="transparent"
                className={classes.navLink + " " + classes.socialIconsButton}
                onClick={() => props.history("/")}
              >
                <SearchIcon style={{ fontSize: "large" }} />
                Search Flights
              </MaterialButton>
            </ListItem>
            {Cookie.get("token") ? (
              <ListItem className={classes.listItem}>
                <MaterialButton
                  color="transparent"
                  className={classes.navLink + " " + classes.socialIconsButton}
                  onClick={() => {
                    Cookie.remove("token");
                    window.location.reload();
                  }}
                >
                  <ExitToAppIcon style={{ fontSize: "large" }} />
                  SIGN OUT
                </MaterialButton>
              </ListItem>
            ) : (
              <ListItem className={classes.listItem} style={{ margin: "5px" }}>
                <MaterialButton
                  color="transparent"
                  className={classes.navLink + " " + classes.socialIconsButton}
                  onClick={() => props.history("/login")}
                >
                  <PersonIcon style={{ fontSize: "large" }} />
                  SIGN IN
                </MaterialButton>
              </ListItem>
            )}
          </List>
        }
      />
      <div
        className={classes.pageHeader}
        style={{
          backgroundImage: "url(" + image + ")",
          backgroundSize: "cover",
          backgroundPosition: "top center",
        }}
      >
        <div className={classes.container}>
          <GridContainer justifyContent="center">
            <GridItem>
              <Typography style={{ textAlign: "center" }}>
                <h1>Welcome</h1>
              </Typography>
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <Card className={classes[cardAnimaton]}>
                <form className={classes.form}>
                  <CardHeader color="primary" className={classes.cardHeader}>
                    <h4>please check your email to verify your account</h4>
                  </CardHeader>
                  <CardBody>
                    <CustomInput
                      required
                      labelText="verifiaction Code..."
                      id="email"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        type: "email",
                        onChange: (event) =>
                          handleVerification(event.target.value),
                        endAdornment: (
                          <InputAdornment position="end">
                            <Email className={classes.inputIconsColor} />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </CardBody>
                  <GridContainer justifyContent="center">
                    {text && (
                      <GridItem style={{ textAlign: "center" }}>
                        <Warning>
                          <b>Fail:</b> Enter unregistered email and password
                          that has a minimum length of 6 characters.
                        </Warning>
                      </GridItem>
                    )}

                    {success && (
                      <GridItem style={{ textAlign: "center" }}>
                        <Success>
                          <b>Success:</b> User Registration Successful.
                        </Success>
                      </GridItem>
                    )}

                    <GridItem style={{ textAlign: "center", fontSize: 9 }}>
                      <Button
                        simple
                        style={{
                          color: store.getState().AgencyReducer.agency.branding
                            .primaryColor,
                          backgroundColor: "white",
                          borderWidth: 0,
                        }}
                        size="lg"
                        onClick={handleSubmit}
                      >
                        submit
                      </Button>
                    </GridItem>
                  </GridContainer>
                </form>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </div>
  );
}
