import * as React from "react";

const MapviewIcon = () => {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.1348 16.7263C15.9765 16.7263 15.826 16.663 15.7152 16.5521L10.6644 11.5013C10.4348 11.2717 10.4348 10.8917 10.6644 10.6621L16.784 4.54255C16.9344 4.39213 17.164 4.33671 17.3698 4.39213C17.5756 4.45546 17.7339 4.62171 17.7814 4.82755C17.9319 5.50046 18.011 6.25254 18.011 7.12338V11.8734C18.011 14.0663 17.5519 15.5467 16.5544 16.5521C16.4435 16.663 16.2773 16.6867 16.1348 16.7263ZM11.9231 11.0817L16.0873 15.2459C16.586 14.478 16.8235 13.3855 16.8235 11.8734V7.12338C16.8235 6.79879 16.8156 6.49796 16.7919 6.21296L11.9231 11.0817Z"
        fill="#362566"
      />
      <path
        d="M4.96439 17.7954C4.91689 17.7954 4.87732 17.7875 4.82982 17.7795C2.20941 17.1779 0.990234 15.3016 0.990234 11.8737V7.1237C0.990234 2.82495 2.8269 0.988281 7.12565 0.988281H11.8757C15.3036 0.988281 17.1798 2.20745 17.7815 4.82787C17.829 5.02579 17.7657 5.23953 17.6232 5.38203L5.38397 17.6212C5.27314 17.732 5.12273 17.7954 4.96439 17.7954ZM7.12565 2.17578C3.47607 2.17578 2.17773 3.47411 2.17773 7.1237V11.8737C2.17773 14.6208 2.93774 15.9983 4.78232 16.5445L16.5386 4.78828C16.0002 2.9437 14.6148 2.18369 11.8677 2.18369H7.12565V2.17578Z"
        fill="#362566"
      />
      <path
        d="M11.8748 18.011H7.12481C6.25397 18.011 5.50981 17.9398 4.82898 17.7815C4.61523 17.734 4.44896 17.5756 4.39355 17.3698C4.33021 17.164 4.39355 16.9423 4.54397 16.784L10.6636 10.6644C10.8931 10.4348 11.2731 10.4348 11.5027 10.6644L16.5536 15.7152C16.6644 15.826 16.7277 15.9765 16.7277 16.1348C16.7277 16.2931 16.6644 16.4435 16.5536 16.5544C15.5481 17.5519 14.0677 18.011 11.8748 18.011ZM6.2144 16.7919C6.4994 16.8156 6.80022 16.8235 7.12481 16.8235H11.8748C13.3948 16.8235 14.4794 16.586 15.2473 16.0873L11.0831 11.9231L6.2144 16.7919Z"
        fill="#362566"
      />
      <path
        d="M7.21967 10.5354C6.72092 10.5354 6.22216 10.3533 5.82633 9.9812C4.56758 8.78579 4.06093 7.47163 4.36176 6.18913C4.6626 4.87496 5.8105 3.98828 7.21967 3.98828C8.62884 3.98828 9.77676 4.87496 10.0776 6.18913C10.3705 7.47954 9.86383 8.78579 8.60508 9.9812C8.21717 10.3454 7.71842 10.5354 7.21967 10.5354ZM5.51759 6.45037C5.26426 7.52703 5.99258 8.49287 6.64967 9.11829C6.97425 9.42704 7.473 9.42704 7.78967 9.11829C8.43884 8.50079 9.16716 7.53495 8.92175 6.45037C8.708 5.50828 7.86884 5.16787 7.21967 5.16787C6.5705 5.16787 5.73926 5.50828 5.51759 6.45037Z"
        fill="#362566"
      />
      <path
        d="M7.24284 7.51302C6.80742 7.51302 6.45117 7.15677 6.45117 6.72135C6.45117 6.28594 6.79951 5.92969 7.24284 5.92969H7.25076C7.68618 5.92969 8.04243 6.28594 8.04243 6.72135C8.04243 7.15677 7.67826 7.51302 7.24284 7.51302Z"
        fill="#362566"
      />
    </svg>
  );
};

export default MapviewIcon;
