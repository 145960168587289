import React from "react";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/captions.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import "react-image-gallery/styles/css/image-gallery.css";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import {
  Grid,
  Rating,
  Button,
  Card,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import TripAdvisorRating from "./components/TripAdvisorRating";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";
import { useState } from "react";
import TvIcon from "@mui/icons-material/Tv";
import Captions from "yet-another-react-lightbox/plugins/captions";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Video from "yet-another-react-lightbox/plugins/video";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import moment, { duration } from "moment";
import store from "store";
import "@fontsource/rubik/400.css";
import "@fontsource/rubik/500.css";
import "@fontsource/rubik/600.css";
import "@fontsource/inter/400.css";
import "@fontsource/inter/500.css";
import "@fontsource/inter/600.css";
import Navbar from "views/Hotels/Components/NavBar";
import Footer from "views/Hotels/Components/Footer";
import Map from "../HotelResults/Map";

const selectorStyleButton = {
  textTransform: "none",
  fontFamily: "Rubik",
  fontWeight: 500,
  color: "#362566",
  paddingBottom: 25,
};

const LineBreak = ({ style }) => (
  <div
    style={{
      backgroundColor: "#C5C5C5",
      margin: "0 auto",
      height: "1px",
      ...style,
      // width: "90%",
    }}
  ></div>
);

const IconAndText = ({ Icon, text }) => (
  <span>
    <Icon
      style={{
        position: "relative",
        paddingRight: 20,
        fontSize: 50,
        bottom: 3,
        color: "#5F41B2",
      }}
    />
    <span style={{ fontSize: 15 }}>{text}</span>
  </span>
);

const ImageGallery = ({ onClick, galleryPreview }) => (
  <Grid container spacing={0.4} onClick={onClick}>
    <Grid item xs={6}>
      <img
        src={galleryPreview[0]?.src}
        style={{
          float: "right",
          height: "100%",
          width: "100%",
        }}
        alt=""
      />
    </Grid>
    <Grid item xs={6} container spacing={0.4}>
      <Grid item xs={6}>
        <img
          src={galleryPreview[1]?.src}
          alt=""
          style={{
            height: "100%",
            width: "100%",
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <img
          src={galleryPreview[2]?.src}
          alt=""
          style={{
            height: "100%",
            width: "100%",
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <img
          src={galleryPreview[3]?.src}
          alt=""
          style={{
            height: "100%",
            width: "100%",
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <img
          src={galleryPreview[4]?.src}
          alt=""
          style={{
            height: "100%",
            width: "100%",
          }}
        />
      </Grid>
    </Grid>
  </Grid>
);

export default function HotelDetails(props) {
  const location = useLocation();
  let hotelDetails = location.state.hotel;
  store.dispatch({
    type: "setHotel",
    payload: hotelDetails,
  });
  let navigate = useNavigate();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  let {
    checkInDate,
    checkOutDate,
    rooms,
    adults,
    children,
    searchPlace,
  } = store.getState().hotelSearch;
  console.log(searchPlace);

  let duration = moment(checkOutDate).diff(checkInDate, "days");
  checkInDate = checkInDate.toLocaleDateString();
  checkOutDate = checkOutDate.toLocaleDateString();

  const [open, setOpen] = React.useState(false);
  let galleryPreview = hotelDetails.gallery.slice(
    0,
    hotelDetails.gallery.length < 5 ? hotelDetails.gallery.length : 5
  );
  let previewAmenities;

  const [slide, setSlide] = useState(0);
  return (
    <div style={{ backgroundColor: "white" }}>
      <Navbar />
      <div
        style={{
          marginRight: isMobile ? 10 : 80,
          marginLeft: isMobile ? 10 : 80,
          fontFamily: "Rubik",
          textAlign: "left",
        }}
      >
        <Grid container justifyContent="center" spacing={isMobile ? 0 : 3}>
          <Grid item xl={8} lg={8} md={8} sm={12} xs={12}>
            <Card
              style={{
                marginTop: 10,
                borderRadius: 30,
                boxShadow: "0px 2px 8px rgba(38, 36, 131, 0.25)",
                width: "100%",
              }}
            >
              <BookmarkIcon
                color="disabled"
                style={{
                  fontSize: 50,
                  float: "right",
                  zIndex: 10,
                  transform: "scaleY(2)",
                  paddingRight: 20,
                  position: "relative",
                  bottom: 5,
                }}
              />

              <div style={{ padding: 10 }}>
                <h1
                  style={{
                    color: "#362566",
                    fontWeight: 600,
                    margin: 5,
                    marginLeft: 20,
                    fontSize: 25,
                  }}
                >
                  {hotelDetails.name}
                </h1>
                <Rating
                  precision={0.5}
                  value={+hotelDetails.categoryName?.split(" ")[0]}
                  readOnly
                  style={{ marginLeft: 20 }}
                />
                <p style={{ color: "#5D5C66", margin: 5, marginLeft: 20 }}>
                  {hotelDetails.hotelContent.city.content +
                    ", " +
                    hotelDetails.hotelContent.country.description.content}{" "}
                </p>
                <TripAdvisorRating
                  value={+hotelDetails.categoryName?.split(" ")[0] - 0.5}
                  style={{ marginLeft: 20 }}
                  reviewCount={34244}
                />
              </div>

              <ImageGallery
                galleryPreview={galleryPreview}
                onClick={() => setOpen(true)}
              />

              <LineBreak style={{ marginTop: 10, marginBottom: 10 }} />
              <Grid container>
                <Grid item md={6} sm={6} xs={12}>
                  <h3
                    style={{
                      margin: 0,
                      marginLeft: 20,
                      color: "#5F41B2",
                      fontFamily: "Rubik",
                      fontWeight: 700,
                    }}
                  >
                    {hotelDetails.minRate + " " + hotelDetails.currency}
                  </h3>

                  <p
                    style={{
                      margin: 0,
                      marginLeft: 20,
                      marginBottom: 10,
                      fontFamily: "Rubik",
                    }}
                  >
                    {`price per ${duration} ${
                      duration > 1 ? "nights" : "night"
                    }`}
                  </p>
                </Grid>
                <Grid item md={6} sm={6} xs={12}>
                  <Button
                    onClick={() => {
                      navigate("/select-rooms", {
                        state: { hotel: hotelDetails },
                      });
                    }}
                    variant="contained"
                    style={{
                      backgroundColor: "#5F41B2",
                      textTransform: "none",
                      fontFamily: "Inter",
                      float: "right",
                      marginRight: 30,
                      marginBottom: 20,
                      width: 200,
                    }}
                  >
                    Select Rooms
                  </Button>
                </Grid>
              </Grid>
            </Card>
          </Grid>
          <Grid
            item
            xl={4}
            lg={4}
            md={4}
            sm={12}
            xs={12}
            container
            direction="row"
            justifyContent="right"
          >
            <div
              style={{
                width: isMobile ? "90%" : "80%",
                float: "right",
                margin: isMobile && "0 auto",
                marginBottom: 40,
              }}
            >
              <Card
                style={{
                  borderRadius: 20,
                  marginTop: 10,
                  border: "1px solid #C4C4C4",
                }}
              >
                <div
                  style={{
                    width: "80%",
                    paddingTop: 20,
                    paddingLeft: 20,
                  }}
                >
                  {[
                    {
                      title: "Destination",
                      value: `${
                        searchPlace.country
                          ? searchPlace.country
                          : hotelDetails.hotelContent.city.content
                      }`,
                    },
                    { title: "Check-in", value: checkInDate },
                    { title: "Check-out", value: checkOutDate },
                    {
                      title: "Travellers",
                      value:
                        adults === 1
                          ? "1 adult"
                          : `${adults} adults` + ", " + children === 1
                          ? "1 child"
                          : `${children} children`,
                    },
                    {
                      title: "Rooms",
                      value: rooms === 1 ? "1 room" : rooms + " rooms",
                    },
                  ].map(({ title, value }, index) => (
                    <span key={index}>
                      <p
                        style={{
                          marginBottom: 0,
                          fontFamily: "Rubik",
                          fontWeight: 600,
                          color: "#362566",
                        }}
                      >
                        {title}
                      </p>
                      <p
                        style={{
                          fontFamily: "Rubik",
                          fontWeight: 400,
                          color: "#5D5C66",
                        }}
                      >
                        {value}
                      </p>
                      <LineBreak />
                    </span>
                  ))}
                </div>
                <div style={{ margin: 10, textAlign: "center" }}>
                  <a
                    href="/"
                    style={{
                      fontFamily: "Inter",
                      fontWeight: 600,
                      color: "#5F41B3",
                    }}
                  >
                    Change
                  </a>
                </div>
              </Card>
            </div>
          </Grid>

          <Grid item xs={12}>
            <Card style={{ padding: 20, borderRadius: 30, marginBottom: 60 }}>
              <Grid container>
                <Grid item xs={12}>
                  <div>
                    {[
                      "Description",
                      "Amenities",
                      "Location",
                      "Reviews",
                      "Policy",
                    ].map((element, index) => (
                      <Button
                        key={index}
                        style={{
                          ...selectorStyleButton,
                          borderBottom: slide === index && "4px solid #FFC725",
                          fontWeight: slide === index ? 600 : 500,
                        }}
                        onClick={() => setSlide(index)}
                      >
                        {element}
                      </Button>
                    ))}
                  </div>
                </Grid>
                <Grid item xs={12}>
                  {slide === 0 && (
                    <div style={{ color: "#5D5C66", margin: 10 }}>
                      <p>{hotelDetails.hotelContent.description.content}</p>
                    </div>
                  )}
                  {slide === 1 && (
                    <>
                      <Grid container>
                        <Grid item sm={3} xs={12}>
                          <IconAndText text="All amenities" Icon={TvIcon} />
                        </Grid>

                        <Grid container>
                          {store
                            .getState()
                            .hotelSearch.hotel.facilities.map((item, index) => {
                              return (
                                <Grid item sm={3} xs={12} key={index}>
                                  <p>{item.description.content}</p>
                                </Grid>
                              );
                            })}
                          <LineBreak />
                        </Grid>
                      </Grid>
                    </>
                  )}
                  {slide === 2 && (
                    <Grid container>
                      {console.log(store.getState().hotelSearch)}
                      <Map
                        width="95vw"
                        height="40vh"
                        long={
                          store.getState().hotelSearch.searchPlace.longitude
                        }
                        lat={store.getState().hotelSearch.searchPlace.latitude}
                      />
                    </Grid>
                  )}
                  {slide === 3 && <></>}
                  {slide === 4 && (
                    <>
                      <div style={{ color: "#5D5C66", margin: 10 }}>
                        <h5>Mandatory fees</h5>
                        You'll be asked to pay the following charges by the
                        property at check-in or check-out:
                        <ul>
                          <li>
                            An effective city/local tax rate of 5.0 per cent
                            will be charged
                          </li>
                          <li>
                            Destination fee: USD 40.16 per accommodation, per
                            night. This fee does not apply to children under 18
                            years of age.
                          </li>
                        </ul>
                        <h5>Renovations and closures</h5>
                        The following facilities or services will be unavailable
                        from June 1 2022 to September 1 2022 (dates subject to
                        change):
                        <ul>
                          <li>Dining venue(s)</li>
                        </ul>
                        <h5>Hygiene & cleanliness</h5>
                        <p>
                          This property advises that enhanced cleaning measures
                          are currently in place. Disinfectant is used to clean
                          the property; commonly touched surfaces are cleaned
                          with disinfectant between stays; bed sheets and towels
                          are laundered at a temperature of at least 60°C/140°F.
                          This property affirms that it adheres to the cleaning
                          and disinfection practices of Ecolab (third-party
                          expert - Global).
                        </p>
                        <h5>Pets</h5>
                        <ul>
                          <li>Service animals exempt from fees</li>
                          <li>
                            Pets are allowed for an extra charge of USD 50.00
                            per pet, per night
                          </li>
                        </ul>
                      </div>
                    </>
                  )}
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Grid>
      </div>
      <Lightbox
        plugins={[Captions, Fullscreen, Slideshow, Thumbnails, Video, Zoom]}
        open={open}
        close={() => setOpen(false)}
        slides={hotelDetails.gallery}
      />
      <Footer />
    </div>
  );
}
