import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import Form from "./Form.js";

const useStyles = makeStyles(styles);

export default function SearchFlight(props) {
  const classes = useStyles();

  return (
      <Form classes={classes} history={props.history} />
  );
}
