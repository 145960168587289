import DateFnsUtils from "@date-io/date-fns";
import FormControl from "@material-ui/core/FormControl";

import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";

import "react-phone-number-input/style.css";
import Warning from "components/Typography/Warning.js";
import dateformat from "dateformat";
import validator from "email-validator";
import isPhone from "is-phone";
import React, { Component } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import store from "store";
const phoneUtil = require("google-libphonenumber").PhoneNumberUtil.getInstance();

export class Passenger extends Component {
  constructor(props) {
    super(props);

    let date = dateformat(this.props.date, "isoDate");
    date = new Date(date);
    if (this.props.name === "Adult") date.setFullYear(date.getFullYear() - 16);

    this.state = {
      title: "mr",
      family_name: "",
      given_name: "",
      born_on: dateformat(date, "isoDate"),
      gender: "m",
      phone_number: "",
      email: "",
    };
  }

  handleEmail = (event) => {
    this.setState({ email: event.target.value }, () =>
      this.props.update(this.state, this.props.index)
    );
  };

  handleTitle = (event) => {
    this.setState(
      {
        title: event.target.value,
      },
      () => this.props.update(this.state, this.props.index)
    );
  };

  handleGivenName = (event) => {
    this.setState(
      {
        given_name: event.target.value,
        type: "adult",
        id: this.props.id,
      },
      () => this.props.update(this.state, this.props.index)
    );
  };

  handleFamilyName = (event) => {
    this.setState(
      {
        family_name: event.target.value,
      },
      () => this.props.update(this.state, this.props.index)
    );
  };

  handleBirthdate = (date) => {
    this.setState(
      {
        born_on: date,
      },
      () => this.props.update(this.state, this.props.index)
    );
  };

  handleGender = (event) => {
    this.setState(
      {
        gender: event.target.value,
      },
      () => this.props.update(this.state, this.props.index)
    );
  };

  render() {
    const classes = this.props.classes;
    let date = this.props.date;
    date = new Date(date);
    date.setFullYear(date.getFullYear() - 16);
    if (this.props.name !== "Adult") {
      date.setDate(date.getDate() + 1);
    }

    const today = new Date();
    let phoneRegExp = /^\+[1-9]\d{11,14}$/;

    return (
      <div style={{ marginBottom: "15px", padding: "16px" }}>
        <Grid container spacing={3}>
          <Grid
            item
            xs={12}
            style={{
              color: store.getState().AgencyReducer.agency.branding
                .primaryColor,
            }}
          >
            <h3> {this.props.name + " " + this.props.number}</h3>
          </Grid>
          {/* style={{ marginLeft: '20px' }} */}
          <Grid item xs={4} sm={2} md={2}>
            <FormControl className={classes.formControl} fullWidth required>
              <InputLabel id="demo-simple-select-label">Title</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                onChange={this.handleTitle}
                value={this.state.title}
                defaultValue="mr"
                required
              >
                <MenuItem value={"mr"}>Mr.</MenuItem>
                <MenuItem value={"mrs"}>Mrs.</MenuItem>
                <MenuItem value={"miss"}>Miss</MenuItem>
                <MenuItem value={"ms"}>Ms.</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={8} sm={6} md={4}>
            <TextField
              required
              label="First Name"
              onChange={this.handleGivenName}
              inputProps={{ maxLength: 20 }}
            />
          </Grid>
          <Grid item sm={12} md={6}>
            <TextField
              fullWidth
              required
              label="Family Name"
              onChange={this.handleFamilyName}
              inputProps={{ maxLength: 20 }}
            />
          </Grid>

          {this.props.name === "Adult" ? (
            <Grid item xs={12} sm={6}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  fullWidth
                  required
                  maxDate={date}
                  autoOk={true}
                  color="primary"
                  disableToolbar
                  variant="inline"
                  format="MM/dd/yyyy"
                  margin="normal"
                  id="Birthdate"
                  openTo="year"
                  label="Date of Birth"
                  value={this.state.born_on}
                  onChange={this.handleBirthdate}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
          ) : (
            <Grid item xs={12} sm={6}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  required
                  minDate={date}
                  maxDate={today}
                  color="primary"
                  disableToolbar
                  variant="inline"
                  format="MM/dd/yyyy"
                  margin="normal"
                  id="Birthdate"
                  openTo="year"
                  label="Date of Birth"
                  value={this.state.born_on}
                  onChange={this.handleBirthdate}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
          )}

          <Grid item xs={12} sm={5}>
            <FormControl fullWidth className={classes.formControl} required>
              <InputLabel id="demo-simple-select-label">Gender</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                onChange={this.handleGender}
                value={this.state.gender}
              >
                <MenuItem value={"m"}>Male</MenuItem>
                <MenuItem value={"f"}>Female</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              required
              label="Email"
              onChange={this.handleEmail}
            />
            {!validator.validate(this.state.email) && (
              <Warning> Add a valid email.</Warning>
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            <PhoneInput
              style={{ color: "#000000" }}
              country={"us"}
              value={this.state.phone_number}
              onChange={(phone) => {
                this.setState({ phone_number: "+" + phone }, () =>
                  this.props.update(this.state, this.props.index)
                );
              }}
            />

            {!isPhone(this.state.phone_number) ? (
              <Warning> Add a valid Phone Number. </Warning>
            ) : !phoneUtil.isValidNumber(
                phoneUtil.parse(this.state.phone_number)
              ) ? (
              <Warning> Add a valid Phone Number. </Warning>
            ) : null}
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default Passenger;
