import { Container } from "@material-ui/core";
import React, { useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

const mapDispatchToState = (dispatch) => {
  return {
    setThemeReducer: (themeReducer) => {
      dispatch({
        type: "setThemeReducer",
        themeReducer: themeReducer,
      });
    },
  };
};

const Theme3Details = ({ setThemeReducer }) => {
  let history = useNavigate();
  const [theme3Info, setTheme3Info] = useState({
    name: "theme3",
    desc: "description of the third theme!",
    budget: "10 BGP",
  });

  return (
    <Container>
      {/* // <div className="container"> */}
      <div className="d-flex justify-content-center  mt-5">
        <h1
          className="h1"
          style={{
            backgroundColor: "#9c27b0",
            padding: 40,
            borderRadius: 10,
            color: "white",
            fontFamily: "Cabin",
            marginBottom: "7%",
          }}
        >
          Theme
        </h1>
      </div>

      <div className="loading">
        {/* <div>
        <svg
          className="progressCircle1Theme"
          width="38"
          height="40"
          viewBox="0 0 38 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M35.8327 19.7194C35.8327 29.8012 28.2045 37.8026 18.9993 37.8026C9.7942 37.8026 2.16602 29.8012 2.16602 19.7194C2.16602 9.63762 9.7942 1.63623 18.9993 1.63623C28.2045 1.63623 35.8327 9.63762 35.8327 19.7194Z"
            fill="#9C27B0"
            stroke="#9C27B0"
            stroke-width="3"
          />
        </svg>

        <svg
          className="progressLine1Theme"
          width="101"
          height="5"
          viewBox="0 0 101 5"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <line
            y1="-1.5"
            x2="100"
            y2="-1.5"
            transform="matrix(0.999941 -0.010899 0.0095525 0.999954 1 4.97827)"
            stroke="#9C27B0"
            stroke-width="3"
          />
        </svg>

        <svg
          className="progressCircle2Theme"
          width="38"
          height="40"
          viewBox="0 0 38 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M35.8327 19.7194C35.8327 29.8012 28.2045 37.8026 18.9994 37.8026C9.79421 37.8026 2.16602 29.8012 2.16602 19.7194C2.16602 9.63762 9.79421 1.63623 18.9994 1.63623C28.2045 1.63623 35.8327 9.63762 35.8327 19.7194Z"
            fill="#9C27B0"
            stroke="#9C27B0"
            stroke-width="3"
          />
        </svg>

        <svg
          className="progressLine2Theme"
          width="101"
          height="5"
          viewBox="0 0 101 5"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <line
            y1="-1.5"
            x2="100"
            y2="-1.5"
            transform="matrix(0.999941 -0.010899 0.0095525 0.999954 1 4.97827)"
            stroke="#9C27B0"
            stroke-width="3"
          />
        </svg>

        <svg
          className="progressCircle3Theme"
          width="38"
          height="40"
          viewBox="0 0 38 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M35.8327 19.7194C35.8327 29.8012 28.2045 37.8026 18.9994 37.8026C9.79421 37.8026 2.16602 29.8012 2.16602 19.7194C2.16602 9.63762 9.79421 1.63623 18.9994 1.63623C28.2045 1.63623 35.8327 9.63762 35.8327 19.7194Z"
            fill="#9C27B0"
            stroke="#9C27B0"
            stroke-width="3"
          />
        </svg>

        <svg
          className="progressLine3Theme"
          width="101"
          height="5"
          viewBox="0 0 101 5"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <line
            y1="-1.5"
            x2="100"
            y2="-1.5"
            transform="matrix(0.999941 -0.010899 0.0095525 0.999954 1 4.97827)"
            stroke="#9C27B0"
            stroke-width="3"
          />
        </svg>

        <svg
          className="progressCircle4Theme"
          width="38"
          height="40"
          viewBox="0 0 38 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M35.8327 19.7194C35.8327 29.8012 28.2045 37.8026 18.9994 37.8026C9.79421 37.8026 2.16602 29.8012 2.16602 19.7194C2.16602 9.63762 9.79421 1.63623 18.9994 1.63623C28.2045 1.63623 35.8327 9.63762 35.8327 19.7194Z"
            fill="#9C27B0"
            stroke="#9C27B0"
            stroke-width="3"
          />
        </svg>

        <svg
          className="progressLine4Theme"
          width="101"
          height="5"
          viewBox="0 0 101 5"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <line
            y1="-1.5"
            x2="100"
            y2="-1.5"
            transform="matrix(0.999941 -0.010899 0.0095525 0.999954 1 4.97827)"
            stroke="#9C27B0"
            stroke-width="3"
          />
        </svg>

        <svg
          className="progressCircle5Theme"
          width="38"
          height="40"
          viewBox="0 0 38 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M35.8327 19.5832C35.8327 29.6649 28.2045 37.6663 18.9994 37.6663C9.79421 37.6663 2.16602 29.6649 2.16602 19.5832C2.16602 9.50139 9.79421 1.5 18.9994 1.5C28.2045 1.5 35.8327 9.50139 35.8327 19.5832Z"
            fill="white"
            stroke="#9C27B0"
            stroke-width="3"
          />
        </svg>

        <svg
          className="progressLine5Theme"
          width="101"
          height="5"
          viewBox="0 0 101 5"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <line
            y1="-1.5"
            x2="100"
            y2="-1.5"
            transform="matrix(0.999941 -0.010899 0.00955249 0.999954 1 4.84204)"
            stroke="#CCCCCC"
            stroke-width="3"
          />
        </svg>

        <svg
          className="progressCircle6Theme"
          width="38"
          height="40"
          viewBox="0 0 38 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M35.8326 19.5832C35.8326 29.6649 28.2045 37.6663 18.9993 37.6663C9.7942 37.6663 2.16602 29.6649 2.16602 19.5832C2.16602 9.50139 9.7942 1.5 18.9993 1.5C28.2045 1.5 35.8326 9.50139 35.8326 19.5832Z"
            fill="white"
            stroke="#CCCCCC"
            stroke-width="3"
          />
        </svg>
      </div> */}
      </div>

      <div>
        {/* <p className="registerTheme">Register</p>
        <p className="verifyTheme">Verify Email</p>
        <p className="businessInfoTheme">Business Information</p>
        <p className="productsTheme">Choose Products</p>
        <p className="themeTheme">Choose Theme</p>
        <p className="launchTheme">Launch</p>
        <p className="actionTextTheme">Template Details</p> */}
      </div>

      <div class="px-4 px-lg-5 my-5">
        <div class="row gx-4 gx-lg-5 align-items-center">
          <div class="col-md-6">
            <img
              class="card-img-top mb-5 mb-md-0"
              src="https://dummyimage.com/600x700/dee2e6/6c757d.jpg"
              alt="..."
            />
          </div>
          <div class="col-md-6">
            <h1 class="display-5 fw-bolder" style={{ textAlign: "center" }}>
              Theme 3 details
            </h1>

            <p class="lead" style={{ textAlign: "center" }}>
              Lorem ipsum dolor sit amet consectetur adipisicing elit.
              Praesentium at dolorem quidem modi. Nam sequi consequatur
              obcaecati excepturi alias magni, accusamus eius blanditiis
              delectus ipsam minima ea iste laborum vero?
            </p>
            <div class="d-flex align-items-center justify-content-center ">
              <div style={{ marginRight: "2%" }}>
                <button
                  className="btn btn-primary "
                  onClick={() => {
                    history("/launch");

                    setThemeReducer(theme3Info);
                  }}
                >
                  Preview
                </button>
              </div>
              <div>
                <button
                  className="btn btn-secondary"
                  onClick={() => history("/theme")}
                >
                  Back
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* </div> */}
    </Container>
  );
};

// export default Theme3Details;
const mapStateToProps = (state) => {
  return { signUpInfo: state.SignUpReducer };
};

export default connect(mapStateToProps, mapDispatchToState)(Theme3Details);
