import React from "react";
import Offers from "./Offers";
import { useNavigate } from "react-router-dom";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

function Sorting({
  offers,
  classes,
  green,
  adults,
  children,
  history,
  handleCheapest,
  handleShortest,
}) {
  const navigate = useNavigate();

  return (
    <div>
      <div
        style={{display:"flex", 
    justifyContent: "center", }}
      >
        <Box sx={{ width: '700px', borderRadius:"15px", border:"1px solid #C4C4C4", display:"flex", 
    justifyContent: "center",  
  }}>
      <Tabs 
      style={{display:"flex", 
    justifyContent: "space-between",}}
      >
  <Tab style={{color: "#362566",
fonFfamily: "Rubik",
fontSize: "16px",
fontStyle: "normal",
fontWeight: "600",
lineHeight: "normal", textTransform: 'none'}} value="Recommended" label="Recommended" />
  <Tab style={{color: "#362566",
fonFfamily: "Rubik",
fontSize: "16px",
fontStyle: "normal",
fontWeight: "600",
lineHeight: "normal", textTransform: 'none'}} onClick={handleCheapest} value="Cheapest" label="Cheapest" />
  <Tab style={{color: "#362566",
fonFfamily: "Rubik",
fontSize: "16px",
fontStyle: "normal",
fontWeight: "600",
lineHeight: "normal", textTransform: 'none'}} onClick={handleShortest} value="Fastest" label="Fastest" />
</Tabs>
</Box>
        {/* <h3 style={{ marginTop: "1%" }}> sort the flights by : </h3>
        <button
          style={{
            borderRadius: "20%",
            marginTop: "1%",
            marginLeft: "7%",
          }}
          onClick={handleCheapest}
          type="button"
          className="btn btn-primary"
        >
          cheapest
        </button>
        <button
          style={{
            borderRadius: "20%",
            marginTop: "1%",
            marginLeft: "1%",
          }}
          onClick={handleShortest}
          type="button"
          className="btn btn-primary"
        >
          shortest duration
        </button> */}
      </div>
      <Offers
        offers={offers}
        classes={classes}
        green={green}
        history={navigate}
        adults={adults}
        children={children}
      />
    </div>
  );
}

export default Sorting;
