import React from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { Grid } from "@mui/material";

export default function TrolliiOptions({ setAction, action }) {
  return (
    <Grid container flexDirection={"row"} sx={{ pt: 4 }}>
      <Grid item md={1} />
      <Grid item>
        <Dropdown>
          <Dropdown.Toggle id="dropdown-basic">{action}</Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item
              onClick={() => {
                setAction("Hotels");
              }}
            >
              Hotels
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => {
                setAction("Flights");
              }}
            >
              Flights
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </Grid>
    </Grid>
  );
}
