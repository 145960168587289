import { Card, TextField, Button, Grid, Typography, Box } from "@mui/material";
import TitleAndBodyCard from "../../Components/TitleAndBodyCard";
import { DeleteOutline } from "@mui/icons-material";
import { DataGrid, gridClasses } from "@mui/x-data-grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useEffect, useState } from "react";
import store from "store";
const cardShadow = { boxShadow: "0px 2px 12px rgba(38, 36, 131, 0.15)" };
import { alpha, styled } from "@mui/material/styles";
import { addStaff } from "views/Services/agencyServices";
import { viewStaff } from "views/Services/agencyServices";
import { deleteStaff } from "views/Services/agencyServices";
import PopUpModal from "views/NewAdminPanel/Components/PopUpModal";
const ODD_OPACITY = 0.2;
export const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: "rgba(95, 65, 178, 0.05)",
    "&:hover, &.Mui-hovered": {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
      "@media (hover: none)": {
        backgroundColor: "transparent",
      },
    },
    "&.Mui-selected": {
      backgroundColor: alpha(
        theme.palette.primary.main,
        ODD_OPACITY + theme.palette.action.selectedOpacity
      ),
      "&:hover, &.Mui-hovered": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY +
            theme.palette.action.selectedOpacity +
            theme.palette.action.hoverOpacity
        ),

        // Reset on touch devices, it doesn't add specificity
        "@media (hover: none)": {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY + theme.palette.action.selectedOpacity
          ),
        },
      },
    },
  },
}));

const Staff = () => {
  const handleDelete = async (data) => {
    try {
      let staff = await deleteStaff({ id: data.id });
      store.dispatch({
        type: "setStaff",
        payload: staff.staff,
      });

      loadStaff();
      setDialogOpen(true);
      setError(false);
      setMsg("Your Agency member has been deleted successfully.");
    } catch (e) {
      setDialogOpen(true);
      setError(true);
      setMsg(`${e.response.data.error} please try again`);
    }
  };
  const [accessLevel, setAccessLevel] = useState("Finance");

  const handleChange = (event) => {
    setAccessLevel(event.target.value);
  };
  const [staff, setStaff] = useState([]);
  const [open, setDialogOpen] = useState(false);
  const [msg, setMsg] = useState("");
  const [error, setError] = useState(false);
  const [staffFields, setStaffFields] = useState({
    firstName: "",
    lastName: "",
    email: "",
  });

  const [pageSize, setPageSize] = useState(5);
  const loadStaff = async () => {
    let staff = await viewStaff();
    setStaff(staff.staff);
  };
  useEffect(() => {
    loadStaff();
  }, []);

  return (
    <>
      <Card style={cardShadow}>
        <div style={{ marginLeft: 30 }}>
          <h1
            style={{
              color: "#3B3666",
              fontWeight: 600,
              fontFamily: "Rubik",
              fontStyle: "normal",
              fontSize: "32px",
              textAlign: "left",
            }}
          >
            Staff
          </h1>
          <h4
            style={{
              color: "#5D5C66",
              fontFamily: "Rubik",
              fontStyle: "normal",
              fontWeight: 400,
              paddingBottom: "2%",
              textAlign: "left",
              fontSize: "24px",
            }}
          >
            Here you can find all the information about your staff members and
            register new members as well.
          </h4>
        </div>
      </Card>
      <>
        <TitleAndBodyCard title="Add new member" style={{ margin: 30 }}>
          <div style={{ padding: 20, margin: "0 auto" }}>
            {["First Name", "Last Name", "Email"].map((element, index) => (
              <div style={{ padding: 10, paddingBottom: 10 }} key={index}>
                <Typography
                  sx={{
                    color: "#5D5C66",
                    fontFamily: "Rubik",
                    fontStyle: "normal",
                    fontSize: "20px",
                    textAlign: "left",
                    fontWeight: 500,
                  }}
                >
                  {element}
                </Typography>
                <TextField
                  onChange={(text) => {
                    setStaffFields({
                      ...staffFields,
                      [element == "First Name"
                        ? "firstName"
                        : element == "Last Name"
                        ? "lastName"
                        : element.toLowerCase()]: text.target.value,
                    });
                  }}
                  style={{ borderRadius: 50 }}
                  fullWidth
                  variant="outlined"
                />
              </div>
            ))}
            <div style={{ padding: 10, paddingBottom: 10 }}>
              <Typography
                sx={{
                  color: "#5D5C66",
                  fontFamily: "Rubik",
                  fontStyle: "normal",
                  fontSize: "20px",
                  textAlign: "left",
                  fontWeight: 500,
                }}
              >
                Acess Level
              </Typography>
              <Box sx={{ minWidth: 120 }}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Access-Level
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={accessLevel}
                    label="Access-Level"
                    onChange={handleChange}
                  >
                    <MenuItem value={"Finance"}>Finance</MenuItem>
                    <MenuItem value={"User"}>User</MenuItem>
                    <MenuItem value={"SuperAdmin"}>SuperAdmin</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </div>
            <PopUpModal
              isOpen={open}
              setDialogOpen={setDialogOpen}
              msg={msg}
              error={error}
            />
            <Button
              onClick={async () => {
                try {
                  let data = {
                    email: staffFields.email,
                    firstName: staffFields.firstName,
                    lastName: staffFields.lastName,
                    accessLevel: accessLevel,
                  };
                  let staff = await addStaff(data);
                  store.dispatch({
                    type: "setStaff",
                    payload: staff.staff,
                  });
                  console.log(store.getState().AgencyReducer.agency);
                  loadStaff();
                  setDialogOpen(true);
                  setError(false);
                  setMsg("Your Agency member has been added successfully.");
                } catch (e) {
                  setDialogOpen(true);
                  setError(true);
                  setMsg(`${e.response.data.error} please try again`);
                }
              }}
              variant="contained"
              style={{
                margin: 40,
                width: "25%",
                color: "white",
                backgroundColor: "#5F41B3",
                textTransform: "none",
                fontFamily: "Rubik",
                fontStyle: "normal",
                fontSize: "18px",
                fontWeight: 500,
              }}
            >
              Add member
            </Button>
          </div>
        </TitleAndBodyCard>
      </>
      <TitleAndBodyCard title="Staff members" style={{ margin: 30 }}>
        <Box
          sx={{
            height: 400,
            width: "100%",
            "& .super-app-theme--header": {
              backgroundColor: "rgba(95, 65, 178, 0.1)",
              fontFamily: "Rubik",
              fontStyle: "normal",
              fontSize: "18px",
              fontWeight: 500,
            },
          }}
        >
          <StripedDataGrid
            sx={{
              ".MuiDataGrid-columnSeparator": {
                display: "none",
              },
              "&.MuiDataGrid-root": {
                border: "none",
              },
              "& .MuiDataGrid-cell:hover": {
                color: "primary.main",
              },
            }}
            getRowClassName={(params) =>
              params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
            }
            rows={staff}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            getRowId={(row) => Math.floor(Math.random() * Date.now())}
            columns={[
              {
                headerName: "First name",
                field: "firstName",
                width: "200",
                headerClassName: "super-app-theme--header",
                headerAlign: "center",
                align: "center",
                flex: 1,
              },
              {
                headerName: "Last name",
                field: "lastName",
                width: "200",
                headerClassName: "super-app-theme--header",
                headerAlign: "center",
                align: "center",
                flex: 1,
              },
              {
                headerName: "Email",
                field: "email",
                width: "200",
                headerClassName: "super-app-theme--header",
                headerAlign: "center",
                align: "center",
                flex: 1,
              },
              {
                headerName: "Access Level",
                field: "accessLevel",
                width: "200",
                headerClassName: "super-app-theme--header",
                headerAlign: "center",
                align: "center",
                flex: 1,
              },
              {
                field: "action",

                width: "200",
                headerClassName: "super-app-theme--header",
                headerAlign: "center",
                align: "center",
                flex: 1,
                headerName: "Delete Staff",
                sortable: false,
                renderCell: (params) => {
                  return (
                    <DeleteOutline
                      onClick={() => handleDelete(params.row)}
                      sx={{ cursor: "pointer" }}
                      htmlColor="#FF3636"
                    />
                  );
                },
              },
            ]}
            pageSize={pageSize}
            pagination
            rowsPerPageOptions={[5, 10, 25, 50, 75, 100]}
            disableSelectionOnClick
            onRowClick={(row, event) => {}}
          />
        </Box>
      </TitleAndBodyCard>
    </>
  );
};
export default Staff;
