let today = new Date();

const initState = {
  searchPlace: {},
  checkInDate: new Date(today.getTime() + 24 * 60 * 60 * 1000),
  loading: "home",
  checkOutDate: new Date(today.getTime() + 48 * 60 * 60 * 1000),
  adults: 1,
  children: 0,
  rooms: 1,
  passengers: [],
  hotel: "",
  rateKey: "",
  image: "",
  qrLink: "",
};

const hotelSearchReducer = (state = initState, action) => {
  if (action.type == "setSearchPlace") {
    state.searchPlace = action.payload;
    return {
      ...state,
    };
  }
  if (action.type == "setQrLink") {
    state.qrLink = action.payload;
    return {
      ...state,
    };
  }
  if (action.type == "setNewPrice") {
    state.hotel.minRate = action.payload;
    return {
      ...state,
    };
  }
  if (action.type == "setHotelImage") {
    state.image = action.payload;
    return {
      ...state,
    };
  }
  if (action.type == "setHotel") {
    state.hotel = action.payload;
    return {
      ...state,
    };
  }
  if (action.type == "setRateKey") {
    state.rateKey = action.payload;
    return {
      ...state,
    };
  }
  if (action.type == "setLoading") {
    state.loading = action.payload;
    return {
      ...state,
    };
  }
  if (action.type == "setSearchAdults") {
    state.adults = action.payload;
    return {
      ...state,
    };
  }
  if (action.type == "setSearchChildren") {
    state.children = action.payload;
    return {
      ...state,
    };
  }
  if (action.type == "setSearchRooms") {
    state.rooms = action.payload;
    return {
      ...state,
    };
  }
  if (action.type == "setHotelCheckInDate") {
    state.checkInDate = action.payload;
    return {
      ...state,
    };
  }
  if (action.type == "addHotelPassenger") {
    state.passengers = action.payload;
    return {
      ...state,
    };
  }
  if (action.type == "setHotelCheckOutDate") {
    state.checkOutDate = action.payload;
    return {
      ...state,
    };
  }
  return state;
};

export default hotelSearchReducer;
