import React from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { removeFAQ } from "views/Services/agencyServices";

function FAQ({ faq, index, toggleFAQ, updateFAQs, canDelete }) {
  return (
    <div
      className={"faq " + (faq.open ? "open" : "")}
      key={index}
      onClick={() => toggleFAQ(index)}
    >
      <div className="faq-question">
        <div>{faq.question}</div>
        <div>
          {canDelete ? (
            <DeleteOutlinedIcon
              onClick={async () => {
                alert("deleted");
                let data = await removeFAQ({
                  question: faq.question,
                  answer: faq.answer,
                });
                updateFAQs(data);
              }}
            />
          ) : null}
          <ArrowDropDownIcon />
        </div>
      </div>
      <div className="faq-answer">{faq.answer}</div>
    </div>
  );
}
export default FAQ;
