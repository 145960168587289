import { Container, Input, InputLabel } from "@material-ui/core";
// ICONS
import BorderColorIcon from "@material-ui/icons/BorderColor";
import ContactMailIcon from "@material-ui/icons/ContactMail";
import EmailIcon from "@material-ui/icons/Email";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import LocationCityIcon from "@material-ui/icons/LocationCity";
import PhoneIcon from "@material-ui/icons/Phone";
import PinterestIcon from "@material-ui/icons/Pinterest";
import PublicIcon from "@material-ui/icons/Public";
import RoomIcon from "@material-ui/icons/Room";
import TwitterIcon from "@material-ui/icons/Twitter";
import YouTubeIcon from "@material-ui/icons/YouTube";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  businessPrivateInfoService,
  businessPublicInfoService,
  businessSocialService,
  businessTypeService,
  loginService,
} from "./SignUpService";

const mapDispatchToState = (dispatch) => {
  return {
    setBusinessTypeReducer: (businessTypeReducer) => {
      dispatch({
        type: "setBusinessTypeReducer",
        businessTypeReducer: businessTypeReducer,
      });
    },

    setBusinessNameReducer: (businessNameReducer) => {
      dispatch({
        type: "setBusinessNameReducer",
        businessNameReducer: businessNameReducer,
      });
    },

    setBusinessPrivateEmailReducer: (businessPrivateEmailReducer) => {
      dispatch({
        type: "setBusinessPrivateEmailReducer",
        businessPrivateEmailReducer: businessPrivateEmailReducer,
      });
    },

    setAddressLine1Reducer: (addressLine1Reducer) => {
      dispatch({
        type: "setAddressLine1Reducer",
        addressLine1Reducer: addressLine1Reducer,
      });
    },

    setAddressLine2Reducer: (addressLine2Reducer) => {
      dispatch({
        type: "setAddressLine2Reducer",
        addressLine2Reducer: addressLine2Reducer,
      });
    },

    setCityReducer: (cityReducer) => {
      dispatch({ type: "setCityReducer", cityReducer: cityReducer });
    },

    setStateReducer: (stateReducer) => {
      dispatch({ type: "setStateReducer", stateReducer: stateReducer });
    },

    setPostReducer: (postReducer) => {
      dispatch({ type: "setPostReducer", postReducer: postReducer });
    },

    setCountryReducer: (countryReducer) => {
      dispatch({ type: "setCountryReducer", countryReducer: countryReducer });
    },
    setPrivateNumberReducer: (privateNumberReducer) => {
      dispatch({
        type: "setPrivateNumberReducer",
        privateNumberReducer: privateNumberReducer,
      });
    }, // end

    setPublicEmailReducer: (publicEmailReducer) => {
      dispatch({
        type: "setPublicEmailReducer",
        publicEmailReducer: publicEmailReducer,
      });
    },
    setPublicNumberReducer: (publicNumberReducer) => {
      dispatch({
        type: "setPublicNumberReducer",
        publicNumberReducer: publicNumberReducer,
      });
    }, // end

    setFbReducer: (fbReducer) => {
      dispatch({ type: "setFbReducer", fbReducer: fbReducer });
    },

    setYtReducer: (ytReducer) => {
      dispatch({ type: "setYtReducer", ytReducer: ytReducer });
    },

    setIgReducer: (igReducer) => {
      dispatch({ type: "setIgReducer", igReducer: igReducer });
    },

    setPtReducer: (ptReducer) => {
      dispatch({ type: "setPtReducer", ptReducer: ptReducer });
    },

    setTwReducer: (twReducer) => {
      dispatch({ type: "setTwReducer", twReducer: twReducer });
    },

    setAgencyTokenReducer: (agencyTokenReducer) => {
      dispatch({
        type: "setAgencyTokenReducer",
        agencyTokenReducer: agencyTokenReducer,
      });
    },
  };
};

const BusinessProfile = ({
  setBusinessTypeReducer,
  setBusinessNameReducer,
  setBusinessPrivateEmailReducer,
  setAddressLine1Reducer,
  setAddressLine2Reducer,
  setCityReducer,
  setStateReducer,
  setPostReducer,
  setCountryReducer,
  setPrivateNumberReducer,

  setPublicEmailReducer,
  setPublicNumberReducer,

  setFbReducer,
  setYtReducer,
  setIgReducer,
  setPtReducer,
  setTwReducer,
  signUpInfo,
  setAgencyTokenReducer,
}) => {
  let history = useNavigate();

  const [token, setToken] = useState("");

  const [businessType, setBusinessType] = useState("Business");
  const [businessName, setBusinessName] = useState("");
  const [privateEmail, setPrivateEmail] = useState(signUpInfo.registerEmail);
  const [addressLine1, setAddressLine1] = useState("");
  const [addressLine2, setAddressLine2] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [postCode, setPostCode] = useState("");
  const [country, setCountry] = useState("");
  const [telephone, setTelephone] = useState("");

  const [publicEmail, setPublicEmail] = useState("");
  const [publicNumber, setPublicNumber] = useState("");

  const [igUrl, setIgUrl] = useState("");
  const [fbUrl, setFbUrl] = useState("");
  const [ytUrl, setYtUrl] = useState("");
  const [ptUrl, setPtUrl] = useState("");
  const [twUrl, setTwUrl] = useState("");
  async function fetchLogin() {
    // You can await here
    try {
      let reqBody = {
        email: signUpInfo.registerEmail,
        password: signUpInfo.passwordReducer,
      };
      let response = await loginService(reqBody);
      setToken(response.data.token);
      setAgencyTokenReducer(response.data.token);
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    fetchLogin();
  }, []);

  const handleSubmit = async (e) => {
    try {
      const newBusinessProfile = {
        businessType,
        businessName,
        privateEmail,
        addressLine1,
        addressLine2,
        city,
        state,
        postCode,
        country,
        telephone,

        publicEmail,
        publicNumber,

        igUrl,
        fbUrl,
        ytUrl,
        ptUrl,
        twUrl,
      };
      e.preventDefault();

      setBusinessTypeReducer(businessType);
      setBusinessNameReducer(businessName);
      setBusinessPrivateEmailReducer(privateEmail);
      setAddressLine1Reducer(addressLine1);
      setAddressLine2Reducer(addressLine2);
      setCityReducer(city);
      setStateReducer(state);
      setPostReducer(postCode);
      setCountryReducer(country);
      setPrivateNumberReducer(telephone);

      setPublicEmailReducer(publicEmail);
      setPublicNumberReducer(publicNumber);

      setFbReducer(fbUrl);
      setYtReducer(ytUrl);
      setIgReducer(igUrl);
      setPtReducer(ptUrl);
      setTwReducer(twUrl);
      if (businessName == "") {
        return alert("please add your business name");
      } else if (privateEmail == "") {
        return alert("please add your private email");
      } else if (addressLine1 == "") {
        return alert("please add your address line 1");
      } else if (city == "") {
        return alert("please add your city");
      } else if (state == "") {
        return alert("please add your state");
      } else if (postCode == "") {
        return alert("please add your postal code");
      } else if (country == "") {
        return alert("please add your country");
      } else if (telephone == "") {
        return alert("please add your private telephone");
      } else if (publicEmail == "") {
        return alert("please add your public email");
      } else if (publicNumber == "") {
        return alert("please add your public phone number");
      }
      let reqBodyPublic = { email: publicEmail, phone: publicNumber };

      let reqBodyPrivate = {
        address_line1: addressLine1,
        address_line2: addressLine2,
        city: city,
        state: state,
        postal_code: postCode,
        country: country,
        phone_number: telephone,
        agency_name: businessName,
        private_email: privateEmail,
        business_type: businessType,
        // add the other attributes: businessType privateEmail
      };

      let reqBodySocial = {
        facebook: fbUrl,
        twitter: twUrl,
        instagram: igUrl,
        youtube: ytUrl,
        pinterest: ptUrl,
      };
      await businessPrivateInfoService(reqBodyPrivate, token); // all the requests gets error 400 with {"error":"Please provide all information!"}

      await businessPublicInfoService(reqBodyPublic, token); // doing well

      await businessSocialService(reqBodySocial, token); // doing well
      history({
        pathname: "/chooseProducts",
      }); //should be called here. so if there is error it must stay at the same page
    } catch (e) {
      if (e.response) alert(e.response.data.error);
      else alert("SOMETHING WENT WRONG. PLEASE TRY AGAIN LATER.");
      console.log(e);
    }
  };

  return (
    <Container className="d-flex justify-content-center">
      {/* <div className="container d-flex justify-content-center"> */}
      <div className="flex-column">
        <div className="loading"></div>
        <form>
          <h1 className="h3" style={{ textAlign: "center", marginTop: 40 }}>
            Private
          </h1>
          <h1 className="h6" style={{ textAlign: "center" }}>
            Contact details will not be displayed publicly on your website and
            will only used for contact by Trollii Ltd.
          </h1>

          <div class="input-group mb-3">
            <span class="input-group-text" id="basic-addon1">
              <BorderColorIcon
                style={{
                  width: 25,
                  height: 25,
                  margin: 5,
                }}
              />
            </span>
            <Input
              type="text"
              className="form-control"
              aria-describedby="basic-addon1"
              value={businessName}
              onChange={async (e) => {
                setBusinessName(e.target.value);
              }}
              required
              placeholder="Registered Business Name"
            />
          </div>

          <div class="input-group mb-3">
            <span class="input-group-text" id="basic-addon1">
              <EmailIcon
                style={{
                  width: 25,
                  height: 25,
                  margin: 5,
                }}
              />
            </span>
            <Input
              type="email"
              className="form-control"
              aria-describedby="basic-addon1"
              value={signUpInfo.registerEmail}
              onChange={(e) => setPrivateEmail(e.target.value)}
              required
              placeholder="Email"
            />
          </div>
          <div class="input-group mb-3">
            <span class="input-group-text" id="basic-addon1">
              <ContactMailIcon
                style={{
                  width: 25,
                  height: 25,
                  margin: 5,
                }}
              />
            </span>
            <Input
              type="text"
              className="form-control"
              aria-describedby="basic-addon1"
              value={addressLine1}
              onChange={async (e) => {
                setAddressLine1(e.target.value);
              }}
              required
              placeholder="Address Line 1"
            />
          </div>
          <div class="input-group mb-3">
            <span class="input-group-text" id="basic-addon1">
              <ContactMailIcon
                style={{
                  width: 25,
                  height: 25,
                  margin: 5,
                }}
              />
            </span>
            <Input
              type="text"
              className="form-control"
              aria-describedby="basic-addon1"
              value={addressLine2}
              onChange={(e) => setAddressLine2(e.target.value)}
              required
              placeholder="Address Line 2"
            />
          </div>
          <div class="input-group mb-3">
            <span class="input-group-text" id="basic-addon1">
              <LocationCityIcon
                style={{
                  width: 25,
                  height: 25,
                  margin: 5,
                }}
              />
            </span>
            <Input
              type="text"
              className="form-control"
              aria-describedby="basic-addon1"
              value={city}
              onChange={(e) => setCity(e.target.value)}
              required
              placeholder="City / District"
            />
          </div>
          <div class="input-group mb-3">
            <span class="input-group-text" id="basic-addon1">
              <LocationCityIcon
                style={{
                  width: 25,
                  height: 25,
                  margin: 5,
                }}
              />
            </span>
            <Input
              type="text"
              className="form-control"
              aria-describedby="basic-addon1"
              value={state}
              onChange={(e) => setState(e.target.value)}
              required
              placeholder="State / Province"
            />
          </div>
          <div class="input-group mb-3">
            <span class="input-group-text" id="basic-addon1">
              <RoomIcon
                style={{
                  width: 25,
                  height: 25,
                  margin: 5,
                }}
              />
            </span>
            <Input
              type="text"
              className="form-control"
              aria-describedby="basic-addon1"
              value={postCode}
              onChange={(e) => setPostCode(e.target.value)}
              required
              placeholder="Postal Code"
            />
          </div>
          <div class="input-group mb-3">
            <span class="input-group-text" id="basic-addon1">
              <PublicIcon
                style={{
                  width: 25,
                  height: 25,
                  margin: 5,
                }}
              />
            </span>
            <Input
              type="text"
              className="form-control"
              aria-describedby="basic-addon1"
              value={country}
              onChange={(e) => setCountry(e.target.value)}
              required
              placeholder="Country"
            />
          </div>
          <div class="input-group mb-3">
            <span class="input-group-text" id="basic-addon1">
              <PhoneIcon
                style={{
                  width: 25,
                  height: 25,
                  margin: 5,
                }}
              />
            </span>
            <Input
              type="text"
              className="form-control"
              aria-describedby="basic-addon1"
              value={telephone}
              onChange={async (e) => {
                setTelephone(e.target.value);
              }}
              required
              placeholder="Telephone"
            />
          </div>

          <h1 className="h3" style={{ textAlign: "center", marginTop: 40 }}>
            Public
          </h1>
          <h1 className="h6" style={{ textAlign: "center" }}>
            This information will be displayed on your website for your
            customers to contact you on{" "}
          </h1>

          <div class="input-group mb-3">
            <span class="input-group-text" id="basic-addon1">
              <EmailIcon
                style={{
                  width: 25,
                  height: 25,
                  margin: 5,
                }}
              />
            </span>
            <Input
              type="email"
              className="form-control"
              aria-describedby="basic-addon1"
              value={publicEmail}
              onChange={(e) => setPublicEmail(e.target.value)}
              required
              placeholder="Email"
            />
          </div>
          <div class="input-group mb-3">
            <span class="input-group-text" id="basic-addon1">
              <PhoneIcon
                style={{
                  width: 25,
                  height: 25,
                  margin: 5,
                }}
              />
            </span>
            <Input
              type="text"
              className="form-control"
              aria-describedby="basic-addon1"
              value={publicNumber}
              onChange={(e) => setPublicNumber(e.target.value)}
              required
              placeholder="Phone Number"
            />
          </div>

          <div>
            <button
              className="btn btn-primary mb-3"
              onClick={(e) => handleSubmit(e)}
              style={{
                width: "100%",
                height: 60,
              }}
            >
              Proceed
            </button>
          </div>
        </form>
      </div>
      {/* </div> */}
    </Container>
  );
};

const mapStateToProps = (state) => {
  return { signUpInfo: state.SignUpReducer }; //state.initialState is what we grab from redux
  //and signupInfo is what we apply this data to
};
export default connect(mapStateToProps, mapDispatchToState)(BusinessProfile);
