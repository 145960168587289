import {
  Grid,
  Card,
  Typography,
  useTheme,
  useMediaQuery,
  Button,
} from "@mui/material";
import validator from "email-validator";

import { Avatar } from "@mui/material";
import React, { Component, useEffect, useMemo } from "react";
import "@fontsource/rubik/600.css";
import "react-intl-tel-input/dist/main.css";
import "@fontsource/rubik/700.css";
import "@fontsource/rubik/400.css";
import "react-phone-input-2/lib/style.css";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import "react-phone-number-input/style.css";
import "@fontsource/rubik/500.css";
import store from "store";
import Footer from "views/Hotels/Components/Footer";
import Navbar from "views/Hotels/Components/NavBar";
import BookingCard from "../SelectRooms/BookingCard";
import PassengerDetails from "./PassengerDetails";
import { useNavigate } from "react-router-dom";
import Popoverhotels from "views/Hotels/Components/Popover";
import LoginPopover from "views/Hotels/Components/LoginPopover";
import { signUpService } from "views/Services/clientServices";
import VerficationPopover from "views/Hotels/Components/VerficationPopover";
export default function HotelPayment() {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [passengersInfo, setPassengers] = React.useState([]);
  const [email, setEmail] = React.useState([]);

  const phoneUtil = require("google-libphonenumber").PhoneNumberUtil.getInstance();
  const [open, setDialogOpen] = React.useState(false);
  const [msg, setMsg] = React.useState("");
  const [error, setError] = React.useState(false);
  const [openLogin, setDialogOpenLogin] = React.useState(false);
  const [openVerfication, setDialogOpenVerfication] = React.useState(false);
  const [verficationCode, setVerficationCode] = React.useState(false);

  const [animation, setAnimation] = React.useState(false);
  const [password, setpassword] = React.useState(false);

  const [verificationModal, setVerficationModal] = React.useState(false);

  let temp = [];
  function animationVisible(mode, verificationModal, loginModal) {
    if (loginModal) {
      setDialogOpen(false);
      setAnimation(mode);
    }
    if (verificationModal) {
      setAnimation(false);
      setVerficationModal(false);
    } else {
      setAnimation(mode);
    }
  }
  useEffect(() => {
    for (let i = 0; i < store.getState().hotelSearch.adults; i++) {
      temp.push({
        type: "Adult",
        title: i == 0 ? `Main passenger -` : `Passenger -`,
        index: i,
        Gender: "Male",
      });
    }
    setPassengers(temp);
  }, []);

  return (
    <div
      style={{
        backgroundColor: "white",
        minHeight: "100vh",
      }}
    >
      <Navbar />
      <Grid container>
        <Grid item md={1} sm={1} xs={1} />
        <Grid item md={7} sm={10} xs={10}>
          <Card
            style={{
              borderRadius: 20,
              marginTop: 10,
              border: "1px solid #C4C4C4",
            }}
          >
            <Grid container style={{ padding: "4%" }}>
              <Grid item md={1} sm={1} xs={1}>
                <Avatar
                  style={{
                    backgroundColor: "white",
                    border: "1px solid #5F41B2",
                    borderRadius: 20,
                  }}
                >
                  <Typography
                    sx={{
                      textAlign: "left",
                      fontFamily: "Rubik",
                      fontStyle: "normal",
                      fontWeight: 600,
                      color: "#5F41B3",
                    }}
                  >
                    1
                  </Typography>
                </Avatar>
              </Grid>
              <Grid item md={0.2} sm={0.2} xs={0.2} />
              <Grid item md={10} sm={10} xs={10}>
                <Typography
                  sx={{
                    textAlign: "left",
                    fontFamily: "Rubik",
                    fontStyle: "normal",
                    fontWeight: 700,
                    color: "#362566",
                  }}
                >
                  Passenger Details
                </Typography>
                <Typography
                  sx={{
                    textAlign: "left",
                    fontFamily: "Rubik",
                    fontStyle: "normal",
                    fontWeight: 400,
                    color: "#362566",
                  }}
                >
                  Please enter the passengers information same as written on the
                  passenger’s passports or national IDs .
                </Typography>
              </Grid>
              <Grid item md={12} sm={12} xs={12}>
                <PassengerDetails
                  passengersInfo={passengersInfo}
                  setPassengers={setPassengers}
                  passenger={passengersInfo[0]}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item md={0.5} sm={0.5} xs={0.5} />
              <Grid item md={2} sx={{ pb: 2 }}>
                <Button
                  sx={{ width: "100%", mt: 1 }}
                  onClick={async () => {
                    store.dispatch({
                      type: "addHotelPassenger",
                      payload: passengersInfo,
                    });

                    if (
                      passengersInfo[0].firstName?.length > 3 &&
                      passengersInfo[0].lastName?.length > 3 &&
                      validator.validate(passengersInfo[0].email) &&
                      passengersInfo[0].validPhone
                    ) {
                      if (store.getState().client.client.loggedIn == false) {
                        setEmail(passengersInfo[0].email);
                        console.log(passengersInfo[0].email);
                        try {
                          let reqBody = {
                            email: passengersInfo[0].email
                              ? passengersInfo[0].email
                              : "",
                            url: store.getState().AgencyReducer.agency.url,
                          };

                          let code = await signUpService(reqBody);
                          setVerficationCode(code.verificationCode);

                          setVerficationModal(true);

                          console.log(code.verificationCode);
                        } catch (e) {
                          console.log(e);
                          setDialogOpenLogin(true);
                        }
                      } else navigate("/paymentDetails");
                    } else if (
                      !passengersInfo[0].firstName ||
                      passengersInfo[0].firstName.length < 3
                    ) {
                      setMsg(
                        "please fill your information with a correct first name"
                      );
                      setError(true);
                      setDialogOpen(true);
                    } else if (
                      !passengersInfo[0].lastName ||
                      passengersInfo[0].lastName.length < 3
                    ) {
                      setMsg(
                        "please fill your information with a correct last name  "
                      );
                      setError(true);
                      setDialogOpen(true);
                    } else if (!passengersInfo[0].validPhone) {
                      setMsg("please fill your information with a valid phone");
                      setError(true);
                      setDialogOpen(true);
                    } else if (!validator.validate(passengersInfo[0].email)) {
                      setMsg(
                        "please fill your information with a correct email"
                      );
                      setError(true);
                      setDialogOpen(true);
                    }
                  }}
                  size="medium"
                  variant="filled"
                  style={{
                    backgroundColor: "#5F41B3",
                    borderRadius: 10,
                  }}
                >
                  <Typography
                    style={{
                      fontFamily: "Inter",
                      fontStyle: "normal",
                      fontWeight: 500,
                      fontSize: 16,
                      color: "white",
                      textTransform: "none",
                    }}
                  >
                    Next
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          </Card>
          <Popoverhotels
            isOpen={open}
            setDialogOpen={setDialogOpen}
            msg={msg}
            error={error}
          />

          <LoginPopover
            open={openLogin}
            onCloseModal={setDialogOpenLogin}
            setPassword={setpassword}
            password={password}
            email={email}
            setAnimation={setAnimation}
            animationVisibleStatus={animation}
            animationVisible={animationVisible}
          />
          <VerficationPopover
            verificationCode={verficationCode}
            setVerficationCode={setVerficationCode}
            setAnimation={setAnimation}
            open={verificationModal}
            email={email}
            animationVisibleStatus={animation}
            onRequestClose={setVerficationModal}
            animationVisible={animationVisible}
          />
          <Card
            style={{
              borderRadius: 20,
              marginTop: 10,
              border: "1px solid #C4C4C4",
            }}
          >
            <Grid container style={{ padding: "2%" }}>
              <Grid item md={1} sm={1} xs={1}>
                <Avatar
                  style={{
                    backgroundColor: "white",
                    border: "1px solid #959595",
                    borderRadius: 20,
                  }}
                >
                  <Typography
                    sx={{
                      textAlign: "left",
                      fontFamily: "Rubik",
                      fontStyle: "normal",
                      fontWeight: 600,
                      color: "#959595",
                    }}
                  >
                    2
                  </Typography>
                </Avatar>
              </Grid>
              <Grid item md={0.2} sm={0.2} xs={0.2} />
              <Grid item md={10} sm={10} xs={10}>
                <Typography
                  sx={{
                    mt: 0.7,
                    textAlign: "left",
                    fontFamily: "Rubik",
                    fontStyle: "normal",
                    fontWeight: 700,
                    fontSize: "1.5vw",
                    color: "#959595",
                  }}
                  variant="h5"
                >
                  Payment Details
                </Typography>
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid item md={0.4} sm={0.4} xs={0.4} />
        <Grid item md={3}>
          {isMobile ? null : <BookingCard />}
        </Grid>
      </Grid>

      <Footer />
    </div>
  );
}
