import React from "react";
import GridItem from "components/Grid/GridItem.js";
import store from "store"
function HomeTitle() {
  return (
    <GridItem xs={12}>
      <h1
        style={{
          color:               store.getState().AgencyReducer.agency
          .branding.sixth ?     store.getState().AgencyReducer.agency
        .branding.sixth : "white"      ,
          margin: "1.2em",
          letterSpacing: "0.5px",
          fontWeight: "bold",
        }}
      >
        Find Your Flights Here!
      </h1>
    </GridItem>
  );
}

export default HomeTitle;
