import Icon from "@material-ui/core/Icon";
import InputAdornment from "@material-ui/core/InputAdornment";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import BookmarkIcon from "@material-ui/icons/Bookmark";
// @material-ui/icons
import Email from "@material-ui/icons/Email";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import PersonIcon from "@material-ui/icons/Person";
import SearchIcon from "@material-ui/icons/Search";
import image from "assets/img/bg7.jpg";
import styles from "assets/jss/material-kit-react/views/loginPage.js";
import axios from "axios";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// core components
import Header from "components/Header/Header.js";
import Success from "components/Typography/Success.js";
import Warning from "components/Typography/Warning.js";
import agency from "constants/agency";
import Cookie from "js-cookie";
import React from "react";
import hosts from "constants/hosts";
import store from "../../store";
import { useParams } from "react-router-dom";

const useStyles = makeStyles(styles);

export default function PasswordChangeMobile(props) {
  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [newPassword, setNewPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [text, setText] = React.useState(false);
  const [passwordMatch, setPasswordMatch] = React.useState(false);
  const [fail, setFail] = React.useState(false);
  const [success, Setsuccess] = React.useState(false);

  window.scrollTo(0, 0);

  const handleSubmit = () => {
    var url = new URL(location.href);
    var resetId = url.searchParams
      .get("resetId")
      .slice(0, url.searchParams.get("resetId").indexOf("/"));
    let body = {
      email,
      password,
      newPassword,
      agency: store.getState().AgencyReducer.agency.url,
      id: resetId,
    };

    if (newPassword.length >= 8 && newPassword === confirmPassword) {
      let url = `${hosts}/users/changepasswordMobile`;

      axios
        .post(url, body)
        .then(async (response) => {
          Setsuccess(true);
        })
        .catch((e) => {
          alert("error cannot verify the owner");
          console.log("error ===>", e);

          Setsuccess(false);
          setFail(true);
        });
    } else {
      alert("passwords didn't match");
    }
  };

  const handleEmail = (value) => {
    setEmail(value);
  };

  const handleLogin = (event) => {
    event.preventDefault();
    props.history("/login");
  };

  const handleOldPassword = (value) => {
    setPassword(value);
  };

  const handleNewPassword = (value) => {
    setNewPassword(value);
  };

  const handleConfirmPassword = (value) => {
    setConfirmPassword(value);
  };

  setTimeout(function () {
    setCardAnimation("");
  }, 700);
  const classes = useStyles();

  return (
    <div>
      <Header
        absolute
        brand="Trollii"
        color="transparent"
        rightLinks={
          <List
            className={classes.list}
            style={{ flexDirection: "row", display: "flex" }}
            dense
          >
            <ListItem className={classes.listItem}>
              <Button
                color="transparent"
                onClick={() => {
                  if (Cookie.get("token")) {
                    props.history("/mybookings");
                  } else props.history("/login");
                }}
                className={classes.navLink + " " + classes.socialIconsButton}
              >
                <BookmarkIcon style={{ fontSize: "large" }} />
                View my bookings
              </Button>
            </ListItem>
            <ListItem className={classes.listItem}>
              <Button
                color="transparent"
                className={classes.navLink + " " + classes.socialIconsButton}
                onClick={() => props.history("/")}
              >
                <SearchIcon style={{ fontSize: "large" }} />
                Search Flights
              </Button>
            </ListItem>
            {Cookie.get("token") ? (
              <ListItem className={classes.listItem}>
                <Button
                  color="transparent"
                  className={classes.navLink + " " + classes.socialIconsButton}
                  onClick={() => {
                    Cookie.remove("token");
                    window.location.reload();
                  }}
                >
                  <ExitToAppIcon style={{ fontSize: "large" }} />
                  SIGN OUT
                </Button>
              </ListItem>
            ) : (
              <ListItem className={classes.listItem} style={{ margin: "5px" }}>
                <Button
                  color="transparent"
                  className={classes.navLink + " " + classes.socialIconsButton}
                  onClick={() => props.history("/login")}
                >
                  <PersonIcon style={{ fontSize: "large" }} />
                  SIGN IN
                </Button>
              </ListItem>
            )}
          </List>
        }
      />
      <div
        className={classes.pageHeader}
        style={{
          backgroundImage: "url(" + image + ")",
          backgroundSize: "cover",
          backgroundPosition: "top center",
        }}
      >
        <div className={classes.container}>
          <GridContainer justifyContent="center">
            <GridItem>
              <Typography style={{ textAlign: "center" }}>
                <h1>Welcome</h1>
              </Typography>
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <Card className={classes[cardAnimaton]}>
                <form className={classes.form}>
                  <CardHeader color="primary" className={classes.cardHeader}>
                    <h4>Change Your Password</h4>
                  </CardHeader>
                  <CardBody>
                    <CustomInput
                      required
                      labelText="Email..."
                      id="email"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        type: "email",
                        onChange: (event) => handleEmail(event.target.value),
                        endAdornment: (
                          <InputAdornment position="end">
                            <Email className={classes.inputIconsColor} />
                          </InputAdornment>
                        ),
                      }}
                    />

                    <CustomInput
                      required
                      labelText="New Password"
                      id="pass2"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        type: "password",
                        onChange: (event) =>
                          handleNewPassword(event.target.value),
                        endAdornment: (
                          <InputAdornment position="end">
                            <Icon className={classes.inputIconsColor}>
                              lock_outline
                            </Icon>
                          </InputAdornment>
                        ),
                        autoComplete: "off",
                      }}
                    />
                    <CustomInput
                      required
                      labelText="Confirm New Password"
                      id="pass3"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        type: "password",
                        onChange: (event) =>
                          handleConfirmPassword(event.target.value),
                        endAdornment: (
                          <InputAdornment position="end">
                            <Icon className={classes.inputIconsColor}>
                              lock_outline
                            </Icon>
                          </InputAdornment>
                        ),
                        autoComplete: "off",
                      }}
                    />
                  </CardBody>
                  <GridContainer justifyContent="center">
                    {passwordMatch && (
                      <GridItem style={{ textAlign: "center" }}>
                        <Warning>
                          <b>Fail:</b> New Passwords must match
                        </Warning>
                      </GridItem>
                    )}

                    {text && (
                      <GridItem style={{ textAlign: "center" }}>
                        <Warning>
                          <b>Fail:</b> enter a password that has a minimum
                          length of 6 characters.
                        </Warning>
                      </GridItem>
                    )}

                    {fail && (
                      <GridItem style={{ textAlign: "center" }}>
                        <Warning>
                          <b>Fail:</b> Enter correct credentials
                        </Warning>
                      </GridItem>
                    )}

                    {success && (
                      <GridItem style={{ textAlign: "center" }}>
                        <Success>
                          <b>Success:</b> Password Changed Successfully.
                        </Success>
                      </GridItem>
                    )}
                    <GridItem style={{ textAlign: "center" }}>
                      <Button
                        simple
                        color="primary"
                        size="lg"
                        onClick={handleSubmit}
                      >
                        Change password
                      </Button>
                    </GridItem>
                    <GridItem style={{ textAlign: "center", fontSize: 9 }}>
                      <Button
                        simple
                        color="primary"
                        size="lg"
                        onClick={handleLogin}
                      >
                        sign in?
                      </Button>
                    </GridItem>
                  </GridContainer>
                </form>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </div>
  );
}
