import React, { useEffect } from "react";
import {
  Grid,
  Button,
  useMediaQuery,
  useTheme,
  Typography,
  TextField,
  InputAdornment,
  Tabs,
  Tab,
  Divider,
  Box,
  CardMedia,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import "./style.css";
import { purple } from "@mui/material/colors";
import "@fontsource/rubik/600.css";

import SearchPanel from "../HotelSearch/SearchPanel";
import TuneIcon from "@mui/icons-material/Tune";
import MapviewIcon from "views/Hotels/assets/icons/MapviewIcon";
import Navbar from "../../Components/NavBar";
import Footer from "../../Components/Footer";
import HotelCard from "./HotelCard";
import Filters from "./Filters";
import { searchSubmit } from "../LandingPage/SearchEntries";
import PlaneAnimation from "components/CardAnimations/plane";
import store from "store";
import PlaceSelector from "../LandingPage/PlaceSelector";
import Popoverhotels from "views/Hotels/Components/Popover";
import PopularDestinations from "./PopularDestinations";

const HotelResults = () => {
  const navigate = useNavigate();
  const theme = useTheme();

  const [value, setValue] = React.useState(0);
  const [loading, setLoading] = React.useState("PopluarDestinations");
  const [open, setDialogOpen] = React.useState(false);
  const [msg, setMsg] = React.useState("");
  const [error, setError] = React.useState(false);
  const handlevalue = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <div style={{ flex: 1, backgroundColor: "white" }}>
        <Navbar />
       
        <Grid container sx={{justifyContent:"space-between", flexWrap:"wrap", paddingLeft:"120px", paddingRight:"120px", paddingTop:"120px"}}>

          <Grid item sm={8} xs={8} md={5.5} sx={{ pb: 2 }}>
            {" "}
            <Typography
              sx={{
                textAlign: "left",
                fontFamily: "Rubik",
                fontSize: "50px",
                fontStyle: "normal",
                lineHeight: "normal",
                fontWeight: 600,
                color: "#362566",
                marginBottom:"22px",
              }}
            >
              Make a new adventure with Trollii
            </Typography>
            <hr style={{width:"200px", height:"1px", color:"#FBFBFB", marginBottom:"35px"}}></hr>
            <Typography
              sx={{
                pt: 2,
                textAlign: "left",
                fontFamily: "Rubik",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: 400,
                color: "#5D5C66",
              }}
            >
              Trollii helps you plan your trip easily. Now by just a few clicks,
              you can travel the world, book an hotel and enjoy your travelling
              experience. It’s time to fasten your seat belt!{" "}
            </Typography>
          </Grid>
          
          <Grid item md={4} sx={{ pb: 2 }}>
            <CardMedia
              component="img"
              height="413"
              width="488"
              image={require("assets/img/Photos/home.png")}
              sx={{
                alignItems: "left",
                objectFit: "contain",
              }}
            />
          </Grid>
          <SearchPanel
            setLoading={setLoading}
            setDialogOpen={setDialogOpen}
            setError={setError}
            msg={msg}
            setMsg={setMsg}
          />
        </Grid>
        <Popoverhotels
          isOpen={open}
          setDialogOpen={setDialogOpen}
          msg={msg}
          error={error}
        />
        {loading == "PopluarDestinations" ? (
          <PopularDestinations
            setLoading={setLoading}
            setDialogOpen={setDialogOpen}
            setError={setError}
            msg={msg}
            setMsg={setMsg}
          />
        ) : loading == "home" ? (
          <PlaneAnimation />
        ) : loading == "empty" ? null : (
          <Grid container>
            <Grid item md={10} sm={10} xs={10} sx={{ pt: 3 }}>
              <Grid container>
                <Grid
                  item
                  md={3}
                  sm={3}
                  xs={0}
                  sx={{
                    display: {
                      xs: "none",
                      md: "block",
                      sm: "block",
                    },
                  }}
                >
                  <Filters setLoading={setLoading} loading={loading} />
                </Grid>
                <Grid item md={9} sm={9} xs={12}>
                  <Grid container rowSpacing={3}>
                    <Grid
                      item
                      md={0}
                      sm={0}
                      xs={12}
                      sx={{ display: { md: "none", sm: "none", xs: "block" } }}
                    >
                      <Grid container columnSpacing={2}>
                        <Grid item xs={6}>
                          <Button
                            size="large"
                            variant="outlined"
                            startIcon={<TuneIcon sx={{ color: "#362566" }} />}
                            sx={{
                              borderRadius: 2,
                              borderColor: "#C4C4C4",
                              fontFamily: "Rubik",
                              fontStyle: "normal",
                              fontWeight: 400,
                              fontSize: 16,
                              color: "#362566",
                              lineHeight: "19px",
                              textTransform: "none",
                              width: "100%",
                              height: "100%",
                            }}
                            onClick={() => {
                              navigate("/Filters");
                            }}
                          >
                            Filters
                          </Button>
                        </Grid>
                        <Grid item xs={6}>
                          <Button
                            variant="outlined"
                            size="large"
                            startIcon={<MapviewIcon />}
                            sx={{
                              borderRadius: 2,
                              borderColor: "#C4C4C4",
                              fontFamily: "Rubik",
                              fontStyle: "normal",
                              fontWeight: 400,
                              fontSize: 16,
                              color: "#362566",
                              lineHeight: "19px",
                              textTransform: "none",
                              width: "100%",
                              height: "100%",
                            }}
                          >
                            Map View
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      md={12}
                      sm={12}
                      xs={0}
                      justifyContent="space-evenly"
                      sx={{
                        display: {
                          xs: "none",
                          md: "block",
                          sm: "block",
                        },
                      }}
                    >
                      <Tabs
                        value={value}
                        centered
                        onChange={handlevalue}
                        style={{
                          color: "#FFC725",
                          boxSizing: "border-box",
                          border: "1px solid #C4C4C4",
                          borderRadius: 20,
                        }}
                      >
                        <Tab
                          style={{
                            fontFamily: "Rubik",
                            fontStyle: "normal",
                            textTransform: "none",
                            color: "#362566",
                            fontSize: 16,
                            fontWeight: 400,
                          }}
                          label="Recommended"
                        />
                        <Divider
                          orientation="vertical"
                          style={{ height: 30, alignSelf: "center" }}
                        />
                        <Tab
                          style={{
                            fontFamily: "Rubik",
                            fontStyle: "normal",
                            textTransform: "none",
                            color: "#362566",
                            fontSize: 16,
                            fontWeight: 400,
                          }}
                          label="Top rated"
                        />
                        <Divider
                          orientation="vertical"
                          style={{ height: 30, alignSelf: "center" }}
                        />
                        <Tab
                          style={{
                            fontFamily: "Rubik",
                            fontStyle: "normal",
                            textTransform: "none",
                            color: "#362566",
                            fontSize: 16,
                            fontWeight: 400,
                          }}
                          label="Most Popular"
                        />
                        <Divider
                          orientation="vertical"
                          style={{ height: 30, alignSelf: "center" }}
                        />
                        <Tab
                          style={{
                            fontFamily: "Rubik",
                            fontStyle: "normal",
                            textTransform: "none",
                            color: "#362566",
                            fontSize: 16,
                            fontWeight: 400,
                          }}
                          label="Cheapest"
                        />
                      </Tabs>
                    </Grid>
                    <br />
                    <br />
                    <br />
                    <Grid item md={12} sm={12} xs={12}>
                      {store
                        .getState()
                        .hotelResults.hotels.map((hotel, index) => {
                          return (
                            <Grid container sx={{ pb: 3 }} key={index}>
                              <HotelCard hotel={hotel} />
                            </Grid>
                          );
                        })}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

          </Grid>
        )}
        <br />
        <br />
        <br />

        <Footer />
      </div>
    </>
  );
};
export default HotelResults;
