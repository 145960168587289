import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormLabel from "@material-ui/core/FormLabel";
import Slider from "@material-ui/core/Slider";
import Typography from "@material-ui/core/Typography";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import APP_COLORS from "constants/themes";
import store from "store";
import React, { Component } from "react";
import "./styles.css";
import { Button } from "reactstrap";

export class Filters extends Component {
  constructor(props) {
    super(props);

    this.state = {
      direct: true,
      oneStop: true,
      twoStops: true,
      noBags: true,
      oneBags: true,
      twoBags: true,
      filteredAirlines: JSON.parse(JSON.stringify(this.props.airlines)),
      allAirlines: JSON.parse(JSON.stringify(this.props.airlines)),

      price: 3400,
      duration: 55,
      green: false,
      outbound: [0, 24],
      inbound: [0, 24],
    };
  }

  handleStops = (event) => {
    if (event.target.value === "0") {
      this.setState(
        {
          direct: !this.state.direct,
        },
        () => this.props.filter(this.state)
      );
    } else if (event.target.value === "1") {
      this.setState(
        {
          oneStop: !this.state.oneStop,
        },
        () => this.props.filter(this.state)
      );
    } else {
      this.setState(
        {
          twoStops: !this.state.twoStops,
        },
        () => this.props.filter(this.state)
      );
    }
  };
  handleBags = (event) => {
    if (event.target.value === "0") {
      this.setState(
        {
          noBags: !this.state.noBags,
        },
        () => this.props.filter(this.state)
      );
    } else if (event.target.value === "1") {
      this.setState(
        {
          oneBags: !this.state.oneBags,
        },
        () => this.props.filter(this.state)
      );
    } else {
      this.setState(
        {
          twoBags: !this.state.twoBags,
        },
        () => this.props.filter(this.state)
      );
    }
  };

  handleAirlines = (event) => {
    const airline = event.target.value;
    let arr = this.state.filteredAirlines;
    if (arr.includes(airline)) {
      arr.splice(arr.indexOf(airline), 1);
    } else arr.push(airline);
    console.log(arr);
    this.setState(
      {
        filteredAirlines: arr,
      },
      () => this.props.filter(this.state)
    );
  };

  handlePrice = (event, newValue) => {
    this.setState(
      {
        price: newValue,
      },
      () => this.props.filter(this.state)
    );
  };

  handleDuration = (event, newValue) => {
    this.setState(
      {
        duration: newValue,
      },
      () => this.props.filter(this.state)
    );
  };

  handleGreen = (event) => {
    this.setState(
      {
        green: !this.state.green,
      },
      () => this.props.filter(this.state)
    );
  };

  handleOutbound = (event, newValue) => {
    this.setState(
      {
        outbound: newValue,
      },
      () => this.props.filter(this.state)
    );
  };

  handleInbound = (event, newValue) => {
    this.setState(
      {
        inbound: newValue,
      },
      () => this.props.filter(this.state)
    );
  };
  clearAirlines = () => {
    this.setState(
      {
        filteredAirlines: [],
      },
      () => this.props.filter(this.state)
    );
  };
  render() {
    const classes = this.props.classes;
    const color = store.getState().AgencyReducer.agency.branding.primaryColor;
    return (
      <GridItem
        xs={12}
        sm={3}
        style={{
          marginTop: "16px",
          marginLeft: "15px",
          paddingLeft: "16px",
        }}
      >
        <div>
          <GridContainer
            direction="column"
            justifyContent="center"
            spacing={3}
            className="filter-container"
          >
            <GridItem style={{ marginLeft: "10px" }} xs={12}>
              <FormControl component="fieldset" className={classes.formControl}>
                <FormHelperText style={{ fontSize: 30, fontStyle: "italic" }}>
                  Filter by :
                </FormHelperText>

                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.direct}
                        onChange={this.handleStops}
                        value="0"
                        style={{ color }}
                      />
                    }
                    label="Direct"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.oneStop}
                        onChange={this.handleStops}
                        value="1"
                        style={{ color }}
                      />
                    }
                    label="1 stop"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.twoStops}
                        onChange={this.handleStops}
                        value="2"
                        style={{ color }}
                      />
                    }
                    label="2+ stops"
                  />
                </FormGroup>
                <FormHelperText>
                  Check the desired number of stops
                </FormHelperText>
              </FormControl>
            </GridItem>
            <hr />
            <GridItem xs={12}>
              <FormControl component="fieldset" className={classes.formControl}>
                <FormLabel component="legend">Number of Bags</FormLabel>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.noBags}
                        onChange={this.handleBags}
                        value="0"
                        style={{ color }}
                      />
                    }
                    label="No bags"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.oneBags}
                        onChange={this.handleBags}
                        value="1"
                        style={{ color }}
                      />
                    }
                    label="1 Bag"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.twoBags}
                        onChange={this.handleBags}
                        value="2"
                        style={{ color }}
                      />
                    }
                    label="2+ Bags"
                  />
                </FormGroup>
                <FormHelperText>
                  Check the desired number of bags
                </FormHelperText>
              </FormControl>
            </GridItem>
            <hr />
            <GridItem sx={12}>
              <FormControl component="fieldset" className={classes.formControl}>
                <FormLabel
                  style={{ color: APP_COLORS.dark, fontSize: "1.5rem" }}
                  component="legend"
                >
                  Airlines
                </FormLabel>

                <FormGroup>
                  {this.props.airlines.map((airline, index) => {
                    return (
                      <FormControlLabel
                        key={index}
                        control={
                          <Checkbox
                            checked={this.state.filteredAirlines.includes(
                              airline
                            )}
                            onChange={this.handleAirlines}
                            value={airline}
                            style={{ color }}
                          />
                        }
                        label={airline}
                      />
                    );
                  })}
                  <Button
                    style={{
                      backgroundColor: store.getState().AgencyReducer.agency
                        .branding.primaryColor,
                      flexDirection: "row",
                    }}
                    onClick={this.clearAirlines}
                  >
                    <div style={{ flexDirection: "row", flex: 1 }}>
                      Clear Airlines
                    </div>
                  </Button>
                </FormGroup>
              </FormControl>
            </GridItem>
            <hr />
            <GridItem xs={12}>
              <FormControl>
                <FormLabel
                  style={{ color: APP_COLORS.dark, fontSize: "1.5rem" }}
                  component="legend"
                >
                  Maximum Price
                </FormLabel>
                <Slider
                  marks={[
                    {
                      value: 100,
                      label: "100" + " GBP",
                    },
                    {
                      value: 3400,
                      label: "3400" + " GBP",
                    },
                  ]}
                  style={{ color }}
                  max={3400}
                  min={100}
                  step={100}
                  value={this.state.price}
                  onChange={this.handlePrice}
                  valueLabelDisplay="auto"
                  aria-labelledby="range-slider"
                  getAriaValueText={(value) => {
                    return value + "GBP";
                  }}
                />

                <FormHelperText>
                  max price is {this.state.price} GBP
                </FormHelperText>
              </FormControl>
            </GridItem>

            <hr />

            <GridItem xs={12}>
              <FormControl>
                <FormLabel
                  style={{ color: APP_COLORS.dark, fontSize: "1.5rem" }}
                  component="legend"
                >
                  Duration Of the flight
                </FormLabel>
                <Slider
                  marks={[
                    {
                      value: 1,
                      label: 1 + " hours",
                      // value: this.props.maxDuration,
                      // label: this.props.maxDuration + " hours"
                    },
                    {
                      value: 55,
                      label: 55 + "+ hours",
                      // value: this.props.minDuration,
                      // label: this.props.minDuration + " hours"
                    },
                  ]}
                  style={{ color }}
                  max={55}
                  step={1}
                  min={1}
                  value={this.state.duration}
                  onChangeCommitted={this.handleDuration}
                  valueLabelDisplay="auto"
                  aria-labelledby="range-slider"
                  getAriaValueText={(value) => {
                    return value + "hours";
                  }}
                />
                <FormHelperText style={{ marginLeft: "5px" }}>
                  {" "}
                  {this.props.minDuration} hours - {this.state.duration} hours{" "}
                </FormHelperText>
              </FormControl>
            </GridItem>
            <hr />
            <GridItem xs={12}>
              <FormControl>
                <FormLabel
                  style={{ color: APP_COLORS.dark, fontSize: "1.5rem" }}
                  component="legend"
                >
                  Departure Times
                </FormLabel>
                <Typography id="range-slider" gutterBottom>
                  Outbound time
                </Typography>
                <Slider
                  marks={[
                    {
                      value: 0,
                      label: "00:00",
                    },
                    {
                      value: 24,
                      label: "23:59",
                    },
                  ]}
                  style={{ color }}
                  max={24}
                  min={0}
                  value={this.state.outbound}
                  onChangeCommitted={this.handleOutbound}
                  valueLabelDisplay="auto"
                  aria-labelledby="range-slider"
                  getAriaValueText={(value) => {
                    return value + "hours";
                  }}
                />
                <Typography id="range-slider" gutterBottom>
                  Return time
                </Typography>
                <Slider
                  marks={[
                    {
                      value: 0,
                      label: "00:00",
                    },
                    {
                      value: 24,
                      label: "23:59",
                    },
                  ]}
                  style={{ color }}
                  max={24}
                  min={0}
                  value={this.state.inbound}
                  onChangeCommitted={this.handleInbound}
                  valueLabelDisplay="auto"
                  aria-labelledby="range-slider"
                  getAriaValueText={(value) => {
                    return value + "hours";
                  }}
                />
              </FormControl>
            </GridItem>
            <hr />
            <GridItem>
              <FormControl component="fieldset" className={classes.formControl}>
                <FormLabel component="legend">Greener Flights</FormLabel>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.green}
                        onChange={this.handleGreen}
                        style={{ color }}
                      />
                    }
                    label="Only flights with lower co2 emissions"
                  />
                </FormGroup>
              </FormControl>
            </GridItem>
          </GridContainer>
        </div>
      </GridItem>
    );
  }
}

export default Filters;
