import React, { useEffect } from "react";
import NavBar from "../../Components/NavBar";
import LandingText from "../LandingPage/LandingText";
import SearchPanel from "./SearchPanel";
import PlaneAnimation from "components/CardAnimations/plane";
import { searchSubmit } from "./SearchPanel";

export default function HotelSearch() {
  const [loading, setLoading] = React.useState("home");

  const [defaultView, setDefaultView] = React.useState(true);

  useEffect(() => {
    window.scroll(0, 0);
    // searchSubmit(setLoading, true);
    setDefaultView(false);
  }, []);
  return defaultView ? (
    <PlaneAnimation />
  ) : (
    <div
      style={{
        backgroundColor: "#E5E5E5",
      }}
    >
      <div
        style={{
          paddingTop: "2%",
          paddingBottom: "4%",
          backgroundColor: "#ffffff!important",
        }}
      >
        <NavBar />
        <LandingText />
      </div>
      <div>
        <SearchPanel setLoading={setLoading} loading={loading} />
      </div>
    </div>
  );
}
