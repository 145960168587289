import React from "react";
import store from "store";
import GridItem from "components/Grid/GridItem.js";
import { Button } from "@material-ui/core";

import SearchIcon from '@mui/icons-material/Search';
import { Grid } from "@mui/material";
function SearchFlightButton({onSubmit,classes}) {
  return (
    <Grid item xs={12} sm={12} md={5}>
    <Button
      style={{
        width: 245,
        backgroundColor: store.getState().AgencyReducer
          .agency.branding.thirdColor
          ? store.getState().AgencyReducer.agency.branding
              .thirdColor
          : store.getState().AgencyReducer.agency.branding
              .primaryColor,
        flexDirection: "row",

        marginTop: "20px",
        padding: "10px 0",
      }}
      className={classes.button}
      onClick={onSubmit}
      endIcon={
        <SearchIcon
          htmlColor={  store.getState().AgencyReducer.agency
            .branding.fifth
            ? store.getState().AgencyReducer.agency
                .branding.fifth
            : "white"}
          style={{ marginLeft: "5px" }}
        />
      }
    >
      <b
        style={{
          color: store.getState().AgencyReducer.agency
            .branding.fifth
            ? store.getState().AgencyReducer.agency
                .branding.fifth
            : "white",
        }}
      >
        Search Flight
      </b>
    </Button>
  </Grid>

   
  );
}

export default SearchFlightButton;
