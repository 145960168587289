import React from "react";
import store from "store";

import MovingComponent from "react-moving-text";

function Spinners({ onSubmit, classes }) {
  return (
    <MovingComponent
      type="shakeMix"
      duration="1000ms"
      delay="0s"
      direction="normal"
      timing="ease"
      iteration="infinite"
      fillMode="both"
    >
      <h3
        style={{
          color: "#005359",
        }}
      >
        Searching the skies
      </h3>
    </MovingComponent>
  );
}

export default Spinners;
