
   
  
import React from "react";
import Warning from "components/Typography/Warning.js";
import dateformat from "dateformat";
import Grid from "@mui/material/Grid";
import Typography from "@material-ui/core/Typography";
import Muted from "components/Typography/Muted.js";
import Success from "components/Typography/Success.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
function OfferStops(offer) {
    return (
        
    offer.offer.slices.map((slice, index) => {
        let departureTime = slice.segments[0].departing_at;
        departureTime = dateformat(departureTime, "ddd HH:MM");
        let arrivalTime =
          slice.segments[slice.segments.length - 1].arriving_at;
        arrivalTime = dateformat(arrivalTime, "ddd HH:MM");

        let min = "00M";
        let time = slice.duration;
        let hrs;
        if (time.includes("H")) {
          time = time.split("H");

          hrs = time[0].match(/\d/g);
          hrs = hrs.join("");
          if (time[1] !== "") {
            min = time[1];
          }
        } else {
          hrs = "00";
          time = time.split("M");
          let minArray = time[0].match(/\d/g);
          min = minArray[0] + minArray[1];
        }

        if (hrs === "00") time = hrs + "H" + min + "M";
        else time = hrs + "H" + min;
        let stops = slice.segments.length - 1;
        let transit = "";
        for (let i = 0; i < slice.segments.length - 1; i++) {
          if (i > 0) {
            transit = transit + ", ";
          } else if (i > 1) {
            transit = transit + ", ...";
            break;
          }
          transit =
            transit + slice.segments[i].destination.iata_code;
        }
        return (

            <div key={index}>
            <Grid container>
              <Grid
                item
                xs={12}
                sm={4}
                md={4}
                style={{
                  marginTop: "15px",
                }}
              >
                <Typography>
                  {" "}
                  {departureTime} - {arrivalTime}
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sm={3}
                style={{ marginTop: "15px" }}
              >
                {stops === 0 ? (
                  <Success>Direct</Success>
                ) : stops === 1 ? (
                  <GridContainer direction="column">
                    <GridItem>
                      <Warning>1 stop</Warning>
                    </GridItem>
                    <GridItem>{transit}</GridItem>
                  </GridContainer>
                ) : (
                  <GridContainer direction="column">
                    <GridItem>
                      <Warning>{stops} stops</Warning>
                    </GridItem>
                    <GridItem>
                      <Muted>{transit}</Muted>
                    </GridItem>
                  </GridContainer>
                )}
              </Grid>
              <Grid
                item
                xs={12}
                sm={4}
                style={{ marginTop: "15px" }}
              >
                <GridContainer direction="column">
                  <Typography>{time}</Typography>
                  <GridItem>
                    <Muted>
                      {slice.segments[0].origin.iata_code}
                      {" - "}
                      {
                        slice.segments[slice.segments.length - 1]
                          .destination.iata_code
                      }{" "}
                    </Muted>
                  </GridItem>
                </GridContainer>
              </Grid>
            </Grid>
          </div>
        );
      }))

}

export default OfferStops;
