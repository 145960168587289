// ICONS
import { Checkbox, Container, Grid } from "@material-ui/core";
import ApartmentIcon from "@material-ui/icons/Apartment";
import BeachAccessIcon from "@material-ui/icons/BeachAccess";
import DirectionsBoatIcon from "@material-ui/icons/DirectionsBoat";
import DirectionsBusIcon from "@material-ui/icons/DirectionsBus";
import DriveEtaIcon from "@material-ui/icons/DriveEta";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import LocalAirportIcon from "@material-ui/icons/LocalAirport";
import { chooseProductsService } from "./SignUpService"; // ICONS

const mapDispatchToState = (dispatch) => {
  return {
    setChosenProductsReducer: (chosenProductsReducer) => {
      dispatch({
        type: "setChosenProductsReducer",
        chosenProductsReducer: chosenProductsReducer,
      });
    },
  };
};

const ChooseProducts = ({ setChosenProductsReducer, signUpInfo }) => {
  let history = useNavigate();

  const [product1, setProduct1] = useState(true);
  const [product2, setProduct2] = useState(false);
  const [product3, setProduct3] = useState(false);
  const [product4, setProduct4] = useState(false);
  const [product5, setProduct5] = useState(false);
  const [product6, setProduct6] = useState(false);

  const handleSubmit = async (e) => {
    const productsChosen = [
      product1,
      product2,
      product3,
      product4,
      product5,
      product6,
    ];
    setChosenProductsReducer(productsChosen);

    const items = [
      "Flights",
      "Hotels",
      "Excursions",
      "Tours",
      "Holidays",
      "Car Rentals",
    ];
    const temp = [];
    for (let i = 0; i < productsChosen.length; i++) {
      if (productsChosen[i] == true) {
        temp.push(items[i]);
      }
    }

    try {
      let reqBodyProducts = { productsToSell: temp };
      await chooseProductsService(
        reqBodyProducts,
        signUpInfo.agencyTokenReducer
      );
    } catch (e) {
      alert(e);
    }

    history("/launch");
  };

  return (
    <Container>
      <div className="loading"></div>

      <div></div>

      <div className="d-flex justify-content-center mt-5">
        <h1
          className="h1"
          style={{
            backgroundColor: "#9c27b0",
            padding: 40,
            borderRadius: 10,
            color: "white",
            fontFamily: "Cabin",
            marginBottom: "5%",
          }}
        >
          Travel Products
        </h1>
      </div>
      <Grid container spacing={2} className="mt-3 mb-5">
        <Grid item xs={4} md={2}>
          <div
            style={{
              backgroundColor: "whitesmoke",
              padding: "20%",
              boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
            }}
          >
            <h6 style={{ color: "#9C27B0" }}>available now!</h6>
            <div>
              <div>
                <LocalAirportIcon
                  style={{
                    width: "40%",
                    height: "40%",
                  }}
                />
                <h6>Flights</h6>
              </div>

              <div>
                <div>
                  <Checkbox
                    onChange={(e) => setProduct1(e.target.checked)}
                    checked={product1}
                    value="flights"
                    inputProps={{ "aria-label": "Checkbox A" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </Grid>

        <Grid item xs={4} md={2}>
          <div
            style={{
              backgroundColor: "whitesmoke",
              padding: "20%",
              boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
            }}
          >
            <h6 style={{ color: "red" }}>coming soon</h6>

            <div>
              <div>
                <ApartmentIcon
                  style={{
                    width: "40%",
                    height: "40%",
                  }}
                />
                <h6>Hotels</h6>
              </div>

              <div>
                <div>
                  <Checkbox
                    disabled={true}
                    onChange={(e) => setProduct2(e.target.checked)}
                    value="hotels"
                    inputProps={{ "aria-label": "Checkbox A" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </Grid>

        <Grid item xs={4} md={2}>
          <div
            style={{
              backgroundColor: "whitesmoke",
              padding: "20%",
              boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
            }}
          >
            <h6 style={{ color: "red" }}>coming soon</h6>

            <div>
              <div>
                <DirectionsBoatIcon
                  style={{
                    width: "40%",
                    height: "40%",
                  }}
                />
                <h6>Excursions</h6>
              </div>

              <div>
                <div>
                  <Checkbox
                    disabled={true}
                    onChange={(e) => setProduct3(e.target.checked)}
                    value="excursions"
                    inputProps={{ "aria-label": "Checkbox A" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </Grid>

        <Grid item xs={4} md={2}>
          <div
            style={{
              backgroundColor: "whitesmoke",
              padding: "20%",
              boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
            }}
          >
            <h6 style={{ color: "red" }}>coming soon</h6>

            <div>
              <div>
                <DirectionsBusIcon
                  style={{
                    width: "40%",
                    height: "40%",
                  }}
                />
                <h6>Tours</h6>
              </div>

              <div>
                <div>
                  <Checkbox
                    disabled={true}
                    onChange={(e) => setProduct4(e.target.checked)}
                    value="tours"
                    inputProps={{ "aria-label": "Checkbox A" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </Grid>

        <Grid item xs={4} md={2}>
          <div
            style={{
              backgroundColor: "whitesmoke",
              padding: "20%",
              boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
            }}
          >
            <h6 style={{ color: "red" }}>coming soon</h6>

            <div>
              <div>
                <BeachAccessIcon
                  style={{
                    width: "40%",
                    height: "40%",
                  }}
                />
                <h6>Holidays</h6>
              </div>

              <div>
                <div>
                  <Checkbox
                    disabled={true}
                    onChange={(e) => setProduct5(e.target.checked)}
                    value="holidays"
                    inputProps={{ "aria-label": "Checkbox A" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </Grid>

        <Grid item xs={4} md={2}>
          <div
            style={{
              backgroundColor: "whitesmoke",
              padding: "20%",
              boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
            }}
          >
            <h6 style={{ color: "red" }}>coming soon</h6>

            <div>
              <div>
                <DriveEtaIcon
                  style={{
                    width: "40%",
                    height: "40%",
                  }}
                />
                <h6>Rentals</h6>
              </div>

              <div>
                <div>
                  <Checkbox
                    disabled={true}
                    onChange={(e) => setProduct6(e.target.checked)}
                    value="car rentals"
                    inputProps={{ "aria-label": "Checkbox A" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
      <div>
        <button
          className="btn btn-primary mb-5"
          onClick={() => handleSubmit()}
          style={{
            width: "100%",
            height: 60,
          }}
        >
          Proceed
        </button>
      </div>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return { signUpInfo: state.SignUpReducer }; //state.initialState is what we grab from redux
  //and signupInfo iswhat we apply this data to
};

export default connect(mapStateToProps, mapDispatchToState)(ChooseProducts);
