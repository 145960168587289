import React from "react";
import ANIMATIONS from "constants/animations.js";
import Modal from "react-modal";
import store from "store";
import Typography from "@material-ui/core/Typography";
import ReactCodeInput from "react-verification-code-input";
import { confirmAccountAuto } from "views/Services/clientServices";
import Cookie from "js-cookie";
import { faker } from "@faker-js/faker";
import Cookies from "js-cookie";
import { confirmationPage } from "views/Services/clientServices";

function VerficationPopover({
  setAnimation,
  name,
  onRequestClose,
  email,
  setSignInStatus,
  open,
  verificationCode,
  animationVisibleStatus,
  animationVisible,
  password,
  history,
}) {
  return (
    <div
      className="App"
      style={{
        position: "absolute",
        zIndex: 10,
        flex: 1,
        display: "flex",
      }}
    >
      <Modal
        isOpen={open}
        onRequestClose={() => {
          onRequestClose(false);
        }}
        contentLabel="My dialog"
        className="mymodal"
        closeTimeoutMS={500}
        style={{
          content: {
            background:
              "linear-gradient(#F5F7FA," +
              store.getState().AgencyReducer.agency.branding.primaryColor +
              ")",
          },
        }}
      >
        {animationVisibleStatus ? (
          <div>{ANIMATIONS.ManLoad}</div>
        ) : (
          <div>
            <Typography
              id="modal-modal-title"
              variant="h2"
              component="span"
              style={{
                textAlign: "center",
                fontSize: 20,
                fontWeight: "Bold",
              }}
            >
              {`Please Enter the Verification Code that has been sent to "${email}"`}
              <p> </p>
              <Typography
                style={{
                  textAlign: "center",
                  fontSize: 15,
                }}
              >
                {" "}
                we will send to you the auto generated password and will be
                redirected to the payment page
              </Typography>
            </Typography>

            <div
              style={{
                alignItems: "center",
                textAlign: "center",
                justifyContent: "Center",
                display: "flex",
                paddingTop: "5%",
              }}
            >
              <ReactCodeInput
                onComplete={async (code) => {
                  animationVisible(true, false, false);
                  console.log(code);
                  console.log(verificationCode);
                  if (code == verificationCode) {
                    try {
                      const reqBody = {
                        email: email,
                        url: store.getState().AgencyReducer.agency.url,
                        input: code,
                        verificationCode: `${verificationCode}`,
                        name: email.substr(0, email.indexOf("@")),
                      };
                      let user;
                      if (setSignInStatus) {
                        reqBody["password"] = password;
                        reqBody.name = name;
                        reqBody.name = user = await confirmationPage(reqBody);
                      } else user = await confirmAccountAuto(reqBody);
                      console.log(user);
                      if (setSignInStatus) setSignInStatus(true);

                      animationVisible(false, false, false);
                      store.dispatch({ type: "setToken", token: user.token });
                      store.dispatch({
                        type: "setUserName",
                        userName: user.activatedUser.name,
                      });
                      store.dispatch({
                        type: "setEmail",
                        email: user.activatedUser.email,
                      });
                      store.dispatch({ type: "setLoggedIn", loggedIn: true });
                      Cookies.set("token", user.token);

                      animationVisible(false, true, false);
                      onRequestClose(false);
                    } catch (e) {
                      console.log(e.response);
                      animationVisible(false, false, false);

                      alert("please check your internet connection");
                    }
                  } else {
                    alert("wrong verification code");
                    animationVisible(false, false, false);
                  }
                }}
              />
            </div>

            <br />
          </div>
        )}
      </Modal>
    </div>
  );
}

export default VerficationPopover;
