import React, { Component } from "react";
import {
  Card,
  Typography,
  Grid,
  useMediaQuery,
  useTheme,
  Button,
} from "@mui/material";
import QRCode from "react-qr-code";

import store from "store";
import moment, { duration } from "moment";
import QrCode2Icon from "@mui/icons-material/QrCode2";
import "@fontsource/rubik/600.css";
import "@fontsource/rubik/500.css";
import "@fontsource/rubik/400.css";
import { useNavigate } from "react-router-dom";
import { ReactComponent as QrCode } from "../../assets/svgs/qrcode.svg";
import QrReader from "react-qr-scanner";

export default function SummaryCard() {
  let today = new Date();

  const navigate = useNavigate();
  let theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const previewStyle = {
    height: 100,
    width: 100,
  };
  console.log(store.getState().hotelSearch.qrLink);
  return (
    <Grid
      style={{
        width: isMobile ? "90%" : "80%",
        float: "right",
        margin: isMobile && "0 auto",
        marginBottom: 40,
        backgroundColor: "white",
      }}
    >
      <Card
        style={{
          borderRadius: 20,
          marginTop: 10,
          border: "1px solid #C4C4C4",
        }}
      >
        <div
          style={{
            margin: "0 auto",
            marginTop: "2%",
            width: "60%",
          }}
        >
          <QRCode
            size={256}
            style={{ height: "auto", maxWidth: "100%", width: "100%" }}
            value={store.getState().hotelSearch.qrLink}
            viewBox={`0 0 256 256`}
          />
        </div>
        <Typography
          sx={{
            fontFamily: "Rubik",
            fontStyle: "normal",
            fontWeight: 400,
            color: "#5D5C66",
          }}
        >
          Name
        </Typography>
        <Typography
          sx={{
            fontFamily: "Rubik",
            fontStyle: "normal",
            fontWeight: 500,
            color: "#362566",
          }}
        >
          Ziad Ashraf
        </Typography>
        <Typography
          sx={{
            fontFamily: "Rubik",
            fontStyle: "normal",
            fontWeight: 400,
            color: "#5D5C66",
          }}
        >
          Hotel
        </Typography>
        <Typography
          sx={{
            fontFamily: "Rubik",
            fontStyle: "normal",
            fontWeight: 500,
            color: "#362566",
          }}
        >
          {store.getState().hotelSearch.hotel.name}
        </Typography>
        <Typography
          sx={{
            fontFamily: "Rubik",
            fontStyle: "normal",
            fontWeight: 400,
            color: "#5D5C66",
          }}
        >
          Check in
        </Typography>
        <Typography
          sx={{
            fontFamily: "Rubik",
            fontStyle: "normal",
            fontWeight: 500,
            color: "#362566",
          }}
        >
          {store.getState().hotelSearch.checkInDate
            ? moment(store.getState().hotelSearch.checkInDate).format(
                "DD, MMM YY"
              )
            : moment(today).format("DD, MMM YY")}
        </Typography>
        <Typography
          sx={{
            fontFamily: "Rubik",
            fontStyle: "normal",
            fontWeight: 400,
            color: "#5D5C66",
          }}
        >
          Check out
        </Typography>
        <Typography
          sx={{
            fontFamily: "Rubik",
            fontStyle: "normal",
            fontWeight: 500,
            color: "#362566",
          }}
        >
          {store.getState().hotelSearch.checkInDate
            ? moment(store.getState().hotelSearch.checkInDate).format(
                "DD, MMM YY"
              )
            : moment(today).format("DD, MMM YY")}
        </Typography>
        <Typography
          sx={{
            fontFamily: "Rubik",
            fontStyle: "normal",
            fontWeight: 400,
            color: "#5D5C66",
          }}
        >
          Price
        </Typography>
        <Typography
          sx={{
            fontFamily: "Rubik",
            fontStyle: "normal",
            fontWeight: 500,
            color: "#362566",
          }}
        >
          {store.getState().hotelSearch.hotel.currency +
            " " +
            store.getState().hotelSearch.hotel.minRate}{" "}
        </Typography>
        <Grid container>
          <Grid item md={2} />
          <Grid item md={8} sm={12} xs={12} sx={{ pb: 1, pt: 1 }}>
            <Button
              onClick={() => {
                window.open(store.getState().hotelSearch.qrLink);
              }}
              sx={{ width: "100%", pt: 1, pb: 1, mt: 1 }}
              size="medium"
              variant="filled"
              style={{
                backgroundColor: "#5F41B3",
                borderRadius: 10,
              }}
            >
              <Typography
                style={{
                  fontFamily: "Inter",
                  fontStyle: "normal",
                  fontWeight: 500,
                  fontSize: 16,
                  color: "white",
                  textTransform: "none",
                }}
              >
                Download voucher
              </Typography>
            </Button>
          </Grid>
        </Grid>
      </Card>
    </Grid>
  );
}
