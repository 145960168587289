import { Grid, Typography } from "@mui/material";
import React, { Component } from "react";
import "@fontsource/rubik/600.css";
import "@fontsource/rubik/500.css";
import "@fontsource/rubik/700.css";
import Footer from "views/Hotels/Components/Footer";
import Navbar from "views/Hotels/Components/NavBar";
import BookingCard from "../SelectRooms/BookingCard";
import { ReactComponent as Successful } from "../../assets/svgs/successful.svg";
import SummaryCard from "./SummaryCard";

export default function Summary() {
  return (
    <Grid container sx={{ bgcolors: "#FFFFFF", minHeight: "100vh" }}>
      <Grid item md={12} sm={12} xs={12}>
        <Navbar />
      </Grid>
      <Grid container sx={{ bgcolor: "#FBFBFB" }}>
        <Grid
          item
          md={8}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Successful />
          <Grid item md={0.5} />

          <Grid item>
            <Typography
              sx={{
                textAlign: "left",
                fontFamily: "Rubik",
                fontStyle: "normal",
                fontWeight: 700,
                fontSize: "1.5vw",
                color: "#362566",
              }}
            >
              Your Booking was completed successfully!
            </Typography>
            <Typography
              sx={{
                textAlign: "left",
                fontFamily: "Rubik",
                fontStyle: "normal",
                fontWeight: 500,
                fontSize: "1.25vw",
                color: "#5D5C66",
              }}
            >
              Download your voucher now to use it at the check-in.
            </Typography>
          </Grid>
        </Grid>
        <Grid
          item
          md={3}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            bgcolor: "#FBFBFB",
          }}
        >
          <SummaryCard />
        </Grid>
      </Grid>

      <Grid
        item
        md={12}
        sx={{
          display: "flex",
          alignItems: "flex-end",
        }}
      >
        <Footer />
      </Grid>
    </Grid>
  );
}
