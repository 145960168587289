import axiosReq from "axios";
import hosts from "../constants/hosts";
const tmp = hosts;

const axios = axiosReq.create({
  baseURL: tmp,
  headers: {
    Authorization: `Bearer ${localStorage.getItem("agencyToken")}`,
  },
});

axios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("agencyToken");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

export default axios;
