const LineBreak = ({ style, color = "#3B3666", vertical = false }) => (
  <div
    style={{
      backgroundColor: color,
      margin: "0 auto",
      width: vertical ? "1px" : "100%",
      height: vertical ? "100%" : "0.5px",
      ...style,
    }}
  ></div>
);
export default LineBreak;
