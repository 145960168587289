const IconAndText = ({ Icon, text, style, iconStyle, textStyle }) => (
  <span style={{ color: "white", ...style }}>
    <Icon
      style={{
        paddingRight: 10,
        fontSize: 30,
        textAlign: "left",
        ...iconStyle,
      }}
    />
    <span
      style={{
        textAlign: "left",
        cursor: "pointer",
        fontSize: 20,
        fontFamily: "Rubik",
        ...textStyle,
      }}
    >
      {text}
    </span>
  </span>
);
export default IconAndText;
