import MButton from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import axios from "axios";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import APP_COLORS from "constants/themes";
import hosts from "constants/hosts";
import Cookie from "js-cookie";
import { Component, default as React } from "react";
import { downloadPDF } from "views/Services/clientServices";
import store from "store";
import agency from "constants/agency";
export class Booking extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      duffelPaymnetDialog: false,

      spinner: false,
    };
  }

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleTicket = async (event) => {
    event.preventDefault();
    try {
      let reqBody = {
        discount: "",
        agency: store.getState().AgencyReducer.agency.url,
        tax: store.getState().chosenOffer.offer.tax_amount,
        total: store.getState().chosenOffer.offer.total_amount,
        curr: store.getState().chosenOffer.offer.convCurrency,

        class: this.props.booking.class,
      };

      let url = `${hosts}/orders/generatepdf/${this.props.booking.id}`;
      alert(
        "please allow the popup window for this site to be able to download your ticket as pdf"
      );

      axios
        .post(url, reqBody)
        .then(async (response) => {
          window.open(response.data);
        })
        .catch(function (error) {
          alert("error in pdf generation");

          console.log(error);
        });
    } catch (e) {
      console.log(e);
    }
  };

  cancelBooking = async (event) => {
    event.preventDefault();
    this.setState({
      spinner: true,
    });
    try {
      let url = `${hosts}/orders/cancel/confirm`;
      const response = await axios.post(
        url,
        {
          id: this.props.booking.refund_id,
          order_id: this.props.booking.id,
        },
        {
          headers: {
            Authorization: `Bearer ${Cookie.get("token")}`,
          },
        }
      );
      this.setState({ spinner: false, open: false });
      window.location.reload(true);
    } catch (error) {
      console.log(error);
      this.setState({ spinner: false });
    }
  };

  render() {
    const color = store.getState().AgencyReducer.agency.branding.primaryColor;
    const booking = this.props.booking;

    return (
      <GridItem xs={12}>
        {booking ? (
          <Card>
            <CardHeader>
              <h3 style={{ fontWeight: "bold", color: "white" }}>
                {booking.destination + " from " + booking.origin}{" "}
              </h3>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} md={4}>
                  <GridContainer justifyContent="center">
                    <GridItem xs={12} style={{ marginRight: "0px" }}>
                      <img src={booking.imageURL} width="100%" height="100%" />
                    </GridItem>
                  </GridContainer>
                </GridItem>
                <GridItem xs={12} md={4}>
                  <GridContainer justifyContent="center">
                    <GridItem xs={12}>
                      <h3 style={{ fontStyle: "italic", color: "purple" }}>
                        {booking.destination + " from " + booking.origin}
                      </h3>
                    </GridItem>
                    <GridItem>
                      <h3>{`${booking.departure} - ${booking.arrival}`}</h3>
                      <h3>{`${booking.passengers} | ${booking.type} | ${booking.class}`}</h3>
                    </GridItem>
                  </GridContainer>
                </GridItem>
                <GridItem xs={12} md={4}>
                  <GridContainer justifyContent="center">
                    <GridItem xs={12} style={{ textAlign: "center", color }}>
                      <Typography>
                        <h2>
                          {store.getState().client.client.currency +
                            " " +
                            parseFloat(booking.markup).toLocaleString()}
                        </h2>
                      </Typography>
                    </GridItem>
                    <div>
                      <GridItem style={{ textAlign: "center", color }}>
                        <MButton
                          color="secondary"
                          onClick={this.handleClickOpen}
                          variant="outlined"
                        >
                          {" "}
                          Cancel{" "}
                        </MButton>
                        <Dialog
                          fullWidth
                          open={this.state.open}
                          keepMounted
                          onClose={this.handleClose}
                        >
                          <DialogTitle>Cancelation</DialogTitle>
                          <GridContainer style={{ margin: "15px" }}>
                            <GridItem xs={12} style={{ margin: "10px" }}>
                              <p>
                                {" "}
                                Are you sure you want to cancel your booking?
                              </p>
                              <p>
                                {" "}
                                The refunded amount will be{" "}
                                <b>
                                  {store.getState().client.client.currency +
                                    " " +
                                    (booking.refund_amount || "") +
                                    " " +
                                    (booking.base_currency || "")}
                                </b>
                                .
                              </p>
                            </GridItem>
                          </GridContainer>
                          <DialogActions>
                            {this.state.spinner && <CircularProgress />}
                            <MButton
                              color="secondary"
                              onClick={this.cancelBooking}
                              variant="contained"
                            >
                              Cancel Booking
                            </MButton>
                            <MButton
                              onClick={this.handleClose}
                              variant="contained"
                            >
                              Close
                            </MButton>
                          </DialogActions>
                        </Dialog>
                      </GridItem>
                      <GridItem style={{ marginTop: "5%" }}>
                        <MButton
                          style={{ color: "#5DD274" }}
                          onClick={this.handleTicket}
                          variant="outlined"
                        >
                          {" "}
                          Download Ticket{" "}
                        </MButton>
                      </GridItem>
                    </div>
                  </GridContainer>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        ) : (
          <div />
        )}
      </GridItem>
    );
  }
}

export default Booking;
