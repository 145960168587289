import * as React from "react";
import store from "store";
import PlaneAnimation from "components/CardAnimations/plane";
import Map from "./Map";
import {
  Typography,
  Grid,
  Slider,
  Divider,
  Rating,
  Button,
  ListItem,
  List,
  ListItemButton,
  ListItemText,
  Collapse,
  FormControlLabel,
  TextField,
  InputAdornment,
  Link,
  ListItemIcon,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import StarRoundedIcon from "@mui/icons-material/StarRounded";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import GoogleMapReact from "google-map-react";

import { Box, Checkbox } from "@mui/joy";
import { styled } from "@mui/material/styles";
import { InputOutlined } from "@mui/icons-material";
import { paginateHotels } from "views/Services/clientServices";
import Popoverhotels from "views/Hotels/Components/Popover";
import { compose, withProps } from "recompose";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from "react-google-maps";
import MyMapComponent from "./Map";

function valuetext(value) {
  return `${value}°C`;
}
const AnyReactComponent = ({ text }) => <div>{text}</div>;

const Filters = ({ setLoading, loading }) => {
  let navigate = useNavigate();

  const styles = {
    titlemob: {
      fontFamily: "Rubik",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: 16,
      color: "#5D5C66",
    },
    titleDesktop: {
      fontFamily: "Rubik",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: 16,
    },
    txtmob: {
      fontFamily: "Rubik",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: 16,
      color: "#362566",
      lineHeight: "19px",
    },
  };
  const StyledRating = styled(Rating)({
    "& .MuiRating-iconFilled": {
      color: "#FFC725",
    },
    "& .MuiRating-iconHover": {
      color: "#C4C4C4",
    },
  });
  const [openSort, setSort] = React.useState("Popularity");

  const [openRating, setOpenRating] = React.useState(true);
  const [openPrice, setOpenPrice] = React.useState(true);

  const [openAmenities, setOpenAmenities] = React.useState(true);
  const [openBoard, setOpenBoard] = React.useState(true);
  const [allBoards, setAllBoards] = React.useState(true);
  const [value, setValue] = React.useState([0, 2000]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [filterReq, setFilterReq] = React.useState({
    stars: ["5est", "4est", "3est", "2est", "1est", "5estlux"],
    price: "all",
    allBoards: true,
    boards: ["ROOM ONLY", "HALF BOARD", "FULL BOARD", "BED AND BREAKFAST"],
  });

  const [checkedAmenities, setCheckedAmenities] = React.useState([true, false]);
  const handleSort = (event) => {
    setSort(event.target.value);
  };
  const handleClick = () => {
    setOpenRating(!openRating);
  };
  const handleClick1 = () => {
    setOpenPrice(!openPrice);
  };
  const handleClick2 = () => {
    setOpenAmenities(!openAmenities);
  };
  const handleFilters = (rate) => {
    let rating = filterReq.stars.indexOf(rate);
    let temp = [];
    if (rating != -1) {
      temp = filterReq.stars;
      temp.splice(rating, 1);
      setFilterReq({ ...filterReq, stars: temp });
    } else {
      temp = filterReq.stars;
      temp.push(rate);
      setFilterReq({
        ...filterReq,
        stars: temp,
      });
    }
    console.log(filterReq.stars);
  };
  const handleBoardFilters = (board) => {
    let rating = filterReq.boards.indexOf(board);
    console.log(rating);
    let temp = [];
    if (rating != -1) {
      temp = filterReq.boards;
      temp.splice(rating, 1);
      console.log(temp);
      setAllBoards(false);
      setFilterReq({ ...filterReq, boards: temp, allBoards: false });
    } else {
      temp = filterReq.boards;
      temp.push(board);

      setFilterReq({
        ...filterReq,
        boards: temp,
      });
    }
    console.log(filterReq);
  };
  const handleClick3 = () => {
    setOpenBoard(!openBoard);
  };
  const defaultProps = {
    center: {
      lat: 10.99835602,
      lng: 77.01502627,
    },
    zoom: 11,
  };

  console.log(store.getState().hotelSearch.searchPlace.latitude);

  const [open, setDialogOpen] = React.useState(false);
  const [msg, setMsg] = React.useState("");
  const [error, setError] = React.useState(false);
  return (
    <Grid container bgcolor="white" direction="column">
      <Grid item>
        <Grid
          container
          alignItems="left"
          justifyContent="left"
          rowSpacing={3}
          sx={{ display: { md: "flex", sm: "flex", xs: "none" } }}
        >
          <Grid item md={12} sm={12} sx={{ pb: 2 }}>
            {store.getState().hotelSearch.searchPlace.longitude ? (
              <Box>
                <Typography
                  sx={{
                    color: "#362566",
                    textTransform: "none",
                    fontFamily: "Rubik",
                    fontStyle: "normal",
                    fontWeight: 500,
                    fontSize: 16,
                    textAlign: "left",
                  }}
                >
                  View on map
                </Typography>
                <Map
                  width="280px"
                  height="200px"
                  long={store.getState().hotelSearch.searchPlace.longitude}
                  lat={store.getState().hotelSearch.searchPlace.latitude}
                />
                <Divider sx={{ width: "85%" }}></Divider>
              </Box>
            ) : null}
          </Grid>

          <Grid item md={12} sm={12} xs={0} sx={{ textAlign: "left" }}>
            <Grid container rowSpacing={1.5}>
              <Grid item md={12} sm={12} xs={0}>
                <Typography
                  sx={{
                    color: "#362566",
                    textTransform: "none",
                    fontFamily: "Rubik",
                    fontStyle: "normal",
                    fontWeight: 500,
                    fontSize: 16,
                    textAlign: "left",
                  }}
                >
                  Filters
                </Typography>
                <Typography
                  sx={{
                    color: "#5D5C66",
                    textTransform: "none",
                    fontFamily: "Rubik",
                    fontStyle: "normal",
                    fontWeight: 400,
                    fontSize: 16,
                  }}
                >
                  123/1234 Results
                </Typography>
              </Grid>
              <Grid item md={12} sm={12} xs={0}>
                <Grid container>
                  <Grid item md={12} sm={12} xs={0}>
                    <Typography
                      sx={{
                        color: "#212427",
                        textTransform: "none",
                        fontFamily: "Rubik",
                        fontStyle: "normal",
                        fontWeight: 600,
                        fontSize: 20,
                      }}
                    >
                      Applied filters
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    md={12}
                    sm={12}
                    xs={0}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      padding: "10px 0px",
                      gap: "10px",
                    }}
                  >
                    <Button
                      variant="contained"
                      endIcon={<HighlightOffRoundedIcon />}
                      sx={{
                        color: "#212427",
                        textTransform: "none",
                        fontFamily: "Rubik",
                        fontStyle: "normal",
                        fontWeight: 600,
                        fontSize: 14,
                        color: "white",
                        backgroundColor: "#5F41B3",
                        borderRadius: 20,
                        textAlign: "center",
                      }}
                    >
                      Gym
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={12} sm={12} xs={0}>
                <List>
                  <ListItemButton onClick={handleClick} sx={{ pl: 0 }}>
                    <ListItemText
                      primaryTypographyProps={{
                        color: "#212427",
                        textTransform: "none",
                        fontFamily: "Rubik",
                        fontStyle: "normal",
                        fontWeight: 500,
                        fontSize: 20,
                        textAlign: "left",
                      }}
                      primary="Rating"
                    />
                    {openRating ? <ExpandLess /> : <ExpandMore />}
                  </ListItemButton>
                  <Collapse in={openRating} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      <ListItem sx={{ pl: 0 }}>
                        <Checkbox
                          defaultChecked
                          onChange={() => {
                            handleFilters("5estlux");
                          }}
                          sx={{ color: "#5F41B3" }}
                          variant="outlined"
                        />
                        <StyledRating
                          readOnly
                          defaultValue={5}
                          precision={0.5}
                          icon={<StarRoundedIcon fontSize="inherit" />}
                          emptyIcon={<StarRoundedIcon fontSize="inherit" />}
                        />
                        <Typography>LUXURY</Typography>
                      </ListItem>
                      <ListItem sx={{ pl: 0 }}>
                        <Checkbox
                          defaultChecked
                          onChange={() => {
                            handleFilters("5est");
                          }}
                          sx={{ color: "#5F41B3" }}
                          variant="outlined"
                        />
                        <StyledRating
                          readOnly
                          defaultValue={5}
                          precision={0.5}
                          icon={<StarRoundedIcon fontSize="inherit" />}
                          emptyIcon={<StarRoundedIcon fontSize="inherit" />}
                        />
                      </ListItem>
                      <ListItem sx={{ pl: 0 }}>
                        <Checkbox
                          defaultChecked
                          onChange={() => {
                            handleFilters("4est");
                          }}
                          sx={{ color: "#5F41B3" }}
                          variant="outlined"
                        />
                        <StyledRating
                          readOnly
                          defaultValue={4}
                          precision={0.5}
                          icon={<StarRoundedIcon fontSize="inherit" />}
                          emptyIcon={<StarRoundedIcon fontSize="inherit" />}
                        />
                      </ListItem>
                      <ListItem sx={{ pl: 0 }}>
                        <Checkbox
                          defaultChecked
                          onChange={() => {
                            handleFilters("3est");
                          }}
                          sx={{ color: "#5F41B3" }}
                          variant="outlined"
                        />
                        <StyledRating
                          readOnly
                          defaultValue={3}
                          precision={0.5}
                          icon={<StarRoundedIcon fontSize="inherit" />}
                          emptyIcon={<StarRoundedIcon fontSize="inherit" />}
                        />
                      </ListItem>
                      <ListItem sx={{ pl: 0 }}>
                        <Checkbox
                          onChange={() => {
                            handleFilters("2est");
                          }}
                          defaultChecked
                          sx={{ color: "#5F41B3" }}
                          variant="outlined"
                        />
                        <StyledRating
                          readOnly
                          defaultValue={2}
                          precision={0.5}
                          icon={<StarRoundedIcon fontSize="inherit" />}
                          emptyIcon={<StarRoundedIcon fontSize="inherit" />}
                        />
                      </ListItem>
                      <ListItem sx={{ pl: 0 }}>
                        <Checkbox
                          defaultChecked
                          onChange={() => {
                            handleFilters("1est");
                          }}
                          sx={{ color: "#5F41B3" }}
                          variant="outlined"
                        />
                        <StyledRating
                          readOnly
                          defaultValue={1}
                          precision={0.5}
                          icon={<StarRoundedIcon fontSize="inherit" />}
                          emptyIcon={<StarRoundedIcon fontSize="inherit" />}
                        />
                      </ListItem>
                    </List>
                  </Collapse>
                </List>
              </Grid>
            </Grid>
          </Grid>

          <Divider sx={{ width: "85%" }}></Divider>

          <Grid item md={12} sm={12} xs={12}>
            <List>
              <ListItemButton onClick={handleClick1} sx={{ pl: 0 }}>
                <ListItemText
                  primaryTypographyProps={{
                    color: "#212427",
                    textTransform: "none",
                    fontFamily: "Rubik",
                    fontStyle: "normal",
                    fontWeight: 500,
                    fontSize: 20,
                    textAlign: "left",
                  }}
                  primary="Price"
                />
                {openPrice ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
              <Collapse in={openPrice} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItem sx={{ pl: 0 }}>
                    <Grid container>
                      <Grid item md={3} sm={3}>
                        <Typography
                          sx={{
                            fontFamily: "Rubik",
                            fontStyle: "normal",
                            fontWeight: 400,
                            fontSize: 14,
                            color: "#5D5C66",
                          }}
                        >
                          From
                        </Typography>
                        <TextField variant="outlined" value={value[0]} />
                      </Grid>
                      <Grid item md={4} sm={4} />
                      <Grid item md={3} sm={3}>
                        <Typography
                          sx={{
                            fontFamily: "Rubik",
                            fontStyle: "normal",
                            fontWeight: 400,
                            fontSize: 14,
                            color: "#5D5C66",
                          }}
                        >
                          To
                        </Typography>
                        <TextField
                          variant="outlined"
                          sx={{ borderRadius: 8, borderColor: "gray" }}
                          value={value[1]}
                        />
                      </Grid>
                    </Grid>
                  </ListItem>
                  <ListItem sx={{ pl: 0 }}>
                    <Slider
                      size="small"
                      getAriaLabel={() => "Temperature range"}
                      value={value}
                      min={0}
                      max={10000}
                      onChange={(event) => {
                        console.log(event.target.value);
                        setValue(event.target.value);
                        setFilterReq({
                          ...filterReq,
                          price: {
                            min: event.target.value[0],
                            max: event.target.value[1],
                          },
                        });
                      }}
                      valueLabelDisplay="auto"
                      getAriaValueText={valuetext}
                      style={{ width: "90%", color: "#5F41B3" }}
                    ></Slider>
                  </ListItem>
                </List>
              </Collapse>
            </List>
          </Grid>
          <Divider sx={{ width: "85%" }}></Divider>

          <Grid
            item
            md={12}
            sm={12}
            rowSpacing={1.5}
            style={{ textAlign: "left" }}
          >
            <List>
              <ListItemButton onClick={handleClick2} sx={{ pl: 0 }}>
                <ListItemText
                  primaryTypographyProps={{
                    color: "#212427",
                    textTransform: "none",
                    fontFamily: "Rubik",
                    fontStyle: "normal",
                    fontWeight: 500,
                    fontSize: 20,
                    textAlign: "left",
                  }}
                  primary="Amenities"
                />
                {openAmenities ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
              <Collapse in={openAmenities} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItem sx={{ pl: 1.5 }}>
                    <FormControlLabel
                      sx={{
                        color: "#362566",
                        fontFamily: "Rubik",
                        fontStyle: "normal",
                        fontWeight: 400,
                        fontSize: 16,
                        lineHeight: "19px",
                      }}
                      control={
                        <Checkbox
                          variant="outlined"
                          sx={{
                            color: "#362566",
                            pr: 1,
                          }}
                          //  checked={}
                        />
                      }
                      label="Select all amenities "
                    />
                  </ListItem>
                  <ListItem sx={{ pl: 0 }}>
                    <Typography
                      style={{
                        color: "#5D5C66",
                        textTransform: "none",
                        fontFamily: "Rubik",
                        fontStyle: "normal",
                        fontWeight: 400,
                        fontSize: 14,
                        textAlign: "left",
                      }}
                    >
                      Amenities
                    </Typography>
                  </ListItem>
                  <ListItem sx={{ pl: 1.5 }}>
                    <FormControlLabel
                      sx={{
                        color: "#362566",
                        fontFamily: "Rubik",
                        fontStyle: "normal",
                        fontWeight: 400,
                        fontSize: 16,
                        lineHeight: "19px",
                      }}
                      control={
                        <Checkbox
                          variant="outlined"
                          sx={{
                            color: "#362566",
                            pr: 1,
                          }}
                          //  checked={}
                        />
                      }
                      label="Gym"
                    />
                  </ListItem>
                  <ListItem sx={{ pl: 1.5 }}>
                    <FormControlLabel
                      sx={{
                        color: "#362566",
                        fontFamily: "Rubik",
                        fontStyle: "normal",
                        fontWeight: 400,
                        fontSize: 16,
                        lineHeight: "19px",
                      }}
                      control={
                        <Checkbox
                          variant="outlined"
                          sx={{
                            color: "#362566",
                            pr: 1,
                          }}
                          //  checked={}
                        />
                      }
                      label="Hot tub"
                    />
                  </ListItem>
                  <ListItem sx={{ pl: 1.5 }}>
                    <FormControlLabel
                      sx={{
                        color: "#362566",
                        fontFamily: "Rubik",
                        fontStyle: "normal",
                        fontWeight: 400,
                        fontSize: 16,
                        lineHeight: "19px",
                      }}
                      control={
                        <Checkbox
                          variant="outlined"
                          sx={{
                            color: "#362566",
                            pr: 1,
                          }}
                          //  checked={}
                        />
                      }
                      label="Free wi-fi"
                    />
                  </ListItem>
                  <ListItem sx={{ pl: 1.5 }}>
                    <FormControlLabel
                      sx={{
                        color: "#362566",
                        fontFamily: "Rubik",
                        fontStyle: "normal",
                        fontWeight: 400,
                        fontSize: 16,
                        lineHeight: "19px",
                      }}
                      control={
                        <Checkbox
                          variant="outlined"
                          sx={{
                            color: "#362566",
                            pr: 1,
                          }}
                          //  checked={}
                        />
                      }
                      label="Free airport shuttle"
                    />
                  </ListItem>
                  <ListItem sx={{ pl: 1.5 }}>
                    <FormControlLabel
                      sx={{
                        color: "#362566",
                        fontFamily: "Rubik",
                        fontStyle: "normal",
                        fontWeight: 400,
                        fontSize: 16,
                        lineHeight: "19px",
                      }}
                      control={
                        <Checkbox
                          variant="outlined"
                          sx={{
                            color: "#362566",
                            pr: 1,
                          }}
                          //  checked={}
                        />
                      }
                      label="Air conditioned"
                    />
                  </ListItem>
                  <ListItem sx={{ pl: 1.5 }}>
                    <FormControlLabel
                      sx={{
                        color: "#362566",
                        fontFamily: "Rubik",
                        fontStyle: "normal",
                        fontWeight: 400,
                        fontSize: 16,
                        lineHeight: "19px",
                      }}
                      control={
                        <Checkbox
                          variant="outlined"
                          sx={{
                            color: "#362566",
                            pr: 1,
                          }}
                          //  checked={}
                        />
                      }
                      label="Pool"
                    />
                  </ListItem>
                  <ListItem sx={{ pl: 1.5 }}>
                    <FormControlLabel
                      sx={{
                        color: "#362566",
                        fontFamily: "Rubik",
                        fontStyle: "normal",
                        fontWeight: 400,
                        fontSize: 16,
                        lineHeight: "19px",
                      }}
                      control={
                        <Checkbox
                          variant="outlined"
                          sx={{
                            color: "#362566",
                            pr: 1,
                          }}
                          //  checked={}
                        />
                      }
                      label="Washer and dryer"
                    />
                  </ListItem>
                  <ListItem sx={{ pl: 1.5 }}>
                    <FormControlLabel
                      sx={{
                        color: "#362566",
                        fontFamily: "Rubik",
                        fontStyle: "normal",
                        fontWeight: 400,
                        fontSize: 16,
                        lineHeight: "19px",
                      }}
                      control={
                        <Checkbox
                          variant="outlined"
                          sx={{
                            color: "#362566",
                            pr: 1,
                          }}
                          //  checked={}
                        />
                      }
                      label="Pet-friendly"
                    />
                  </ListItem>
                  <ListItem sx={{ pl: 1.5 }}>
                    <FormControlLabel
                      sx={{
                        color: "#362566",
                        fontFamily: "Rubik",
                        fontStyle: "normal",
                        fontWeight: 400,
                        fontSize: 16,
                        lineHeight: "19px",
                      }}
                      control={
                        <Checkbox
                          variant="outlined"
                          sx={{
                            color: "#362566",
                            pr: 1,
                          }}
                          //  checked={}
                        />
                      }
                      label="Restaurant"
                    />
                  </ListItem>
                </List>
              </Collapse>
            </List>
          </Grid>
          <Divider sx={{ width: "85%" }}></Divider>

          <Grid
            item
            md={12}
            sm={12}
            rowSpacing={1.5}
            style={{ textAlign: "left" }}
          >
            <List>
              <ListItemButton onClick={handleClick3} sx={{ pl: 0 }}>
                <ListItemText
                  primaryTypographyProps={{
                    color: "#212427",
                    textTransform: "none",
                    fontFamily: "Rubik",
                    fontStyle: "normal",
                    fontWeight: 500,
                    fontSize: 20,
                    textAlign: "left",
                  }}
                  primary="Board"
                />
                {openBoard ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
              <Collapse in={openBoard} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItem sx={{ pl: 1.5 }}>
                    <FormControlLabel
                      sx={{
                        color: "#362566",
                        fontFamily: "Rubik",
                        fontStyle: "normal",
                        fontWeight: 400,
                        fontSize: 16,
                        lineHeight: "19px",
                      }}
                      control={
                        <Checkbox
                          checked={allBoards}
                          onChange={() => {
                            setAllBoards(!allBoards);
                            setFilterReq({
                              ...filterReq,
                              allBoards: !filterReq.allBoards,
                              boards: [
                                "ROOM ONLY",
                                "HALF BOARD",
                                "FULL BOARD",
                                "BED AND BREAKFAST",
                              ],
                            });
                          }}
                          variant="outlined"
                          sx={{
                            color: "#362566",
                            pr: 1,
                          }}
                        />
                      }
                      label="Select all types"
                    />
                  </ListItem>
                  <ListItem sx={{ pl: 0 }}>
                    <Typography
                      style={{
                        color: "#5D5C66",
                        textTransform: "none",
                        fontFamily: "Rubik",
                        fontStyle: "normal",
                        fontWeight: 400,
                        fontSize: 14,
                        textAlign: "left",
                      }}
                    >
                      Board types
                    </Typography>
                  </ListItem>
                  <ListItem sx={{ pl: 1.5 }}>
                    <FormControlLabel
                      sx={{
                        color: "#362566",
                        fontFamily: "Rubik",
                        fontStyle: "normal",
                        fontWeight: 400,
                        fontSize: 16,
                        lineHeight: "19px",
                      }}
                      control={
                        <Checkbox
                          checked={
                            allBoards
                              ? true
                              : filterReq.boards.indexOf("ROOM ONLY") > -1
                          }
                          onChange={() => {
                            handleBoardFilters("ROOM ONLY");
                          }}
                          variant="outlined"
                          sx={{
                            color: "#362566",
                            pr: 1,
                          }}
                        />
                      }
                      label="Room only"
                    />
                  </ListItem>
                  <ListItem sx={{ pl: 1.5 }}>
                    <FormControlLabel
                      sx={{
                        color: "#362566",
                        fontFamily: "Rubik",
                        fontStyle: "normal",
                        fontWeight: 400,
                        fontSize: 16,
                        lineHeight: "19px",
                      }}
                      control={
                        <Checkbox
                          checked={
                            allBoards
                              ? true
                              : filterReq.boards.indexOf("BED AND BREAKFAST") >
                                -1
                          }
                          onChange={() => {
                            handleBoardFilters("BED AND BREAKFAST");
                          }}
                          variant="outlined"
                          sx={{
                            color: "#362566",
                            pr: 1,
                          }}
                          //  checked={}
                        />
                      }
                      label="Bed and Breakfast"
                    />
                  </ListItem>
                  <ListItem sx={{ pl: 1.5 }}>
                    <FormControlLabel
                      sx={{
                        color: "#362566",
                        fontFamily: "Rubik",
                        fontStyle: "normal",
                        fontWeight: 400,
                        fontSize: 16,
                        lineHeight: "19px",
                      }}
                      control={
                        <Checkbox
                          checked={
                            allBoards
                              ? true
                              : filterReq.boards.indexOf("HALF BOARD") > -1
                          }
                          onChange={() => {
                            handleBoardFilters("HALF BOARD");
                          }}
                          variant="outlined"
                          sx={{
                            color: "#362566",
                            pr: 1,
                          }}
                          //  checked={}
                        />
                      }
                      label="Half Board"
                    />
                  </ListItem>
                  <ListItem sx={{ pl: 1.5 }}>
                    <FormControlLabel
                      sx={{
                        color: "#362566",
                        fontFamily: "Rubik",
                        fontStyle: "normal",
                        fontWeight: 400,
                        fontSize: 16,
                        lineHeight: "19px",
                      }}
                      control={
                        <Checkbox
                          checked={
                            allBoards
                              ? true
                              : filterReq.boards.indexOf("FULL BOARD") > -1
                          }
                          onChange={() => {
                            handleBoardFilters("FULL BOARD");
                          }}
                          variant="outlined"
                          sx={{
                            color: "#362566",
                            pr: 1,
                          }}
                          //  checked={}
                        />
                      }
                      label="Full Board"
                    />
                  </ListItem>
                </List>
              </Collapse>
            </List>
          </Grid>
          <Popoverhotels
            isOpen={open}
            setDialogOpen={setDialogOpen}
            msg={msg}
            error={error}
          />
          <Divider sx={{ width: "85%" }}></Divider>
          <Grid
            item
            md={10}
            sm={10}
            rowSpacing={1.5}
            style={{ textAlign: "left" }}
          >
            <Button
              onClick={async () => {
                try {
                  setLoading("home");

                  const hotels = await paginateHotels({
                    hotels: store.getState().hotelResults.allHotels,
                    filters: filterReq,
                  });
                  store.dispatch({ type: "setHotelResults", payload: hotels });
                  setLoading(false);
                } catch (e) {
                  setLoading("empty");
                  console.log(e);
                  setError(true);
                  setDialogOpen(true);
                  if (e.response.status == 403) {
                    setMsg("no results matched your current Filters");
                  } else {
                    setMsg("please try again with different dates");
                  }
                }
              }}
              sx={{ justifyContent: "center" }}
              size="medium"
              variant="filled"
              style={{
                backgroundColor: "#5F41B3",
                borderRadius: 10,
                width: "100%",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Inter",
                  fontStyle: "normal",
                  fontWeight: 500,
                  fontSize: 16,
                  color: "white",
                  textTransform: "none",
                }}
              >
                Apply filters
              </Typography>
            </Button>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        item
        xs={12}
        justifyContent="left"
        rowSpacing={3}
        sx={{ display: { md: "none", sm: "none", xs: "block" } }}
      >
        <Grid container>
          <Grid item xs={12} justifyContent="left">
            <List>
              <ListItem
                sx={{
                  pt: 4,
                  boxShadow: "0px 1px 5px rgba(0, 0, 0, 0.1)",
                }}
              >
                <ListItemButton
                  onClick={() => {
                    navigate("/hotels");
                  }}
                >
                  <ListItemIcon>
                    <CloseOutlinedIcon
                      sx={{ fontSize: 28, color: "#362566" }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary="Sort and filter"
                    primaryTypographyProps={{
                      fontFamily: "Rubik",
                      fontStyle: "normal",
                      fontWeight: 500,
                      fontSize: 16,
                      color: "#362566",
                      lineHeight: "19px",
                      textTransform: "none",
                      fontSize: 20,
                      fontWeight: "medium",
                      letterSpacing: 0,
                    }}
                  />
                </ListItemButton>
              </ListItem>

              <ListItem>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography sx={styles.titlemob}>Sort by</Typography>
                  </Grid>
                  <Grid item xs={12} justifyContent="center" sx={{ pr: 1 }}>
                    <FormControl
                      fullWidth
                      sx={{
                        m: 1,
                      }}
                    >
                      <Select
                        variant="standard"
                        disableUnderline
                        sx={{
                          fontFamily: "Rubik",
                          fontStyle: "normal",
                          fontWeight: 400,
                          fontSize: 16,
                          textAlign: "left",
                          color: "#362566",
                          borderRadius: 5,
                          boxShadow: "0px 1px 5px rgba(0, 0, 0, 0.1)",
                          outlineStyle: "auto",
                          outlineColor: "gray",
                          height: 48,
                          pl: 3,
                        }}
                        fullWidth
                        onChange={handleSort}
                        displayEmpty
                        value={openSort}
                        inputProps={{ "aria-label": "Without label" }}
                      >
                        <MenuItem value={"Popularity"}>Popularity</MenuItem>
                        <MenuItem value={"Recommended"}>Recommended</MenuItem>
                        <MenuItem value={"Cheapest"}>Cheapest</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </ListItem>

              <ListItem>
                <Grid container rowSpacing={1.5}>
                  <Grid item xs={12}>
                    <Typography sx={styles.titlemob}>Rating</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Checkbox
                      defaultChecked
                      sx={{ color: "#5F41B3" }}
                      variant="outlined"
                    />
                    <StyledRating
                      sx={{ pl: 1.5 }}
                      readOnly
                      defaultValue={5}
                      precision={0.5}
                      icon={<StarRoundedIcon fontSize="inherit" />}
                      emptyIcon={<StarRoundedIcon fontSize="inherit" />}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Checkbox
                      defaultChecked
                      sx={{ color: "#5F41B3" }}
                      variant="outlined"
                    />
                    <StyledRating
                      sx={{ pl: 1.5 }}
                      readOnly
                      defaultValue={4}
                      precision={0.5}
                      icon={<StarRoundedIcon fontSize="inherit" />}
                      emptyIcon={<StarRoundedIcon fontSize="inherit" />}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Checkbox
                      defaultChecked
                      sx={{ color: "#5F41B3" }}
                      variant="outlined"
                    />
                    <StyledRating
                      sx={{ pl: 1.5 }}
                      readOnly
                      defaultValue={3}
                      precision={0.5}
                      icon={<StarRoundedIcon fontSize="inherit" />}
                      emptyIcon={<StarRoundedIcon fontSize="inherit" />}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Checkbox
                      defaultChecked
                      sx={{ color: "#5F41B3" }}
                      variant="outlined"
                    />
                    <StyledRating
                      sx={{ pl: 1.5 }}
                      readOnly
                      defaultValue={2}
                      precision={0.5}
                      icon={<StarRoundedIcon fontSize="inherit" />}
                      emptyIcon={<StarRoundedIcon fontSize="inherit" />}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Checkbox
                      defaultChecked
                      sx={{ color: "#5F41B3" }}
                      variant="outlined"
                    />
                    <StyledRating
                      sx={{ pl: 1.5 }}
                      readOnly
                      defaultValue={1}
                      precision={0.5}
                      icon={<StarRoundedIcon fontSize="inherit" />}
                      emptyIcon={<StarRoundedIcon fontSize="inherit" />}
                    />
                  </Grid>
                </Grid>
              </ListItem>

              <ListItem sx={{ pl: 1, pr: 1.5 }}>
                <Divider sx={{ width: "100%" }}></Divider>
              </ListItem>

              <ListItem>
                <Grid container rowSpacing={1.5} display="inline-flex">
                  <Grid item xs={6} sx={{ pt: 0.5 }}>
                    <Typography sx={styles.titlemob}>Price</Typography>
                  </Grid>
                  <Grid item xs={2.5} />
                  <Grid item xs={3.5} justifyContent="left" alignItems="end">
                    <Button
                      variant="Text"
                      sx={{
                        fontFamily: "Rubik",
                        fontWeight: 400,
                        fontSize: 14,
                        textAlign: "right",
                        fontStyle: "normal",
                        color: "#5D5C66",
                        textTransform: "none",
                        lineHeight: "19px",
                      }}
                    >
                      All prices
                    </Button>
                  </Grid>
                  <Grid item xs={12}>
                    <Slider
                      size="small"
                      style={{ width: "100%", color: "#5F41B3" }}
                    ></Slider>
                  </Grid>
                </Grid>
              </ListItem>

              <ListItem sx={{ pl: 1, pr: 1.5 }}>
                <Divider sx={{ width: "100%" }}></Divider>
              </ListItem>

              <ListItem>
                <Grid container rowSpacing={1.5}>
                  <Grid item xs={12}>
                    <Typography sx={styles.titlemob}>Amenities</Typography>
                  </Grid>
                  <Grid item xs={12} sx={{ pl: 1.5 }}>
                    <FormControlLabel
                      sx={styles.txtmob}
                      control={
                        <Checkbox
                          variant="outlined"
                          sx={{
                            color: "#362566",
                            pr: 1,
                          }}
                          //  checked={}
                        />
                      }
                      label="Select all amenities "
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      style={{
                        color: "#5D5C66",
                        textTransform: "none",
                        fontFamily: "Rubik",
                        fontStyle: "normal",
                        fontWeight: 400,
                        fontSize: 14,
                        textAlign: "left",
                      }}
                    >
                      Amenities
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sx={{ pl: 1.5 }}>
                    <FormControlLabel
                      sx={styles.txtmob}
                      control={
                        <Checkbox
                          variant="outlined"
                          sx={{
                            color: "#362566",
                            pr: 1,
                          }}
                          //  checked={}
                        />
                      }
                      label="Gym"
                    />
                  </Grid>
                  <Grid item xs={12} sx={{ pl: 1.5 }}>
                    <FormControlLabel
                      sx={styles.txtmob}
                      control={
                        <Checkbox
                          variant="outlined"
                          sx={{
                            color: "#362566",
                            pr: 1,
                          }}
                          //  checked={}
                        />
                      }
                      label="Hot tub"
                    />
                  </Grid>
                  <Grid item xs={12} sx={{ pl: 1.5 }}>
                    <FormControlLabel
                      sx={styles.txtmob}
                      control={
                        <Checkbox
                          variant="outlined"
                          sx={{
                            color: "#362566",
                            pr: 1,
                          }}
                          //  checked={}
                        />
                      }
                      label="Free wi-fi"
                    />
                  </Grid>
                  <Grid item xs={12} sx={{ pl: 1.5 }}>
                    <FormControlLabel
                      sx={styles.txtmob}
                      control={
                        <Checkbox
                          variant="outlined"
                          sx={{
                            color: "#362566",
                            pr: 1,
                          }}
                          //  checked={}
                        />
                      }
                      label="Free airport shuttle"
                    />
                  </Grid>
                  <Grid item xs={12} sx={{ pl: 1.5 }}>
                    <FormControlLabel
                      sx={styles.txtmob}
                      control={
                        <Checkbox
                          variant="outlined"
                          sx={{
                            color: "#362566",
                            pr: 1,
                          }}
                          //  checked={}
                        />
                      }
                      label="Air conditioned"
                    />
                  </Grid>
                  <Grid item xs={12} sx={{ pl: 1.5 }}>
                    <FormControlLabel
                      sx={styles.txtmob}
                      control={
                        <Checkbox
                          variant="outlined"
                          sx={{
                            color: "#362566",
                            pr: 1,
                          }}
                          //  checked={}
                        />
                      }
                      label="Pool"
                    />
                  </Grid>
                  <Grid item xs={12} sx={{ pl: 1.5 }}>
                    <FormControlLabel
                      sx={styles.txtmob}
                      control={
                        <Checkbox
                          variant="outlined"
                          sx={{
                            color: "#362566",
                            pr: 1,
                          }}
                          //  checked={}
                        />
                      }
                      label="Washer and dryer"
                    />
                  </Grid>
                  <Grid item xs={12} sx={{ pl: 1.5 }}>
                    <FormControlLabel
                      sx={styles.txtmob}
                      control={
                        <Checkbox
                          variant="outlined"
                          sx={{
                            color: "#362566",
                            pr: 1,
                          }}
                          //  checked={}
                        />
                      }
                      label="Pet-friendly"
                    />
                  </Grid>
                  <Grid item xs={12} sx={{ pl: 1.5 }}>
                    <FormControlLabel
                      sx={styles.txtmob}
                      control={
                        <Checkbox
                          variant="outlined"
                          sx={{
                            color: "#362566",
                            pr: 1,
                          }}
                          //  checked={}
                        />
                      }
                      label="Restaurant"
                    />
                  </Grid>
                </Grid>
              </ListItem>
              <ListItem>
                <Divider sx={{ width: "100%" }}></Divider>
              </ListItem>
              <ListItem>
                <Grid container rowSpacing={1.5}>
                  <Grid item xs={12}>
                    <Typography sx={styles.titlemob}>Board</Typography>
                  </Grid>
                  <Grid item xs={12} sx={{ pl: 1.5 }}>
                    <FormControlLabel
                      sx={styles.txtmob}
                      control={
                        <Checkbox
                          variant="outlined"
                          sx={{
                            color: "#362566",
                            pr: 1,
                          }}
                        />
                      }
                      label="Select all types"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      style={{
                        color: "#5D5C66",
                        textTransform: "none",
                        fontFamily: "Rubik",
                        fontStyle: "normal",
                        fontWeight: 400,
                        fontSize: 14,
                        textAlign: "left",
                      }}
                    >
                      Board types
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sx={{ pl: 1.5 }}>
                    <FormControlLabel
                      sx={styles.txtmob}
                      control={
                        <Checkbox
                          variant="outlined"
                          sx={{
                            color: "#362566",
                            pr: 1,
                          }}
                        />
                      }
                      label="Room only"
                    />
                  </Grid>
                  <Grid item xs={12} sx={{ pl: 1.5 }}>
                    <FormControlLabel
                      sx={styles.txtmob}
                      control={
                        <Checkbox
                          variant="outlined"
                          sx={{
                            color: "#362566",
                            pr: 1,
                          }}
                          //  checked={}
                        />
                      }
                      label="Bed and Breakfast"
                    />
                  </Grid>
                  <Grid item xs={12} sx={{ pl: 1.5 }}>
                    <FormControlLabel
                      sx={styles.txtmob}
                      control={
                        <Checkbox
                          variant="outlined"
                          sx={{
                            color: "#362566",
                            pr: 1,
                          }}
                          //  checked={}
                        />
                      }
                      label="Half Board"
                    />
                  </Grid>
                  <Grid item xs={12} sx={{ pl: 1.5 }}>
                    <FormControlLabel
                      sx={styles.txtmob}
                      control={
                        <Checkbox
                          variant="outlined"
                          sx={{
                            color: "#362566",
                            pr: 1,
                          }}
                          //  checked={}
                        />
                      }
                      label="Full Board"
                    />
                  </Grid>
                </Grid>
              </ListItem>

              {/* <Button
                onClick={() => {}}
                sx={{ justifyContent: "center" }}
                size="medium"
                variant="filled"
                style={{
                  backgroundColor: "#5F41B3",
                  borderRadius: 10,
                  width: "100%",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Inter",
                    fontStyle: "normal",
                    fontWeight: 500,
                    fontSize: 16,
                    color: "white",
                    textTransform: "none",
                  }}
                >
                  Hotel Details
                </Typography>
              </Button> */}
              <ListItem>
                <Grid container display="inline-flex">
                  <Grid item xs={6.5} />
                  <Grid item xs={2.5}>
                    <Button
                      variant="text"
                      sx={{
                        fontFamily: "Inter",
                        fontStyle: "normal",
                        fontWeight: 500,
                        fontSize: 16,
                        color: "#5F41B3",
                        textAlign: "center",
                        textTransform: "none",
                      }}
                    >
                      Reset
                    </Button>
                  </Grid>
                  <Grid item xs={3}>
                    <Button
                      fullWidth
                      variant="contained"
                      sx={{
                        fontFamily: "Inter",
                        fontStyle: "normal",
                        fontWeight: 500,
                        fontSize: 16,
                        color: "white",
                        textAlign: "center",
                        textTransform: "none",
                        backgroundColor: "#5F41B3",
                        borderRadius: 15,
                      }}
                    >
                      Done
                    </Button>
                  </Grid>
                </Grid>
              </ListItem>
            </List>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Filters;
