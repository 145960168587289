import React from "react";
// import { ReactComponent as ANALITICS } from '../assets/animations/Analytics.svg';
import Lottie from "react-lottie";
import Suppliers from "../assets/animations/AboutUs.json";
import Analytics from "../assets/animations/Analytics.json";
import Apps from "../assets/animations/Apps.json";
import Branding from "../assets/animations/Branding.json";
import ChatBot from "../assets/animations/ChatBot.json";
import ConnectInsta from "../assets/animations/ConnectInsta.json";
import Domain from "../assets/animations/Domain.json";
import Finance from "../assets/animations/Finance.json";
import Images from "../assets/animations/Images.json";
import Report from "../assets/animations/report.json";
import Login from "../assets/animations/login.json";

import ManLoad from "../assets/animations/manLoad.json";
import MobileApp from "../assets/animations/MobileApp.json";
import PrivateInfo from "../assets/animations/PrivateInfo.json";
import PublicInfo from "../assets/animations/PublicInfo.json";
import Snippet from "../assets/animations/Snippet.json";
import SocialMedia from "../assets/animations/SocialMedia.json";
import Staff from "../assets/animations/Staff.json";
import Subscription from "../assets/animations/Subscription.json";
import TicketLoad from "../assets/animations/ticketLoad.json";
import Plane from "../assets/animations/plane.json";
import Instagram from "../assets/animations/instagram.json";
import Radar from "../assets/animations/radar.json";
import LoginAn from "../assets/animations/loginAn.json";
import Enjoy from "../assets/animations/Enjoy.json";
import Thinking from "../assets/animations/thinking.json";
import SearchBar from "../assets/animations/searchbar.json";
import PlaneLoad from "../assets/animations/planeload.json";
import Book from "../assets/animations/book.json";

const defaultOptions = (data, animated) => ({
  loop: animated,
  autoplay: true,
  animationData: data,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
});

const ANIMATIONS = {
  ANALITICS: (
    <Lottie
      options={defaultOptions(Analytics, false)}
      height={300}
      width={300}
    />
  ),
  PlaneLoad: (
    <Lottie
      options={defaultOptions(PlaneLoad, true)}
      height={300}
      width={300}
    />
  ),
  SearchBar: (
    <Lottie
      options={defaultOptions(SearchBar, true)}
      height={300}
      width={300}
    />
  ),
  Thinking: (
    <Lottie options={defaultOptions(Thinking, true)} height={300} width={300} />
  ),
  Enjoy: (
    <Lottie options={defaultOptions(Enjoy, true)} height={300} width={300} />
  ),
  LoginAn: (
    <Lottie options={defaultOptions(LoginAn, true)} height={300} width={300} />
  ),
  Book: (
    <Lottie options={defaultOptions(Book, true)} height={300} width={300} />
  ),
  Radar: (
    <Lottie options={defaultOptions(Radar, true)} height={300} width={300} />
  ),
  Report: (
    <Lottie options={defaultOptions(Report, true)} height={300} width={300} />
  ),
  FINANCE: (
    <Lottie options={defaultOptions(Finance, false)} height={275} width={275} />
  ),
  SUPPLIERS: (
    <Lottie
      options={defaultOptions(Suppliers, false)}
      height={275}
      width={275}
    />
  ),
  Login: (
    <Lottie options={defaultOptions(Login, true)} height={275} width={275} />
  ),

  ManLoad: (
    <Lottie options={defaultOptions(ManLoad, true)} height={275} width={275} />
  ),
  Instagram: (
    <Lottie
      options={defaultOptions(Instagram, true)}
      height={275}
      width={275}
    />
  ),
  Plane: (
    <Lottie options={defaultOptions(Plane, true)} height={275} width={275} />
  ),
  TicketLoad: (
    <Lottie
      options={defaultOptions(TicketLoad, true)}
      style={{ top: 200 }}
      height={"50%"}
      width={"50%"}
    />
  ),
  MARKETPLACE: (
    <Lottie options={defaultOptions(Apps, false)} height={275} width={275} />
  ),
  CHATBOT: (
    <Lottie options={defaultOptions(ChatBot, false)} height={275} width={275} />
  ),
  MOBILE_APP: (
    <Lottie
      options={defaultOptions(MobileApp, false)}
      height={275}
      width={275}
    />
  ),
  Staff: (
    <Lottie options={defaultOptions(Staff, false)} height={275} width={375} />
  ),
  Snippet: (
    <Lottie options={defaultOptions(Snippet, false)} height={275} width={375} />
  ),
  Branding: (
    <Lottie
      options={defaultOptions(Branding, false)}
      height={275}
      width={375}
    />
  ),
  ConnectInsta: (
    <Lottie
      options={defaultOptions(ConnectInsta, true)}
      height={275}
      width={375}
    />
  ),
  Domain: (
    <Lottie options={defaultOptions(Domain, false)} height={275} width={375} />
  ),
  Images: (
    <Lottie options={defaultOptions(Images, false)} height={275} width={375} />
  ),
  PublicInfo: (
    <Lottie
      options={defaultOptions(PublicInfo, false)}
      height={275}
      width={375}
    />
  ),
  PrivateInfo: (
    <Lottie
      options={defaultOptions(PrivateInfo, false)}
      height={275}
      width={375}
    />
  ),
  SocialMedia: (
    <Lottie
      options={defaultOptions(SocialMedia, false)}
      height={275}
      width={375}
    />
  ),
  Subscription: (
    <Lottie
      options={defaultOptions(Subscription, false)}
      height={275}
      width={375}
    />
  ),
};

export default ANIMATIONS;
