import React from "react";
import Grid from "@mui/material/Grid";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import LocalAirportIcon from "@material-ui/icons/LocalAirport";
import PeopleIcon from "@material-ui/icons/People";
import Card from "@material-ui/core/Card";

import TimelapseIcon from "@material-ui/icons/Timelapse";
import WorkIcon from "@material-ui/icons/Work";
import Timeline from "@material-ui/lab/Timeline";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineOppositeContent from "@material-ui/lab/TimelineOppositeContent";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { FaSuitcaseRolling } from "react-icons/fa";
import { Icon } from "@iconify/react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import store from "store"
import Warning from "components/Typography/Warning.js";
import dateformat from "dateformat";
function OfferExpand({state , offer ,handleFare,color}) {
    console.log(offer)
  return (
    <div>
    {state.expand ? (
        <div
          style={{
            backgroundColor: "#FFFFFF",
            borderRadius: "5px",
            marginTop: "20px",
          }}
        >
          {offer.slices.map((slice, index) => {
            let min = "00M";
            let time = slice.duration;
            let hrs;
            if (time.includes("H")) {
              time = time.split("H");

              hrs = time[0].match(/\d/g);
              hrs = hrs.join("");
              if (time[1] !== "") {
                min = time[1];
              }
            } else {
              hrs = "00";
              time = time.split("M");
              let minArray = time[0].match(/\d/g);
              min = minArray[0] + minArray[1];
            }

            if (hrs === "00") time = hrs + "H" + min + "M";
            else time = hrs + "H" + min;

            return (
              <div key={index}>
                <GridContainer>
                  <Grid
                    item
                    xs={5}
                    style={{ marginLeft: "0px", marginTop: "10px" }}
                  >
                    <p
                      style={{
                        fontSize: "1.23rem",
                        fontWeight: "bold",
                      }}
                    >
                      {"Flight " +
                        (index + 1) +
                        "  " +
                        slice.origin.iata_code +
                        " - " +
                        slice.destination.iata_code}
                    </p>
                  </Grid>
                  <Grid
                    item
                    xs={5}
                    style={{ marginLeft: "0px", marginTop: "10px" }}
                  >
                    <p
                      style={{
                        fontSize: "1.23rem",
                        fontWeight: "bold",
                      }}
                    >
                      {time}
                    </p>
                  </Grid>
                </GridContainer>
                <br />
                <Grid container>
                  <Grid item xs={8}>
                    <div style={{ backgroundColor: "#ffffff" }}>
                      {slice.segments.map((segment, index) => {
                        let min = "00M";
                        let time = segment.duration;

                        let hrs;
                        if (time.includes("H")) {
                          time = time.split("H");

                          hrs = time[0].match(/\d/g);
                          hrs = hrs.join("");
                          if (time[1] !== "") {
                            min = time[1];
                          }
                        } else {
                          hrs = "00";
                          time = time.split("M");
                          let minArray = time[0].match(/\d/g);
                          min = minArray[0] + minArray[1];
                        }

                        if (hrs === "00") time = hrs + "H" + min + "M";
                        else time = hrs + "H" + min;

                        const passengers = segment.passengers.length;
                        let cabin =
                          segment.passengers[0]
                            .cabin_class_marketing_name;

                        let checkedBaggage = 0;
                        let carryOn = 0;

                        for (
                          let i = 0;
                          i < segment.passengers[0].baggages.length;
                          i++
                        ) {
                          if (
                            segment.passengers[0].baggages[i].type ===
                            "checked"
                          ) {
                            checkedBaggage =
                              checkedBaggage +
                              segment.passengers[0].baggages[i]
                                .quantity;
                          } else if (
                            segment.passengers[0].baggages[i].type ===
                            "carry_on"
                          ) {
                            carryOn =
                              carryOn +
                              segment.passengers[0].baggages[i]
                                .quantity;
                          }
                        }

                        let elapsedMinutes = 0;
                        let elapsedHours = 0;

                        if (index < slice.segments.length - 1) {
                          const time1 = new Date(segment.arriving_at);
                          const time2 = new Date(
                            slice.segments[index + 1].departing_at
                          );
                          let elapsed = (time2 - time1) / (1000 * 60);
                          elapsedHours = Math.floor(elapsed / 60);
                          elapsedMinutes = elapsed % 60;
                        }

                        return (
                          <div key={index}>
                            <Timeline position="alternate">
                              <TimelineItem>
                                <TimelineOppositeContent
                                  sx={{ m: "auto 0" }}
                                  align="right"
                                  variant="body2"
                                  color="text.secondary"
                                >
                                  {dateformat(
                                    segment.departing_at,
                                    "ddd, d mmm"
                                  )}
                                  <br />
                                  <p style={{ color: "grey" }}>
                                    {dateformat(
                                      segment.departing_at,
                                      "HH:MM"
                                    ) +
                                      " - " +
                                      dateformat(
                                        segment.arriving_at,
                                        "HH:MM"
                                      )}{" "}
                                  </p>
                                </TimelineOppositeContent>

                                <TimelineSeparator color="#9c27b0">
                                  <TimelineConnector color="#9c27b0" />
                                  <TimelineDot
                                    color="white"
                                    sx={{
                                      borderColor: "primary.main",
                                    }}
                                  >
                                    <Icon
                                      icon="el:plane"
                                      color={
                                        store.getState().AgencyReducer
                                          .agency.branding.primaryColor
                                      }
                                      style={{ textSize: "2rem" }}
                                    />
                                  </TimelineDot>
                                  <TimelineConnector
                                    sx={{ bgcolor: "success.dark" }}
                                  />
                                </TimelineSeparator>

                                <TimelineContent
                                  sx={{ py: "12px", px: 2 }}
                                >
                                  <GridContainer>
                                    <Grid item xs={12}>
                                      <LocalAirportIcon
                                        style={{
                                          fontSize: "small",
                                        }}
                                      />{" "}
                                      {segment.origin.name +
                                        " (" +
                                        segment.origin.iata_code +
                                        ") - "}{" "}
                                      <LocalAirportIcon
                                        style={{
                                          fontSize: "small",
                                        }}
                                      />{" "}
                                      {segment.destination.name +
                                        " (" +
                                        segment.destination.iata_code +
                                        ")"}
                                    </Grid>
                                  </GridContainer>
                                  <Typography>
                                    {segment.marketing_carrier.name +
                                      " " +
                                      segment.marketing_carrier
                                        .iata_code +
                                      segment.marketing_carrier_flight_number +
                                      " | " +
                                      (segment.aircraft
                                        ? segment.aircraft.name
                                        : "") +
                                      " | " +
                                      cabin}
                                  </Typography>

                                  <GridContainer justifyContent="centre">
                                    <Grid item xs={1}>
                                      <PeopleIcon />
                                    </Grid>
                                    <Grid item xs={1}>
                                      <p> {passengers}</p>
                                    </Grid>
                                    <Grid item xs={1}>
                                      <Tooltip
                                        style={{
                                          backgroundColor: "#fafafa",
                                        }}
                                        arrow
                                        placement="top"
                                        title={"carry On: " + carryOn}
                                      >
                                        <WorkIcon />
                                      </Tooltip>
                                    </Grid>
                                    <Grid item xs={1}>
                                      <Tooltip
                                        style={{
                                          backgroundColor: "#fafafa",
                                        }}
                                        arrow
                                        placement="top"
                                        title={"carry On: " + carryOn}
                                      >
                                        <Typography>
                                          {" "}
                                          {carryOn}{" "}
                                        </Typography>
                                      </Tooltip>
                                    </Grid>
                                    <Grid item xs={1}>
                                      <Tooltip
                                        style={{
                                          backgroundColor: "#fafafa",
                                        }}
                                        arrow
                                        placement="top"
                                        title={
                                          "Checked Baggage: " +
                                          checkedBaggage
                                        }
                                      >
                                        <Typography>
                                          <FaSuitcaseRolling
                                            style={{
                                              fontSize: "large",
                                            }}
                                          />
                                        </Typography>
                                      </Tooltip>
                                    </Grid>
                                    <Grid item xs={1}>
                                      <Tooltip
                                        style={{
                                          backgroundColor: "#fafafa",
                                        }}
                                        arrow
                                        placement="top"
                                        title={
                                          "Checked Baggage: " +
                                          checkedBaggage
                                        }
                                      >
                                        <Typography>
                                          {" "}
                                          {checkedBaggage}{" "}
                                        </Typography>
                                      </Tooltip>
                                    </Grid>
                                  </GridContainer>
                                </TimelineContent>
                              </TimelineItem>

                              <br />

                              {index < slice.segments.length - 1 ? (
                                <>
                                  <TimelineItem>
                                    <TimelineSeparator color="#9c27b0">
                                      <TimelineConnector color="#9c27b0" />

                                      <TimelineConnector
                                        sx={{
                                          bgcolor: "success.dark",
                                        }}
                                      />
                                    </TimelineSeparator>

                                    <TimelineContent
                                      sx={{ py: "12px", px: 2 }}
                                    >
                                      <Warning>
                                        {" "}
                                        Change planes in{" "}
                                        {segment.destination.name}{" "}
                                        <LocalAirportIcon
                                          style={{
                                            color: "#FF9800",
                                            fontSize: "medium",
                                          }}
                                        />
                                      </Warning>
                                      <Warning>
                                        <TimelapseIcon
                                          style={{
                                            fontSize: "small",
                                          }}
                                        />{" "}
                                        {elapsedHours +
                                          "H" +
                                          elapsedMinutes +
                                          "M wait"}
                                      </Warning>
                                    </TimelineContent>
                                  </TimelineItem>
                                </>
                              ) : (
                                <div></div>
                              )}
                            </Timeline>
                          </div>
                        );
                      })}
                    </div>
                  </Grid>
                </Grid>
              </div>
            );
          })}

          <Card elevation={0} style={{ margin: "5px" }}>
            <GridContainer style={{ justifyContent: "flex-start" }}>
              <Grid item xs={9} sm={6}>
                <GridContainer
                  spacing={1}
                  style={{
                    marginTop: "4px",
                    justifyContent: "flex-start",
                  }}
                >
                  <RadioGroup
                    row
                    value={state.fare}
                    onChange={handleFare}
                  >
                   
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                          }}
                        >
                          <GridContainer>
                            <GridItem>
                              <Typography
                                style={{
                                  fontSize: 12,
                                  textAlign: "center",
                                  marginBottom: "0px",
                                }}
                              >
                                {offer.slices[0].fare_brand_name ||
                                  "null"}
                              </Typography>
                            </GridItem>
                            <GridItem>
                              <FormControlLabel
                                style={{ marginRight: "0px" }}
                                control={
                                  <Radio
                                    checkedIcon={<CheckCircleIcon />}
                                    style={{ color: color }}
                                  />
                                }
                              />
                              {store.getState().client.client.currency +
                                parseFloat(
                                  offer.total_amount
                                ).toLocaleString()}
                            </GridItem>
                          </GridContainer>
                        </div>
                      
                    
                  </RadioGroup>
                </GridContainer>
              </Grid>
            </GridContainer>
          </Card>
          <div style={{ marginTop: "10px" }}></div>
        </div>
      ) : (
        <div></div>
      )}
   </div>  
  );
}

export default OfferExpand;
