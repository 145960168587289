import store from "../store";

const APP_COLORS = {
  primary: "2C2C2C",
  accent: "#3D3569",
  backGround: "#F5F5FB",
  white: "#FEFEFE",
  grey: "#9E9E9E",
  dark: "#2C2C2C",
};
export default APP_COLORS;
// "#0C4B46"
