import { Card, Typography, Grid, Divider } from "@mui/material";
import React, { Component } from "react";
import Footer from "../../Components/Footer";
import Navbar from "../../Components/NavBar";
import BookingCard from "./BookingCard";
import "@fontsource/rubik/600.css";
import RoomCard from "./RoomCard";
import { useLocation } from "react-router-dom";

export default function SelectRooms() {
  const location = useLocation();

  let hotel = location.state.hotel;

  return (
    <div style={{ backgroundColor: "white", minHeight: "100vh" }}>
      <Navbar />
      <Grid container style={{ backgroundColor: "#FBFBFB" }} sx={{ pb: 2 }}>
        <Grid item md={1} sm={1} xs={1} />
        <Grid item md={7} sm={10} xs={10}>
          <Typography
            sx={{ pt: 2 }}
            style={{
              textAlign: "left",
              fontFamily: "Rubik",
              fontStyle: "normal",
              fontWeight: 600,
              color: "#362566",
            }}
            variant="h4"
          >
            Room Selection
          </Typography>

          <Grid item>
            {hotel.rooms.map((item, index) => {
              const roomGeneralDetails = hotel.hotelContent
                ? hotel.hotelContent.rooms.find((x) => x.roomCode == item.code)
                : null;
              const roomImage = hotel.hotelContent
                ? hotel.hotelContent.images.find((x) => {
                    return x.roomCode == item.code;
                  })
                : null;
              return (
                <RoomCard
                  key={index}
                  roomDetails={item}
                  roomGeneralDetails={roomGeneralDetails}
                  roomImage={roomImage}
                />
              );
            })}
          </Grid>
        </Grid>

        <Grid
          item
          sx={{ pt: 9, display: { xs: "none", md: "block" } }}
          md={3}
          sm={3}
          xs={0}
          justifyContent="right"
        >
          <BookingCard />
        </Grid>
        <Grid item md={0.2} sm={1} xs={1} />
      </Grid>
      <Footer />
    </div>
  );
}
