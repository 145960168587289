import "assets/scss/material-kit-react.scss?v=1.10.0";
import React from "react";
import { Route, Routes } from "react-router-dom";
import SearchFlight from "../views/SearchFlight/SearchFlight.js";
import Passengers from "../views/BookingFlights/PassengersFunctional.js";
const FlightDetails = React.lazy(() =>
  import("../views/BookingFlights/FlightDetailsFunctional.js")
);
//const Passengers = React.lazy(()=> import("../views/BookingFlights/PassengersFunctional.js"));
const LoginPage = React.lazy(() => import("../views/LoginModule/LoginPage.js"));
const PasswordChange = React.lazy(() =>
  import("../views/LoginModule/PasswordChange")
);
const PasswordChangeMobile = React.lazy(() =>
  import("../views/LoginModule/PasswordChangeMobile")
);
const ResetPassword = React.lazy(() =>
  import("../views/LoginModule/ResetPassword")
);
import { MyBookingsFunctional } from "views/MyBookings/MyBookingsFunctional.js";
import Verification from "views/Signup/Verification.js";
import PassengersFunctional from "../views/BookingFlights/PassengersFunctional.js";

const ConnectInstagram = React.lazy(() =>
  import("../views/NewDashBoard/MarketingTab/ConnectInstagram.js")
);
const Pay = React.lazy(() => import("../views/Payments/PayFunctional.js"));
const Signup = React.lazy(() => import("../views/Signup/Signup.js"));
const SummaryPage = React.lazy(() =>
  import("../views/Summary/SummaryFunctional")
);

function FlightNavigation() {
  return (
    <div>
      <Routes>
        <Route exact path="/flights" element={<SearchFlight />} />
        <Route path="/connectInstagram" element={<ConnectInstagram />} />

        <Route path="/login" element={<LoginPage />} />

        <Route path="/changepassword" element={<PasswordChange />} />
        <Route
          exact
          path="/changepasswordMobile"
          element={<PasswordChangeMobile />}
        />

        <Route path="/resetPassword" element={<ResetPassword />} />

        <Route path="/signup" element={<Signup />} />
        <Route path="/verification" element={<Verification />} />

        <Route path="/FlightDetails" element={<FlightDetails />} />
        <Route path="/Passengers" element={<PassengersFunctional />} />
        <Route path="/pay" element={<Pay />} />
        <Route path="/mybookings" element={<MyBookingsFunctional />} />

        <Route path="/Summary" element={<SummaryPage />} />
      </Routes>
    </div>
  );
}

export default FlightNavigation;
