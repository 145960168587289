import React from "react";
import { Button } from "@material-ui/core";

function SearchFlightButton({onSubmit,classes}) {
  return (
    
    <Button
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "0.75rem 6rem",
        color:"#fff",
        flexShrink: "0",
        borderRadius: "10px",
        background: "#5F41B2",
        fontFamily: "Inter",
        fontSize: "16px",
        fontWeight: "500",
        lineHeight: "normal",
        height: "fit-content",
      }}
      className={classes.button}
      onClick={onSubmit}
>      
        Search Flight
    </Button>

   
  );
}

export default SearchFlightButton;
