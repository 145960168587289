import React, { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Slide from "@material-ui/core/Slide";
import SmsFailedIcon from "@mui/icons-material/SmsFailed";
import { Avatar, Button } from "@mui/material";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Popoverhotels({ isOpen, setDialogOpen, msg, error }) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  return (
    <div>
      {isOpen ? (
        <div>
          <Dialog
            open={isOpen}
            onClose={() => setDialogOpen((prev) => !prev)}
            TransitionComponent={Transition}
            keepMounted
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <div style={{ padding: 40 }}>
              {error ? (
                <DialogContent style={{ textAlign: "center" }}>
                  <DialogContentText
                    id="alert-dialog-description"
                    style={{ justifyContent: "center" }}
                  >
                    <SmsFailedIcon
                      htmlColor="#FF0000"
                      style={{ justifyContent: "center", fontSize: "5rem" }}
                    />
                  </DialogContentText>
                  <DialogContentText id="alert-dialog-description">
                    <span style={{ color: "#FF0000" }}>Oops...</span>
                  </DialogContentText>
                  <DialogContentText id="alert-dialog-description">
                    <span style={{ color: "grey" }}> {msg}</span>
                  </DialogContentText>
                </DialogContent>
              ) : (
                <DialogContent style={{ textAlign: "center" }}>
                  <DialogContentText
                    id="alert-dialog-description"
                    style={{ justifyContent: "center" }}
                  >
                    <Icon
                      icon="clarity:success-standard-line"
                      color="#32b54e"
                      style={{ justifyContent: "center", fontSize: "5rem" }}
                    />
                  </DialogContentText>
                  <DialogContentText id="alert-dialog-description">
                    <span style={{ color: "#32b54e" }}> {msg}</span>
                  </DialogContentText>
                </DialogContent>
              )}
              <DialogContentText
                id="alert-dialog-description"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Button
                  onClick={() => {
                    setDialogOpen(!isOpen);
                  }}
                >
                  Close
                </Button>
              </DialogContentText>
            </div>
          </Dialog>
        </div>
      ) : null}
    </div>
  );
}
