import { Card, Divider, Input } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import data from "airportsjs/airports.json";
import "./dateSelector.css";
import { useRef } from "react";
import LocationIcon from "../../../../assets/img/location.png";
import { makeStyles } from "@mui/styles";
import store from "store";

const useStyles = makeStyles({
  placeholder: {
    textAlign: "left !important",
    fontFamily: "Rubik !important",
    fontStyle: "normal",
    fontWeight: "400",
    backgroundColor: "white",
    width: " 14vw",
    height: "1.8vw",
    paddingLeft: "0px",
    borderRadius: "15px",
    border: "0px solid #ffffff",
    "&::placeholder": {
      color: "blue",
    },
  },
});

const style = {
  "input-label": {
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    width: "100%",
    color: "red",
  },

  input: {},
};

export default function Dropdown() {
  const [countries, setCountries] = React.useState([]);
  const [countryMatch, setCountryMatch] = useState([]);
  const [placeholder, setPlaceholder] = useState("Where to ?");
  const [typing, setTyping] = useState(false);
  const [selected, setSelected] = useState("");

  const btnRef = useRef(null);
  const styles = useStyles();

  const loadCountries = async () => {
    setCountries(data);
    setCountryMatch(data);
  };

  useEffect(() => {
    loadCountries();
  }, []);

  useEffect(() => {
    const closeDropdown = (e) => {};

    document.body.addEventListener("click", closeDropdown, true);

    return () => document.body.removeEventListener("click", closeDropdown);
  }, []);

  const searchCountries = (text) => {
    setTyping(true);
    let matches = countries.filter((country) => {
      const regex = new RegExp(`${text}`, "gi");
      return country.country.match(regex) || country.iata.match(regex);
    });
    setCountryMatch(matches);
  };

  const handleSelect = (e, index) => {
    setTyping(false);
    console.log(data[index]);
    setPlaceholder(data[index].city + ", " + data[index].country);
    setSelected(data[index].city + ", " + data[index].country);
    store.dispatch({
      type: "setSearchPlace",
      payload: data[index],
    });
  };

  return (
    <div>
      {typing === false ? (
        <Input
          disableUnderline
          placeholder={placeholder}
          onClick={(e) => searchCountries(e.target.value)}
        />
      ) : (
        <div></div>
      )}

      <>
        {typing ? (
          <div
            ref={btnRef}
            style={{
              maxHeight: "20vw",
              width: "20vw",
              position: "relative",
              right: "2.1vw",
              bottom: "0.1vw",
              right: "3vw",
              overflowY: "scroll",
              zIndex: "9",
              borderBlockStart: "15px",
              borderBottomLeftRadius: "15px",
              borderBottomRightRadius: "15px",
              borderTopLeftRadius: "15px",
              borderTopRightRadius: "15px",
              border: "1px solid #C4C4C4",
              boxShadow: "0px 2px 8px rgba(38, 36, 131, 0.1)",
            }}
          >
            <Card
              sx={{
                width: "20vw",
                height: "5vw",
                textAlign: "left ",
                paddingLeft: "1vw",
                borderRadius: "0px !important",
                fontSize: "16px",
                lineHeight: "2vw",
                borderBottom: "0.5px solid #C4C4C4",
                display: "flex",
              }}
              title={placeholder}
            >
              <img
                style={{
                  marginTop: "1.5vw",
                  width: "1.6vw",
                  height: "1.6vw",
                }}
                src={LocationIcon}
              />
              <Input
                disableUnderline
                sx={{
                  backgroundColor: "white",
                  width: " 17.2vw",
                  height: "5vw",
                  paddingLeft: "0.5vw !important",
                  paddingBottom: "0.3vw !important",
                  borderRadius: "10px",
                  alignContent: "center",
                  justifyContent: "center",
                  textAlign: "left !important",
                  fontFamily: "Rubik",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "16px",
                  color: "#5D5C66",
                  border: "0px solid #ffffff",
                }}
                placeholder={placeholder}
                onChange={(e) => searchCountries(e.target.value)}
              />
            </Card>
            {countryMatch &&
              countryMatch.map((item, index) => (
                <div key={index}>
                  <Card
                    sx={{
                      width: "20vw",
                      height: "5vw",
                      textAlign: "left",
                      paddingLeft: "1vw",
                      paddingTop: "1vw",
                      borderRadius: "0px !important",
                      lineHeight: "2vw",
                      borderBottom: "0.5px solid #C4C4C4",
                      "&:hover": {
                        background: "#E7E5F6",
                        color: "#5F41B3",
                        "& p": {
                          color: "#5F41B3",
                          fontWeight: "500",
                        },
                      },
                    }}
                    title={item.country}
                    onClick={(e) => {
                      handleSelect(e, index);
                    }}
                  >
                    <p
                      style={{
                        fontFamily: "Rubik",
                        fontSize: "16px",
                        color: "#5F41B3",
                      }}
                    >
                      {item.city}
                    </p>

                    <div
                      style={{
                        color: "#5F41B3",
                        fontFamily: "Rubik",
                        fontSize: "16px",
                      }}
                    >
                      {" "}
                      {item.country}
                    </div>
                  </Card>
                </div>
              ))}
          </div>
        ) : (
          <div></div>
        )}
      </>
    </div>
  );
}
