import { CardContent, Container, Grid } from "@material-ui/core";
import React from "react";
import { useNavigate } from "react-router-dom";

const Theme = () => {
  let history = useNavigate();

  return (
    <Container className="d-flex justify-content-center">
      <div>
        <div className="d-flex justify-content-center mt-5">
          <h1
            className="h1"
            style={{
              backgroundColor: "#9c27b0",
              padding: 40,
              borderRadius: 10,
              color: "white",
              fontFamily: "Cabin",
              marginBottom: "7%",
            }}
          >
            Theme
          </h1>
        </div>
        <div className="loading"></div>

        <Grid container spacing={2} className="mt-3 mb-5">
          <Grid item xs={12} sm={12}>
            <div
              style={{
                backgroundColor: "whitesmoke",
                padding: "5%",
                boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
              }}
            >
              <img
                src="https://dummyimage.com/450x300/dee2e6/6c757d.jpg"
                alt="..."
                width="100%"
                height="100%"
              />

              <div>
                <div>
                  <h5>
                    BookMyTrip - Web UI Kit Traveling Apps by ilhammeidi in
                    Travel Super Clear and Clean Layout. 70+ elaborate high
                    quality iOS screensFully Vector Icons
                  </h5>
                  GBP 10
                </div>
              </div>

              <div>
                <div>
                  <button
                    className="btn btn-outline-dark mt-auto"
                    onClick={() => history("/theme1Details")}
                  >
                    View Details
                  </button>
                </div>
              </div>
            </div>
          </Grid>

          <Grid item xs={12} sm={12}>
            <div
              style={{
                backgroundColor: "whitesmoke",
                padding: "5%",
                boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
              }}
            >
              <img
                src="https://dummyimage.com/450x300/dee2e6/6c757d.jpg"
                alt="..."
                width="100%"
                height="100%"
              />

              <div>
                <div>
                  <h5>
                    BookMyTrip - Web UI Kit Traveling Apps by ilhammeidi in
                    Travel Super Clear and Clean Layout. 70+ elaborate high
                    quality iOS screensFully Vector Icons
                  </h5>
                  GBP 10
                </div>
              </div>

              <div>
                <div>
                  <button
                    className="btn btn-outline-dark mt-auto"
                    onClick={() => history("/theme2Details")}
                  >
                    View Details
                  </button>
                </div>
              </div>
            </div>
          </Grid>

          <Grid item xs={12} sm={12}>
            <div
              style={{
                backgroundColor: "whitesmoke",
                padding: "5%",
                boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
              }}
            >
              <img
                src="https://dummyimage.com/450x300/dee2e6/6c757d.jpg"
                alt="..."
                width="100%"
                height="100%"
              />

              <div>
                <div>
                  <h5>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry’s
                    standard dummy text ever since the 1500s,
                  </h5>
                  GBP 10
                </div>
              </div>

              <div>
                <div>
                  <button
                    className="btn btn-outline-dark mt-auto"
                    onClick={() => history("/theme3Details")}
                  >
                    View Details
                  </button>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </Container>
  );
};

export default Theme;
