import {
  Card,
  CardContent,
  Grid,
  Typography,
  CardMedia,
  Button,
  Divider,
  Box,
} from "@mui/material";
import React, { Component } from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useNavigate } from "react-router-dom";

import "@fontsource/rubik/600.css";
import "@fontsource/rubik/500.css";

import "@fontsource/rubik/400.css";
import { ReactComponent as Plus } from "../../assets/svgs/add-circle.svg";
import store from "store";
import moment from "moment";
import CategoryIcon from "@mui/icons-material/Category";
import PersonIcon from "@mui/icons-material/Person";
import "./styles.css";
export default function RoomCard({
  roomDetails,
  roomGeneralDetails,
  roomImage,
}) {
  let { checkInDate, checkOutDate } = store.getState().hotelSearch;
  let navigate = useNavigate();
  let duration = moment(checkOutDate).diff(checkInDate, "days");
  const [value, setValue] = React.useState(false);
  const [rateKey, setRateKey] = React.useState("");

  return (
    <Grid item sm={12} xs={12} sx={{ pt: 2 }}>
      <Card
        style={{
          borderRadius: 20,
          marginTop: 10,
          border: "1px solid #C4C4C4",
          boxShadow: "0px 2px 8px rgba(38,36,131,0.25)",
        }}
      >
        <Grid container>
          <Grid item md={4} sm={4} xs={4}>
            <CardMedia
              component="img"
              style={{ objectFit: "fill", height: "100%" }}
              image={
                roomImage
                  ? `http://photos.hotelbeds.com/giata/bigger/${roomImage.path}`
                  : "https://www.gannett-cdn.com/-mm-/05b227ad5b8ad4e9dcb53af4f31d7fbdb7fa901b/c=0-64-2119-1259/local/-/media/USATODAY/USATODAY/2014/08/13/1407953244000-177513283.jpg"
              }
            />
          </Grid>
          <Grid item md={0.2} />
          <Grid item md={7.6} sm={7.6} xs={7.6} sx={{ pt: 2 }}>
            <Grid container>
              <Grid item md={0.2} sm={0.2} xs={0.2} />
              <Grid item md={10} sm={10} xs={10}>
                <Typography
                  style={{
                    textAlign: "left",
                    fontFamily: "Rubik",
                    fontStyle: "normal",
                    fontWeight: 600,
                    color: "#362566",
                  }}
                  variant="h5"
                >
                  {roomDetails.name}
                </Typography>
              </Grid>
              <Grid
                item
                md={1.3}
                sm={1.8}
                xs={12}
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                {[...Array(roomDetails.rates[0].adults)].map((item, index) => {
                  return <PersonIcon key={index} htmlColor="#5F41B3" />;
                })}
              </Grid>
            </Grid>
            <Grid container sx={{ pt: 1 }}>
              {roomGeneralDetails.roomFacilities?.map((item, index) => {
                return (
                  <Grid
                    key={index}
                    item
                    md={5.5}
                    sm={12}
                    xs={12}
                    sx={{ display: "flex", ml: 1, pb: 0.5 }}
                  >
                    <CategoryIcon htmlColor="#5F41B3" />
                    <Typography
                      sx={{
                        ml: 1,
                        fontFamily: "Rubik",
                        fontStyle: "normal",
                        fontWeight: 400,
                        color: "#707070",
                      }}
                    >
                      {item.description.content +
                        ` ${item.number ? item.number : ""}`}
                    </Typography>
                  </Grid>
                );
              })}
            </Grid>
            <Grid container>
              <Grid item md={0.2} sm={0.2} xs={0.2} />

              <Grid item sx={{ ml: 0.2 }}>
                <Plus transform="scale(1.5)" />
              </Grid>
              <Grid item>
                <Typography
                  sx={{
                    ml: 1,
                    fontFamily: "Rubik",
                    fontStyle: "normal",
                    fontWeight: 400,
                    color: "#5F41B3",
                  }}
                >
                  See all room amenities
                </Typography>
              </Grid>
            </Grid>
            <Grid item md={11.5}>
              <hr />
            </Grid>

            <Grid container>
              <Grid item md={0.2} sm={0.2} xs={0.2} />
              <Grid item>
                <Typography
                  sx={{
                    fontFamily: "Rubik",
                    fontStyle: "normal",
                    fontWeight: 500,
                    color: "#5F41B3",
                    fontSize: "130%",
                  }}
                  variant="h5"
                >
                  Choose meal plan
                </Typography>
              </Grid>
            </Grid>

            <Grid Container>
              <Grid item md={12} sx={{ ml: "1.5%" }}>
                <RadioGroup>
                  {roomDetails.rates.map((item, index) => {
                    return (
                      <FormControlLabel
                        key={index}
                        value={item.boardName}
                        onClick={() => {
                          setValue(index);
                          setRateKey(item.rateKey);
                          store.dispatch({
                            type: "setNewPrice",
                            payload: item.net,
                          });
                        }}
                        checked={index == value ? true : false}
                        control={<Radio style={{ color: "#5F41B3" }} />}
                        label={
                          <Typography
                            style={{
                              fontFamily: "Rubik",
                              fontStyle: "normal",
                              fontWeight: 400,
                              color: "#5D5C66",
                            }}
                          >
                            {item.boardName +
                              " book for : " +
                              store.getState().hotelSearch.hotel.currency +
                              " " +
                              item.net}
                          </Typography>
                        }
                      />
                    );
                  })}
                </RadioGroup>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={0.4} sm={0.4} xs={0.4} />
        </Grid>
        <Divider />
        <Grid container>
          <Grid item md={0.5} sm={0.5} xs={0.5} />
          <Grid item md={6} sm={1} xs={1}>
            <Grid item sx={{ display: "flex", pt: 1 }}>
              <Typography
                sx={{
                  color: "#5F41B2",
                  fontFamily: "Rubik",
                  fontStyle: "normal",
                  fontWeight: 600,
                }}
                variant="h5"
              >
                {`${store.getState().client.client.currency} ${
                  roomDetails.rates[0].net
                }`}
              </Typography>
              <Typography
                sx={{
                  color: "#5D5C66",
                  fontFamily: "Rubik",
                  fontStyle: "normal",
                  fontWeight: 400,
                  fontSize: "120%",
                  ml: 0.5,
                }}
              >
                {`/${duration} ${duration > 1 ? "nights" : "night"}`}
              </Typography>
            </Grid>
            <Grid item md={12} sm={12} xs={12} sx={{ pb: 1 }}>
              <Typography
                sx={{
                  textAlign: "left",
                  color: "#5D5C66",
                  fontFamily: "Rubik",
                  fontStyle: "normal",
                  fontWeight: 400,
                  color: "#362566",
                }}
              >
                {roomDetails.taxes
                  ? `+ ${roomDetails.taxes} taxes and charges`
                  : null}
              </Typography>
            </Grid>
          </Grid>
          <Grid item md={2.2} sm={2.2} xs={2.2} />
          <Grid item md={2.8} sm={12} xs={12} sx={{ pb: 1, pt: 1 }}>
            <Button
              onClick={() => {
                if (rateKey == "") {
                  store.dispatch({
                    type: "setRateKey",
                    payload: roomDetails.rates[0].rateKey,
                  });
                  navigate("/hotelPayment");
                } else {
                  store.dispatch({
                    type: "setRateKey",
                    payload: rateKey,
                  });
                  navigate("/hotelPayment");
                }
              }}
              sx={{ width: "100%", pt: 1, pb: 1, mt: 1 }}
              size="medium"
              variant="filled"
              style={{
                backgroundColor: "#5F41B3",
                borderRadius: 10,
              }}
            >
              <Typography
                style={{
                  fontFamily: "Inter",
                  fontStyle: "normal",
                  fontWeight: 500,
                  fontSize: 16,
                  color: "white",
                  textTransform: "none",
                }}
              >
                Reserve rooms
              </Typography>
            </Button>
          </Grid>
        </Grid>
      </Card>
    </Grid>
  );
}
