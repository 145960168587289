
   
  
import React from "react";
import store from "store"
import Grid from "@mui/material/Grid";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { Button } from "reactstrap";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
function MoreInfo({expand,handleExpand,classes}) {
  return (  
    <GridContainer>
    <Grid container>
      <GridItem style={{ margin: "8px 0" }}>
        <Button
          style={{
             backgroundColor: "#5F41B2",
             color:"white",
                      borderRadius:"15px", border:"1px solid", height:"50px",
                      width:"100px",
            flexDirection: "row",
          }}
          className={classes.button}
          onClick={handleExpand}
        >
          <div style={{ flexDirection: "row", flex: 1 }}>
            More Info
            {expand ? (
              <ExpandLessIcon />
            ) : (
              <ExpandMoreIcon />
            )}
          </div>
        </Button>
      </GridItem>
    </Grid>
  </GridContainer>
  );
}

export default MoreInfo;
