import React, { useEffect, useState } from "react";
import Footer from "views/Hotels/Components/Footer";

import Navbar from "views/Hotels/Components/NavBar";
import "@fontsource/inter/600.css";
import { getUserBookings } from "views/Services/clientServices";
import TrolliiOptions from "./TrolliiOptions";
import TimingNavbar from "./TimingNavbar";
import Bookings from "./Bookings";
import BookingAnimation from "components/CardAnimations/book";
export default function MyBookings() {
  const [action, setAction] = useState("Hotels");
  const [TimingOption, setTimingOption] = useState("Active");
  const [loading, setLoading] = useState(true);
  const [bookingsList, setBookingsList] = useState(undefined);

  useEffect(() => {
    const getBookings = async () => {
      try {
        const bookings = await getUserBookings();
        setBookingsList(bookings);
        setLoading(false);
      } catch (e) {
        console.log(e);
      }
    };
    getBookings();
  }, []);
  if (loading && !bookingsList) return <BookingAnimation />;
  else
    return (
      <div style={{ backgroundColor: "white", minHeight: "100vh" }}>
        <Navbar />
        <TrolliiOptions setAction={setAction} action={action} />
        <TimingNavbar setTimingOption={setTimingOption} />
        {TimingOption == "Active" ? (
          <Bookings bookings={bookingsList.activeBookings} />
        ) : TimingOption == "Past" ? (
          <Bookings bookings={bookingsList.pastBookings} />
        ) : null}
        <div
          style={{
            position: "fixed",
            bottom: 0,
            width: "100%",
          }}
        >
          <Footer />
        </div>
      </div>
    );
}
