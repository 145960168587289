import React from "react";
import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";
import MyGreatPlace from "./PlaceOnMap";
const AnyReactComponent = ({ text }) => <h1>{text}</h1>;

function MyComponent({ long, lat, width, height }) {
  const containerStyle = {
    width: width,
    height: height,
  };
  const center = {
    lat: parseFloat(lat),
    lng: parseFloat(long),
  };

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "",
  });

  const [map, setMap] = React.useState(null);

  const onLoad = React.useCallback(function callback(map) {
    // This is just an example of getting and using the map instance!!! don't just blindly copy!
    const bounds = new window.google.maps.LatLngBounds(center);
    map.fitBounds(bounds);

    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center}
      zoom={-5}
      onLoad={onLoad}
      onUnmount={onUnmount}
    >
      <MyGreatPlace
        lat={center.lat}
        lng={center.long}
        text={"Hotel"} /* Kreyser Avrora */
      />
    </GoogleMap>
  ) : (
    <></>
  );
}

export default React.memo(MyComponent);
