import Axios from "axios";
import hosts from "../../constants/hosts";

export async function connectInstagramAdminPanel(code, domain) {
  let url = `${hosts}/travelAgency/connectInstagramWeb`;
  let response = await Axios.post(url, { code: code, domain: domain });
  if (response) {
    let info = response.data;
    return info;
  }
}
export async function viewbookings(timeFilter) {
  let config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("agencyToken")}`,
    },
  };

  let url = `${hosts}/travelAgency/viewbookings?timeFilter=${timeFilter}`;
  let response = await Axios.get(url, config);
  if (response) {
    let info = response.data;

    return info;
  }
}
export async function viewMarkups() {
  let config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("agencyToken")}`,
    },
  };

  let url = `${hosts}/travelAgency/markupSummary`;
  let response = await Axios.get(url, config);
  if (response) {
    let info = response.data;

    return info;
  }
}
export async function deleteMarkup(body) {
  let config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("agencyToken")}`,
    },
  };

  let url = `${hosts}/travelAgency/deleteMarkup`;
  let response = await Axios.post(url, body, config);
  if (response) {
    let info = response.data;

    return info;
  }
}
export async function getBookings(timeFilter, agencyUrl) {
  let config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("agencyToken")}`,
    },
  };

  let url = `${hosts}/travelAgency/getBookings?timeFilter=${timeFilter}`;
  let response = await Axios.post(
    url,
    { timeFilter: timeFilter, agencyUrl: agencyUrl },
    config
  );
  if (response) {
    let info = response.data;

    return info;
  }
}
export async function getDiscounts(body) {
  let config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("agencyToken")}`,
    },
  };

  let url = `${hosts}/travelAgency/discountSummary`;
  let response = await Axios.post(url, body, config);
  if (response) {
    let info = response.data;

    return info;
  }
}
export async function getHotelSearches(timeFilter, agencyUrl, page, pageSize) {
  let config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("agencyToken")}`,
    },
  };

  let url = `${hosts}/travelAgency/viewHotelSearches?timeFilter=${timeFilter}`;
  let response = await Axios.post(url, { page, pageSize }, config);
  if (response) {
    let info = response.data;

    return info;
  }
}
export async function getHotelAbandoned(timeFilter, agencyUrl, page, pageSize) {
  let config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("agencyToken")}`,
    },
  };

  let url = `${hosts}/travelAgency/viewHotelAbandoned`;
  let response = await Axios.post(url, { page, pageSize }, config);
  if (response) {
    let info = response.data;

    return info;
  }
}
export async function pauseSite() {
  let config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("agencyToken")}`,
    },
  };

  let url = `${hosts}/travelAgency/pauseSite`;
  let response = await Axios.post(url, {}, config);
  if (response) {
    let info = response.data;

    return info;
  }
}
export async function retrieveWebsite() {
  let config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("agencyToken")}`,
    },
  };

  let url = `${hosts}/travelAgency/retrieveWebsite`;
  let response = await Axios.post(url, {}, config);
  if (response) {
    let info = response.data;

    return info;
  }
}
export async function addPaymentMethod(body) {
  let config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("agencyToken")}`,
    },
  };

  let url = `${hosts}/travelAgency/addpaymentmethod`;
  let response = await Axios.post(url, body, config);
  if (response) {
    let info = response.data;

    return info;
  }
}
export async function addStaff(body) {
  let config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("agencyToken")}`,
    },
  };

  let url = `${hosts}/travelAgency/addStaff`;
  let response = await Axios.post(url, body, config);
  if (response) {
    let info = response.data;

    return info;
  }
}
export async function viewStaff(body) {
  let config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("agencyToken")}`,
    },
  };

  let url = `${hosts}/travelAgency/viewStaff`;
  let response = await Axios.post(url, body, config);
  if (response) {
    let info = response.data;

    return info;
  }
}
export async function deleteStaff(body) {
  let config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("agencyToken")}`,
    },
  };

  let url = `${hosts}/travelAgency/deleteStaff`;
  let response = await Axios.post(url, body, config);
  if (response) {
    let info = response.data;

    return info;
  }
}

export async function addPaymentGateway(body) {
  let config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("agencyToken")}`,
    },
  };

  let url = `${hosts}/travelAgency/paymentgateway`;
  let response = await Axios.post(url, body, config);
  if (response) {
    let info = response.data;

    return info;
  }
}
export async function deleteSite() {
  let config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("agencyToken")}`,
    },
  };

  let url = `${hosts}/travelAgency/deleteSite`;
  let response = await Axios.delete(url, config);
  if (response) {
    let info = response.data;

    return info;
  }
}
export async function addSubscription(body) {
  let config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("agencyToken")}`,
    },
  };

  let url = `${hosts}/travelAgency/addSubscription`;
  let response = await Axios.post(url, body, config);
  if (response) {
    let info = response.data;

    return info;
  }
}
export async function addMarketPlace(body) {
  let config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("agencyToken")}`,
    },
  };

  let url = `${hosts}/travelAgency/addMarketPlace`;
  let response = await Axios.post(url, body, config);
  if (response) {
    let info = response.data;

    return info;
  }
}
export async function viewAbandonedFlights(timeFilter, agencyUrl) {
  let config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("agencyToken")}`,
    },
  };

  let url = `${hosts}/travelAgency/viewabandonedCarts?timeFilter=${timeFilter}`;
  let response = await Axios.get(url, config);
  if (response) {
    let info = response.data;

    return info;
  }
}
export async function viewAbandonedHotels(timeFilter, agencyUrl) {
  let config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("agencyToken")}`,
    },
  };

  let url = `${hosts}/travelAgency/viewAbandonedHotels?timeFilter=${timeFilter}`;
  let response = await Axios.get(url, config);
  if (response) {
    let info = response.data;

    return info;
  }
}
export async function getHotelBooking(timeFilter, agencyUrl, page, pageSize) {
  let config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("agencyToken")}`,
    },
  };

  let url = `${hosts}/travelAgency/getHotelBookings?timeFilter=${timeFilter}`;
  let response = await Axios.post(
    url,
    {
      timeFilter: timeFilter,
      agencyUrl: agencyUrl,
      page: page,
      pageSize: pageSize,
    },
    config
  );
  if (response) {
    let info = response.data;

    return info;
  }
}
export async function singleFileUploadHandler(file) {
  const data = new FormData();
  let config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("agencyToken")}`,
    },
  };
  // If file selected
  if (file) {
    data.append("logo", file, file.name);
    let response = await Axios.post(
      `${hosts}/travelAgency/uploadlogo`,
      data,
      config
    );
    if (response) {
      let info = response.data;

      return info;
    }
  }
}
export async function backgroundUploadHandler(file) {
  const data = new FormData();
  let config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("agencyToken")}`,
    },
  };
  // If file selected
  if (file) {
    data.append("logo", file, file.name);
    let response = await Axios.post(
      `${hosts}/travelAgency/uploadlogo`,
      data,
      config
    );
    if (response) {
      let info = response.data;

      return info;
    }
  }
}

export async function addDiscount(reqBody) {
  let config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("agencyToken")}`,
    },
  };

  let url = `${hosts}/travelAgency/discount`;

  let response = await Axios.post(url, reqBody, config);
  if (response) {
    let info = response.data;

    return info;
  }
}
export async function getAgencyServices(reqBody) {
  let config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("agencyToken")}`,
    },
  };

  let url = `${hosts}/travelAgency/getServices`;
  let response = await Axios.post(url, reqBody, config);
  if (response) {
    let info = response.data;

    return info;
  }
}
export async function subscripeService(reqBody) {
  let config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("agencyToken")}`,
    },
  };

  let url = `${hosts}/travelAgency/subscripe-service`;
  let response = await Axios.post(url, reqBody, config);
  if (response) {
    let info = response.data;

    return info;
  }
}
export async function uploadHomeImages(file) {
  let config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("agencyToken")}`,
    },
  };
  const data = new FormData();
  data.append("image", file, file.name);

  let url = `${hosts}/travelAgency/uploadHomeImages`;
  let response = await Axios.post(url, data, config);
  if (response) {
    let info = response.data;

    return info;
  }
}
export async function getPixabay() {
  let config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("agencyToken")}`,
    },
  };

  let url = `${hosts}/travelAgency/getPixabay`;
  let response = await Axios.get(url, config);
  if (response) {
    let info = response.data;

    return info;
  }
}
export async function uploadPixabay(data) {
  let config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("agencyToken")}`,
    },
  };

  let url = `${hosts}/travelagency/uploadPixabay`;
  let response = await Axios.post(url, data, config);
  if (response) {
    let info = response.data;

    return info;
  }
}
export async function deletePixabay(data) {
  let config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("agencyToken")}`,
    },
  };

  let url = `${hosts}/travelagency/deletePixabay`;
  let response = await Axios.post(url, data, config);
  if (response) {
    let info = response.data;

    return info;
  }
}
export async function connectInstagram(data) {
  let config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("agencyToken")}`,
    },
  };

  let url = `${hosts}/travelAgency/connectInstagram`;
  let response = await Axios.post(url, data, config);
  return response;
}
export async function AddFAQ(body) {
  let config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("agencyToken")}`,
    },
  };

  let url = `${hosts}/travelAgency/addingFAQ`;
  let response = await Axios.post(url, body, config);
  if (response) {
    let info = response.data;

    return info;
  }
}
export async function editFAQ(body) {
  let config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("agencyToken")}`,
    },
  };

  let url = `${hosts}/travelAgency/editFAQ`;
  let response = await Axios.patch(url, body, config);
  if (response) {
    let info = response.data;

    return info;
  }
}
export async function removeFAQ(body) {
  let config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("agencyToken")}`,
    },
  };

  let url = `${hosts}/travelAgency/removeFAQ`;
  let response = await Axios.post(url, body, config);
  if (response) {
    let info = response.data;

    return info;
  }
}
export async function chooseColor(body) {
  let config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("agencyToken")}`,
    },
  };

  let url = `${hosts}/travelAgency/chooseColor`;
  let response = await Axios.post(url, body, config);
  if (response) {
    let info = response.data;

    return info;
  }
}
export async function Chatpopup(body) {
  let config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("agencyToken")}`,
    },
  };

  let url = `${hosts}/travelAgency/Chatpopup`;
  let response = await Axios.post(url, body, config);
  if (response) {
    let info = response.data;

    return info;
  }
}
export async function Analytics(body) {
  let config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("agencyToken")}`,
    },
  };

  let url = `${hosts}/travelAgency/Anayltics`;
  let response = await Axios.post(url, body, config);
  if (response) {
    let info = response.data;

    return info;
  }
}
export async function Hotjar(body) {
  let config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("agencyToken")}`,
    },
  };

  let url = `${hosts}/travelAgency/Hotjar`;
  let response = await Axios.post(url, body, config);
  if (response) {
    let info = response.data;

    return info;
  }
}
export async function FacebookSnippet(body) {
  let config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("agencyToken")}`,
    },
  };

  let url = `${hosts}/travelAgency/Facebook`;
  let response = await Axios.post(url, body, config);
  if (response) {
    let info = response.data;

    return info;
  }
}
export async function editDomain(body) {
  let config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("agencyToken")}`,
    },
  };

  let url = `${hosts}/travelAgency/editDomain`;
  let response = await Axios.patch(url, body, config);
  if (response) {
    let info = response.data;

    return info;
  }
}
export async function getSnippet(body) {
  let config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("agencyToken")}`,
    },
  };

  let url = `${hosts}/travelAgency/snippets`;
  let response = await Axios.get(url, config);
  if (response) {
    let info = response.data;

    return info;
  }
}
export async function getAnalytics(body) {
  let config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("agencyToken")}`,
    },
  };

  let url = `${hosts}/travelAgency/analytics`;
  let response = await Axios.get(url, config);
  if (response) {
    let info = response.data;

    return info;
  }
}
export async function getFAQs() {
  let config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("agencyToken")}`,
    },
  };

  let url = `${hosts}/travelAgency/getFAQs`;
  let response = await Axios.get(url, config);
  if (response) {
    let info = response.data;

    return info;
  }
}

export async function forgotPassword(reqBody) {
  let url = `${hosts}/travelAgency/forgotPassword`;

  let response = await Axios.post(url, reqBody);
  if (response) {
    let info = response.data;
    return info;
  }
}

export async function getPrivateInfo() {
  let config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("agencyToken")}`,
    },
  };

  let url = `${hosts}/travelAgency/privateInfo`;
  let response = await Axios.get(url, config);
  if (response) {
    let info = response.data;

    return info;
  }
}
export async function getPublicInfo() {
  let config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("agencyToken")}`,
    },
  };

  let url = `${hosts}/travelAgency/publicInfo`;
  let response = await Axios.get(url, config);
  if (response) {
    let info = response.data;

    return info;
  }
}
export async function updatePrivateInfo(body) {
  let config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("agencyToken")}`,
    },
  };

  let url = `${hosts}/travelAgency/privateInfo`;
  let response = await Axios.post(url, body, config);
  if (response) {
    let info = response.data;

    return info;
  }
}
export async function updatePublicInfo(body) {
  let config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("agencyToken")}`,
    },
  };

  let url = `${hosts}/travelAgency/publicInfo`;
  let response = await Axios.post(url, body, config);
  if (response) {
    let info = response.data;

    return info;
  }
}
