import React from "react";
import SearchFlightButton from "./SearchFlightButton";
import GridItem from "components/Grid/GridItem.js";
import Children from "./Children";
import CabinClasses from "./CabinClasses";
import GridContainer from "components/Grid/GridContainer";

function SearchEntries({handleCabinChange,onSubmit ,classes,cabinClass,state,selectAge,removeAdults,addAdults,addChild,removeChild}) {
  return (  
  
    <GridItem xs={12} style={{ paddingBottom: "10px" }}>
    <GridContainer>
  
      <Children
        state={state}
        classes={classes}
        selectAge={selectAge}
        removeAdults={removeAdults}
        addAdults={addAdults}
        addChild={addChild}
        removeChild={removeChild}
      />
      <SearchFlightButton
        onSubmit={onSubmit}
        classes={classes}
      />
    </GridContainer>
  </GridItem>
   
  );
}

export default SearchEntries;
