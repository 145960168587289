import React, { Component } from "react";
import Typography from "@mui/material/Typography";
import Styles from "../LandingPage/Styles";
import { Grid } from "@material-ui/core";
import store from "store";
export default function LandingText() {
  const name = store
    .getState()
    .AgencyReducer.agency.url.substring(
      0,
      store.getState().AgencyReducer.agency.url.indexOf(".")
    );

  return (
    <Grid container style={{ paddingBottom: "4%", paddingTop: "2%" }}>
      <Grid item xs={1} sm={1} md={1} />

      <Grid
        item
        xs={11}
        sm={11}
        md={11}
        style={{ alignItems: "flex-start", display: "flex" }}
      >
        <Typography variant="h2" style={Styles.LandingText}>
          Up for a Travel ?
        </Typography>
      </Grid>
      <Grid item xs={1} sm={1} md={1} />

      <Grid
        item
        xs={10}
        sm={10}
        md={10}
        style={{ alignItems: "flex-start", display: "flex" }}
      >
        <Typography
          variant="h4"
          style={Styles.LandingText}
        >{`Use ${"Trollii"} To search for the cheapest hotel deal for all major destinations around the world`}</Typography>
      </Grid>
    </Grid>
  );
}
