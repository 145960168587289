import { Box, Typography } from "@material-ui/core";
import { Grid, useTheme, useMediaQuery } from "@mui/material";

import React, { Component } from "react";
import "@fontsource/rubik/500.css";
import "@fontsource/rubik/400.css";
import "@fontsource/inter/500.css";
import store from "store";
import "@fontsource/rubik/600.css";

import "@fontsource/fredoka/600.css";

import "./styles.css";
import { signUpService } from "views/Services/clientServices";
import VerficationPopover from "views/Hotels/Components/VerficationPopover";
export default function SignUp({ setSignInStatus, signInStatus }) {
  const [Photos, setPhotos] = React.useState([
    {
      text:
        "Search Millions of Routes & Find The Best Travel Deals around the world",
      path: "./Rectangle.jpg",
    },
    {
      text: "Get The Best Deals for Your Next Hotel Booking with few steps.",
      path: "./slide2.jpg",
    },
    {
      text: "Discover the most beautiful places to travel around the world.",
      path: "./slide3.jpg",
    },
  ]);
  const { useState } = React;
  const [inputtext, setinputtext] = useState({
    name: "",
    email: "",
    password: "",
  });

  const [verficationCode, setVerficationCode] = React.useState(false);

  const [animation, setAnimation] = React.useState(false);

  const [verificationModal, setVerficationModal] = React.useState(false);
  const [warnemail, setwarnemail] = useState(false);
  let [counter, setCounter] = React.useState(0);
  const [isActive, setIsActive] = React.useState(false);

  const [warnpassword, setwarnpassword] = useState(false);

  const [eye, seteye] = useState(true);
  const [password, setpassword] = useState("password");
  const [type, settype] = useState(false);
  function animationVisible(mode, verificationModal, loginModal) {
    if (loginModal) {
      setDialogOpen(false);
      setAnimation(mode);
    }
    if (verificationModal) {
      setAnimation(false);
      setVerficationModal(false);
    } else {
      setAnimation(mode);
    }
  }
  const inputEvent = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setinputtext((lastValue) => {
      return {
        ...lastValue,
        [name]: value,
      };
    });
  };
  const submitForm = async (e) => {
    e.preventDefault();
    setwarnemail(false);
    setwarnpassword(false);
    if (inputtext.email == "") {
      setwarnemail(true);
      alert("incorrect creditinals");
    } else if (inputtext.password == "") {
      setwarnpassword(true);
      alert("incorrect creditinals");
    } else {
      try {
        console.log(inputtext.email);
        const code = await signUpService({
          url: store.getState().AgencyReducer.agency.url,
          email: inputtext.email,
          password: inputtext.password,
          name: inputtext.name,
        });
        setVerficationCode(code.verificationCode);
        setVerficationModal(true);

        console.log(user);
      } catch (e) {
        console.log(e.response.data.msg);
        alert(e.response.data.msg);
      }
    }
  };

  const Eye = () => {
    if (password == "password") {
      setpassword("text");
      seteye(false);
      settype(true);
    } else {
      setpassword("password");
      seteye(true);
      settype(false);
    }
  };
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Grid
      container
      sx={{ backgroundColor: "white", height: isMobile ? null : "100vh" }}
    >
      <Grid item md={2} sm={2} xs={2} />
      <Grid item>
        <Typography
          style={{
            fontFamily: "Fredoka",
            fontStyle: "normal",
            letterSpacing: "-0.3%",
            color: "#5F41B3",
            fontWeight: 600,
          }}
          variant="h4"
        >
          Trollii
        </Typography>
      </Grid>
      <VerficationPopover
        verificationCode={verficationCode}
        setVerficationCode={setVerficationCode}
        setAnimation={setAnimation}
        open={verificationModal}
        password={inputtext.password}
        name={inputtext.name}
        email={inputtext.email}
        animationVisibleStatus={animation}
        setSignInStatus={setSignInStatus}
        onRequestClose={setVerficationModal}
        animationVisible={animationVisible}
      />
      <Grid container>
        <Grid item md={3} sm={3} xs={3} />
        <Grid
          item
          md={6}
          sm={6}
          xs={6}
          style={{
            backgroundColor: "#FFFFFF",
          }}
        >
          <form onSubmit={submitForm}>
            <Grid container>
              <Grid item md={1} />
              <Grid item md={10}>
                <Grid item md={12}>
                  <Typography
                    style={{
                      display: "flex",
                      flexDirection: "flex-start",
                      fontFamily: "Rubik",
                      fontStyle: "normal",
                      letterSpacing: "-0.3%",
                      color: "#362566",
                      fontWeight: 500,
                    }}
                    variant="h4"
                  >
                    Sign up{" "}
                  </Typography>
                </Grid>

                <Grid item sx={{ pt: 2 }}>
                  <Typography
                    style={{
                      display: "flex",
                      flexDirection: "flex-start",
                      fontFamily: "Rubik",
                      fontStyle: "normal",
                      letterSpacing: "-0.3%",
                      color: "#5D5C66",
                      fontWeight: 400,
                    }}
                  >
                    Join us now by signing up
                  </Typography>
                </Grid>
                <Grid item sx={{ pt: 2 }} className="input-text">
                  <Typography
                    style={{
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                      display: "flex",
                      fontFamily: "Rubik",
                      fontStyle: "normal",
                      letterSpacing: "-0.3%",
                      color: "#5D5C66",
                      fontWeight: 500,
                    }}
                  >
                    Name
                  </Typography>
                  <input
                    type="text"
                    value={inputtext.name}
                    onChange={inputEvent}
                    name="name"
                  />
                </Grid>

                <Grid item sx={{ mt: 8 }} className="input-text">
                  <Typography
                    style={{
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                      display: "flex",
                      fontFamily: "Rubik",
                      fontStyle: "normal",
                      letterSpacing: "-0.3%",
                      color: "#5D5C66",
                      fontWeight: 500,
                    }}
                  >
                    Email
                  </Typography>
                  <input
                    type="text"
                    className={` ${warnemail ? "warning" : ""}`}
                    value={inputtext.email}
                    onChange={inputEvent}
                    name="email"
                  />
                </Grid>
                <Grid sx={{ mt: 8 }} item className="input-text">
                  <Typography
                    style={{
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                      display: "flex",
                      fontFamily: "Rubik",
                      fontStyle: "normal",
                      letterSpacing: "-0.3%",
                      color: "#5D5C66",
                      fontWeight: 500,
                    }}
                  >
                    Password
                  </Typography>{" "}
                  <input
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    type={password}
                    className={` ${warnpassword ? "warning" : ""} ${
                      type ? "type_password" : ""
                    }`}
                    value={inputtext.password}
                    onChange={inputEvent}
                    name="password"
                  />
                  {eye ? (
                    <div onClick={Eye} className="fa-eye-slashSignUp">
                      <Typography
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          fontFamily: "Rubik",
                          fontWeight: 500,
                          color: "#5F41B3",
                        }}
                      >
                        show
                      </Typography>
                    </div>
                  ) : (
                    <div onClick={Eye} className="fa-eye-slashSignUp">
                      <Typography
                        style={{
                          fontFamily: "Rubik",
                          fontWeight: 500,
                          color: "#5F41B3",
                        }}
                      >
                        hide
                      </Typography>
                    </div>
                  )}
                </Grid>

                <Grid sx={{ mt: 8 }} item className="input-text">
                  <Typography
                    style={{
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                      display: "flex",
                      fontFamily: "Rubik",
                      fontStyle: "normal",
                      letterSpacing: "-0.3%",
                      color: "#5D5C66",
                      fontWeight: 500,
                    }}
                  >
                    Confirm password
                  </Typography>{" "}
                  <input
                    type={password}
                    className={` ${warnpassword ? "warning" : ""} ${
                      type ? "type_password" : ""
                    }`}
                    value={inputtext.password}
                    onChange={inputEvent}
                    name="password"
                  />
                  {eye ? (
                    <div onClick={Eye} className="fa-eye-slashSignUp">
                      <Typography
                        style={{
                          fontFamily: "Rubik",
                          fontWeight: 500,
                          color: "#5F41B3",
                        }}
                      >
                        show
                      </Typography>
                    </div>
                  ) : (
                    <div onClick={Eye} className="fa-eye-slashSignUp">
                      <Typography
                        style={{
                          fontFamily: "Rubik",
                          fontWeight: 500,
                          color: "#5F41B3",
                        }}
                      >
                        hide
                      </Typography>
                    </div>
                  )}
                </Grid>
                <Grid item sx={{ pt: 7 }} className="buttons">
                  <button type="submit" style={{ backgroundColor: "#5F41B3" }}>
                    <Typography
                      style={{ fontFamily: "Inter", fontWeight: 500 }}
                    >
                      Sign up
                    </Typography>
                  </button>
                </Grid>
              </Grid>
            </Grid>

            <div className="forgot">
              <p style={{ fontFamily: "Rubik", fontWeight: 400 }}>
                already have an account?{" "}
                <a
                  onClick={() => {
                    setSignInStatus(true);
                  }}
                  style={{
                    cursor: "pointer",
                    color: "#5F41B2",
                    fontFamily: "Rubik",
                    fontWeight: 500,
                  }}
                >
                  Sign in
                </a>
              </p>
            </div>
          </form>
        </Grid>
        <Grid item md={3} />
      </Grid>
    </Grid>
  );
}
