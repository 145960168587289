import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import BookmarkIcon from "@material-ui/icons/Bookmark";
import MaterialButton from "components/CustomButtons/Button.js";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import PersonIcon from "@material-ui/icons/Person";
import store from "store";
import Header from "components/Header/Header.js";
import Cookie from "js-cookie";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import "react-country-dropdown/dist/index.css";
import CountrySelect from "./CountrySelect";
const useStyles = makeStyles(styles);

function Navbar({ history, countrySelection }) {
  const classes = useStyles();
  return (
    <Header
      style={{ color: "black" }}
      brand="Trollii"
      color="white"
      history={history}
      rightLinks={
        <List
          className={classes.list}
          style={{
            flexDirection: "row",
            display: "flex",
            color: "black",
          }}
          dense
        >
          <ListItem className={classes.listItem}>
            {countrySelection ? <CountrySelect /> : null}

            <MaterialButton
              color="transparent"
              onClick={() => {
                if (Cookie.get("token")) {
                  history("/mybookings");
                } else history("/login");
              }}
              className={classes.navLink + " " + classes.socialIconsButton}
            >
              <BookmarkIcon style={{ fontSize: "large" }} />
              View my bookings
            </MaterialButton>
          </ListItem>

          {Cookie.get("token") ? (
            <ListItem className={classes.listItem}>
              <MaterialButton
                color="transparent"
                className={classes.navLink + " " + classes.socialIconsButton}
                onClick={() => {
                  Cookie.remove("token");
                  window.location.reload();
                }}
              >
                <ExitToAppIcon style={{ fontSize: "large" }} />
                SIGN OUT
              </MaterialButton>
            </ListItem>
          ) : (
            <ListItem className={classes.listItem} style={{ margin: "5px" }}>
              <MaterialButton
                color="transparent"
                className={classes.navLink + " " + classes.socialIconsButton}
                onClick={() => history("/login")}
              >
                <PersonIcon style={{ fontSize: "large" }} />
                SIGN IN
              </MaterialButton>
            </ListItem>
          )}
        </List>
      }
    />
  );
}

export default Navbar;
