import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

export const Chatpopup = function (tawk) {
  // var value = "";
  // value = tawk.split('<script type="text/javascript">');
  // value = value[1].split("</script>");
  // value = value[0];
  // return <div>{eval(value)}</div>;
};
export const Anayltics = function (google_anayltics) {
  var value = "";
  value = google_anayltics.split("<script>");
  value = value[1].split("</script>");
  value = value[0];
  return <div>{eval(value)}</div>;
};
export const Facebook = function (facebook_pixel) {
  var value = "";
  value = facebook_pixel.split("<script>");

  value = value[1].split("</script>");

  value = value[0];

  var value2 = facebook_pixel.split("<noscript>");
  value2 = value2[1].split("</noscript>");
  return (
    <div>
      <script>{eval(value)}</script>
      <noscript>{eval(value2)}</noscript>
    </div>
  );
};
export const Hotjar = function (hotjar) {
  var value = "";
  value = hotjar.split("<script>");
  value = value[1].split("</script>");
  value = value[0];
  return (
    <div>
      <script>{eval(value)}</script>
    </div>
  );
};
export const sentry = function () {
  Sentry.init({
    dsn:
      "https://5b5efd0f9ddb4118891e95b9e842b72f@o1040078.ingest.sentry.io/6009222",
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
  });
};
export const WebCallback = function (webCallback) {
  var value = "";
  value = webCallback.split("<script>");
  value = value[1].split("</script>");

  value = value[0];

  return (
    <div>
      <script>{eval(value)}</script>
    </div>
  );
};
