import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import Dropdown from "./Dropdown";
import airport from "airportsjs";
import LocationIcon from "../../../../assets/img/location.png";

export default function PlaceSelector({ selectDestinationAirport }) {
  const options = airport.searchByAirportName(" ");

  const defaultProps = {
    getOptionLabsel: (option) => {
      return option.city + "\n ," + option.country;
    },
  };

  return (
    <Grid container xs={12} sm={12} md={12}>
      <Grid
        item
        xs={1}
        sm={1}
        md={1}
        style={{
          width: "30vw",
          marginTop: "0.2vw",
          marginLeft: "0.5vw",
          marginRight: "0.6vw",
        }}
      >
        <img
          style={{
            width: "1.6vw",
            height: "1.6vw",
          }}
          src={LocationIcon}
        />
      </Grid>
      <Grid item xs={8} sm={8} md={8}>
        <Dropdown />
      </Grid>
    </Grid>
  );
}
