const initState = {
  token: "",
  superAdmin: "",
};

const SuperAdminReducer = (state = initState, action) => {
  if (action.type == "setSuperAdminToken") {
    state.token = action.payload;
    return {
      ...state,
    };
  }
  if (action.type == "setSuperAdmin") {
    state.superAdmin = action.payload;
    return {
      ...state,
    };
  }

  return state;
};

export default SuperAdminReducer;
