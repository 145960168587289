import { Grid, Typography } from "@mui/material";
import React from "react";
import store from "store";
import "@fontsource/rubik/600.css";
import IntlTelInput from "react-intl-tel-input";
import "react-intl-tel-input/dist/main.css";
import "@fontsource/rubik/700.css";
import "@fontsource/rubik/400.css";
import MenuItem from "@mui/material/MenuItem";
import "react-phone-input-2/lib/style.css";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import "react-phone-number-input/style.css";
import Select from "@mui/material/Select";
import "@fontsource/rubik/500.css";
import Popoverhotels from "views/Hotels/Components/Popover";

export default function PassengerDetails({
  passenger,
  passengersInfo,
  setPassengers,
}) {
  let temp = passengersInfo;
  const updatePassenger = (param, targetValue) => {
    for (let i = 0; i < passengersInfo.length; i++) {
      if (temp[i].index == passenger.index) {
        //law hwa da el passenger update
        temp[i][param] = targetValue;
      }
    }
    setPassengers(temp);
  };

  let [value, setValue] = React.useState("Male");
  const handleMonthChange = (e) => {
    updatePassenger("Month", e.target.value);
  };
  const handleDayChange = (e) => {
    updatePassenger("Day", e.target.value);
  };
  const handleYearChange = (e) => {
    updatePassenger("Year", e.target.value);
  };
  const handleChange = (event) => {
    setValue(event.target.value);
    updatePassenger("Gender", event.target.value);
  };

  return (
    <div>
      <Grid container sx={{ mt: 2 }}>
        <Grid item sx={{ pt: 4 }}>
          <Typography
            sx={{
              textAlign: "left",
              fontFamily: "Rubik",
              fontStyle: "normal",
              fontWeight: 500,
              color: "#362566",
            }}
          >
            {" "}
            Main passenger
          </Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item md={5} sm={5} xs={5} sx={{ pt: 4 }}>
          <Typography
            sx={{
              textAlign: "left",
              fontFamily: "Rubik",
              fontStyle: "normal",
              fontWeight: 500,
              color: "#5D5C66",
            }}
          >
            First name
          </Typography>
          <input
            onChange={(text) => {
              updatePassenger("firstName", text.target.value);
            }}
            style={{
              height: "100%",
              width: "100%",
              textIndent: "10%",

              borderRadius: "15px",
              border: "1px solid #C4C4C4",
            }}
          />
        </Grid>
        <Grid item md={2} sm={2} xs={2} />
        <Grid item md={5} sm={5} xs={5} sx={{ pt: 4 }}>
          <Typography
            sx={{
              textAlign: "left",
              fontFamily: "Rubik",
              fontStyle: "normal",
              fontWeight: 500,
              color: "#5D5C66",
            }}
          >
            Last name
          </Typography>
          <input
            onChange={(text) => {
              updatePassenger("lastName", text.target.value);
            }}
            style={{
              height: "100%",
              textIndent: "10%",

              width: "100%",
              borderRadius: "15px",
              border: "1px solid #C4C4C4",
            }}
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item md={5} sm={5} xs={5} sx={{ pt: 4 }}>
          <Typography
            sx={{
              mt: 1,

              textAlign: "left",
              fontFamily: "Rubik",
              fontStyle: "normal",
              fontWeight: 500,
              color: "#5D5C66",
            }}
          >
            Gender
          </Typography>
          <Select
            sx={{ mt: 1 }}
            fullWidth
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={value}
            vak
            style={{ height: "50%" }}
            label="Age"
            onChange={handleChange}
          >
            <MenuItem value={"Male"}>
              <Typography
                sx={{
                  justifyContent: "flex-start",
                  display: "flex",
                  alignItems: "flex-start",
                  fontFamily: "Rubik",
                  color: "#5D5C66",
                  fontStyle: "normal",
                  fontWeight: 400,
                }}
              >
                Male
              </Typography>
            </MenuItem>
            <MenuItem value={"Female"}>
              {" "}
              <Typography
                sx={{
                  justifyContent: "flex-start",
                  display: "flex",
                  alignItems: "flex-start",
                  fontFamily: "Rubik",
                  color: "#5D5C66",
                  fontStyle: "normal",
                  fontWeight: 400,
                }}
              >
                Female
              </Typography>
            </MenuItem>
          </Select>
        </Grid>
        <Grid item md={2} sm={2} xs={2} />
        <Grid item md={5} sm={5} xs={5} sx={{ pt: 4 }}>
          <Grid container>
            <Grid container>
              <Typography
                sx={{
                  textAlign: "left",
                  fontFamily: "Rubik",
                  fontStyle: "normal",
                  fontWeight: 500,
                  pb: 1,
                  mt: 1,
                  color: "#5D5C66",
                }}
              >
                Date of birth
              </Typography>
            </Grid>
            <Grid item md={3} sm={3} xs={3}>
              <select
                placeholder="Month"
                style={{
                  borderRadius: "15px",
                  border: "1px solid #C4C4C4",
                  width: "100%",
                  height: "200%",
                  color: "#959595",
                  textAlignLast: "center",
                }}
                onChange={handleMonthChange}
              >
                <option
                  style={{ color: "black" }}
                  value=""
                  disabled
                  selected
                  hidden
                >
                  <Typography
                    sx={{
                      fontFamily: "Rubik",
                      fontStyle: "normal",
                      fontWeight: 400,
                      color: "#FFFFFF",
                    }}
                  >
                    Month
                  </Typography>
                </option>

                {[...Array(12)].map((x, i) => {
                  return (
                    <option>
                      <Typography>{i + 1}</Typography>
                    </option>
                  );
                })}
              </select>
            </Grid>
            <Grid item md={1} sm={1} xs={1} />
            <Grid item md={3} sm={3} xs={3}>
              <select
                onChange={handleDayChange}
                style={{
                  borderRadius: "15px",
                  border: "1px solid #C4C4C4",
                  width: "100%",
                  height: "200%",
                  textAlignLast: "center",

                  color: "#959595",
                }}
              >
                <option
                  style={{ color: "black" }}
                  value=""
                  disabled
                  selected
                  hidden
                >
                  <Typography
                    sx={{
                      fontFamily: "Rubik",
                      fontStyle: "normal",
                      fontWeight: 400,
                      color: "#FFFFFF",
                    }}
                  >
                    Day
                  </Typography>
                </option>
                {[...Array(30)].map((x, i) => {
                  return (
                    <option>
                      <Typography>{i + 1}</Typography>
                    </option>
                  );
                })}
              </select>
            </Grid>
            <Grid item md={1} sm={1} xs={1} />

            <Grid item md={3} sm={3} xs={3}>
              <select
                onChange={handleYearChange}
                style={{
                  borderRadius: "15px",
                  border: "1px solid #C4C4C4",
                  width: "100%",
                  height: "200%",
                  color: "#959595",
                  textAlignLast: "center",
                }}
              >
                <option
                  style={{ color: "black" }}
                  value=""
                  disabled
                  selected
                  hidden
                >
                  <Typography
                    sx={{
                      fontFamily: "Rubik",
                      fontStyle: "normal",
                      fontWeight: 400,
                      color: "#FFFFFF",
                    }}
                  >
                    Year
                  </Typography>
                </option>
                {[...Array(72)].map((x, i) => {
                  return (
                    <option
                      style={{
                        alignItems: "center",
                        justifyContent: "center",
                        display: "flex",
                      }}
                    >
                      <Typography
                        style={{
                          alignItems: "center",
                          justifyContent: "center",
                          display: "flex",
                        }}
                      >
                        {1950 + i}
                      </Typography>
                    </option>
                  );
                })}
              </select>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item md={5} sm={5} xs={5} sx={{ pt: 2 }}>
          <Typography
            sx={{
              textAlign: "left",
              fontFamily: "Rubik",
              fontStyle: "normal",
              fontWeight: 500,
              color: "#5D5C66",
            }}
          >
            E-mail
          </Typography>
          <input
            onChange={(text) => {
              updatePassenger("email", text.target.value);
            }}
            style={{
              textIndent: "10%",
              height: "100%",
              width: "100%",
              borderRadius: "15px",
              border: "1px solid #C4C4C4",
            }}
          />
        </Grid>
        <Grid item md={2} sm={2} xs={2} />

        <Grid item md={5} sm={5} xs={5} sx={{ pt: 2 }}>
          <Typography
            sx={{
              textAlign: "left",
              fontFamily: "Rubik",
              fontStyle: "normal",
              fontWeight: 500,
              color: "#5D5C66",
            }}
          >
            Phone number
          </Typography>

          <Grid item md={0.1} sm={0.1} xs={0.1} sx={{ pt: 0.5 }}>
            <IntlTelInput
              style={{
                width: "23%",
                position: "absolute",
                zIndex: 0,
              }}
              onPhoneNumberChange={(valid, phone) => {
                updatePassenger("phoneNumber", phone);
                updatePassenger("validPhone", valid);
              }}
              containerClassName="intl-tel-input"
              inputClassName="form-control"
            />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
