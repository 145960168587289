import {
  Box,
  Button,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import SignPhotos from "views/Hotels/pages/LoginModule/SignPhotos";

export default function AgencyResetPassword() {
  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  let theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));
  const renderPasswordInputs = [
    {
      label: "Password",
      placeholder: "••••••••••••••",
      type: "password",
      hasShow: true,
    },
    {
      label: "Re-enter Password",
      placeholder: "••••••••••••••",
      type: "password",
      hasShow: false,
    },
  ];

  const history = useNavigate();
  const handleLogIn = (event) => {
    event.preventDefault();
    history("/AgencyLogin");
  };

  return (
    <Box sx={{ display: "flex", height: "100vh" }}>
      <Box
        sx={{
          width: isMobile ? "100vw" : "38vw",
          display: "flex",
          alignItems: isMobile ? "start" : "center",
        }}
      >
        {!isMobile && (
          <Box
            sx={{
              margin: { lg: "0.875em 0 0 5em", md: "0.875em 0 0 2em" },
              position: "fixed",
              top: "0",
            }}
          >
            <Typography
              fontFamily="Fredoka"
              fontWeight="600"
              fontSize="2.19rem"
              color="#5F41B2"
              lineHeight="1.2em"
            >
              Trollii
            </Typography>
            <Typography
              fontFamily="Rubik"
              fontWeight="500"
              fontSize="0.875rem"
              color="#362566"
              lineHeight="1.21em"
            >
              Travel Admin Dashboard
            </Typography>
          </Box>
        )}
        <Box
          sx={{
            margin: "0 auto",
            width: "fit-content",
            display: "flex",
            flexDirection: "column",
            width: isMobile ? "91%" : "60%",
            minWidth: "20.5em",
            marginTop: isMobile ? "2.5em" : "0",
          }}
        >
          <Typography
            fontFamily="Rubik"
            fontWeight="500"
            fontSize="1.5rem"
            color="#362566"
            lineHeight="1.6667em"
            marginBottom="1.33em"
          >
            Reset your password
          </Typography>
          <Typography
            fontFamily="Rubik"
            fontSize="1rem"
            color="#5D5C66"
            lineHeight="1.1875em"
            marginBottom="1em"
          >
            To keep your valuable information safe, <br></br>
            we require that you use a strong password.
          </Typography>

          {renderPasswordInputs.map(({ label, placeholder, type, hasShow }) => {
            return (
              <>
                <label
                  style={{
                    color: "#5D5C66",
                    fontFamily: "Rubik",
                    fontWeight: "500",
                    fontSize: "0.875rem",
                    lineHeight: "1.214em",
                    marginBottom: "0.57em",
                  }}
                >
                  {label}
                </label>
                <div style={{ position: "relative" }}>
                  <input
                    type={showPassword ? "text" : type}
                    placeholder={placeholder}
                    style={{
                      color: "#5D5C66",
                      fontFamily: "Rubik",
                      fontSize: "1rem",
                      marginBottom: "1em",
                      borderRadius: "0.9375em",
                      height: "3em",
                      border: "1px solid #C4C4C4",
                      padding: "1em",
                      backgroundColor: "#FBFBFB",
                      width: "100%",
                    }}
                  />
                  {hasShow && (
                    <p
                      style={{
                        fontSize: "1rem",
                        fontWeight: "500",
                        fontFamily: "Rubik",
                        lineHeight: "1.1875em",
                        color: "#5F41B2",
                        margin: "0",
                        cursor: "pointer",
                        position: "absolute",
                        top: "0.9375em",
                        right: "1em",
                      }}
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? "hide" : "show"}
                    </p>
                  )}
                </div>
              </>
            );
          })}
          <Button
            onClick={handleLogIn}
            style={{
              height: "3em",
              borderRadius: "0.9375em",
              backgroundColor: "#5F41B2",
              color: "#FFFFFF",
              fontFamily: "Inter",
              fontWeight: "700",
              fontSize: "1rem",
              textTransform: "none",
              margin: "1em 0 1em 0",
            }}
          >
            Change password
          </Button>

          <p
            onClick={handleLogIn}
            style={{
              textAlign: "center",
              fontFamily: "Inter",
              fontWeight: "600",
              lineHeight: "1.1875em",
              color: "#5F41B2",
              fontSize: "1rem",
              textDecoration: "underline",
              cursor: "pointer",
              textUnderlineOffset: "3px",
            }}
          >
            Back to log in
          </p>
        </Box>
      </Box>
      <SignPhotos isAgency={true} />
    </Box>
  );
}
