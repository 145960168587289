import {
  Avatar,
  Box,
  Card,
  Checkbox,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import React, { Component } from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import axios from "axios";

import Footer from "views/Hotels/Components/Footer";
import Navbar from "views/Hotels/Components/NavBar";
import "@fontsource/rubik/600.css";
import "@fontsource/rubik/500.css";
import "@fontsource/rubik/700.css";
import store from "store";
import "@fontsource/rubik/400.css";
import CheckIcon from "@mui/icons-material/Check";
import BookingCard from "../SelectRooms/BookingCard";
import PaymentOption from "./PaymentOptions";

export default function PaymentDetails() {
  let price = parseFloat(store.getState().hotelSearch.hotel.minRate) * 100;
  console.log(price);
  const handleCheckout = () => {
    axios
      .post("http://localhost:8080/create-checkout-session", {
        currency: store.getState().hotelSearch.hotel.currency,
        amount: price,
        hotelName: store.getState().hotelSearch.hotel.name,
        hotelImage: store.getState().hotelSearch.image,
      })
      .then((res) => {
        if (res.data.url) {
          window.location.href = res.data.url;
        }
      })
      .catch((e) => {
        console.log(e.message);
      });
  };
  const [paymentDate, setPaymentDate] = React.useState("Pay now");
  console.log(store.getState().hotelSearch.hotel);

  return (
    <Grid container sx={{ bgcolor: "white", minHeight: "100vh" }}>
      <Grid item md={12} sm={12} xs={12}>
        <Navbar />
      </Grid>
      <Grid container>
        <Grid item md={1} sm={1} xs={1} />
        <Grid item md={7} sm={10} xs={10}>
          <Card
            style={{
              borderRadius: 20,
              marginTop: 10,
              border: "1px solid #C4C4C4",
            }}
          >
            <Grid container style={{ padding: "2%" }}>
              <Grid item md={1} sm={1} xs={1}>
                <Avatar
                  style={{
                    backgroundColor: "white",
                    border: "1px solid #5F41B3",
                    borderRadius: 20,
                  }}
                >
                  <CheckIcon
                    sx={{
                      textAlign: "left",
                      fontFamily: "Rubik",
                      fontStyle: "normal",
                      fontWeight: 600,
                      color: "#5F41B3",
                    }}
                  />
                </Avatar>
              </Grid>
              <Grid item md={0.2} sm={0.2} xs={0.2} />
              <Grid item md={10} sm={10} xs={10}>
                <Typography
                  sx={{
                    mt: 0.7,
                    textAlign: "left",
                    fontFamily: "Rubik",
                    fontStyle: "normal",
                    fontWeight: 700,
                    fontSize: "1.5vw",
                    color: "#959595",
                  }}
                  variant="h5"
                >
                  Passenger Details
                </Typography>
              </Grid>
            </Grid>
          </Card>
          <Card
            style={{
              borderRadius: 20,
              marginTop: 10,
              border: "1px solid #C4C4C4",
            }}
          >
            <Grid container style={{ padding: "4%" }}>
              <Grid item md={1} sm={1} xs={1}>
                <Avatar
                  style={{
                    backgroundColor: "white",
                    border: "1px solid #5F41B2",
                    borderRadius: 20,
                  }}
                >
                  <Typography
                    sx={{
                      textAlign: "left",
                      fontFamily: "Rubik",
                      fontStyle: "normal",
                      fontWeight: 600,
                      color: "#5F41B3",
                    }}
                  >
                    2
                  </Typography>
                </Avatar>
              </Grid>
              <Grid item md={0.2} sm={0.2} xs={0.2} />
              <Grid item md={10} sm={10} xs={10}>
                <Typography
                  sx={{
                    textAlign: "left",
                    fontFamily: "Rubik",
                    fontStyle: "normal",
                    fontWeight: 700,
                    color: "#362566",
                  }}
                >
                  Payment Details
                </Typography>
                <Typography
                  sx={{
                    textAlign: "left",
                    fontFamily: "Rubik",
                    fontStyle: "normal",
                    fontWeight: 400,
                    color: "#362566",
                  }}
                >
                  You will be charged the total amount once your order is
                  confirmed. If confirmation isn't received instantly, an
                  authorization for the total amount will be held.
                </Typography>
              </Grid>

              <Grid item md={12} sm={12} xs={12} sx={{ pt: 3, pb: 3 }}>
                <Typography
                  sx={{
                    textAlign: "left",
                    fontFamily: "Rubik",
                    fontStyle: "normal",
                    fontWeight: 600,
                    color: "#362566",
                  }}
                >
                  Choose a payment date
                </Typography>
              </Grid>
              <Grid item md={12} sm={12} xs={12}>
                <Card
                  sx={{
                    border: "1px solid #C4C4C4",
                    borderRadius: 4,
                    boxShadow:
                      "0px 0px 4px rgba(0,0,0,0.04), 0px 4px 8px rgba(0,0,0,0.06)",
                  }}
                >
                  <Grid item>
                    <Grid
                      container
                      sx={{ display: "flex", flexDirection: "column" }}
                    >
                      <FormControl>
                        <RadioGroup defaultValue="Pay now">
                          <Grid container>
                            <Grid item md={0.3} sm={0.3} xs={0.3} />
                            <Grid item sx={{ pt: 2, pb: 2 }}>
                              <FormControlLabel
                                onChange={() => {
                                  setPaymentDate("Pay now");
                                }}
                                value="Pay now"
                                control={
                                  <Radio
                                    sx={{
                                      "&.Mui-checked": {
                                        color: "#5F41B3",
                                      },
                                    }}
                                  />
                                }
                                label={
                                  <Typography
                                    sx={{
                                      color:
                                        paymentDate == "Pay now"
                                          ? "#5F41B3"
                                          : "#5D5C66",
                                      fontFamily: "Rubik",
                                      fontStyle: "normal",
                                      fontWeight: 500,
                                    }}
                                  >
                                    Pay now
                                  </Typography>
                                }
                              />
                            </Grid>
                          </Grid>
                          <Grid item md={12}>
                            <Divider />
                          </Grid>
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Card>
                <Grid item md={12} sx={{ pt: 3, pb: 3 }}>
                  <Divider />
                </Grid>
                <Grid item md={12} sm={12} xs={12} sx={{ pb: 3 }}>
                  <Typography
                    sx={{
                      textAlign: "left",
                      fontFamily: "Rubik",
                      fontStyle: "normal",
                      fontWeight: 600,
                      color: "#362566",
                    }}
                  >
                    Choose a payment method
                  </Typography>
                </Grid>
                <Grid item md={12} xs={12} sm={12}>
                  <PaymentOption handleCheckout={handleCheckout} />
                </Grid>
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid item md={3} xs={0} sx={{ display: { xs: "none", md: "block" } }}>
          <BookingCard paymentPage={true} />
        </Grid>
      </Grid>

      <Grid item md={12}>
        <Footer />
      </Grid>
    </Grid>
  );
}
