import React from "react";
import ANIMATIONS from "constants/animations.js";
import Modal from "react-modal";
import store from "store";
import IconButton from "@mui/material/IconButton";

import Typography from "@material-ui/core/Typography";
import "@fontsource/rubik/500.css";
import "@fontsource/rubik/400.css";
import Button from "@material-ui/core/Button";
import { forgotPassword } from "views/Services/clientServices";
import { LoginService } from "views/Services/clientServices";
import Cookies from "js-cookie";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import {
  Checkbox,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import "../pages/LoginModule/styles.css";
function LoginModal({
  state,
  open,
  onCloseModal,
  setPassword,
  password,
  animationVisibleStatus,
  email,
  animationVisible,
}) {
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  return (
    <Modal
      isOpen={open}
      onRequestClose={() => onCloseModal(false)}
      contentLabel="My dialog"
      closeTimeoutMS={500}
      style={{
        overlay: {
          backgroundColor: "rgba(0,0,0,0.5)",
        },
        content: {
          backgroundColor: "#FFFFFF",
          height: "60%",
          width: "60%",
          position: "absolute",
          left: "20%",
          top: "20%",
        },
      }}
    >
      {animationVisibleStatus ? (
        <div>{ANIMATIONS.ManLoad}</div>
      ) : (
        <Grid
          sx={{
            alignItems: "center",
            textAlign: "center",
            justifyContent: "Center",
            pt: 6,
          }}
        >
          <Typography
            style={{
              fontFamily: "Rubik",
              fontStyle: "normal",
              letterSpacing: "-0.3%",
              fontWeight: 500,
              color: "#362566",
              fontSize: "32px",
            }}
          >
            Please enter your password to proceed{" "}
          </Typography>
          <Typography
            id="modal-modal-title"
            variant="h3"
            component="span"
            style={{
              fontFamily: "Rubik",
              fontStyle: "normal",
              letterSpacing: "-0.3%",
              fontWeight: 400,

              textAlign: "center",
              color: "#5D5C66",
              fontSize: "24px",
            }}
          >
            {`Signed in with ${email}`}
          </Typography>

          <Grid container sx={{ pt: 2 }}>
            <Grid item md={7}>
              <Typography
                style={{
                  fontFamily: "Rubik",
                  fontStyle: "normal",
                  letterSpacing: "-0.3%",
                  color: "#5D5C66",
                  fontWeight: 400,
                  fontSize: "14px",
                }}
              >
                Password
              </Typography>
            </Grid>
            <Grid item md={12}>
              <FormControl sx={{ m: 1, width: "50%" }} variant="outlined">
                <InputLabel htmlFor="outlined-adornment-password">
                  Password
                </InputLabel>
                <OutlinedInput
                  sx={{
                    [`& fieldset`]: {
                      borderRadius: 5,
                    },
                  }}
                  id="outlined-adornment-password"
                  type={showPassword ? "text" : "password"}
                  onChange={(text) => {
                    setPassword(text.target.value);
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Password"
                />
              </FormControl>
            </Grid>
            <Grid item md={3} />
            <Grid item md={0.2}>
              <Checkbox />
            </Grid>
            <Grid item md={2}>
              <Typography>Remember me ?</Typography>
            </Grid>
            <Grid item md={6}>
              <p
                onClick={async () => {
                  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                  if (re.test(state.passengerInfo[0].email.toLowerCase())) {
                    try {
                      let body = {
                        email: state.passengerInfo[0].email,
                        agency: store.getState().AgencyReducer.agency.url,
                      };

                      await forgotPassword(body);
                      alert("check your email to get your new password");
                    } catch (error) {
                      console.log(error);
                      alert("this email is not registered with us");
                    }
                  } else {
                    alert("please enter a correct email");
                  }
                }}
                style={{
                  cursor: "pointer",
                  fontFamily: "Rubik",
                  textDecorationLine: "underline",
                  fontWeight: 500,
                  alignItems: "center",
                  justifyContent: "center",
                  color: "#5DSC66",
                }}
              >
                Forgot Password?
              </p>
            </Grid>
          </Grid>
          <br />
          <Grid container>
            <Grid item md={12}>
              <Button
                onClick={async () => {
                  try {
                    let reqBody = {
                      email: email,
                      password: password,
                      agency: store.getState().AgencyReducer.agency.url,
                    };

                    animationVisible(true, false);
                    const user = await LoginService(reqBody);

                    store.dispatch({ type: "setToken", token: user.token });
                    store.dispatch({
                      type: "setUserName",
                      userName: user.user.name,
                    });
                    store.dispatch({
                      type: "setEmail",
                      email: user.user.email,
                    });
                    store.dispatch({ type: "setLoggedIn", loggedIn: true });
                    Cookies.set("token", user.token);

                    animationVisible(false, false, true);
                    onCloseModal(false);
                  } catch (error) {
                    console.log(error);
                    animationVisible(false, false, false);
                    alert("Please check your credentials");
                  }
                }}
                size="medium"
                variant="filled"
                style={{
                  backgroundColor: "#5F41B3",
                  borderRadius: 10,
                  width: "50%",
                  height: "120%",
                }}
              >
                <Typography
                  style={{
                    fontFamily: "Inter",
                    fontStyle: "normal",
                    fontWeight: 500,
                    fontSize: 16,
                    color: "white",
                    textTransform: "none",
                  }}
                >
                  Log in
                </Typography>
              </Button>
            </Grid>
          </Grid>
          {/* <Button
            size="large"
            variant="contained"
            style={{
              borderWidth: 0,
              borderRadius: 15,
              fontSize: "16px",
              backgroundColor: "#4caf50",
              fontFamily: "cursive",
              color: "white",
              marginTop: "40%",
              width: "220px",
            }}
            onClick={async () => {
              try {
                let reqBody = {
                  email: email,
                  password: password,
                  agency: store.getState().AgencyReducer.agency.url,
                };

                animationVisible(true, false);
                const user = await LoginService(reqBody);

                store.dispatch({ type: "setToken", token: user.token });
                store.dispatch({
                  type: "setUserName",
                  userName: user.user.name,
                });
                store.dispatch({
                  type: "setEmail",
                  email: user.user.email,
                });
                store.dispatch({ type: "setLoggedIn", loggedIn: true });
                Cookies.set("token", user.token);

                animationVisible(false, false, true);
                onCloseModal(false);
              } catch (error) {
                console.log(error);
                animationVisible(false, false, false);
                alert("Please check your credentials");
              }
            }}
          >
            <div style={{ color: "black" }}>Sign in</div>
          </Button> */}
          {/* <Button
            size="large"
            variant="contained"
            style={{
              borderWidth: 0,
              borderRadius: 15,
              fontSize: "16px",
              marginTop: "15px",
              backgroundColor: "#f50057",
              fontFamily: "cursive",
              color: "white",
              width: "220px",
            }}
            onClick={async () => {
              const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
              if (re.test(state.passengerInfo[0].email.toLowerCase())) {
                try {
                  let body = {
                    email: state.passengerInfo[0].email,
                    agency: store.getState().AgencyReducer.agency.url,
                  };

                  await forgotPassword(body);
                  alert("check your email to get your new password");
                } catch (error) {
                  console.log(error);
                  alert("this email is not registered with us");
                }
              } else {
                alert("please enter a correct email");
              }
            }}
          >
            Reset Password
          </Button> */}
        </Grid>
      )}
    </Modal>
  );
}

export default LoginModal;
