import * as React from "react";

const HelpIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 8.13086V12.9609"
        stroke="#362566"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.24086 6.20008C4.24086 5.14008 5.11086 4.27008 6.17086 4.27008H7.90086C8.30086 4.27008 8.86086 4.06008 9.16086 3.81008L10.7409 2.46008C11.4409 1.87008 12.5709 1.87008 13.2509 2.46008L14.8309 3.81008C15.1309 4.06008 15.7009 4.27008 16.1009 4.27008H17.8009C18.8609 4.27008 19.7309 5.14008 19.7309 6.20008V7.90008C19.7309 8.30008 19.9409 8.86008 20.1909 9.16008L21.5409 10.7401C22.1309 11.4401 22.1309 12.5701 21.5409 13.2501L20.1909 14.8301C19.9409 15.1301 19.7309 15.6901 19.7309 16.0901V17.7901C19.7309 18.8501 18.8609 19.7201 17.8009 19.7201H16.1009C15.7009 19.7201 15.1409 19.9301 14.8409 20.1801L13.2609 21.5301C12.5609 22.1201 11.4309 22.1201 10.7509 21.5301L9.17086 20.1801C8.87086 19.9301 8.30086 19.7201 7.91086 19.7201H6.17086C5.11086 19.7201 4.24086 18.8501 4.24086 17.7901V16.0801C4.24086 15.6901 4.04086 15.1201 3.79086 14.8301L2.44086 13.2401C1.86086 12.5501 1.86086 11.4301 2.44086 10.7401L3.00086 10.0801"
        stroke="#362566"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.9941 16H12.0031"
        stroke="#362566"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default HelpIcon;
