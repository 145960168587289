import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import store from "store";
import countries from "./Countries";
export default function CountrySelect() {
  const [info, setInfo] = React.useState({
    countryCode: store.getState().client.client.countryCode,
  });
  return (
    <Box
      style={{ display: "flex", justifyItems: "center", alignItems: "center" }}
    >
      <Autocomplete
        style={{ width: "100%" }}
        id="country-select-demo"
        defaultValue={() => {
          let temp = countries.find(
            (o) => o.currencyCode === store.getState().client.client.currency
          );
          return temp;
        }}
        onChange={(event, newValue) => {
          setInfo(newValue);
          console.log(info);

          store.dispatch({
            type: "setCurrency",
            payload: newValue.currencyCode,
          });
        }}
        options={countries}
        autoHighlight
        getOptionLabel={(option) => {
          return `${option.currencyCode}`;
        }}
        renderOption={(props, option) => {
          return (
            <Box
              component="li"
              sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
              {...props}
            >
              <img
                loading="lazy"
                width="20"
                src={`https://flagcdn.com/w20/${option.countryCode.toLowerCase()}.png`}
                srcSet={`https://flagcdn.com/w40/${option.countryCode.toLowerCase()}.png 2x`}
                alt=""
              />
              {option.countryName} ({option.currencyCode})
            </Box>
          );
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            id="outlined-uncontrolled"
            label="Choose a country"
          />
        )}
      />
      <img
        style={{ paddingLeft: "4%" }}
        loading="lazy"
        width="20%"
        height="20%"
        src={`https://flagcdn.com/w20/${info.countryCode.toLowerCase()}.png`}
        srcSet={`https://flagcdn.com/w40/${info.countryCode.toLowerCase()}.png 2x`}
        alt=""
      />
    </Box>
  );
}
