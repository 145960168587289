import { Typography } from "@material-ui/core";
import { Grid, useTheme, useMediaQuery } from "@mui/material";
import "@fontsource/rubik/500.css";
import { ReactComponent as ArrowLeft } from "assets/svg/Arrowleft.svg";
import { ReactComponent as ArrowRight } from "assets/svg/Arrowright.svg";

import React, { Component } from "react";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRightOutlined";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
import Signup from "./Signup";
import Recatngle from "./Rectangle.jpg";
import "./styles.css";
import Signin from "./SignIn";
import { Photo, RecentActors } from "@material-ui/icons";
export default function LandingSign({ SignUpRoute }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [Photos, setPhotos] = React.useState([
    {
      text:
        "Search Millions of Routes & Find The Best Travel Deals around the world",
      path: "/mock-images/Registration/Rectangle.jpg",
    },
    {
      text: "Get The Best Deals for Your Next Hotel Booking with few steps.",
      path: "/mock-images/Registration/slide2.jpg",
    },
    {
      text: "Discover the most beautiful places to travel around the world.",
      path: "/mock-images/Registration/slide3.jpg",
    },
  ]);
  const { useState } = React;
  const [inputtext, setinputtext] = useState({
    email: "",
    password: "",
  });

  const [warnemail, setwarnemail] = useState(false);
  let [counter, setCounter] = React.useState(0);
  const [isActive, setIsActive] = React.useState(false);

  const [warnpassword, setwarnpassword] = useState(false);

  const [eye, seteye] = useState(true);
  const [signInStatus, setSignInStatus] = useState(!SignUpRoute);

  const [password, setpassword] = useState("password");
  const [type, settype] = useState(false);

  const inputEvent = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setinputtext((lastValue) => {
      return {
        ...lastValue,
        [name]: value,
      };
    });
  };
  const submitForm = (e) => {
    e.preventDefault();
    setwarnemail(false);
    setwarnpassword(false);
    if (inputtext.email == "") {
      setwarnemail(true);
    } else if (inputtext.password == "") {
      setwarnpassword(true);
    } else {
      alert("form submitted");
    }
  };

  const Eye = () => {
    if (password == "password") {
      setpassword("text");
      seteye(false);
      settype(true);
    } else {
      setpassword("password");
      seteye(true);
      settype(false);
    }
  };

  return (
    <Grid container>
      <Grid
        item
        md={6}
        sm={12}
        xs={12}
        style={{
          display: "flex",
          flex: 1,
          justifyContent: "center",
          backgroundColor: "#FFFFFF",
        }}
      >
        {signInStatus ? (
          <Signin
            setSignInStatus={setSignInStatus}
            signInStatus={signInStatus}
          />
        ) : (
          <Signup
            setSignInStatus={setSignInStatus}
            signInStatus={signInStatus}
          />
        )}
      </Grid>
      {!isMobile ? (
        <Grid item md={6} sm={6} xs={6}>
          <article
            className={isActive ? "fade-in" : "fade-out"}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              height: "100%",
              backgroundImage: `url(${Photos[counter].path})`,
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              backgroundSize: "cover",
            }}
          >
            <Grid container sx={{ mt: 30 }}>
              <Grid item md={1} sm={1} xs={1} />

              <Grid item md={10} sm={10} xs={10} sx={{ mt: 10 }}>
                <Typography
                  variant="h3"
                  style={{
                    textAlign: "left",
                    fontFamily: "Rubik",
                    fontWeight: 500,
                    fontStyle: "normal",
                    color: "#FFFFFF",
                  }}
                >
                  {Photos[counter].text}
                </Typography>
              </Grid>
            </Grid>
            <Grid Container style={{ display: "flex" }} sx={{ pt: 2 }}>
              <Grid item md={7} />
              <Grid item md={2}>
                <ArrowLeft
                  onClick={() => {
                    counter != 0
                      ? (setIsActive(!isActive), setCounter(--counter))
                      : (setIsActive(!isActive), setCounter(2));
                  }}
                  style={{
                    fontSize: "7vh",
                    cursor: "pointer",
                    bottom: "10vh",
                    fontFamily: "Rubik",
                    fontStyle: "normal",
                    color: "#FFFFFF",
                  }}
                ></ArrowLeft>
              </Grid>

              <Grid item md={2}>
                <ArrowRight
                  onClick={() => {
                    counter < 2
                      ? (setIsActive(!isActive), setCounter(++counter))
                      : (setIsActive(!isActive), setCounter(0));

                    console.log(counter);
                  }}
                  style={{
                    cursor: "pointer",

                    fontSize: "7vh",
                    bottom: "10vh",
                    fontFamily: "Rubik",
                    fontStyle: "normal",
                    color: "#FFFFFF",
                  }}
                ></ArrowRight>
              </Grid>
            </Grid>
          </article>
        </Grid>
      ) : null}
    </Grid>
  );
}
