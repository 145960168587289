import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
// import GridContainer from "components/Grid/GridContainer.js";
// import GridItem from "components/Grid/GridItem.js";
import Grid from "@material-ui/core/Grid";

import APP_COLORS from "constants/themes.js";
import dateformat from "dateformat";
import React, { Component } from "react";
import Small from "../../components/Typography/Small.js";
import store from "../../store";
import { TextField, Button } from "@material-ui/core";
import { chooseOffer } from "../../redux/actions/offerActions";
import { connect } from "react-redux";
import { createDuffelPayment } from "views/Services/clientServices.js";
import axios from "axios";
import hosts from "../../constants/hosts";

export class TravelCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      discountCode: "",
      discountAmount: 0,
      class: "",
      loading: true,
      isApply: false,
    };
  }
  // store.getState().chosenOffer.offer.slices[0].segment.passengers[0]
  // .cabin_class_marketing_name
  render() {
    const offer = this.props.offer;
    const classes = this.props.classes;
    const adults = this.props.adults;
    const children = this.props.children;

    const buttonClick = async (e) => {
      e.preventDefault();

      if (this.state.discountCode == "")
        return console.log("Please Provide discount code");
      try {
        const resp = await axios.get(
          `${hosts}/travelagency/checkDiscount/${
            store.getState().AgencyReducer.agency.url
          }/${store.getState().offers.offerClass}/${this.state.discountCode}`
        );
        this.setState({ isApply: true });
        this.setState({ discountAmount: resp.data });
        alert(`Done, ${resp.data} is off now!`);

        offer.total_amount = offer.total_amount - resp.data;

        this.props.chooseOffer(offer, () => {});
        let duffelPaymentBody = {
          data: {
            currency: offer.total_currency,
            amount: parseFloat(offer.total_amount).toFixed(2),
          },
        };
        const createDuffelPaymentService = await createDuffelPayment(
          duffelPaymentBody
        );
        store.dispatch({
          type: "setPaymentClientToken",
          payload: createDuffelPaymentService.data,
        });
      } catch (error) {
        if (error.response) {
          console.log(error.response.data.error);
          alert(error.response.data.error);
        }
      }
    };

    return (
      <Card
        className={classes.raised}
        style={{
          marginBottom: "20px",
          boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
          borderRadius: "32px",
          padding: "8px",
        }}
      >
        <Grid container>
          <Grid item style={{ marginLeft: "5px" }}>
            <img
              src={
                "https://daisycon.io/images/airline/?width=200&height=50&color=ffffff&iata=" +
                offer.owner.iata_code
              }
            />
          </Grid>
          {offer.slices.map((slice) => {
            return slice.segments.map((segment) => {
              const originCity =
                segment.origin.city_name || segment.origin.name;
              const destinationCity =
                segment.destination.city_name || segment.destination.name;

              const originTerminal = segment.origin_terminal || "N/A";
              const destinationTerminal = segment.destination_terminal || "N/A";

              const operating = segment.operating_carrier.name;

              let checkedBaggage = 0;
              let carryOn = 0;

              for (let i = 0; i < segment.passengers[0].baggages.length; i++) {
                if (segment.passengers[0].baggages[i].type === "checked") {
                  checkedBaggage =
                    checkedBaggage + segment.passengers[0].baggages[i].quantity;
                } else if (
                  segment.passengers[0].baggages[i].type === "carry_on"
                ) {
                  carryOn =
                    carryOn + segment.passengers[0].baggages[i].quantity;
                }
              }

              const operatingIata =
                segment.operating_carrier.iata_code ||
                segment.marketing_carrier.iata_code;
              const opertingFN =
                segment.operating_carrier_flight_number ||
                segment.marketing_carrier_flight_number;

              return (
                <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs={8}>
                      <Typography
                        style={{
                          color: store.getState().AgencyReducer.agency.branding
                            .primaryColor,
                        }}
                      >
                        <h4>{originCity + " - " + destinationCity}</h4>
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography
                        style={{
                          marginTop: "10px",
                        }}
                      >
                        {operatingIata + " " + opertingFN}
                      </Typography>
                      <Small>Flight Num.</Small>
                    </Grid>
                    <Grid container xs={12}>
                      <Grid item xs={6}>
                        <Typography>
                          {dateformat(segment.departing_at, "HH:MM") +
                            " " +
                            segment.origin.iata_code}{" "}
                        </Typography>
                        <Typography>{"Terminal " + originTerminal} </Typography>
                        <Typography>
                          {dateformat(segment.departing_at, "mmm d, yyyy")}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography>
                          {dateformat(segment.arriving_at, "HH:MM") +
                            " " +
                            segment.destination.iata_code}{" "}
                        </Typography>
                        <Typography>
                          {"Terminal " + destinationTerminal}{" "}
                        </Typography>
                        <Typography>
                          {dateformat(segment.arriving_at, "mmm d, yyyy")}
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid item xs={12}>
                      <p>
                        <br />
                        {checkedBaggage +
                          store.getState().chosenOffer.numberOfSelectedBags +
                          " checked bag and " +
                          carryOn +
                          " cabin bags per person and " +
                          store.getState().chosenOffer.numberOfSelectedBags +
                          " additional bags"}{" "}
                        <br /> Operated by {operating}{" "}
                        {"| " +
                          segment.passengers[0].cabin_class_marketing_name}
                      </p>
                    </Grid>
                  </Grid>
                </Grid>
              );
            });
          })}
          <Grid item xs={12}>
            <h5
              style={{
                color: store.getState().AgencyReducer.agency.branding
                  .primaryColor,
              }}
            >
              Passengers
            </h5>
          </Grid>
          <Grid item xs={12}>
            <Typography>{adults + " " + children}</Typography>
          </Grid>

          <Grid item xs={12}>
            <TextField
              placeholder="Discount"
              value={this.state.discountCode}
              onChange={(e) => this.setState({ discountCode: e.target.value })}
            ></TextField>
            <Button
              style={{
                color: store.getState().AgencyReducer.agency.branding
                  .primaryColor,
              }}
              onClick={(e) => buttonClick(e)}
              disabled={this.state.isApply}
            >
              Apply
            </Button>
          </Grid>

          <Grid
            item
            xs={12}
            style={{
              color: store.getState().AgencyReducer.agency.branding
                .primaryColor,
            }}
          >
            <Typography>
              <h2>
                {store.getState().client.client.currency +
                  " " +
                  parseFloat(
                    this.state.discountAmount
                      ? offer.total_amount - this.state.discountAmount
                      : offer.total_amount
                  ).toLocaleString()}
              </h2>
            </Typography>
          </Grid>
        </Grid>
      </Card>
    );
  }
}

// export default TravelCard;

const mapStateToProps = (state) => ({
  chosenOffer: state.chosenOffer.offer,
});

export default connect(mapStateToProps, { chooseOffer })(TravelCard);
