import React from "react";
import store from "store";
import InputLabel from "@material-ui/core/InputLabel";
import TextField from "@material-ui/core/TextField";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import InputAdornment from '@mui/material/InputAdornment';
import FlightLandIcon from '@mui/icons-material/FlightLand';
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2)
  },
  gpsIcon: {
    position: "absolute",
    borderLeftColor: "3%",
  },
  input: {
    "& > label": {
      paddingLeft: "10%"
    },
    "& > div > input": {
      paddingLeft: "1.4em !important"
    }
  }
}));
function TextFieldLandingForm({params,text }) {
  const classes = useStyles();

  const [val, setVal] = React.useState("");

  return (
    <div>
    <InputLabel
      id="demo-simple-select-label"
      style={{
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        color: store.getState().AgencyReducer
          .agency.branding.fifth
          ? store.getState().AgencyReducer.agency
              .branding.fifth
          : "black",
      }}
    >
      {text}
    </InputLabel>
    <TextField
          {...params}
                  className={classes.input}
                  label={text=="from"? "Where from ?" :"Where to ?"}
          InputProps={{
            ...params.InputProps,
            disableUnderline:true,
            startAdornment: (
              <InputAdornment position="start">
                {text=="from"?
               <LocationOnIcon className={classes.gpsIcon} htmlColor="#353539"/> :<FlightLandIcon className={classes.gpsIcon} htmlColor="#353539"/> }
              </InputAdornment>
            ),
          }}
      style={{
        paddingBottom: ".25vh",
                borderRadius:'5%',
        paddingLeft:'2%',
        backgroundColor: store.getState()
          .AgencyReducer.agency.branding
          .sixth
          ? store.getState().AgencyReducer.agency
              .branding.sixth
          : "white",
      }}
      required
      margin="normal"
    />
  </div>
  );
}

export default TextFieldLandingForm;
