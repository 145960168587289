import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import React from "react";
import {
  getBookingsService,
  getCountryPhoto,
} from "views/Services/clientServices.js";
import MyBookings from "./MyBookings.js";
const useStyles = makeStyles(styles);

export function MyBookingsFunctional(props) {
  const classes = useStyles();

  return <MyBookings classes={classes} history={props.history} />;
}

function formatDate(date) {
  let days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  let months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  let formattedDate = new Date(date);
  return `${days[formattedDate.getDay()]}, ${formattedDate.getDate()} ${
    months[formattedDate.getMonth()]
  }`;
}

function formatPassengers(passengers) {
  let traveller = passengers.length == 1 ? "Traveller" : "Travellers";
  return `${passengers.length} ${traveller}`;
}

function formatType(slices) {
  if (slices.length == 1) {
    return "One Way";
  } else if (slices.length == 2) {
    return "Return";
  } else {
    return "Multi-City";
  }
}

function capitalizeFirstLetter(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export async function loadBookings() {
  try {
    let list = [];
    let bookings = await getBookingsService();

    for (let i = 0; i < bookings?.length; i++) {
      let image = await getCountryPhoto(
        `${bookings[i].slices[0].destination.city_name}`
      );
      if (bookings[i]) {
        list.push({
          amount: bookings[i].total_amount,
          refund_amount: bookings[i].refund_amount,
          base_currency: bookings[i].base_currency,
          slices: bookings[i].slices,
          markup: bookings[i].markup,
          refund_id: bookings[i].refund_id,
          tax_amount: bookings[i].tax_amount,
          id: bookings[i].id,
          origin: bookings[i].slices[0].origin.city_name,
          destination: bookings[i].slices[0].destination.city_name,
          departure: formatDate(bookings[i].slices[0].segments[0].departing_at),
          arrival: formatDate(bookings[i].slices[0].segments[0].arriving_at),
          passengers: formatPassengers(bookings[i].passengers),
          imageURL: image,
          type: formatType(bookings[i].slices),
          class: bookings[i].slices[0].segments[0].passengers[0].cabin_class,
        });
      }
    }

    return list;
  } catch (e) {
    console.log(e);
    alert("failed to load the bookings");
  }
}
