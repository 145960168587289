import { Grid } from "@material-ui/core";
import React from "react";
import Filters from "./Filters.js";
import GridItem from "components/Grid/GridItem.js";
import Sorting from "./Sorting";
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
function OffersDetails({classes,state,history,handleCheapest,handleShortest,filter}) {
  return (  
 
      <div
                className={classes.main}
                style={{ backgroundColor: "#F6F7F8" }}
              >
             
{/* <div style={{display:"flex", 
    justifyContent: "center"}}>
    <Box sx={{ width: '70%', borderRadius:"15px", border:"1px solid #C4C4C4", boxShadow: "0px 2px 10px 0px", display:"flex", 
    justifyContent: "center"
  }}>
      <Tabs style={{display:"flex", 
    justifyContent: "space-between"}} >
        <Tab style={{color: "#362566",
fonFfamily: "Rubik",
fontSize: "16px",
fontStyle: "normal",
fontWeight: "500",
lineHeight: "normal",}} label="Recommended" />
        <Tab style={{color: "#362566",
fonFfamily: "Rubik",
fontSize: "16px",
fontStyle: "normal",
fontWeight: "500",
lineHeight: "normal",}} label="Cheapest" />
        <Tab style={{color: "#362566",
fonFfamily: "Rubik",
fontSize: "16px",
fontStyle: "normal",
fontWeight: "500",
lineHeight: "normal",}} label="Fastest" />
      </Tabs>
    </Box>
    </div> */}
  
                
                <Grid container>
                  <Filters
                    classes={classes}
                    airlines={state.airlines}
                    max={state.maxPrice}
                    minDuration={state.minDuration}
                    maxDuration={state.maxDuration}
                    filter={filter}
                  />
                  <GridItem xs={12} sm={8} style={{ marginTop: "16px" }}>
                    {!state.filtersWarning && !state.spinner && (
                      <Sorting
                        offers={state.offers}
                        classes={classes}
                        green={state.green}
                        adults={state.adults}
                        children={state.children}
                        history={history}
                        handleCheapest={handleCheapest}
                        handleShortest={handleShortest}
                      />
                    )}
                  </GridItem>
                </Grid>
              </div>
  );
}

export default OffersDetails;
