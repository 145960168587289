import React from "react";
import store from "store";
import InputLabel from "@material-ui/core/InputLabel";
  import GridItem from "components/Grid/GridItem.js";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { Grid } from "@material-ui/core";
import { styled, alpha } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Menu, { MenuProps } from '@mui/material/Menu';
import EditIcon from '@mui/icons-material/Edit';
import Divider from '@mui/material/Divider';
import ArchiveIcon from '@mui/icons-material/Archive';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useState } from "react";

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));

export default function TravellersDropdown({removeAdults,adults,addAdults,removeChild,selectAge,addChild}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (cabinClass) => { 
    setCabinClass(cabinClass);
    setAnchorEl(null);
  };
const [cabinClass, setCabinClass] = useState('Economy');  return (
    <div>
      <Button
        id="demo-customized-button"
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
        style={{backgroundColor: 'white', color:"#5F41B2", "font-family": "Rubik",
            "font-size": "16px",
            "font-weight": "400",
            "line-height": "normal", textTransform: 'none'}}
      >
        {cabinClass}
      </Button>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClick={()=>{setAnchorEl(null)}}
      >
        <MenuItem  onClick={()=>{handleClose("Economy")}} disableRipple>
         Economy
        </MenuItem>
          <Divider sx={{ my: 0.5 }} />
        <MenuItem onClick={()=>{handleClose("Premium economy")}} disableRipple>
          Premium economy
        </MenuItem>
        <Divider sx={{ my: 0.5 }} />
        <MenuItem  onClick={()=>{handleClose("Business class")}} disableRipple>
         Business class
        </MenuItem>
          <Divider sx={{ my: 0.5 }} />
        <MenuItem  onClick={()=>{handleClose("First class")}} disableRipple>
         First class
        </MenuItem>

      </StyledMenu>
    </div>
  );
}


// function CabinClasses({handleCabinChange,classes,cabinClass}) {
//   return (
//     <Grid item xs={12} sm={12} md={2}>

//       <Select
//       disableUnderline
//         labelId="demo-simple-select-label"
//         id="demo-simple-select"
//         style={{
//           width: 140,
//           backgroundColor: store.getState().AgencyReducer
//             .agency.branding.sixth
//             ? store.getState().AgencyReducer.agency
//                 .branding.sixth
//             : "white",
//             "font-family": "Rubik",
//             "font-size": "16px",
//             "font-weight": "400",
//             "line-height": "normal",
//               borderRadius: "6px",
//             "text-overflow": "ellipsis",
//           color: "#5F41B2",
//         }}
//         onChange={handleCabinChange}
//         value={cabinClass}
//       >
//       <h2 style={{color: "#362566",
// fontFamily: "Rubik",
// fontSize: "18px",
// fontStyle: "normal",
// fontWeight: "600",
// lineHeight: "normal"}}>Cabin Class </h2>
//         <MenuItem style={{color: "#5F41B2",  "font-family": "Rubik",
//             "font-size": "16px",
//             "font-weight": "400",
//             "line-height": "normal", borderBottom:"1px solid #C4C4C4"}} value={"economy"}>Economy</MenuItem>

//         <MenuItem style={{color: "#5F41B2",  "font-family": "Rubik",
//             "font-size": "16px",
//             "font-weight": "400",
//             "line-height": "normal", borderBottom:"1px solid #C4C4C4"}} value={"premium_economy"}>
//           Premium Economy
//         </MenuItem>
//         <MenuItem style={{color: "#5F41B2",  "font-family": "Rubik",
//             "font-size": "16px",
//             "font-weight": "400",
//             "line-height": "normal", borderBottom:"1px solid #C4C4C4"}} value={"business"}>
//           Business Class
//         </MenuItem>
//         <MenuItem style={{color: "#5F41B2",  "font-family": "Rubik",
//             "font-size": "16px",
//             "font-weight": "400",
//             "line-height": "normal", borderBottom:"1px solid #C4C4C4"}} value={"first"}>First Class</MenuItem>
//       </Select>
//   </Grid>
//   );
// }

