import React, { Component, useState } from "react";
import {
  Card,
  Typography,
  Grid,
  useMediaQuery,
  useTheme,
  Button,
  OutlinedInput,
} from "@mui/material";
import store from "store";
import moment, { duration } from "moment";
import "@fontsource/rubik/600.css";
import "@fontsource/rubik/500.css";
import "@fontsource/rubik/400.css";
import { useNavigate } from "react-router-dom";
import { confirmBooking } from "views/Services/clientServices";
import { checkDiscount } from "views/Services/clientServices";
import SignalWifiStatusbar4BarSharp from "@mui/icons-material/SignalWifiStatusbar4BarSharp";

const LineBreak = ({ style }) => (
  <div
    style={{
      backgroundColor: "#C5C5C5",
      margin: "0 auto",
      height: "1px",
      ...style,
      // width: "90%",
    }}
  ></div>
);
export default function BookingCard({ paymentPage }) {
  const [code, setCode] = useState(false);
  const [status, setStatus] = useState(false);
  const [color, setColor] = useState("#5F41B3");

  const [price, setPrice] = useState(
    store.getState().hotelSearch.hotel.minRate
  );

  const navigate = useNavigate();
  let theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  let {
    checkInDate,
    checkOutDate,
    rooms,
    adults,
    children,
    searchPlace,
  } = store.getState().hotelSearch;
  let duration = moment(checkOutDate).diff(checkInDate, "days");
  checkInDate = checkInDate.toLocaleDateString();
  checkOutDate = checkOutDate.toLocaleDateString();
  console.log(searchPlace);
  return (
    <Grid
      style={{
        width: isMobile ? "90%" : "80%",
        float: "right",
        margin: isMobile && "0 auto",
        marginBottom: 40,
      }}
    >
      <Card
        style={{
          borderRadius: 20,
          marginTop: 10,
          border: "1px solid #C4C4C4",
        }}
      >
        <div
          style={{
            width: "80%",
            paddingTop: 20,
            margin: "0 auto",
          }}
        >
          {[
            {
              title: "Destination",
              value: `${
                searchPlace.country
                  ? store.getState().hotelSearch.hotel.destinationName
                  : store.getState().hotelSearch.hotel.destinationName
              }`,
            },
            { title: "Check-in", value: checkInDate },
            { title: "Check-out", value: checkOutDate },
            {
              title: "Travellers",
              value:
                adults === 1
                  ? "1 adult"
                  : `${adults} adults` + ", " + children === 1
                  ? "1 child"
                  : `${children} children`,
            },
            {
              title: "Rooms",
              value: rooms === 1 ? "1 room" : rooms + " rooms",
            },
          ].map(({ title, value }, index) => (
            <Grid key={index}>
              <Typography
                sx={{
                  fontFamily: "Rubik",
                  fontStyle: "normal",
                  fontWeight: 500,
                  color: "#362566",
                }}
                style={{
                  marginBottom: 0,
                  textAlign: "left",
                }}
              >
                {title}
              </Typography>
              <Typography
                sx={{ pt: 1 }}
                style={{
                  fontFamily: "Rubik",
                  textAlign: "left",
                  fontWeight: 400,
                  color: "#5D5C66",
                }}
              >
                {value}
              </Typography>
              <Grid sx={{ pt: 2, pb: 2 }}>
                <LineBreak />
              </Grid>
            </Grid>
          ))}
        </div>

        {paymentPage ? (
          <Grid container>
            <Grid container>
              <Grid item md={1} />
              <Grid item md={4} sm={4} xs={4} sx={{ pb: 1, pt: 1 }}>
                <Typography
                  style={{
                    fontFamily: "Inter",
                    fontStyle: "normal",
                    fontWeight: 500,
                    fontSize: 16,
                    color: "#5D5C66",
                    textTransform: "none",
                  }}
                >
                  Total price :
                </Typography>
              </Grid>

              <Grid item md={1} />
              <Grid item md={5} sm={12} xs={12} sx={{ pb: 1, pt: 1 }}>
                <Typography
                  sx={{
                    fontFamily: "Rubik",
                    fontStyle: "normal",
                    fontWeight: 600,
                    color: "#5F41B3",
                  }}
                >
                  {store.getState().hotelSearch.hotel.currency + " " + price}
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item md={2} />
            </Grid>
            <Grid container>
              <Grid item md={1} />
              <Grid item md={12} sm={12} xs={12} sx={{ pb: 1, pt: 1 }}>
                <Typography
                  style={{
                    fontFamily: "Inter",
                    fontStyle: "normal",
                    fontWeight: 500,
                    fontSize: 16,
                    color: "#5D5C66",
                    textTransform: "none",
                  }}
                >
                  Add discount code :
                </Typography>
              </Grid>

              <Grid item md={12} sm={12} xs={12} sx={{ pb: 1, pt: 1 }}>
                <OutlinedInput
                  onChange={(text) => {
                    setCode(text.target.value);
                  }}
                  variant="outlined"
                  style={{ borderRadius: 20, width: "80%" }}
                />
                <Button
                  disabled={status}
                  onClick={async () => {
                    const dis = await checkDiscount({
                      url: store.getState().AgencyReducer.agency.url,
                      class: "hotel",
                      code: code,
                      currency: store.getState().hotelSearch.hotel.currency,
                    });
                    setStatus(true);
                    setColor("grey");
                    store.dispatch({
                      type: "setNewPrice",
                      payload: price - dis,
                    });
                    setPrice(price - dis);
                    alert("discount applied !");
                  }}
                  variant
                  contained
                  style={{
                    marginTop: 30,
                    marginBottom: 30,
                    width: "80%",
                    textTransform: "none",
                    background: color,
                    color: "white",
                    borderRadius: 10,
                    fontWeight: 400,
                    fontFamily: "Rubik",
                    fontStyle: "normal",
                    fontSize: "15px",
                    textAlign: "left",
                  }}
                >
                  Add discount
                </Button>
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <div style={{ margin: 10, textAlign: "center" }}>
            <a
              href="/"
              style={{
                fontFamily: "Inter",
                fontWeight: 600,
                color: "#5F41B3",
              }}
            >
              Change
            </a>
          </div>
        )}
      </Card>
    </Grid>
  );
}
