import {
  Box,
  Button,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import SignPhotos from "views/Hotels/pages/LoginModule/SignPhotos";

export default function AgencyResetLink() {
  let theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));

  const history = useNavigate();
  const handleLogIn = (event) => {
    event.preventDefault();
    history("/AgencyLogin");
  };
  const handleResetPassword = (event) => {
    event.preventDefault();
    history("/resetPasswordAgency");
  };

  return (
    <Box sx={{ display: "flex", height: "100vh" }}>
      <Box
        sx={{
          width: isMobile ? "100vw" : "38vw",
          display: "flex",
          alignItems: isMobile ? "start" : "center",
        }}
      >
        {!isMobile && (
          <Box
            sx={{
              margin: { lg: "0.875em 0 0 5em", md: "0.875em 0 0 2em" },
              position: "fixed",
              top: "0",
            }}
          >
            <Typography
              fontFamily="Fredoka"
              fontWeight="600"
              fontSize="2.19rem"
              color="#5F41B2"
              lineHeight="1.2em"
            >
              Trollii
            </Typography>
            <Typography
              fontFamily="Rubik"
              fontWeight="500"
              fontSize="0.875rem"
              color="#362566"
              lineHeight="1.21em"
            >
              Travel Admin Dashboard
            </Typography>
          </Box>
        )}
        <Box
          sx={{
            margin: "0 auto",
            width: "fit-content",
            display: "flex",
            flexDirection: "column",
            width: isMobile ? "91%" : "60%",
            minWidth: "20.5em",
            marginTop: isMobile ? "2.5em" : "0",
          }}
        >
          {!isMobile && (
            <>
              <Typography
                fontFamily="Rubik"
                fontWeight="500"
                fontSize="2.5rem"
                color="#362566"
                lineHeight="1.185em"
                marginBottom="0.2em"
              >
                Don't worry
              </Typography>
              <Typography
                style={{ inlineSize: "80%" }}
                fontFamily="Rubik"
                fontSize="0.875rem"
                color="#5D5C66"
                lineHeight="1.214em"
                marginBottom="2.286em"
              >
                Don't worry, you can reset your password using your email
              </Typography>
            </>
          )}
          <Typography
            fontFamily="Rubik"
            fontWeight="500"
            fontSize="1.25rem"
            color="#362566"
            lineHeight="1.2em"
            marginBottom={isMobile ? "1.6em" : "0.8em"}
          >
            Reset your password
          </Typography>
          {isMobile && (
            <Typography
              fontFamily="Rubik"
              fontWeight="400"
              fontSize="1rem"
              lineHeight="1.1875em"
              color="#5D5C66"
              marginBottom="1em"
            >
              Enter e-mail address using with the account. We'll e-mail a link
              to reset your password.
            </Typography>
          )}
          <label
            style={{
              color: "#5D5C66",
              fontFamily: "Rubik",
              fontWeight: "500",
              fontSize: "0.875rem",
              lineHeight: "1.214em",
              marginBottom: "0.57em",
            }}
          >
            Email
          </label>
          <input
            type="email"
            placeholder="Example@gmail.com"
            style={{
              color: "#5D5C66",
              fontFamily: "Rubik",
              borderRadius: "0.9375em",
              height: "3em",
              border: "1px solid #C4C4C4",
              padding: "1em",
              backgroundColor: "#FBFBFB",
            }}
          />
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              marginTop: "0.4375em",
            }}
          ></Box>
          <Button
            onClick={handleResetPassword}
            style={{
              height: "3em",
              borderRadius: "0.9375em",
              backgroundColor: "#5F41B2",
              color: "#FFFFFF",
              fontFamily: "Inter",
              fontWeight: "700",
              fontSize: "1rem",
              textTransform: "none",
              margin: "2em 0 1em 0",
            }}
          >
            Send reset link
          </Button>

          <p
            onClick={handleLogIn}
            style={{
              textAlign: "center",
              fontFamily: "Inter",
              fontWeight: "600",
              lineHeight: "1.1875em",
              color: "#5F41B2",
              fontSize: "1rem",
              textDecoration: "underline",
              cursor: "pointer",
              textUnderlineOffset: "3px",
            }}
          >
            Back to log in
          </p>
        </Box>
      </Box>
      <SignPhotos isAgency={true} />
    </Box>
  );
}
