import React from "react";
import ANIMATIONS from "constants/animations.js";

function PlaneAnimation() {
  return (  
    <div
    style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
      backgroundColor: "white",
    }}
  >
    {ANIMATIONS.Plane}
  </div>
   
  );
}

export default PlaneAnimation;
