import React from "react";
import store from "store";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';import GridItem from "components/Grid/GridItem.js";
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';import MenuItem from "@material-ui/core/MenuItem";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CabinClasses from "./CabinClasses";
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Select from "@material-ui/core/Select";
import TravellersDropdown from "./TravellersDropdown";

import 'dropdown-select/dist/css/dropdown-select.css';
import LoopIcon from '@mui/icons-material/Loop';import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
const dropdownStyles = makeStyles({
  underline: {
    borderBottom: "0px solid red !important",
    "&:hover": {
      borderBottom: "0px solid rgba(0,0,0,0)"
    }
  }
});
function RouteWays({handleRoute}) {
  const ddnSt = dropdownStyles();

  return (
    <Grid item style={{alignItems:'flex-start',justifyContent:'space-between' , display:'flex' , paddingBottom:'1%'}}>
    
       <FormControl>

      <FormLabel id="demo-row-radio-buttons-group-label"></FormLabel>
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
      >
        <FormControlLabel onClick={handleRoute} value="Two Way" control={<Radio style={{color:"#5F41B2"}} />} label="Round Trip" />
        <FormControlLabel onClick={handleRoute} value="One Way" control={<Radio style={{color:"#5F41B2"}} />} label="One-Way" />
        <FormControlLabel onClick={handleRoute} value="multi city" control={<Radio style={{color:"#5F41B2"}} />} label="Multi-City" />
      
      </RadioGroup>
    </FormControl>
  </Grid>

   
  );
}

export default RouteWays;
