import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { confirmationPage } from "./SignUpService";

const mapDispatchToState = (dispatch) => {
  return {
    setVerificationCode: (
      verificationCode //whenever we call this function we are dispatching this action
    ) => {
      dispatch({
        type: "setVerificationCode",
        verificationCode: verificationCode,
      });
    },
  };
};

const mapStateToProps = (state) => {
  return { signUpInfo: state.SignUpReducer }; //state.initialState is what we grab from redux
  //and signupInfo iswhat we apply this data to
};

const InputCode = ({ length, label, loading, onComplete, signUpInfo }) => {
  const [code, setCode] = useState([...Array(length)].map(() => ""));

  let history = useNavigate();

  const inputs = useRef([]);

  const processInput = async (e, slot) => {
    const num = e.target.value;
    if (/[^0-9]/.test(num)) return;
    const newCode = [...code];
    newCode[slot] = num;
    setCode(newCode);
    if (slot !== length - 1) {
      inputs.current[slot + 1].focus();
    }
    if (newCode.every((num) => num !== "")) {
      onComplete(newCode.join(""));

      let convertedCode = "";
      for (let i = 0; i < newCode.length; i++) {
        convertedCode = convertedCode + newCode[i];
      }

      convertedCode = parseInt(convertedCode);

      if (convertedCode == signUpInfo.verificationCode) {
        try {
          let reqBody = {
            firstName: signUpInfo.firstNameRegister,
            lastName: signUpInfo.lastNameRegister,

            email: signUpInfo.registerEmail,
            password: signUpInfo.passwordReducer,
            verificationCode: signUpInfo.verificationCode,
          };
          let response = await confirmationPage(reqBody);
          history("/businessProfile");
        } catch (e) {
          console.log(e);
          console.log(signUpInfo.verificationCode);
          alert("Invalid Verification Code");
          setCode([...Array(6)].map(() => ""));
        }
      } else {
        alert("Invalid Verification Codee");
        console.log(signUpInfo.verificationCode);

        setCode([...Array(6)].map(() => ""));
      }
    }
  };

  const onKeyUp = (e, slot) => {
    if (e.keyCode === 8 && !code[slot] && slot !== 0) {
      const newCode = [...code];
      newCode[slot - 1] = "";
      setCode(newCode);
      inputs.current[slot - 1].focus();
    }
  };

  return (
    <div>
      <div className="d-flex justify-content-center align-items-center m-5">
        {code.map((num, idx) => {
          return (
            <input
              style={{
                margin: 3,
                textAlign: "center",
              }}
              key={idx}
              type="text"
              inputMode="numeric"
              maxLength={1}
              value={num}
              autoFocus={!code[0].length && idx === 0}
              readOnly={loading}
              onChange={(e) => processInput(e, idx)}
              onKeyUp={(e) => onKeyUp(e, idx)}
              ref={(ref) => inputs.current.push(ref)}
            />
          );
        })}
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToState)(InputCode);
