import React from "react";
import Grid from "@material-ui/core/Grid";
import "react-google-flight-datepicker/dist/main.css";
import Dropdown from "react-bootstrap/Dropdown";
import { Typography } from "@material-ui/core";
import "./style.css";
import "@fontsource/rubik/400.css";

import CounterInput from "./CounterInput";
import PersonIcon from "../../../../assets/img/personIcon.png";
import AddCircleIcon from "../../../../assets/img/add-circle.png";
import ChildrenIcon from "../../../../assets/img/childrenIcon.png";
import store from "store";
import { Divider } from "@mui/material";

export default function Selectors(props) {
  const [rooms, setRooms] = React.useState(1);
  const [adults, setAdults] = React.useState(1);
  const [children, setChildren] = React.useState(0);
  const [infants, setInfants] = React.useState(0);

  return (
    <Grid item >
      <Dropdown>
        <Dropdown.Toggle id="dropdown-basic">
          <Grid container>
            <Grid item  xs={2} sm={1}>
              <img src={PersonIcon} style={{ width: "24px", height: "24px", padding:'0.2rem' }} />
            </Grid>
            <Grid item xs={10} sm={11}>
              <Typography
                sx={{
                  fontFamily: "Rubik",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "16px",
                  color: "#5D5C66",
                  justifyContent: "center !important",
                  alignContent: "center !important",
                }}
              >{`${rooms} ${rooms > 1 ? "Rooms" : "Room"} , ${adults} ${
                adults > 1 ? "Adults" : "Adult"
              } , ${children} ${
                children > 1 ? "Children" : "Child"
              }`}</Typography>
            </Grid>
          </Grid>
        </Dropdown.Toggle>

        <Dropdown.Menu
          style={{
            width: "400px",
            backgroundColor: "white",
            height: "420px",

            borderBottomLeftRadius: "20px",
            borderBottomRightRadius: "20px",
            borderTopLeftRadius: "20px",
            borderTopRightRadius: "20px",
          }}
        >
          <Grid
            container
            style={{
              width: "400px",
              height: "70px",
              justifyContent: "center",
              alignContent: "center",
            }}
          >
            <Grid item md={1} xs={1} sm={1}>
              <img src={PersonIcon} style={{ width: "24px", height: "24px" }} />
            </Grid>

            <Grid item md={9} xs={8} sm={8}>
              <Typography
                style={{
                  fontFamily: "Rubik",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "16px",
                  color: "#5D5C66",
                  width: "11vw",
                  marginLeft: "0.5vw",
                }}
              >{`${rooms} ${rooms > 1 ? "Rooms" : "Room"} , ${adults} ${
                adults > 1 ? "Adults" : "Adult"
              } , ${children} ${
                children > 1 ? "Children" : "Child"
              }`}</Typography>
            </Grid>
          </Grid>
          <Divider />

          <Grid container>
            <Grid item md={1} />
            <Grid item md={11} sm={11} style={{ marginBottom: "3%" }}>
              <Typography
                style={{
                  fontFamily: "Rubik",
                  fontStyle: "normal",
                  fontWeight: "600",
                  fontSize: "18px",
                  lineHeight: "21px",
                  color: "#3B3666",
                  marginTop: "1vw",
                }}
              >
                {" "}
                Room 1
              </Typography>
            </Grid>
          </Grid>

          <Grid container>
            <Grid item md={1} />
            <Grid item md={1} style={{ marginTop: "3%", marginRight: "2%" }}>
              <img
                src={PersonIcon}
                style={{ width: "24px", height: "24px", marginTop: "0.5vw" }}
              />
            </Grid>
            <Grid item md={5} style={{ marginTop: "3%" }}>
              <Typography
                style={{
                  fontFamily: "Rubik",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "16px",
                  lineHeight: "19px",
                  color: "#5D5C66",
                }}
              >
                {" "}
                <p
                  style={{
                    fontFamily: "Rubik",
                    fontStyle: "normal",
                    fontWeight: "400",
                    fontSize: "16px",
                    color: "#3B3666",
                  }}
                >
                  Adults
                </p>
                <p
                  style={{
                    fontFamily: "Rubik",
                    fontStyle: "normal",
                    fontWeight: "400",
                    fontSize: "16px",
                    lineHeight: "19px",
                    color: "#5D5C66",
                  }}
                >
                  12+ years
                </p>
              </Typography>
            </Grid>

            <Grid item md={4} style={{ marginTop: "1vw" }}>
              <CounterInput
                min={1}
                max={adults + children + infants == 6 ? adults : 6}
                onCountChange={(count) => {
                  setAdults(count);
                  store.dispatch({
                    type: "setSearchAdults",
                    payload: count,
                  });
                }}
              />
            </Grid>
          </Grid>
          <Divider />

          <Grid container>
            <Grid item md={1} />
            <Grid item md={1} style={{ marginTop: "3%", marginRight: "2%" }}>
              <img
                src={ChildrenIcon}
                style={{ width: "30px", height: "24px", marginTop: "0.5vw" }}
              />{" "}
            </Grid>
            <Grid item md={5} style={{ marginTop: "3%" }}>
              <Typography
                style={{
                  fontFamily: "Rubik",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "16px",
                  lineHeight: "19px",
                  color: "#5D5C66",
                }}
              >
                {" "}
                <p
                  style={{
                    fontFamily: "Rubik",
                    fontStyle: "normal",
                    fontWeight: "400",
                    fontSize: "16px",
                    color: "#3B3666",
                  }}
                >
                  Children
                </p>
                <p
                  style={{
                    fontFamily: "Rubik",
                    fontStyle: "normal",
                    fontWeight: "400",
                    fontSize: "16px",
                    lineHeight: "19px",
                    color: "#5D5C66",
                  }}
                >
                  1-12 years
                </p>
              </Typography>
            </Grid>

            <Grid item md={4} style={{ marginTop: "1vw" }}>
              <CounterInput
                min={0}
                max={adults + children + infants == 6 ? children : 6}
                onCountChange={(count) => {
                  setChildren(count);
                  store.dispatch({
                    type: "setSearchChildren",
                    payload: count,
                  });
                }}
              />
            </Grid>
          </Grid>
          <Divider />

          <Grid container>
            {/* <Grid item md={1} />
            <Grid item md={1} style={{ marginTop: "3%", marginRight: "2%" }}>
              <img
                src={infantsIcon}
                style={{ width: "35px", height: "35px", marginTop: "0.5vw" }}
              />{" "}
            </Grid> */}
            {/* <Grid item md={5} style={{ marginTop: "3%" }}>
              <Typography
                style={{
                  fontFamily: "Rubik",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "16px",
                  lineHeight: "19px",
                  color: "#5D5C66",
                }}
              >
                {" "}
                <p
                  style={{
                    fontFamily: "Rubik",
                    fontStyle: "normal",
                    fontWeight: "400",
                    fontSize: "16px",
                    color: "#3B3666",
                  }}
                >
                  Infants
                </p>
                <p
                  style={{
                    fontFamily: "Rubik",
                    fontStyle: "normal",
                    fontWeight: "400",
                    fontSize: "16px",
                    lineHeight: "19px",
                    color: "#5D5C66",
                  }}
                >
                  Under 2 years
                </p>
              </Typography>
            </Grid> */}

            {/* <Grid item md={4} style={{ marginTop: "1vw" }}>
              <CounterInput
                min={0}
                max={adults + children + infants == 6 ? infants : 6}
                onCountChange={(count) => {
                  setInfants(count);
                  store.dispatch({
                    type: "setSearchInfants",
                    payload: count,
                  });
                }}
              />
            </Grid> */}
          </Grid>
          <Divider />

          <Grid container style={{ marginTop: "6%" }}>
            <Grid item md={3} />
            <Grid item md={1}>
              <img
                src={AddCircleIcon}
                style={{ width: "24px", height: "24px", textAlign: "center" }}
              />
            </Grid>
            <Grid item md={6}>
              <Typography
                style={{
                  marginTop: "1%",
                  fontFamily: "Inter",
                  fontStyle: "normal",
                  fontWeight: "600",
                  fontSize: "16px",
                  lineHeight: "19px",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "#5F41B3",
                }}
              >
                Add another room
              </Typography>
            </Grid>
            <Grid item md={2} />
          </Grid>
        </Dropdown.Menu>
      </Dropdown>
    </Grid>
  );
}
