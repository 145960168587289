const Styles = {
  Logo: {
    fontFamily: "Nova Square",
    fontStyle: "normal",
    color: "#FFFFFF",
  },
  LandingText: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    color: "#5D5C66 ",
  },
  Entries: {
    backgroundColor: "white",
    padding: "2%",
    boxShadow: "0px 8px 20px rgba(202, 202, 202, 0.7)",
    borderRadius: "2%",
    display: "flex",
    flex: 1,
    flexDirection: window.innerWidth < 720 ? "column" : "row",
  },
};
export default Styles;
