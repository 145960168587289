import Axios from "axios";
import hosts from "../constants/hosts";

let url1 = `${hosts}/travelagency`;
export async function confirmationPage(reqBody) {
  let url = `${url1}/confirmationPage`;

  let response = await Axios.post(url, reqBody);

  return response;
}
export async function launchService(reqBody) {
  let url = `${url1}/launchEmails`;

  let response = await Axios.post(url, reqBody);

  return response;
}
export async function loginService(reqBody) {
  let url = `${url1}/login`;

  let response = await Axios.post(url, reqBody);

  return response;
}

export async function businessTypeService(reqBody, token) {
  let url = `${url1}/businessType`;

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  let response = await Axios.post(url, reqBody, config);

  return response;
}

export async function businessPrivateInfoService(reqBody, token) {
  let url = `${url1}/privateInfo`;

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  let response = await Axios.post(url, reqBody, config);

  return response;
}

export async function businessPublicInfoService(reqBody, token) {
  let url = `${url1}/publicInfo`;

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  let response = await Axios.post(url, reqBody, config);

  return response;
}

export async function businessSocialService(reqBody, token) {
  let url = `${url1}/connectSocials`;

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  let response = await Axios.post(url, reqBody, config);

  return response;
}

export async function chooseProductsService(reqBody, token) {
  let url = `${url1}/selectProductsToSell`;

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  let response = await Axios.post(url, reqBody, config);

  return response;
}
