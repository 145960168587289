import React from "react";
import store from "store";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';import GridItem from "components/Grid/GridItem.js";
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';import MenuItem from "@material-ui/core/MenuItem";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CabinClasses from "./CabinClasses";

import Select from "@material-ui/core/Select";
import 'dropdown-select/dist/css/dropdown-select.css';
import LoopIcon from '@mui/icons-material/Loop';import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
const dropdownStyles = makeStyles({
  underline: {
    borderBottom: "0px solid red !important",
    "&:hover": {
      borderBottom: "0px solid rgba(0,0,0,0)"
    }
  }
});
function RouteWays({route,handleRoute,cabinClass,handleCabinChange,classes}) {
  const ddnSt = dropdownStyles();

  return (
    <Grid item style={{alignItems:'flex-start',justifyContent:'flex-start' , display:'flex' , paddingBottom:'1%'}}>
          <Grid item xs={12} sm={12} md={2}>

 <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        inputProps={{ 'aria-label': 'Without label'  }}
      disableUnderline
        style={{
          width: 140,
          marginTop:"8%",
      
          backgroundColor: store.getState().AgencyReducer
            .agency.branding.sixth
            ? store.getState().AgencyReducer.agency
                .branding.sixth
            : "white",
          color: "black",
        }}
        onChange={handleRoute}
        value={route}
      >
        <MenuItem value={"Two Way"}><SwapHorizIcon style={{paddingRight:'3%'}}/>Round Trip</MenuItem>
        <MenuItem value={"One Way"}>
        <ArrowRightAltIcon style={{paddingRight:'3%'}}/>    One-Way
        </MenuItem>
        <MenuItem value={"Multi City"}>
        <LoopIcon style={{paddingRight:'3%'}}/>  Multi-City
        </MenuItem>
      </Select>
      </Grid>
      <CabinClasses
        handleCabinChange={handleCabinChange}
        classes={classes}
        cabinClass={cabinClass}
      />
  </Grid>

   
  );
}

export default RouteWays;
