import Navbar from "../../Components/NavBar";
import React, { Component, useEffect } from "react";
import LandingText from "./LandingText";
import SearchEntries, { searchSubmit } from "./SearchEntries";
import AnimationHotel from "./AnimationHotel";
import { SearchHotels } from "views/Services/clientServices";
import store from "store";
import PlaneAnimation from "components/CardAnimations/plane";

export default function LandingPage() {
  const [loading, setLoading] = React.useState("home");

  const [defaultView, setDefaultView] = React.useState(true);

  useEffect(() => {
    window.scroll(0, 0);
    searchSubmit(setLoading, true);
    setDefaultView(false);
  }, []);

  return defaultView ? (
    <PlaneAnimation />
  ) : (
    <div>
      <div
        style={{ background: "#E5E5E5", paddingTop: "2%", paddingBottom: "4%" }}
      >
        <Navbar />
        <LandingText />
        <SearchEntries setLoading={setLoading} loading={loading} />
      </div>
      <AnimationHotel loading={loading} />
    </div>
  );
}
