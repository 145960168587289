import React from "react";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import store from "store"
import { Card, Grid } from "@material-ui/core";
import Passenger from "./Passenger.js";
import Child from "./Child";
import Button from "@material-ui/core/Button";
import TravelCard from "./TravelCard.js";

function Instructions({offer,classes,handleSubmit,travellers,update,date,adults,children}) {
  return (  
    offer.slices ? (
        <div>
          <GridContainer
            className={classes.container}
            style={{ backgroundColor: "#F6F7F8" }}
          >
            <GridItem xs={12} sm={7} md={7}>
              <form onSubmit={handleSubmit}>
                <GridItem xs={12}>
                  <h2
                    style={{
                      color: store.getState().AgencyReducer.agency.branding
                        .primaryColor,
                    }}
                  >
                    {" "}
                    Who is Travelling{" "}
                  </h2>
                </GridItem>
                <GridItem xs={11} style={{ color: "#000000" }}>
                  <h6>
                    Passenger Information must be an exact match to
                    government issued Passport you will be travelling with.
                  </h6>
                  <h6>
                    Passengers who turn 16 before the return flight must
                    book as adults and pay the full adult fare.
                  </h6>
                </GridItem>
                {travellers.passengers.map((passenger, index) => {
                  if (index < travellers.adults) {
                    // display adults
                    let temp = index + 1;
                    return (
                      <Card
                        key={index}
                        style={{
                          marginTop: "16px",
                          boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
                          borderRadius: "16px",
                        }}
                      >
                        <Passenger
                          classes={classes}
                          number={temp}
                          name="Adult"
                          id={passenger.id}
                          index={index}
                          update={update}
                          date={date}
                        />
                      </Card>
                    );
                  } else {
                    let temp = index + 1 - travellers.adults;
                    return (
                      <Card
                        style={{
                          marginTop: "16px",
                          boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
                          borderRadius: "16px",
                        }}
                      >
                        <Child
                          classes={classes}
                          number={temp}
                          name={passenger.type}
                          born_on={passenger.born_on}
                          id={passenger.id}
                          index={index}
                          update={this.update}
                          date={date}
                        />
                      </Card>
                    );
                  }
                })}

                <Button
                  size="large"
                  type="submit"
                  style={{
                    color: store.getState().AgencyReducer.agency.branding
                      .primaryColor,
                    marginTop: "16px",
                    boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
                  }}
                  variant="outlined"
                >
                  Proceed to payment{" "}
                </Button>
              </form>
            </GridItem>

            <Grid item ite xs={12} sm={5} md={4} style={{ marginTop: "7%" ,marginLeft:"3%" }}>
              <TravelCard
                offer={offer}
                classes={classes}
                adults={adults}
                children={children}
              />
            </Grid>
          </GridContainer>
        </div>
      ) : (
        <div />
      )
   
  );
}

export default Instructions;
