import React from "react";
import store from "store";
import InputLabel from "@material-ui/core/InputLabel";
  import GridItem from "components/Grid/GridItem.js";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { Grid } from "@material-ui/core";

function CabinClasses({handleCabinChange,classes,cabinClass}) {
  return (
    <Grid item xs={12} sm={12} md={2}>

      <Select
      disableUnderline
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        style={{
          width: 140,
          marginTop:"3rem",
          backgroundColor: store.getState().AgencyReducer
            .agency.branding.sixth
            ? store.getState().AgencyReducer.agency
                .branding.sixth
            : "white",
          color: "black",
        }}
        onChange={handleCabinChange}
        value={cabinClass}
      >
        <MenuItem value={"economy"}>Economy</MenuItem>
        <MenuItem value={"premium_economy"}>
          Premium Economy
        </MenuItem>
        <MenuItem value={"business"}>
          Business Class
        </MenuItem>
        <MenuItem value={"first"}>First Class</MenuItem>
      </Select>
  </Grid>
  );
}

export default CabinClasses;
