import "assets/scss/material-kit-react.scss?v=1.10.0";
import React from "react";
import { Route, Routes } from "react-router-dom";
import AgencyPasswordChange from "views/LoginModule/AgencyPasswordChange";
import AgencyResetPassword from "views/LoginModule/AgencyResetPassword";
import RegisterationBusinessProfile from "../AgencyRegisteration/BusinessProfile";
import RegisterationChooseProducts from "../AgencyRegisteration/ChooseProducts";
import RegisterationInstagram from "../AgencyRegisteration/Instagram";
import RegisterationLaunch from "../AgencyRegisteration/Launch";
import RegisterationTheme from "../AgencyRegisteration/Theme";
import RegisterationTheme1Details from "../AgencyRegisteration/Theme1Details";
import RegisterationTheme2Details from "../AgencyRegisteration/Theme2Details";
import RegisterationTheme3Details from "../AgencyRegisteration/Theme3Details";
import RegisterationVerificationCode from "../AgencyRegisteration/VerificationCode";
import AgencyLogin from "../views/LoginModule/AgencyLogin.js";
import PasswordChange from "../views/LoginModule/PasswordChange.js";
import RegisterationSignUp from "../AgencyRegisteration/SignUp";
import AgencyResetLink from "views/LoginModule/AgencyResetLink";
import LandingResetLink from "views/Hotels/pages/LoginModule/LandingResetLink";
import LandingResetPassword from "views/Hotels/pages/LoginModule/LandingResetPassword";
import LandingSign from "views/Hotels/pages/LoginModule/LandingSign";

function LoginNavigation() {
  return (
    <div>
      <Routes>
        <Route exact path="/AgencyLogin" element={<AgencyLogin />} />
        <Route exact path="/landingSign" element={<LandingSign />} />
        <Route exact path="/changepassword" element={<PasswordChange />} />
        <Route
          exact
          path="/verification"
          element={<RegisterationVerificationCode />}
        />
        <Route
          exact
          path="/businessProfile"
          element={<RegisterationBusinessProfile />}
        />
        <Route
          exact
          path="/chooseProducts"
          element={<RegisterationChooseProducts />}
        />
        <Route exact path="/theme" element={<RegisterationTheme />} />
        <Route
          exact
          path="/theme1Details"
          element={<RegisterationTheme1Details />}
        />
        <Route
          exact
          path="/theme2Details"
          element={<RegisterationTheme2Details />}
        />
        <Route
          exact
          path="/theme3Details"
          element={<RegisterationTheme3Details />}
        />
        <Route exact path="/launch" element={<RegisterationLaunch />} />
        <Route
          exact
          path="/resetPasswordAgency"
          element={<AgencyResetPassword />}
        />
        <Route
          exact
          path="/resetPasswordLanding"
          element={<LandingResetPassword />}
        />
        <Route exact path="/resetLinkAgency" element={<AgencyResetLink />} />
        <Route exact path="/resetLinkLanding" element={<LandingResetLink />} />
        <Route path="/newPassword" element={<AgencyPasswordChange />} />

        <Route exact path="/instagram" element={<RegisterationInstagram />} />
      </Routes>
    </div>
  );
}

export default LoginNavigation;
