import React, { useState } from "react";
import GridItem from "components/Grid/GridItem.js";
import Typography from "@material-ui/core/Typography";
import imgSvg from "../../../assets/img/Svg/undraw_exploring_re_grb8.svg";
import "./illustrationSection.css";
import ANIMATIONS from "constants/animations";

export const IllustrationSection = () => {
  const [ilItems, setIlItems] = useState([
    {
      Title: "Ready when you are",
      description:
        "See where you can travel to right now and find the best deals across thousands of flights",
      img: ANIMATIONS.SearchBar,
    },
    {
      Title: "Plan with confidence",
      description:
        "Stay one step ahead with the latest travel updates and COVID-19 travel insurance",
      img: ANIMATIONS.Thinking,
    },
    {
      Title: "Keep it simple",
      description:
        "No hidden fees. No hidden charges. No funny business. With us, you’ll always know exactly where your money goes. So you can relax before your trip even begins",
      img: ANIMATIONS.Enjoy,
    },
  ]);

  return (
    <div className="il-section">
      {ilItems.map((item, i) => {
        return (
          <div className="il-item" key={i}>
            {item.img}
            <h3>{item.Title}</h3>
            <div className="il-description">{item.description}</div>
          </div>
        );
      })}
    </div>
  );
};
