import * as React from "react";

const CurrencyIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.9802 16.02H11.2202C9.95015 16.02 8.91016 14.95 8.91016 13.64C8.91016 13.23 9.25016 12.89 9.66016 12.89C10.0702 12.89 10.4102 13.23 10.4102 13.64C10.4102 14.13 10.7702 14.52 11.2202 14.52H12.9802C13.3102 14.52 13.5901 14.22 13.5901 13.85C13.5901 13.38 13.4802 13.32 13.1602 13.21L10.3502 12.23C9.39016 11.89 8.92017 11.22 8.92017 10.17C8.92017 8.97 9.86015 8 11.0302 8H12.7902C14.0602 8 15.1002 9.07 15.1002 10.38C15.1002 10.79 14.7602 11.13 14.3502 11.13C13.9402 11.13 13.6002 10.79 13.6002 10.38C13.6002 9.89 13.2402 9.5 12.7902 9.5H11.0302C10.7002 9.5 10.4202 9.8 10.4202 10.17C10.4202 10.64 10.5302 10.7 10.8502 10.81L13.6602 11.79C14.6202 12.13 15.0901 12.8 15.0901 13.85C15.0801 15.04 14.1402 16.02 12.9802 16.02Z"
        fill="#362566"
      />
      <path
        d="M12 16.9508C11.59 16.9508 11.25 16.6108 11.25 16.2008V7.80078C11.25 7.39078 11.59 7.05078 12 7.05078C12.41 7.05078 12.75 7.39078 12.75 7.80078V16.2008C12.75 16.6108 12.41 16.9508 12 16.9508Z"
        fill="#362566"
      />
      <path
        d="M12 22.75C6.07 22.75 1.25 17.93 1.25 12C1.25 6.07 6.07 1.25 12 1.25C17.93 1.25 22.75 6.07 22.75 12C22.75 17.93 17.93 22.75 12 22.75ZM12 2.75C6.9 2.75 2.75 6.9 2.75 12C2.75 17.1 6.9 21.25 12 21.25C17.1 21.25 21.25 17.1 21.25 12C21.25 6.9 17.1 2.75 12 2.75Z"
        fill="#362566"
      />
    </svg>
  );
};

export default CurrencyIcon;
