import { Component } from "react";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

import Booking from "./Booking.js";
import store from "store";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import PersonIcon from "@material-ui/icons/Person";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import BookmarkIcon from "@material-ui/icons/Bookmark";
import SearchIcon from "@material-ui/icons/Search";
import Header from "components/Header/Header.js";
import Cookie from "js-cookie";
import CButton from "components/CustomButtons/Button.js";
import CircularProgress from "@material-ui/core/CircularProgress";
import { loadBookings } from "./MyBookingsFunctional.js";
export class MyBookings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      bookings: [],
      fetching: true,
    };
  }

  async componentDidMount() {
    try {
      const bookings = await loadBookings();
      this.setState({
        bookings: bookings,
        fetching: false,
      });
    } catch (e) {
      console.log(e);
      alert("cannot load bookings");
    }
  }

  render() {
    const classes = this.props.classes;
    const theme = createTheme({
      palette: {
        primary: {
          main: store.getState().AgencyReducer.agency.branding.primaryColor,
        },
      },
      spacing: 1,
    });

    return (
      <div className={classes.main}>
        <div className={classes.raised}>
          <MuiThemeProvider theme={theme}>
            <Header
              brand="Trollii"
              rightLinks={
                <List
                  className={classes.list}
                  style={{ flexDirection: "row", display: "flex" }}
                  dense
                >
                  <ListItem className={classes.listItem}>
                    <CButton
                      color="transparent"
                      onClick={() => {
                        if (Cookie.get("token")) {
                          this.props.history("/mybookings");
                        } else this.props.history("/login");
                      }}
                      className={
                        classes.navLink + " " + classes.socialIconsButton
                      }
                    >
                      <BookmarkIcon style={{ fontSize: "large" }} />
                      View my bookings
                    </CButton>
                  </ListItem>
                  <ListItem className={classes.listItem}>
                    <CButton
                      color="transparent"
                      className={
                        classes.navLink + " " + classes.socialIconsButton
                      }
                      onClick={() => this.props.history("/")}
                    >
                      <SearchIcon style={{ fontSize: "large" }} />
                      Search Flights
                    </CButton>
                  </ListItem>
                  {store.getState().client.client.token != "" ||
                  Cookie.get("token") ? (
                    <ListItem className={classes.listItem}>
                      <CButton
                        color="transparent"
                        className={
                          classes.navLink + " " + classes.socialIconsButton
                        }
                        onClick={() => {
                          Cookie.remove("token");
                          this.props.history("/");
                        }}
                      >
                        <ExitToAppIcon style={{ fontSize: "large" }} />
                        SIGN OUT
                      </CButton>
                    </ListItem>
                  ) : (
                    <ListItem
                      className={classes.listItem}
                      style={{ margin: "5px" }}
                    >
                      <CButton
                        color="transparent"
                        className={
                          classes.navLink + " " + classes.socialIconsButton
                        }
                        onClick={() => this.props.history("/login")}
                      >
                        <PersonIcon style={{ fontSize: "large" }} />
                        SIGN IN
                      </CButton>
                    </ListItem>
                  )}
                </List>
              }
            />

            {this.state.fetching && (
              <div>
                <CircularProgress style={{ color: "#00AEC5" }} />
                <h5 style={{ color: "#00AEC5" }}> Fetching bookings... </h5>
              </div>
            )}

            {this.state.bookings.length > 0 ? (
              <GridContainer className={classes.container}>
                <GridItem>
                  <Typography style={{ color: "#00AEC5" }}>
                    <h2> Bookings </h2>
                  </Typography>
                </GridItem>
                {this.state.bookings.map((booking, index) => {
                  return (
                    <Booking booking={booking} history={this.props.history} />
                  );
                })}
              </GridContainer>
            ) : (
              !this.state.fetching && (
                <h1 style={{ textAlign: "center" }}>
                  You have not made any <b>bookings</b>
                </h1>
              )
            )}
          </MuiThemeProvider>
        </div>
      </div>
    );
  }
}

export default MyBookings;
