import React, { useState } from "react";
import ANIMATIONS from "constants/animations.js";
import Modal from "react-modal";
import store from "store";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Cookie from "js-cookie";
import { forgotPassword } from "views/Services/clientServices";
import { LoginService } from "views/Services/clientServices";
import Cookies from "js-cookie";

function ResetPassword({ open, onCloseModal }) {
  const [email, setEmail] = useState("");
  const [animation, setAnimation] = useState(false);

  return (
    <div className="App" style={{ zIndex: 10 }}>
      <Modal
        isOpen={open}
        onRequestClose={() => onCloseModal(false)}
        contentLabel="My dialog"
        className="mymodal"
        closeTimeoutMS={500}
        style={{
          content: {
            background:
              "linear-gradient(#F5F7FA," +
              store.getState().AgencyReducer.agency.branding.primaryColor +
              ")",
          },
        }}
      >
        {animation ? (
          <div>{ANIMATIONS.ManLoad}</div>
        ) : (
          <div
            style={{
              alignItems: "center",
              textAlign: "center",
              justifyContent: "Center",
            }}
          >
            <AccountCircleOutlinedIcon
              style={{
                height: 100,
                width: 100,
                color: store.getState().AgencyReducer.agency.branding
                  .primaryColor,
              }}
            ></AccountCircleOutlinedIcon>
            <h2
              style={{
                textAlign: "center",
                color: "white",
                fontFamily: "cursive",
              }}
            >
              Reset your password !
            </h2>

            <Box component="form">
              <TextField
                fullWidth
                size="large"
                type="email"
                name="email"
                placeholder=" Please enter your email"
                variant="standard"
                style={{
                  backgroundColor: "white",

                  marginTop: "20px",
                  borderRadius: 8,
                }}
                onChange={(text) => {
                  setEmail(text.target.value);
                }}
              />
            </Box>
            <br />

            <Button
              size="large"
              variant="contained"
              style={{
                borderWidth: 0,
                borderRadius: 15,
                fontSize: "16px",
                backgroundColor: "#4caf50",
                fontFamily: "cursive",
                color: "white",
                width: "220px",
              }}
              onClick={async () => {
                try {
                  let body = {
                    email: email,
                    agency: store.getState().AgencyReducer.agency.url,
                  };
                  setAnimation(true);
                  await forgotPassword(body);
                  setAnimation(false);
                  onCloseModal(false);
                } catch (error) {
                  console.log(error);
                  setAnimation(false);
                  alert("this account isn't registered for us");
                }
              }}
            >
              <div style={{ color: "black" }}>Send Reset Link</div>
            </Button>
          </div>
        )}
      </Modal>
    </div>
  );
}

export default ResetPassword;
