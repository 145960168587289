import React from "react";
import MuiButton from "@material-ui/core/Button";
import APP_COLORS from "constants/themes";

export default function AppButton({ onClick, text, variant }) {
  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        flexDirection: "row",
        justifyContent: "center",
        marginTop: 32,
        marginBottom: 32,
      }}
    >
      <MuiButton
        onClick={onClick}
        style={{ borderRadius: 32, width: "80%", height: 56 }}
        color="primary"
        variant={variant ? variant : "contained"}
      >
        {text}
      </MuiButton>
    </div>
  );
}
