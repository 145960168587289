import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import Autocomplete from "@material-ui/lab/Autocomplete";
import airport from "airportsjs";
import store from "store";
import TextFieldLandingForm from "./TextFieldLandingForm";
export default function PlaceSelector({ selectDestinationAirport }) {
  const options = airport.searchByAirportName(" ");
  const defaultProps = {
    getOptionLabel: (option) => {
      return option.city + "\n ," + option.country;
    },
  };
  return (
    <Grid item xs={10} sm={4} md={12} sx={{ mb: 5 }}>
      <Autocomplete
        required
        disablePortal
        id="combo-box-demo"
        freeSolo
        {...defaultProps}
        options={options}
        autoSelect={false}
        placeholder={"Where to ?sasa"}
        groupBy={(option) => option.country}
        onChange={(s, v) => {
          store.dispatch({
            type: "setSearchPlace",
            payload: v,
          });
        }}
        renderInput={(params) => <TextFieldLandingForm params={params} />}
      />
    </Grid>
  );
}
